import React from 'react';
import { Translate } from 'react-jhipster';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLocalizedLandingPageLogo } from 'app/shared/util/logo-utils';

export const NavDropdown = props => (
  <UncontrolledDropdown nav inNavbar id={props.id}>
    <DropdownToggle nav caret className="align-items-center">
      <FontAwesomeIcon icon={props.icon} />
      <span>{props.name}</span>
    </DropdownToggle>
    <DropdownMenu right style={props.style}>
      {props.children}
    </DropdownMenu>
  </UncontrolledDropdown>
);

export const BrandIcon = props => {
  const { darkMode, ...others } = props;
  return (
    <div {...others} className="brand-icon">
      <img src={getLocalizedLandingPageLogo(darkMode)} alt="Logo" />
    </div>
  );
};

export const Brand = props => (
  <NavbarBrand tag={HashLink} to="/" className="brand-logo">
    <BrandIcon {...props} />
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const OmDigiJag = props => (
  <NavItem>
    {/* {props.useAnchor ? (
      <NavLink tag={HashLink} to={'/#more'} className="d-flex align-items-center">
        <FontAwesomeIcon icon="info-circle" />
        <Translate contentKey="global.menu.about">About Digijag</Translate>
      </NavLink>
    ) : ( */}
    {/* <a href="https://www.projekt-digijag.se" target="_tab" className="d-flex align-items-center nav-link">
      <FontAwesomeIcon icon="info-circle" />
      <Translate contentKey="global.menu.about">About Digijag</Translate>
    </a> */}
    {/* )} */}
  </NavItem>
);

export const Contact = () => (
  <NavItem>
    <NavLink tag={HashLink} to="/#contact" className="d-flex align-items-center">
      <FontAwesomeIcon icon="envelope" />
      <Translate contentKey="global.menu.contact">Contact us</Translate>
    </NavLink>
  </NavItem>
);

export const DashboardLink = () => (
  <NavItem>
    <NavLink tag={HashLink} to="/dashboard" className="d-flex align-items-center">
      <FontAwesomeIcon icon="columns" />
      <Translate contentKey="global.menu.dashboard">Dashboard</Translate>
    </NavLink>
  </NavItem>
);
