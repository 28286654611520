import * as React from 'react';
import CustomVoiceInput from '../../shared-components/CustomVoiceInput';

export const validateIncome = (amount, maxValue, inputStep, excludeAmounts) => {
  let multiplier = Math.round((amount > maxValue ? maxValue : amount) / inputStep);
  let validIncome = multiplier * inputStep;

  while (excludeAmounts && excludeAmounts.indexOf(validIncome) !== -1 && validIncome < maxValue) {
    multiplier = multiplier + 1;
    validIncome = multiplier * inputStep;
  }

  return validIncome;
};

interface IIncomeVoiceInputProps {
  amount: number;
  maxValue: number;
  inputStep: number;
  excludeAmounts?: number[];
  onChange: Function;
}

export class IncomeVoiceInput extends React.Component<IIncomeVoiceInputProps> {
  handleChange = amount => {
    this.props.onChange(parseInt(amount, 10) || 0);
  };

  onBlur = () => {
    const { amount, inputStep, excludeAmounts, maxValue } = this.props;
    const validIncome = validateIncome(amount, maxValue, inputStep, excludeAmounts);
    this.handleChange(validIncome);
  };

  render() {
    const { amount } = this.props;

    return <CustomVoiceInput value={String(amount)} handleChange={this.handleChange} extra={{ onBlur: this.onBlur }} />;
  }
}

export default IncomeVoiceInput;
