import { IApp } from 'app/shared/model/app.model';
import { IAddress } from './address.model';
import { Moment } from 'moment';

export interface IOrganization {
  id?: number;
  name?: string;
  imgUrl?: string;
  licenses?: number;
  availableLicenses?: number;
  legalName?: string;
  organizationNumber?: number;
  billingAddress?: IAddress;
  referenceInformation?: string;
  lastInvoiceNumber?: string;
  lastInvoiceSent?: Moment;
}

export const defaultValue: Readonly<IOrganization> = {};
