import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login, loginRemote } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import { getQueryParam } from 'app/shared/util/queryParamExtractor';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> {
  loginError: boolean;
  errorMessage: any;
  showModal: boolean;
  isAuthenticated: boolean;
}

export interface ILoginState {
  showModal: boolean;
}

export class Login extends React.Component<ILoginProps, ILoginState> {
  state: ILoginState = {
    showModal: this.props.showModal
  };

  componentDidUpdate(prevProps: ILoginProps, prevState) {
    if (this.props !== prevProps) {
      this.setState({ showModal: this.props.showModal });
    }
  }

  handleLogin = (username, password, rememberMe = false) => {
    this.props.login(username, password, rememberMe);
  };

  handleLoginRemote = remoteControlToken => {
    this.props.loginRemote(remoteControlToken);
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  render() {
    // todo make this prettier once design is in place
    const { location, isAuthenticated, account } = this.props; // location from RouteComponentProps
    const { from } = location.state || { from: { pathname: '/', search: location.search } };
    const { fromRemoteLogin } = location.state || { fromRemoteLogin: { pathname: '/dashboard' } };
    const { showModal } = this.state;

    const remoteControlToken = getQueryParam(location, 'remoteControlLogin');

    if (remoteControlToken) {
      this.handleLoginRemote(remoteControlToken);
      return <Redirect to={fromRemoteLogin} />;
    }

    if (!isAuthenticated) {
      return (
        <LoginModal
          showModal={showModal}
          handleLogin={this.handleLogin}
          handleClose={this.handleClose}
          loginError={this.props.loginError}
          errorMessage={this.props.errorMessage}
        />
      );
    }

    if (account.authorities && account.authorities.includes('ROLE_STUDENT')) {
      return <Redirect to="/dashboard" />;
    } else {
      return <Redirect to={from} />;
    }
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  account: authentication.account,
  errorMessage: authentication.errorMessage
});

const mapDispatchToProps = { login, loginRemote };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
