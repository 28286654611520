import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, setFileData } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getAllCommunityAdmins } from 'app/entities/user-data/user-data.reducer';
import { getAllEntities as getAllCommunities } from 'app/entities/community/community.reducer';
import { getEntity, updateEntity, createEntity, setBlob, reset } from './content.reducer';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer } from 'app/shared/util/date-time-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import ImageInput from 'app/custom-components/form-inputs/imageInput';

export interface IContentUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IContentUpdateState {
  isNew: boolean;
  authorId: string;
  communityId: string;
  imgUrl: string;
}

export class ContentUpdate extends React.Component<IContentUpdateProps, IContentUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      authorId: '0',
      communityId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      imgUrl: ''
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAllCommunityAdmins();
    this.props.getAllCommunities();
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined);
  };

  saveEntity = (event, errors, values) => {
    values.createdAt = new Date(values.createdAt);
    values.updatedAt = new Date(values.updatedAt);
    values.auditedAt = new Date(values.auditedAt);

    if (errors.length === 0) {
      const { contentEntity } = this.props;
      const entity = {
        ...contentEntity,
        ...values,
        communities: mapIdList(values.communities),
        imgUrl: this.state.imgUrl || contentEntity.imgUrl
      };

      if (this.state.isNew) {
        this.props.createEntity(entity, '');
      } else {
        this.props.updateEntity(entity);
      }
      this.handleClose();
    }
  };

  handleImageChange = value => {
    this.setState({ imgUrl: value });
  };

  handleClose = () => {
    this.props.history.push('/entity/content');
  };

  render() {
    const { contentEntity, communityAdmins, communities, loading, updating } = this.props;
    const { isNew, imgUrl } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="digijagApp.content.home.createOrEditLabel">
              <Translate contentKey="digijagApp.content.home.createOrEditLabel">Create or edit a Content</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : contentEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="content-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="name">
                    <Translate contentKey="digijagApp.content.name">Name</Translate>
                  </Label>
                  <AvField
                    id="content-name"
                    type="text"
                    name="name"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="description">
                    <Translate contentKey="digijagApp.content.description">Description</Translate>
                  </Label>
                  <AvField
                    id="content-description"
                    type="text"
                    name="description"
                    validate={{
                      maxLength: { value: 1000, errorMessage: translate('entity.validation.maxlength', { max: 1000 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="imgUrlLabel" for="content-imgUrl">
                    <Translate contentKey="digijagApp.content.imgUrl">Image</Translate>
                  </Label>
                  <ImageInput
                    id="content-imgUrl"
                    imgUrl={imgUrl || contentEntity.imgUrl}
                    handleChange={this.handleImageChange}
                    required={false}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="globalLabel" check>
                    <AvInput id="content-global" type="checkbox" className="form-control" name="global" />
                    <Translate contentKey="digijagApp.content.global">Global</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="auditAcceptedLabel" check>
                    <AvInput id="content-auditAccepted" type="checkbox" className="form-control" name="auditAccepted" />
                    <Translate contentKey="digijagApp.content.auditAccepted">Audit Accepted</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="publishedLabel" check>
                    <AvInput id="content-published" type="checkbox" className="form-control" name="published" />
                    <Translate contentKey="digijagApp.content.published">Published</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="createdAtLabel" for="createdAt">
                    <Translate contentKey="digijagApp.content.createdAt">Created At</Translate>
                  </Label>
                  <AvInput
                    id="content-createdAt"
                    type="datetime-local"
                    className="form-control"
                    name="createdAt"
                    value={isNew ? null : convertDateTimeFromServer(this.props.contentEntity.createdAt)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="updatedAtLabel" for="updatedAt">
                    <Translate contentKey="digijagApp.content.updatedAt">Updated At</Translate>
                  </Label>
                  <AvInput
                    id="content-updatedAt"
                    type="datetime-local"
                    className="form-control"
                    name="updatedAt"
                    value={isNew ? null : convertDateTimeFromServer(this.props.contentEntity.updatedAt)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="auditedAtLabel" for="auditedAt">
                    <Translate contentKey="digijagApp.content.auditedAt">Audited At</Translate>
                  </Label>
                  <AvInput
                    id="content-auditedAt"
                    type="datetime-local"
                    className="form-control"
                    name="auditedAt"
                    value={isNew ? null : convertDateTimeFromServer(this.props.contentEntity.auditedAt)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="author.id">
                    <Translate contentKey="digijagApp.content.author">Author</Translate>
                  </Label>
                  <AvInput id="content-author" type="select" className="form-control" name="authorId">
                    <option value="" key="0" />
                    {communityAdmins
                      ? communityAdmins.map(userData => (
                          <option value={userData.id} key={userData.id}>
                            {userData.userLogin}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="content-communities">
                    <Translate contentKey="digijagApp.content.communities">Communities</Translate>
                  </Label>
                  <AvInput
                    id="content-communities"
                    type="select"
                    multiple
                    className="form-control"
                    name="communities"
                    value={contentEntity.communities && contentEntity.communities.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {communities
                      ? communities.map(community => (
                          <option value={community.id} key={community.id}>
                            {community.displayName ? community.displayName : community.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/content" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  communityAdmins: storeState.userData.communityAdmins,
  communities: storeState.community.entities,
  contentEntity: storeState.content.entity,
  loading: storeState.content.loading,
  updating: storeState.content.updating
});

const mapDispatchToProps = {
  getAllCommunityAdmins,
  getAllCommunities,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentUpdate);
