import * as React from 'react';
import { withStyles, createStyles, Theme, Grid, Typography, Box } from '@material-ui/core';
import { Classes } from 'jss';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IWeek } from 'app/shared/model/fixatiden/week.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActivitiesList from './ActivitiesList';
import ActivitiesListContainer from './ActivitiesListContainer';
import { ViewMode } from '../pages/TimePlanningPage';
import ActivitiesListTime from './ActivitiesListTime';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const DaysListStyles = (theme: Theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100% - 90px)'
    },
    dayColumn: {
      '&:not(:first-child)': {
        borderLeft: '1px solid #BABABA'
      }
    },
    dayTitle: {
      position: 'sticky',
      whiteSpace: 'nowrap',
      top: 80,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      padding: '1rem',
      lineHeight: 1,
      zIndex: 1,
      '& .svg-inline--fa': {
        marginRight: 14.5
      },
      '@media print': {
        top: 0
      }
    },
    activitiesList: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    }
  });

interface IDaysListProps {
  classes: Classes;
  week: IWeek;
  onMoveActivity: any;
  viewMode: ViewMode;
}

function DaysList(props: IDaysListProps) {
  const { classes, week, onMoveActivity, viewMode } = props;

  const handleDrop = destDayIdx => e => {
    e.preventDefault();

    if (!e.currentTarget.contains(e.relatedTarget)) {
      e.currentTarget.style.backgroundColor = null;
    }

    const origDayIdx = e.dataTransfer.getData('origDayIdx');
    const activityIdx = e.dataTransfer.getData('activityIdx');
    onMoveActivity(origDayIdx, destDayIdx, activityIdx);
  };

  const handleDragStart = dayIdx => (e, activityIdx) => {
    e.dataTransfer.setData('origDayIdx', `${dayIdx}`);
    e.dataTransfer.setData('activityIdx', `${activityIdx}`);
  };

  const handleDragEnter = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      e.currentTarget.style.backgroundColor = '#293A5F';
    }
  };

  const handleDragLeave = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      e.currentTarget.style.backgroundColor = null;
    }
  };

  return (
    <Grid container spacing={0} wrap="nowrap" className={classes.root}>
      <ActivitiesListContainer noGap>
        {(viewMode === ViewMode.QUARTERS || viewMode === ViewMode.HOURS) && (
          <Box pt="75px" pl={2} pr={3}>
            <ActivitiesListTime viewMode={viewMode as any} offsetTop={139} />
          </Box>
        )}
        {week.days.sort((a: IDay, b: IDay) => a.order - b.order).map((day: IDay, idx: number) => (
          <Grid
            item
            xs
            key={`${week.name}-days-${idx}`}
            className={`${classes.dayColumn} day-col`}
            onDrop={handleDrop(`${idx}`)}
            onDragOver={e => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
          >
            <Typography paragraph className={classes.dayTitle}>
              <FontAwesomeIcon icon={'day-sun' as IconProp} style={{ color: day.color }} />
              {day.name}
            </Typography>
            <div className={classes.activitiesList}>
              <ActivitiesList day={day} viewMode={viewMode as any} onDragStart={handleDragStart(idx)} draggable />
            </div>
          </Grid>
        ))}
      </ActivitiesListContainer>
    </Grid>
  );
}

export default withStyles(DaysListStyles)(DaysList);
