import React from 'react';
import { Classes } from 'jss';
import { ButtonProps } from '@material-ui/core/Button';
import CustomButton from './CustomButton';
import { Translate } from 'react-jhipster';
import { createStyles, Theme, withStyles } from '@material-ui/core';

export const DeclineButtonStyles = ({ breakpoints }: Theme) =>
  createStyles({
    buttonIcon: {
      height: 25,
      width: 25,
      marginRight: 10,
      [breakpoints.down('xs')]: {
        height: 18,
        width: 18
      }
    }
  });

interface IDeclineButtonProps extends ButtonProps {
  classes: Classes;
  onClick?: any;
}

export class DeclineButton extends React.Component<IDeclineButtonProps> {
  render() {
    const { onClick, children, classes, ...otherProps } = this.props;
    return (
      <CustomButton onClick={onClick} color="secondary" {...otherProps}>
        <img src="content/icons/disagree.svg" alt="" className={classes.buttonIcon} />
        {children || <Translate contentKey="entity.action.no">Nej</Translate>}
      </CustomButton>
    );
  }
}

export default withStyles(DeclineButtonStyles)(DeclineButton);
