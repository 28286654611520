import React from 'react';
import { withStyles, createStyles, Grid, Button, Slide, Theme } from '@material-ui/core';
import { Classes } from 'jss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import { IZoomMeeting } from 'app/shared/model/zoom-meeting.model';
import DigiJagConfirmationDialog from '../digijag-dialogs/base-dialogs/DigiJagConfirmationDialog';
import ZoomMultipleMeetingsDialog from '../digijag-dialogs/zoom/ZoomMultipleMeetingsDialog';
import ZoomConfirmMeetingDialog from '../digijag-dialogs/zoom/ZoomConfirmMeetingDialog';
import { translate } from 'react-jhipster';

const ZoomNotificationStyles = ({ breakpoints }: Theme) =>
  createStyles({
    zoomNotificationButton: {
      height: 48,
      margin: '7px 0px 7px 0px',
      padding: '0px 10px 0px 0px',
      borderRadius: 10,
      backgroundColor: '#F2AE40',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        backgroundColor: '#c57b00'
      },
      [breakpoints.down('sm')]: {
        padding: 0,
        minWidth: 0
      }
    },
    avatar: {
      width: 48,
      height: 48,
      margin: 0,
      objectFit: 'contain',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    icon: {
      height: 37,
      width: 37,
      margin: '0 8px'
    },
    text: {
      fontWeight: 700,
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  });

interface IZoomNotificationProps extends StateProps, RouteComponentProps<{}> {
  classes: Classes;
}

interface IZoomNotificationState {
  myMeetingDialogOpen: boolean;
  joinMeetingDialogOpen: boolean;
  multipleMeetingsDialogOpen: boolean;
  selectedZoomMeeting: IZoomMeeting;
}

export class ZoomNotification extends React.Component<IZoomNotificationProps, IZoomNotificationState> {
  constructor(props) {
    super(props);
    this.state = {
      myMeetingDialogOpen: false,
      joinMeetingDialogOpen: false,
      multipleMeetingsDialogOpen: false,
      selectedZoomMeeting: null
    };
  }

  isSingleIncoming = () => this.props.zoomMeetings.length === 1 && this.props.zoomMeetings[0].hostId !== this.props.currentUser.id;
  isSingleOutgoing = () => this.props.zoomMeetings.length === 1 && this.props.zoomMeetings[0].hostId === this.props.currentUser.id;
  isMultiple = () => this.props.zoomMeetings.length > 1;

  onOpenMultipleMeetingDialog = () => {
    const { zoomMeetings, currentUser } = this.props;
    this.setState({
      multipleMeetingsDialogOpen: true
    });
    this.addBackdrop();
  };

  onCloseMultipleMeetingDialog = () => {
    this.setState({ multipleMeetingsDialogOpen: false });
    this.removeBackdrop();
  };

  onOpenJoinMeetingDialog = () => {
    this.setState({
      selectedZoomMeeting: this.props.zoomMeetings[0],
      joinMeetingDialogOpen: true
    });
    this.addBackdrop();
  };

  onCloseJoinMeetingDialog = () => {
    this.setState({ joinMeetingDialogOpen: false });
    this.removeBackdrop();
  };

  onJoinMeeting = meetingId => {
    this.setState({
      myMeetingDialogOpen: false,
      joinMeetingDialogOpen: false,
      multipleMeetingsDialogOpen: false
    });
    this.props.history.push(`/dashboard${translate('digijagApp.routes.meeting')}/${meetingId}`);
  };

  isInMeeting = () => this.props.location.pathname.includes(`/dashboard${translate('digijagApp.routes.meeting')}/`);

  private addBackdrop() {
    const container = window.document.getElementById('dashboard-content-area');
    if (container && !container.className.includes('blur-backdrop')) {
      container.className += ' blur-backdrop';
    }
  }

  private removeBackdrop() {
    const container = window.document.getElementById('dashboard-content-area');
    if (container) {
      container.className = container.className.replace(/blur-backdrop/g, '');
    }
  }

  render() {
    const { classes, zoomMeetings } = this.props;
    const { myMeetingDialogOpen, joinMeetingDialogOpen, multipleMeetingsDialogOpen, selectedZoomMeeting } = this.state;

    return (
      <>
        {!this.isInMeeting() && zoomMeetings && zoomMeetings.length > 0 ? (
          <Route
            render={({ history }) => (
              <>
                <Slide direction="left" in mountOnEnter unmountOnExit>
                  <div>
                    {this.isSingleIncoming() && (
                      <Button onClick={this.onOpenJoinMeetingDialog} className={classes.zoomNotificationButton}>
                        <Grid container alignItems="center" wrap="nowrap">
                          <Grid item>
                            <img
                              src={
                                zoomMeetings[0].hostImgUrl ||
                                'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                              }
                              alt=""
                              className={classes.avatar}
                            />
                          </Grid>
                          <Grid item className={classes.icon}>
                            <img src="content/icons/zoom-logo.svg" alt="" />
                          </Grid>
                          <Grid item className={classes.text}>
                            {zoomMeetings[0].hostName}
                          </Grid>
                        </Grid>
                      </Button>
                    )}
                    {this.isMultiple() && (
                      <Button className={classes.zoomNotificationButton} onClick={this.onOpenMultipleMeetingDialog}>
                        <Grid container alignItems="center">
                          <Grid item className={classes.icon}>
                            <img src="content/icons/zoom-logo.svg" alt="" />
                          </Grid>
                          <Grid item className={classes.text}>
                            {`${zoomMeetings.length} ${translate('global.menu.entities.zoom')}`}
                          </Grid>
                        </Grid>
                      </Button>
                    )}
                    {this.isSingleOutgoing() &&
                      // <Grid container alignItems="center">
                      //   <Grid item className={classes.icon}>
                      //     <img src="content/icons/zoom-logo.svg" />
                      //   </Grid>
                      //   <Grid item className={classes.text}>
                      //     {`Mitt möte`}
                      //   </Grid>
                      // </Grid>
                      null}
                  </div>
                </Slide>

                {/* TODO: Below we should update to 3 new dialogs for zoom call(s) */}

                {/* Join Meeting Dialog */}
                <ZoomConfirmMeetingDialog
                  isOpen={joinMeetingDialogOpen}
                  onClose={this.onCloseJoinMeetingDialog}
                  onConfirm={() => this.onJoinMeeting(selectedZoomMeeting.id)}
                  meeting={selectedZoomMeeting}
                />

                {/* Multiple Meetings Dialog */}
                <ZoomMultipleMeetingsDialog
                  isOpen={multipleMeetingsDialogOpen}
                  onClose={this.onCloseMultipleMeetingDialog}
                  meetings={zoomMeetings}
                />

                {/* Create Meeting Dialog */}
                {/* <ZoomCreateMeetingDialog
                  isOpen={myMeetingDialogOpen}
                  onClose={() => this.setState({ myMeetingDialogOpen: false })}
                  onConfirm={() => history.push(`/dashboard/zoom/${selectedZoomMeeting.meetingId}`)}
                /> */}
              </>
            )}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = ({ authentication, zoom }: IRootState) => ({
  currentUser: authentication.account,
  zoomMeetings: zoom.zoomMeetings
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(
  mapStateToProps,
  null
)(withStyles(ZoomNotificationStyles)(withRouter(ZoomNotification)));
