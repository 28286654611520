const getSimpleVariantsDistribution = (qttyArray, amount, variants, idx) => {
  if (idx >= variants.length) {
    return [qttyArray, amount];
  }

  let qtty = Math.floor(amount / variants[idx]);

  let amountLeft = amount - variants[idx] * qtty;
  qttyArray[idx] = qtty;

  let nextDistribution = getSimpleVariantsDistribution(qttyArray, amountLeft, variants, idx + 1);

  while (nextDistribution[1] > 0 && qtty > 0) {
    qtty = qtty - 1;
    qttyArray[idx] = qtty;
    amountLeft = amount - variants[idx] * qtty;
    nextDistribution = getSimpleVariantsDistribution(qttyArray, amountLeft, variants, idx + 1);
  }

  return nextDistribution;
};

const getAllVariantsDistributions = (distsArray, amount, variants, idx) => {
  if (idx >= variants.length) {
    return distsArray;
  }

  let qtty = Math.floor(amount / variants[idx]);
  let amountLeft = amount - variants[idx] * qtty;
  distsArray[distsArray.length - 1][idx] = qtty;
  distsArray[distsArray.length - 1][variants.length] = amountLeft;

  if (idx === variants.length - 1) {
    return distsArray;
  }

  let nextDistsArray = getAllVariantsDistributions(distsArray, amountLeft, variants, idx + 1);

  while (qtty > 0) {
    nextDistsArray.push(nextDistsArray[nextDistsArray.length - 1].map(x => x));
    qtty = qtty - 1;
    amountLeft = amount - variants[idx] * qtty;
    nextDistsArray[nextDistsArray.length - 1][idx] = qtty;
    nextDistsArray[nextDistsArray.length - 1][variants.length] = amountLeft;
    nextDistsArray = getAllVariantsDistributions(nextDistsArray, amountLeft, variants, idx + 1);
  }

  return nextDistsArray;
};

const getLessModeDistribution = (amount, variants) => {
  const sortedVariants = variants.map(x => x);
  sortedVariants.sort((a, b) => b - a);
  const [distribution, amountLeft] = getSimpleVariantsDistribution([], amount, sortedVariants, 0);

  return amountLeft > 0 ? null : (Object as any).fromEntries(sortedVariants.map((val, idx) => [val, distribution[idx]]));
};

const getMoreModeDistribution = (amount, variants) => {
  const sortedVariants = variants.map(x => x);
  sortedVariants.sort((a, b) => a - b);
  const [distribution, amountLeft] = getSimpleVariantsDistribution([], amount, sortedVariants, 0);

  return amountLeft > 0 ? null : (Object as any).fromEntries(sortedVariants.map((val, idx) => [val, distribution[idx]]));
};

const getDistributionBalance = distribution => {
  let distributionBalanceNote = 0;
  for (let i = 0; i < distribution.length - 2; i++) {
    distributionBalanceNote += Math.abs(distribution[i] - distribution[i + 1]);
  }
  return distributionBalanceNote;
};

const getBalancedModeDistribution = (amount, variants) => {
  const sortedVariants = variants.map(x => x);
  sortedVariants.sort((a, b) => b - a);
  const distributions = getAllVariantsDistributions([new Array(variants.length + 1).fill(0)], amount, sortedVariants, 0);

  const filteredDistributions = distributions.filter(dist => dist[dist.length - 1] === 0);

  if (filteredDistributions.length === 0) {
    return null;
  }

  const distributionResult = filteredDistributions.reduce((elected, candidate) => {
    const electedBalance = getDistributionBalance(elected);
    const candidateBalance = getDistributionBalance(candidate);
    return electedBalance <= candidateBalance ? elected : candidate;
  }, filteredDistributions[0]);

  const distributionValues = distributionResult.slice(0, -1);
  return (Object as any).fromEntries(sortedVariants.map((val, idx) => [val, distributionValues[idx]]));
};

export const getAmountDistribution = (amount, variants, mode) => {
  if (variants.length < 0) {
    return null;
  }

  switch (mode) {
    case 'less':
      return getLessModeDistribution(amount, variants);
    case 'more':
      return getMoreModeDistribution(amount, variants);
    default:
      return getBalancedModeDistribution(amount, variants);
  }
};
