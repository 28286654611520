import * as React from 'react';
import { IZoomMeeting } from 'app/shared/model/zoom-meeting.model';
import { updateMeeting } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { connect } from 'react-redux';
import moment from 'moment';
import { translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { isIOS } from 'react-device-detect';

interface IZoomProps extends StateProps, DispatchProps {
  zoomMeeting: IZoomMeeting;
  zoomSignature: string;
  userDataId: number;
  userName: string;
}

export class ZoomApp extends React.Component<IZoomProps> {
  constructor(props) {
    super(props);
  }

  getPassword = () => {
    const { zoomMeeting } = this.props;
    return zoomMeeting.joinUrl.split('pwd=').pop();
  };

  getLeaveUrl = () =>
    window.location.protocol +
    '//' +
    window.location.host +
    '/#/dashboard' +
    translate('digijagApp.routes.meeting') +
    '/' +
    (this.props.zoomMeeting.hostId === this.props.userDataId ? `${this.props.zoomMeeting.id}/leave` : 'leave');

  componentDidMount() {
    const { zoomMeeting, zoomSignature, userDataId, userName, locale } = this.props;

    const { ZoomMtg } = require('@zoomus/websdk');

    const isHost = zoomMeeting.hostId === userDataId;

    const successCallback =
      isHost && !zoomMeeting.startDate
        ? () => {
            this.props.updateMeeting({
              ...zoomMeeting,
              startDate: moment()
            });

            if (isIOS) {
              window.top.location.href = zoomMeeting.joinUrl;
            }
          }
        : () => {};

    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.0/lib', '/av');

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();

    if (locale === 'sv') {
      ZoomMtg.i18n.load('content/zoom/sv-SV.json', 'sv-SV');
      ZoomMtg.i18n.reload('sv-SV');
      ZoomMtg.reRender({ lang: 'sv-SV' });
    }

    ZoomMtg.init({
      leaveUrl: this.getLeaveUrl(),
      isSupportAV: true,
      success: () => {
        ZoomMtg.join({
          signature: zoomSignature,
          apiKey: zoomMeeting.apiKeyUsed,
          meetingNumber: zoomMeeting.meetingId,
          userName,
          passWord: this.getPassword(),
          success: successCallback,
          error: () => {}
        });
      }
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ locale }: IRootState) => ({
  locale: locale.currentLocale
});

const mapDispatchToProps = { updateMeeting };

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomApp);
