import React from 'react';
import { Button } from '@material-ui/core';
import KeyboardVoiceIcon from '@material-ui/icons/KeyboardVoice';
import { withStyles } from '@material-ui/core/styles';

interface ISpeechToTextButtonProps {
  className?: string;
  language?: string;
  color?: any;
  variant?: any;
  extra?: any;
  classes: any;
  onSpeechEnd(value: string): any;
}

const SpeechToTextButtonStyles = {
  button: {
    color: 'black'
  }
};

interface ISpeechToTextButtonState {
  spokenInput: string;
  recognition: any;
  synth: any;
  speechRecognitionSupported: boolean;
}

class SpeechToTextButton extends React.Component<ISpeechToTextButtonProps, ISpeechToTextButtonState> {
  readonly state = {
    spokenInput: '',
    recognition: undefined,
    synth: undefined,
    speechRecognitionSupported: false
  };

  componentWillMount = () => {
    const myWindow = window as any;
    const SpeechRecognition = myWindow.SpeechRecognition || myWindow.webkitSpeechRecognition;
    const synth = speechSynthesis;
    // Check if speech-to-text is supported by browser
    if ('webkitSpeechRecognition' in window) {
      this.setState({
        recognition: new SpeechRecognition(),
        synth,
        speechRecognitionSupported: true
      });
    }
  };

  componentDidMount = () => {
    const { recognition } = this.state;
    const { language } = this.props;
    if (!this.state.speechRecognitionSupported) return;

    recognition.interimResults = true;
    recognition.lang = language || 'sv-SE';

    recognition.onresult = event => {
      // https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognitionResult/isFinal#Examples
      let { spokenInput } = this.state;
      spokenInput = event.results[0][0].transcript;
      this.setState({
        spokenInput
      });
    };

    recognition.onspeechend = () => {
      const { onSpeechEnd } = this.props;
      const { spokenInput } = this.state;
      recognition.stop();

      onSpeechEnd(spokenInput);
    };

    recognition.onerror = event => {
      // tslint:disable-next-line:no-console
      console.log(`Error occurred in recognition: ${event.error}`);
    };
  };

  recordAudio = () => {
    const { recognition } = this.state;
    recognition.start();
  };

  render() {
    const { className, children, color, variant, classes } = this.props;
    const { recordAudio } = this;
    const { speechRecognitionSupported } = this.state;
    const extraAttrs = this.props.extra || {};
    return speechRecognitionSupported ? (
      <Button
        className={className ? className : classes.button}
        color={color || 'default'}
        variant={variant || 'contained'}
        onClick={recordAudio}
        aria-label="Tryck för att säga det du vill skriva."
        {...extraAttrs}
      >
        {children ? children : <KeyboardVoiceIcon />}
      </Button>
    ) : null;
  }
}

export default withStyles(SpeechToTextButtonStyles)(SpeechToTextButton);
