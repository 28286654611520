import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './user-data.reducer';
import { IUserData } from 'app/shared/model/user-data.model';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IUserDataDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class UserDataDetail extends React.Component<IUserDataDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { userDataEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="digijagApp.userData.detail.title">UserData</Translate> [<b>{userDataEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="profileImage">
                <Translate contentKey="digijagApp.userData.profileImage">Profile image</Translate>
              </span>
            </dt>
            <dd>
              <img src={userDataEntity.userImageUrl} className="profilepic" alt="" />
            </dd>
            <dt>
              <span id="fontSize">
                <Translate contentKey="digijagApp.userData.email">Email</Translate>
              </span>
            </dt>
            <dd>{userDataEntity.userEmail}</dd>
            <dt>
              <span id="firstName">
                <Translate contentKey="digijagApp.userData.firstName">First name</Translate>
              </span>
            </dt>
            <dd>{userDataEntity.userFirstName}</dd>
            <dt>
              <span id="firstName">
                <Translate contentKey="digijagApp.userData.lastName">Last name</Translate>
              </span>
            </dt>
            <dd>{userDataEntity.userLastName}</dd>
            <dd>{userDataEntity.zoomUserId}</dd>
            <dt>
              <span id="phoneNumber">
                <Translate contentKey="digijagApp.userData.phoneNumber">Phone Number</Translate>
              </span>
            </dt>
            <dd>{userDataEntity.phoneNumber}</dd>
            <dt>
              <span id="personalInfo">
                <Translate contentKey="digijagApp.userData.personalInfo">Personal Info</Translate>
              </span>
            </dt>
            <dd>{userDataEntity.personalInfo}</dd>
            <dt>
              <span id="ssn">
                <Translate contentKey="digijagApp.userData.ssn">Ssn</Translate>
              </span>
            </dt>
            <dd>{userDataEntity.ssn}</dd>
            <dt>
              <span id="lastLogin">
                <Translate contentKey="digijagApp.userData.lastLogin">Last Login</Translate>
              </span>
            </dt>
            <dd>
              <TextFormat value={userDataEntity.lastLogin} type="date" format={APP_DATE_FORMAT} />
            </dd>
            <dt>
              <Translate contentKey="digijagApp.userData.user">User</Translate>
            </dt>
            <dd>{userDataEntity.userId ? userDataEntity.userId : ''}</dd>
            <dt>
              <Translate contentKey="digijagApp.userData.apps">Favorite apps</Translate>
            </dt>
            <dd>
              {userDataEntity.apps
                ? userDataEntity.apps.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.name}</a>
                      {i === userDataEntity.apps.length - 1 ? '' : ', '}
                    </span>
                  ))
                : null}
            </dd>
            <dt>
              <Translate contentKey="digijagApp.userData.community">Community</Translate>
            </dt>
            <dd>
              {userDataEntity.communities
                ? userDataEntity.communities.map((val, i) => (
                    <span key={val.id}>
                      <a>{val.name}</a>
                      {i === userDataEntity.communities.length - 1 ? '' : ', '}
                    </span>
                  ))
                : null}
            </dd>
          </dl>
          <Button tag={Link} to="/entity/user-data" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/user-data/${userDataEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ userData }: IRootState) => ({
  userDataEntity: userData.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDataDetail);
