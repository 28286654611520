import React from 'react';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import { withStyles, createStyles, Theme, ListItem } from '@material-ui/core';
import { Classes } from 'jss';
import IDashBoardMenuButton from './DashBoardMenuButton.model';
import DashBoardTopBarMenuDropdown from './DashBoardTopBarMenuDropdown';
import { DASH_BOARD_MENU_BUTTONS } from './DashBoardMenuButtons';
import { Translate, translate } from 'react-jhipster';

export const DashBoardTopBarMenuButtonStyles = ({ breakpoints }: Theme) =>
  createStyles({
    button: {
      color: '#fff',
      padding: '0 1rem',
      width: 'auto',
      height: '100%',
      '&:hover': {
        color: '#e5e5e5',
        backgroundColor: '#790f3d',
        textDecoration: 'none'
      },
      '&:focus': {
        color: '#e5e5e5',
        backgroundColor: '#790f3d',
        textDecoration: 'none'
      },
      [breakpoints.down('xs')]: {
        padding: '0 .5rem'
      }
    },

    activeButton: {
      backgroundColor: '#790f3d'
    },

    icon: {
      width: 'auto',
      height: '1.875rem',
      [breakpoints.down('xs')]: {
        height: '1.375rem'
      },
      ['@media (max-width: 415px)']: {
        height: '1.875rem'
      }
    },

    navigationText: {
      fontSize: '.875rem',
      fontWeight: 600,
      letterSpacing: 0.8,
      padding: '.375rem 0',
      marginLeft: 10,
      [breakpoints.down('xs')]: {
        fontSize: '.75rem',
        letterSpacing: 0.5,
        padding: '.25rem 0'
      },
      ['@media (max-width: 415px)']: {
        display: 'none'
      }
    },

    arrowDownIcon: {
      width: 'auto',
      height: '.5rem',
      marginLeft: 10,
      [breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    parentDiv: {
      position: 'relative'
    }
  });

interface IDashBoardTopBarMenuButtonProps {
  classes: Classes;
  menuButton: IDashBoardMenuButton;
  handleDropdownAction: Function;
  handleToggleMenu: Function;
  handleCloseMenu: Function;
  activeMenu: DASH_BOARD_MENU_BUTTONS;
  isGroupAdmin: boolean;
}

export class DashBoardTopBarMenuButton extends React.Component<IDashBoardTopBarMenuButtonProps> {
  menuButtonRef = React.createRef<HTMLDivElement>();

  render() {
    const { isGroupAdmin, classes, menuButton, handleDropdownAction, handleToggleMenu, handleCloseMenu, activeMenu } = this.props;

    const { menuButtonRef } = this;

    return (
      <div ref={menuButtonRef} className={classes.parentDiv}>
        <ListItem
          button
          className={activeMenu === menuButton.name ? `${classes.button} ${classes.activeButton}` : classes.button}
          onClick={() => handleToggleMenu(menuButton.name)}
          aria-expanded={activeMenu === menuButton.name ? 'true' : 'false'}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <img src={`content/icons/${menuButton.icon}.svg`} alt="" className={classes.icon} />
            <Typography variant="body1" color="inherit" className={classes.navigationText}>
              <Translate contentKey={menuButton.buttonText} />
            </Typography>
            <img src="content/icons/pil_nerat.svg" alt="" className={classes.arrowDownIcon} />
          </Grid>
        </ListItem>
        {activeMenu === menuButton.name ? (
          <DashBoardTopBarMenuDropdown
            menuButtonRef={menuButtonRef}
            dropdownItems={menuButton.dropdownMenuItems}
            handleDropdownAction={handleDropdownAction}
            handleCloseMenu={handleCloseMenu}
            isGroupAdmin={isGroupAdmin}
          />
        ) : null}
      </div>
    );
  }
}

export default withStyles(DashBoardTopBarMenuButtonStyles)(DashBoardTopBarMenuButton);
