import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IExpense } from 'app/shared/model/budgettavlan/expense.model';

interface IDeleteExpenseDialogProps {
  open: boolean;
  expense: IExpense;
  onClose: any;
  onSubmit: any;
}

export class DeleteExpenseDialog extends React.Component<IDeleteExpenseDialogProps> {
  render() {
    const { open, onClose, expense, onSubmit } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="delete-budget-dialog-title" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="delete-budget-dialog-title">
          <FontAwesomeIcon icon="times-circle" />
          Ta bort kostnad
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1" align="center" gutterBottom>
            <FontAwesomeIcon icon="exclamation-triangle" size="2x" />
          </Typography>
          <Typography variant="body1" align="center">
            Är du säker på att du vill ta bort {expense.name}?
          </Typography>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={() => onSubmit(expense.id)} color="primary" startIcon={<FontAwesomeIcon icon="thumbs-up" />}>
            Okej
          </Button>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DeleteExpenseDialog;
