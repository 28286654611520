import React from 'react';
import { withStyles, Button } from '@material-ui/core';

const DialogBackButton = withStyles({
  root: {
    background: '#F4F4F4 !important'
  }
})(Button);

export default DialogBackButton;
