export const getLocalizedLandingPageLogo = (darkMode: boolean): string => {
  const domain = getCurrentDomain();

  if (domain === 'no') {
    return darkMode ? 'content/images/brand-bg-small-text-no.svg' : 'content/images/brand-color-no.svg';
  } else {
    return darkMode ? 'content/images/brand-bw.svg' : 'content/images/brand-color-black-text.svg';
  }
};

export const getLocalizedDashboardLogo = (): string => {
  const domain = getCurrentDomain();

  if (domain === 'no') {
    return 'content/images/brand-white-no.svg';
  } else {
    return 'content/images/brand-bw2.svg';
  }
};

const getCurrentDomain = (): string => {
  const hostname = window.location.hostname;
  const domain = hostname.substring(hostname.lastIndexOf('.') + 1, hostname.length + 1);
  return domain;
};
