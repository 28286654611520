import React from 'react';
import { withStyles, createStyles, Theme, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const SideBarButtonStyles = (theme: Theme) =>
  createStyles({
    formBtn: {
      backgroundColor: 'transparent !important',
      transition: 'all 250ms ease',
      '&:not(:first-child)': {
        borderTop: '1px solid #fff'
      },
      '&:hover, &:focus': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        '& $formBtnIcon': {
          color: `${theme.palette.secondary.main} !important`
        },
        '& $formBtnText': {
          color: '#000 !important'
        }
      }
    },
    formBtnIcon: {
      color: '#fff !important'
    },
    formBtnText: {
      color: '#fff !important'
    }
  });

interface ISideBarButtonProps {
  classes: Classes;
  icon: IconProp;
  text: string;
  onClick: any;
  disabled?: boolean;
}

function SideBarButton(props: React.PropsWithChildren<ISideBarButtonProps>) {
  const { classes, icon, text, onClick, disabled } = props;

  return (
    <ListItem button onClick={onClick} className={classes.formBtn} disabled={disabled}>
      <ListItemIcon className={classes.formBtnIcon}>
        <FontAwesomeIcon icon={icon} />
      </ListItemIcon>
      <ListItemText className={classes.formBtnText}>{text}</ListItemText>
    </ListItem>
  );
}

export default withStyles(SideBarButtonStyles)(SideBarButton);
