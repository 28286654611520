import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { withStyles, createStyles, Theme, Grid } from '@material-ui/core';
import { Classes } from 'jss';
import DashBoardTopBarMenuButton from './DashBoardTopBarMenuButton';
import IDashBoardMenuButton from './DashBoardMenuButton.model';
import DashBoardTopBarMenuSingleLink from './DashBoardTopBarMenuSingleLink';
import { DASH_BOARD_DROP_DOWN_ACTIONS } from './DashBoardDropdownActions';
import { DASH_BOARD_MENU_BUTTONS } from './DashBoardMenuButtons';
import ZoomNotification from '../zoom/zoom-notification';
import { IRootState } from '../../../app/shared/reducers';
import { connect } from 'react-redux';
import { translate } from 'react-jhipster';
import { hasAnyAuthority } from '../../../app/shared/auth/private-route';
import { AUTHORITIES } from '../../../app/config/constants';
import { getLocalizedDashboardLogo } from 'app/shared/util/logo-utils';

export const menuButtons: IDashBoardMenuButton[] = [
  {
    name: DASH_BOARD_MENU_BUTTONS.ENTITIES,
    buttonText: 'digijagApp.dashboard.menuButtons.handle.buttonText',
    icon: 'hantera',
    dropdownMenuItems: [
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.handle.subMenus.user',
        icon: 'users_circle',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ENTITY_USERS
      },
      {
        visibleToGroupAdmin: false,
        name: 'digijagApp.dashboard.menuButtons.handle.subMenus.assistant',
        icon: 'helpers_circle',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ENTITY_HELPERS
      },
      {
        visibleToGroupAdmin: false,
        name: 'digijagApp.dashboard.menuButtons.handle.subMenus.groups',
        icon: 'groups_circle',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ENTITY_COMMUNITIES
      },
      {
        visibleToGroupAdmin: false,
        name: 'digijagApp.dashboard.menuButtons.handle.subMenus.meetings',
        icon: 'zoom_circle',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ENTITY_MEETINGS
      },
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.handle.subMenus.apps',
        icon: 'apps_circle',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ENTITY_APPS
      },
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.handle.subMenus.content',
        icon: 'materials_circle',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ENTITY_CONTENT
      }
    ]
  },
  {
    name: DASH_BOARD_MENU_BUTTONS.HELP,
    buttonText: 'digijagApp.dashboard.menuButtons.help.buttonText',
    icon: 'hjalp_button',
    dropdownMenuItems: [
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.help.subMenus.askForHelp',
        icon: 'hjalp_button',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ASK_FOR_HELP
      },
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.help.subMenus.inviteAssistant',
        icon: 'bjud_in_medhjalpare',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.INVITE_HELPER
      }
    ]
  },
  {
    name: DASH_BOARD_MENU_BUTTONS.SETTINGS,
    buttonText: 'digijagApp.dashboard.menuButtons.settings.buttonText',
    icon: 'installningar_button',
    dropdownMenuItems: [
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.settings.subMenus.addToHomeScreen',
        icon: 'plus_icon',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.ADD_TO_HOME_SCREEN
      },
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.settings.subMenus.changePassword',
        icon: 'byt_losenord',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.CHANGE_PASSWORD
      },
      {
        visibleToGroupAdmin: true,
        name: 'digijagApp.dashboard.menuButtons.settings.subMenus.logOut',
        icon: 'logout_menu',
        action: DASH_BOARD_DROP_DOWN_ACTIONS.LOGOUT
      }
    ]
  }
];

export const DashBoardTopBarStyles = ({ zIndex, breakpoints }: Theme) =>
  createStyles({
    appBar: {
      zIndex: zIndex.drawer + 2,
      height: '4rem',
      [breakpoints.down('sm')]: {
        height: '4rem'
      }
    },

    toolbar: {
      height: '100%'
    },

    brand: {
      marginRight: 'auto',
      marginLeft: 15,
      [breakpoints.down('sm')]: {
        marginLeft: 0
      },
      '& a': {
        color: '#ffffff'
      }
    },

    brandWithText: {
      width: 'auto',
      height: '2rem',
      display: 'inline',
      [breakpoints.down('sm')]: {
        display: 'none'
      }
    },

    brandNoText: {
      width: 'auto',
      height: '2rem',
      display: 'none',
      [breakpoints.down('sm')]: {
        display: 'inline'
      }
    },

    nav: {
      height: '100%',
      marginRight: 30,
      [breakpoints.down('sm')]: {
        marginRight: 0
      }
    },

    navContainer: {
      height: '100%'
    }
  });

interface IDashBoardTopBarProps extends DispatchProps {
  classes: Classes;
  handleDropdownAction: Function;
  disabledMenuButtons?: string[];
  isGroupAdmin: boolean;
  isAnyAdminType: boolean;
}

interface IDashBoardTopBarState {
  activeMenu: DASH_BOARD_MENU_BUTTONS;
}

export function DashBoardTopBar({ classes, handleDropdownAction, disabledMenuButtons, isGroupAdmin, isAnyAdminType }: IDashBoardTopBarProps) {
  const [activeMenu, setActiveMenu] = useState<IDashBoardTopBarState['activeMenu']>(undefined);

  const handleToggleMenu = (menuButton: DASH_BOARD_MENU_BUTTONS) => {
    setActiveMenu(activeMenu === menuButton ? undefined : menuButton);
  };

  const handleCloseMenu = () => {
    setActiveMenu(undefined);
  };

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar color="primary" className={classes.toolbar}>
        <Typography className={classes.brand} variant="h6" color="inherit">
          <a href="/#/">
            {/* <img src="content/images/brain-white.svg" alt="DigiJag" className={classes.brandNoText} /> */}
            <img src={getLocalizedDashboardLogo()} alt="DigiJag" className={classes.brandWithText} />
          </a>
        </Typography>
        <nav aria-label={translate('digijagApp.dashboard.ariaLabels.nav')} className={classes.nav}>
          <Grid container direction="row" justify="flex-end" className={classes.navContainer}>
            <ZoomNotification />
            {isAnyAdminType && <DashBoardTopBarMenuSingleLink buttonText={translate('global.menu.dashboard')} iconName="columns" linkPath="/dashboard" />}
            {menuButtons.map((menuButton: IDashBoardMenuButton, index: number) => (
              <React.Fragment key={index}>
                {!(disabledMenuButtons.indexOf(menuButton.name) > -1) &&
                  {
                    ENTITIES: (
                      <DashBoardTopBarMenuButton
                        menuButton={menuButton}
                        handleDropdownAction={handleDropdownAction}
                        handleToggleMenu={handleToggleMenu}
                        handleCloseMenu={handleCloseMenu}
                        activeMenu={activeMenu}
                        isGroupAdmin={isGroupAdmin}
                      />
                    ),
                    HELP: (
                      <DashBoardTopBarMenuButton
                        menuButton={menuButton}
                        handleDropdownAction={handleDropdownAction}
                        handleToggleMenu={handleToggleMenu}
                        handleCloseMenu={handleCloseMenu}
                        activeMenu={activeMenu}
                        isGroupAdmin={isGroupAdmin}
                      />
                    ),
                    SETTINGS: (
                      <DashBoardTopBarMenuButton
                        menuButton={menuButton}
                        handleDropdownAction={handleDropdownAction}
                        handleToggleMenu={handleToggleMenu}
                        handleCloseMenu={handleCloseMenu}
                        activeMenu={activeMenu}
                        isGroupAdmin={isGroupAdmin}
                      />
                    )
                  }[menuButton.name]}
              </React.Fragment>
            ))}
          </Grid>
        </nav>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  isGroupAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.GROUP_ADMIN]),
  isAnyAdminType: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.GROUP_ADMIN, AUTHORITIES.SUPERADMIN, AUTHORITIES.COMMUNITY_ADMIN])
});

const mapDispatchToProps = {};

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(DashBoardTopBarStyles)(DashBoardTopBar));
