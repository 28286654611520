import { createMuiTheme, darken, lighten } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';
import { convertPxToRem } from '../../../../shared/util/number-utils';

export const customPalette = createPalette({
  primary: {
    light: lighten('#E9FFA2', 0.1),
    main: '#E9FFA2',
    dark: darken('#E9FFA2', 0.1),
    contrastText: '#000000'
  },
  secondary: {
    light: lighten('#3B538B', 0.1),
    main: '#3B538B',
    dark: darken('#3B538B', 0.1),
    contrastText: '#FFFFFF'
  },
  background: {
    default: '#FFFFFF'
  }
});

interface IOverrides extends CoreOverrides {
  MuiToggleButtonGroup?: any;
  MuiToggleButton?: any;
}

export const baseFontSize = 16;

const overrides: IOverrides = {
  MuiCssBaseline: {
    '@global': {
      'html, body': {
        fontFamily: 'Quicksand, sans-serif',
        fontSize: baseFontSize
      },
      body: {
        minHeight: '100vh',
        backgroundImage: 'url(/content/images/bokeh-blue-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        letterSpacing: 0
      },
      a: {
        color: 'inherit',
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline'
        },
        '&.external:after': {
          fontFamily: '"Font Awesome 5 Free"',
          fontWeight: 900,
          content: '" \\F35D"'
        }
      },
      '[draggable]': {
        cursor: 'grab'
      },
      '[role="button"]': {
        cursor: 'pointer'
      },
      '.fa-day-sun, .fa-week-sun': {
        verticalAlign: 'middle',
        fontSize: '1.75rem !important'
      }
    }
  },
  MuiButton: {
    root: {
      borderRadius: 10,
      padding: '1.5rem',
      height: 'auto',
      justifyContent: 'flex-start'
    },
    label: {
      lineHeight: 1,
      textAlign: 'left'
    },
    textSizeSmall: {
      fontSize: '1rem',
      padding: '.5rem 1.5rem',
      height: 'auto'
    },
    contained: {
      border: '2px solid ' + customPalette.secondary.main,
      backgroundColor: '#FFFFFF',
      '&:hover, &:focus': {
        backgroundColor: customPalette.primary.main
      }
    },
    startIcon: {
      marginRight: '1rem'
    },
    containedPrimary: {
      backgroundColor: '#E5E9F7',
      color: '#000000',
      '& .svg-inline--fa': {
        color: customPalette.secondary.main
      },
      '&:hover, &:focus': {
        backgroundColor: customPalette.primary.main
      },
      '&.Mui-disabled': {
        opacity: '50%'
      }
    },
    containedSizeSmall: {
      border: 'none',
      padding: `${convertPxToRem(12, baseFontSize)} ${convertPxToRem(20, baseFontSize)}`,
      boxShadow: '2px 3px 8px #00000026'
    },
    outlined: {
      padding: null
    },
    outlinedSecondary: {
      backgroundColor: customPalette.secondary.main,
      color: customPalette.secondary.contrastText,
      border: `2px solid #FFFFFF !important`,
      '& .svg-inline--fa': {
        color: 'inherit'
      },
      '&:hover, &:focus': {
        backgroundColor: customPalette.primary.main,
        color: customPalette.primary.contrastText
      },
      '&.Mui-disabled': {
        opacity: '50%'
      }
    },
    sizeLarge: {
      padding: '1rem 1.5rem'
    }
  },
  MuiToggleButtonGroup: {
    root: {
      borderRadius: 15
    }
  },
  MuiToggleButton: {
    root: {
      color: 'inherit',
      border: '2px solid #000000',
      backgroundColor: '#FFFFFF',
      borderRadius: 15,
      padding: '.8rem',
      height: 'auto',
      '&$selected': {
        color: 'inherit !important',
        backgroundColor: '#1AB7B9 !important',
        border: '4px solid #000000 !important',
        padding: 'calc(.8rem - 2px)',
        '&:hover': {
          backgroundColor: '#1AB7B9 !important'
        }
      },
      '&:hover': {
        backgroundColor: '#FFFFFF !important'
      }
    },
    label: {
      display: 'inline-block'
    }
  },
  MuiIconButton: {
    root: {
      borderRadius: 0
    },
    colorSecondary: {
      '&:hover, &:focus': {
        backgroundColor: customPalette.primary.main
      }
    },
    edgeEnd: {
      marginRight: 0,
      height: '100%',
      padding: `0 ${convertPxToRem(30, baseFontSize)}`
    }
  },
  MuiCard: {
    root: {
      background: 'rgba(255, 255, 255, .9) 0% 0% no-repeat padding-box',
      borderRadius: 15
    }
  },
  MuiCardHeader: {
    root: {
      padding: '1rem 2rem',
      backgroundColor: customPalette.secondary.main
    },
    content: {
      color: '#FFFFFF',
      fontSize: '1.25rem',
      '& > .svg-inline--fa': {
        color: '#FFFFFF',
        marginRight: '1rem'
      }
    }
  },
  MuiCardContent: {
    root: {
      padding: `${convertPxToRem(20, baseFontSize)} !important`
    }
  },
  MuiBackdrop: {
    root: {
      background: 'transparent linear-gradient(119deg, rgb(59 83 139 / 75%) 0%, rgb(30 42 70 / 75%) 100%) 0% 0% no-repeat padding-box'
    }
  },
  MuiDialog: {
    paper: {
      backgroundColor: 'rgba(255, 255, 255, .5)',
      padding: 0,
      borderRadius: 20
    }
  },
  MuiDialogTitle: {
    root: {
      padding: '1rem 1.5rem !important',
      backgroundColor: customPalette.secondary.main,
      border: '1px solid #FFFFFF',
      borderTopRightRadius: 20,
      borderTopLeftRadius: 20,
      color: '#FFFFFF',
      '& .svg-inline--fa': {
        marginRight: '1rem'
      }
    }
  },
  MuiDialogContent: {
    root: {
      padding: '1.5rem !important',
      background: '#fff !important'
    }
  },
  MuiDialogActions: {
    root: {
      margin: '0 !important',
      padding: '0 !important',
      justifyContent: 'flex-start',
      display: 'block',
      '& button': {
        background: '#FFFFFF',
        padding: '1.5rem',
        borderRadius: 0,
        '& .svg-inline--fa': {
          color: `${customPalette.secondary.main} !important`
        },
        '&:hover, &:focus': {
          background: `${customPalette.primary.main} !important`
        },
        '&.Mui-disabled': {
          '& .svg-inline--fa': {
            opacity: '26%'
          }
        }
      }
    },
    spacing: {
      margin: '0 !important',
      '&:last-child': {
        margin: '0 !important'
      },
      '& > :not(:first-child)': {
        marginLeft: '0',
        borderTop: '1px solid #3B538B'
      }
    }
  },
  MuiSlider: {
    root: {
      height: '1rem',
      padding: '2rem 0'
    },
    thumb: {
      height: 70,
      width: 70,
      lineHeight: 70,
      marginTop: `-${convertPxToRem(17, baseFontSize)}`,
      marginLeft: `-${convertPxToRem(35, baseFontSize)}`,
      backgroundColor: customPalette.primary.main,
      fontSize: '2rem',
      color: '#000000',
      border: '2px solid #000000',
      boxShadow: '0px 3px 6px #00000029',
      '&:before': {
        fontFamily: '"Font Awesome 5 Free"',
        fontWeight: 900,
        content: '" \\F337"'
      },
      '&:focus, &:hover, &$active': {
        boxShadow: '0px 3px 6px #00000029 !important',
        backgroundColor: customPalette.secondary.main
      }
    },
    rail: {
      opacity: 1,
      height: 32,
      backgroundColor: customPalette.primary.main,
      border: '2px solid #000000',
      borderRadius: 15,
      boxShadow: '0px 3px 6px #00000029'
    },
    track: {
      height: 32,
      backgroundColor: customPalette.primary.main,
      backgroundImage: 'linear-gradient(270deg, #7BF7B5 0%, #C0EF00B2 100%)',
      border: '2px solid #000000',
      borderRadius: 15,
      boxShadow: '0px 3px 6px #00000029'
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 15
    },
    input: {
      color: '#000000',
      zIndex: 1,
      maxHeight: '3rem',
      padding: '0 1rem'
    },
    notchedOutline: {
      border: '2px solid #3B538B !important',
      backgroundColor: '#FFFFFF',
      top: 0,
      zIndex: 0,
      '& legend': {
        lineHeight: '0 !important'
      }
    }
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  MuiListItem: {
    root: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      backgroundColor: customPalette.background.default,
      transition: 'none',
      '&$selected': {
        backgroundColor: '#E5E9F7 !important',
        '& + &$selected': {
          borderTop: '1px solid #3B538B'
        }
      }
    },
    container: {
      '&.mv-selected': {
        '& + &.mv-selected': {
          borderTop: '1px solid #3B538B'
        }
      }
    },
    gutters: {
      padding: `${convertPxToRem(24, baseFontSize)} !important`,
      '&.mv-shrink': {
        padding: `${convertPxToRem(18, baseFontSize)} !important`
      }
    },
    button: {
      transition: 'none',
      '&:hover, &:focus': {
        backgroundColor: customPalette.primary.main
      }
    }
  },
  MuiListItemIcon: {
    root: {
      minWidth: '2rem',
      paddingTop: 9,
      color: customPalette.secondary.main,
      '&$selected': {
        color: '#000000'
      }
    }
  },
  MuiListItemText: {
    root: {
      color: customPalette.primary.contrastText,
      wordBreak: 'break-word',
      marginRight: '3rem'
    }
  },
  MuiListItemSecondaryAction: {
    root: {
      height: '100%',
      right: 0
    }
  },
  MuiMenu: {
    paper: {
      borderRadius: 10
    },
    list: {
      border: '1px solid #FFFFFF',
      borderRadius: 10,
      overflow: 'hidden'
    }
  },
  MuiMenuItem: {
    root: {
      backgroundColor: '#293A5F',
      padding: `${convertPxToRem(18, baseFontSize)} !important`,
      color: '#ffffff',
      borderTop: '1px solid #ffffff',
      '& svg': {
        fontSize: '1.4rem',
        verticalAlign: 'bottom',
        marginRight: 15
      },
      '&:hover, &:focus, &$selected': {
        backgroundColor: `${customPalette.primary.main} !important`,
        color: '#000000',
        '& svg': {
          color: customPalette.secondary.main
        },
        '&:before, &:after': {
          content: '',
          boxShadow: 'none !important'
        }
      }
    }
  },
  MuiDivider: {
    root: {
      borderTop: '1px solid #00000060',
      height: 0,
      backgroundColor: 'none'
    }
  },
  MuiAppBar: {
    colorDefault: {
      backgroundColor: customPalette.background.default
    }
  }
};

const customTheme = createMuiTheme({
  typography: {
    fontFamily: ['Quicksand', 'sans-serif'].join(', '),
    fontWeightLight: 700,
    fontWeightRegular: 700,
    fontWeightMedium: 700,
    button: {
      textTransform: 'none',
      fontSize: '1rem'
    }
  },
  shape: {
    borderRadius: 20
  },
  palette: customPalette,
  overrides,
  mixins: {
    toolbar: {
      minHeight: 80
    }
  }
});

export default customTheme;
