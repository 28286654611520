import BudgettavlanApp from './mittvuxenliv/budgettavlan/app';
/* import RelationerApp from './relationer-som-funkar/app'; */
import TidsplaneringApp from './mittvuxenliv/fixatiden/app';

export default {
  budgettavlan: {
    name: 'Budgettavlan',
    component: BudgettavlanApp
  },
  fixatiden: {
    name: 'Fixa tiden',
    component: TidsplaneringApp
  }
  /* relationer: {
    name: 'Relationer som Funkar',
    component: RelationerApp
  } */
};
