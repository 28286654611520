import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, Storage } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { defaultValue, IActivity } from 'app/shared/model/fixatiden/activity.model';
import { storageKey as localStorageKeyWeeks } from '../week/week.reducer';
import { storageKey as localStorageKeyDays } from '../day/day.reducer';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IWeek } from 'app/shared/model/fixatiden/week.model';

export const ACTION_TYPES = {
  FETCH_ACTIVITY_LIST: 'activity/FETCH_ACTIVITY_LIST',
  FETCH_ACTIVITY: 'activity/FETCH_ACTIVITY',
  CREATE_ACTIVITY: 'activity/CREATE_ACTIVITY',
  UPDATE_ACTIVITY: 'activity/UPDATE_ACTIVITY',
  DELETE_ACTIVITY: 'activity/DELETE_ACTIVITY',
  UPDATE_ACTIVITY_LOCAL_STATE: 'activity/UPDATE_ACTIVITY_LOCAL_STATE',
  RESET: 'activity/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IActivity>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type ActivityState = Readonly<typeof initialState>;

// Reducer

export default (state: ActivityState = initialState, action): ActivityState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ACTIVITY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ACTIVITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ACTIVITY):
    case REQUEST(ACTION_TYPES.UPDATE_ACTIVITY):
    case REQUEST(ACTION_TYPES.DELETE_ACTIVITY):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ACTIVITY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ACTIVITY):
    case FAILURE(ACTION_TYPES.CREATE_ACTIVITY):
    case FAILURE(ACTION_TYPES.UPDATE_ACTIVITY):
    case FAILURE(ACTION_TYPES.DELETE_ACTIVITY):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTIVITY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ACTIVITY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ACTIVITY): {
      const newEntitiesArray = state.entities.slice();
      newEntitiesArray.push(action.payload.data);
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        entities: newEntitiesArray
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_ACTIVITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        entities: state.entities.map(entity => (entity.id === action.payload.data.id ? { ...entity, ...action.payload.data } : entity))
      };
    case SUCCESS(ACTION_TYPES.DELETE_ACTIVITY):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {},
        entities: state.entities.filter(entity => entity.id !== action.meta.id)
      };
    case ACTION_TYPES.UPDATE_ACTIVITY_LOCAL_STATE: {
      // When set locally without failure/success status
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        loading: false,
        errorMessage: null,
        entity: action.payload.entity,
        entities: action.payload.updatedEntities
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/tidsplanering/activity';
const localStorageKey = 'tidsplanering/activities';

// Actions

export const getEntities: ICrudGetAllAction<IActivity> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ACTIVITY_LIST,
    payload: axios.get<IActivity>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}?page=0&size=10000`;
  return {
    type: ACTION_TYPES.FETCH_ACTIVITY_LIST,
    payload: axios.get<IActivity>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IActivity> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ACTIVITY,
    payload: axios.get<IActivity>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IActivity> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ACTIVITY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IActivity> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/${entity.id}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ACTIVITY,
    payload: axios.put(requestUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IActivity> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ACTIVITY,
    payload: axios.delete(requestUrl),
    meta: { id }
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

//
// Actions for non-logged in users:
//

export function addIdsToActivitiesBeforeSavingWeek(week: IWeek): IWeek {
  if (!week || !week.days || !week.days.length) {
    return week;
  }
  let nextId = getNextActivityId();

  week.days = week.days.map(day => {
    if (!day || !day.activities || !day.activities.length) {
      return day;
    } else {
      day.activities = day.activities.map(a => {
        a.id = a.id || nextId++;
        return a;
      });
      return day;
    }
  });

  return week;
}

export function addIdsToActivitiesBeforeSavingDay(day: IDay): IDay {
  if (!day || !day.activities || !day.activities.length) {
    return day;
  } else {
    let nextId = getNextActivityId();
    day.activities = day.activities.map(a => {
      a.id = a.id || nextId++;
      return a;
    });
    return day;
  }
}

export function getNextActivityId() {
  const days = getDaysFromLocalStorage();
  const maxActivityIdInDays = getMaxActivityIdFromDays(days);
  const weeks = getWeeksFromLocalStorage();
  const maxActivityIdInWeeks = getMaxActivityIdFromWeeks(weeks);
  return Math.max(maxActivityIdInDays, maxActivityIdInWeeks) + 1;
}

function getMaxActivityIdFromWeeks(weeks: IWeek[]) {
  return weeks && weeks.length ? Math.max(...weeks.map(week => getMaxActivityIdFromDays(week.days))) : 0;
}

function getMaxActivityIdFromDays(days: IDay[]) {
  return days && days.length ? Math.max(...days.map(day => getMaxActivityIdFromDay(day))) : 0;
}

function getMaxActivityIdFromDay(day: IDay) {
  return day.activities && day.activities.length ? Math.max(...day.activities.map(activity => activity.id || 0)) : 0;
}

function getWeeksFromLocalStorage(): IWeek[] {
  const storedObject = window.sessionStorage.getItem(localStorageKeyWeeks);
  return storedObject ? (JSON.parse(storedObject) as IWeek[]) : ([] as IWeek[]);
}

function getDaysFromLocalStorage(): IDay[] {
  const storedObject = window.sessionStorage.getItem(localStorageKeyDays);
  return storedObject ? (JSON.parse(storedObject) as IDay[]) : ([] as IDay[]);
}

function storeEntities(entities: IActivity[]) {
  window.sessionStorage.setItem(localStorageKey, JSON.stringify(entities));
}

function getEntitiesFromLocalStorage(): IActivity[] {
  const storedObject = window.sessionStorage.getItem(localStorageKey);
  return storedObject ? (JSON.parse(storedObject) as IActivity[]) : ([] as IActivity[]);
}

export const createEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  // Add id as max ID in localstorage + 1, or '1' if first object stored
  entity.id = entities.length ? Math.max(...entities.map(o => o.id)) + 1 : 1;
  entities.push(entity);
  storeEntities(entities);
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITY_LOCAL_STATE,
    payload: { entity, updatedEntities: entities }
  };
};

export const updateEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.map(storedEntity => (storedEntity.id === entity.id ? { ...storedEntity, ...entity } : storedEntity));
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITY_LOCAL_STATE,
    payload: { entity, updatedEntities }
  };
};

export const deleteEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.filter(e => e.id !== id);
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITY_LOCAL_STATE,
    payload: {
      entity: null,
      updatedEntities
    }
  };
};

export const getEntitiesNoBackend = () => {
  const entities = getEntitiesFromLocalStorage();
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITY_LOCAL_STATE,
    payload: {
      entity: entities && entities.length ? entities[0] : null,
      updatedEntities: entities
    }
  };
};

export const getEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const entity = entities.find(e => e.id === id);
  return {
    type: ACTION_TYPES.UPDATE_ACTIVITY_LOCAL_STATE,
    payload: {
      entity,
      updatedEntities: entities
    }
  };
};
