import React from 'react';
import { NavLink } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { withStyles, createStyles, Theme, ListItem } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DashBoardTopBarMenuSingleLinkStyles = ({ breakpoints }: Theme) =>
  createStyles({
    button: {
      color: '#fff',
      padding: '0 1rem',
      width: 'auto',
      height: '100%',
      '&:hover': {
        color: '#e5e5e5',
        backgroundColor: '#790f3d',
        textDecoration: 'none'
      },
      '&:focus': {
        color: '#e5e5e5',
        backgroundColor: '#790f3d',
        textDecoration: 'none'
      },
      [breakpoints.down('sm')]: {
        padding: '0 .5rem'
      },
      '& a': {
        color: 'inherit'
      }
    },

    activeButton: {
      backgroundColor: '#790f3d'
    },

    icon: {
      color: 'inherit',
      width: 'auto !important',
      height: '1.875rem',
      [breakpoints.down('sm')]: {
        height: '1.375rem'
      },
      ['@media (max-width: 415px)']: {
        height: '1.875rem'
      }
    },

    navigationText: {
      fontSize: '.875rem',
      fontWeight: 600,
      letterSpacing: 0.8,
      padding: '.375rem 0',
      marginLeft: 10,
      [breakpoints.down('sm')]: {
        fontSize: '.75rem',
        letterSpacing: 0.5,
        padding: '.25rem 0'
      },
      ['@media (max-width: 415px)']: {
        display: 'none'
      }
    }
  });

interface IDashBoardTopBarMenuSingleLinkProps {
  classes: Classes;
  buttonText: string;
  iconName: any;
  linkPath: string;
}

export class DashBoardTopBarMenuSingleLink extends React.Component<IDashBoardTopBarMenuSingleLinkProps> {
  render() {
    const { classes, iconName, linkPath, buttonText } = this.props;

    return (
      <ListItem className={classes.button}>
        <Grid container direction="row" justify="center" alignItems="center">
          <NavLink to={linkPath} className="d-flex align-items-center">
            <FontAwesomeIcon icon={iconName} className={classes.icon} />
            <span className={classes.navigationText}>{buttonText}</span>
          </NavLink>
        </Grid>
      </ListItem>
    );
  }
}

export default withStyles(DashBoardTopBarMenuSingleLinkStyles)(DashBoardTopBarMenuSingleLink);
