import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Col, Row, Table, Alert } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, reset as resetCommunity, searchEntities } from './community.reducer';
import { ICommunity } from 'app/shared/model/community.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { createCommunityZoomMeeting, getMeetingsByCurrentHost, reset as resetZoom } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { getCurrentUserData } from 'app/entities/user-data/user-data.reducer';
// tslint:disable-next-line:no-unused-variable
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { createStyles, Grid, Typography, Zoom } from '@material-ui/core';

export interface ICommunityProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface ICommunityState extends IPaginationBaseState {
  searchStr: string;
}

export class Community extends React.Component<ICommunityProps, ICommunityState> {
  state: ICommunityState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    searchStr: ''
  };

  componentDidMount() {
    this.reset();
    this.props.getCurrentUserData();
    this.props.getMeetingsByCurrentHost();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateSuccess) {
      this.reset();
    }

    // After starting zoom meeting for community, start and join newly created meeting automatically
    if (prevProps.zoomMeeting !== this.props.zoomMeeting && this.props.zoomMeeting.joinUrl && this.props.zoomMeeting.startUrl) {
      this.props.history.push(`/dashboard${translate('digijagApp.routes.meeting')}/${this.props.zoomMeeting.id}`);
    }
  }

  reset = () => {
    this.props.resetCommunity();
    this.props.resetZoom();
    this.setState({ activePage: 1 }, () => {
      this.getEntities();
    });
  };

  sliceText = (text: string, length: number): string => (text ? text.slice(0, length) + '...' : '');

  handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      this.setState({ activePage: this.state.activePage + 1 }, () => this.getEntities());
    }
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => {
        this.reset();
      }
    );
  };

  getEntities = () => {
    event.preventDefault();
    const { activePage, itemsPerPage, sort, order, searchStr } = this.state;
    if (searchStr) {
      this.props.searchEntities(searchStr, activePage - 1, itemsPerPage, `${sort}`);
    } else {
      this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    }
  };

  onChange = e => {
    this.setState({
      searchStr: e.currentTarget.value
    });
  };

  startZoomMeeting = (community: ICommunity) => {
    if (this.props.currentUserData.zoomUserId) {
      this.props.createCommunityZoomMeeting(community.id);
    } else {
      alert(translate('entity.action.askForZoomAccount'));
    }
  };

  createOrStartMeetingButton = (community: ICommunity) => {
    const { hostedMeetings } = this.props;
    {
      /* Zoom meeting exists for the community, replace button with link text */
    }
    const existingMeeting = hostedMeetings.find(meeting => meeting.communityId === community.id);
    if (existingMeeting) {
      return (
        <Link to={`/dashboard${translate('digijagApp.routes.meeting')}/${existingMeeting.id}`}>
          <FontAwesomeIcon icon="video" /> <Translate contentKey="entity.action.zoomMeetingInProgress">Zoom meeting in progress</Translate>
        </Link>
      );
    } else {
      return (
        <Button onClick={() => this.startZoomMeeting(community)} color="primary" size="sm">
          <FontAwesomeIcon icon="video" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.startZoomMeeting">Start Zoom Meeting</Translate>
          </span>
        </Button>
      );
    }
  };

  render() {
    const { communityList, match, zoomError, zoomErrorDetails } = this.props;
    return (
      <div>
        {zoomError && (
          <Alert color="danger">
            <strong>{zoomError}</strong>
            {zoomErrorDetails}
          </Alert>
        )}
        <Grid container direction="row" justify="space-between" alignItems="flex-end" alignContent="center" className={'mb-3'}>
          <h2 id="community-heading">
            <Translate contentKey="digijagApp.community.home.title">Communities</Translate>
          </h2>
          <Grid item>
            <form onSubmit={this.getEntities}>
              <div>
                <label htmlFor="searchbar">
                  <Translate contentKey="digijagApp.community.searchBarLabel">Search for Community</Translate>
                </label>
                <div className={'d-flex'}>
                  <input id="searchbar" autoFocus type="text" className={'form-control'} value={this.state.searchStr} onChange={this.onChange} />
                  <div className={'input-group-append'}>
                    <button className={'btn btn-outline-secondary'}>
                      <Translate contentKey="entity.action.search"> Search</Translate>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Grid>
          <Grid item>
            <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="digijagApp.community.home.createLabel">Create new Community</Translate>
            </Link>
          </Grid>
        </Grid>
        <div className="table-responsive">
          <InfiniteScroll
            pageStart={this.state.activePage}
            loadMore={this.handleLoadMore}
            hasMore={this.state.activePage - 1 < this.props.links.next}
            loader={<div className="loader">Loading ...</div>}
            threshold={0}
            initialLoad={false}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={this.sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('name')}>
                    <Translate contentKey="digijagApp.community.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('description')}>
                    <Translate contentKey="digijagApp.community.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('logoUrl')}>
                    <Translate contentKey="digijagApp.community.logoUrl">Logo Url</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="digijagApp.community.zoom">Zoom</Translate>
                  </th>
                  <th className="hand" onClick={this.sort('organization')}>
                    <Translate contentKey="digijagApp.community.organization">Organization</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {communityList &&
                  communityList.map((community, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <Button tag={Link} to={`${match.url}/${community.id}`} color="link" size="sm">
                          {community.id}
                        </Button>
                      </td>
                      <td>{community.name}</td>
                      <td>{this.sliceText(community.description, 30)}</td>
                      <td>{this.sliceText(community.logoUrl, 30)}</td>
                      <td>{this.createOrStartMeetingButton(community)}</td>
                      <td>{community.organizationName ? community.organizationName : ''}</td>
                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`/entity/user-data?community=${community.id}`} color="info" size="sm">
                            <FontAwesomeIcon icon="user" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.users">View Users</Translate>
                            </span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${community.id}/edit`} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${community.id}/delete`} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ community, authentication, userData, zoom }: IRootState) => ({
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPERADMIN]),
  isCommunityAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.COMMUNITY_ADMIN]),
  communityList: community.entities,
  totalItems: community.totalItems,
  links: community.links,
  entity: community.entity,
  updateSuccess: community.updateSuccess,
  currentUserData: userData.entity,
  zoomMeeting: zoom.zoomMeeting,
  hostedMeetings: zoom.hostedZoomMeetings,
  zoomError: zoom.error,
  zoomErrorDetails: zoom.errorDetails
});

const mapDispatchToProps = {
  getEntities,
  resetCommunity,
  resetZoom,
  createCommunityZoomMeeting,
  getCurrentUserData,
  searchEntities,
  getMeetingsByCurrentHost
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Community));
