import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  SET_LOCATION: 'LOCATION/SET_LOCATION'
};

const initialState = {
  loading: false,
  errorMessage: null,
  currentLocation: null
};

export type LocationState = Readonly<typeof initialState>;

export default (state: LocationState = initialState, action): LocationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SET_LOCATION):
      return {
        ...state,
        errorMessage: null,
        loading: true
      };
    case FAILURE(ACTION_TYPES.SET_LOCATION):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.SET_LOCATION):
      return {
        ...state,
        loading: false,
        currentLocation: action.payload.data
      };
    default:
      return state;
  }
};

const apiUrl = 'api/dashboard';

export const setLocation = (lon, lat) => {
  const requestUrl = `${apiUrl}/${lon}/${lat}`;
  return {
    type: ACTION_TYPES.SET_LOCATION,
    payload: axios.get(requestUrl)
  };
};
