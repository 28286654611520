import React from 'react';
import { withStyles, createStyles, Theme, DialogContent, DialogActions, Button, Grid, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import AcceptButton from 'app/custom-components/form-inputs/AcceptButton';
import { Translate } from 'react-jhipster';

export const InviteHelperSuccessStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogContent: {
      flex: 'none',
      paddingBottom: 50,
      [breakpoints.down('xs')]: {
        padding: 24
      }
    },

    dialogIcon: {
      [breakpoints.down('xs')]: {
        height: 90
      }
    },

    dialogText: {
      fontSize: 18,
      fontWeight: 600,
      textAlign: 'center',
      lineHeight: 1.8,
      marginTop: 30,
      [breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: 1.5
      }
    },

    mailLink: {
      color: '#9a1750'
    },

    dialogActions: {
      margin: 0,
      paddingBottom: 24
    }
  });

interface IInviteHelperSuccessProps {
  classes: Classes;
  handleCloseDialog: Function;
  email: string;
}

export const InviteHelperSuccess: React.SFC<IInviteHelperSuccessProps> = ({ classes, handleCloseDialog, email }) => (
  <React.Fragment>
    <DialogContent className={classes.dialogContent}>
      <Grid container direction="column" justify="center" alignItems="center">
        <img src="content/icons/smile.svg" alt="" className={classes.dialogIcon} />
        <Typography id="dialog-description" variant="h1" color="inherit" className={classes.dialogText}>
          <Translate contentKey="digijagApp.inviteHelper.successDialog.info1" />
          <br />
          <a href={`mailto:${email}`} className={classes.mailLink}>
            {email}
          </a>
          <br />
          <Translate contentKey="digijagApp.inviteHelper.successDialog.info2" />
        </Typography>
      </Grid>
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.buttonGrid}>
        <AcceptButton onClick={() => handleCloseDialog()} />
      </Grid>
    </DialogActions>
  </React.Fragment>
);

export default withStyles(InviteHelperSuccessStyles)(InviteHelperSuccess);
