import React, { useState } from 'react';
import { withStyles, createStyles, Theme, Card, CardContent, Typography, Button, CardHeader, Paper, Box, Grid } from '@material-ui/core';
import { Classes } from 'jss';
import VerticalContainer from '../../shared-components/VerticalContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IWeek } from 'app/shared/model/fixatiden/week.model';
import CreateDayDialog from '../components/dialogs/CreateDayDialog';
import CreateWeekDialog from '../components/dialogs/CreateWeekDialog';
import DefaultActivitiesDialog from '../components/dialogs/DefaultActivitiesDialog';
import { IActivity, defaultActivities } from 'app/shared/model/fixatiden/activity.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const HomePageStyles = (theme: Theme) =>
  createStyles({
    welcomeCard: {
      border: `1px solid #FFFFFF`,
      margin: '0 auto',
      maxWidth: '90%'
    },
    welcomeCardContent: {
      backgroundColor: '#FFFFFF',
      padding: '0 !important'
    },
    welcomeCardButton: {
      '&:not(:first-child)': {
        borderTop: '1px solid #3B538B',
        marginLeft: 0
      },
      '&:hover, &:focus': {
        background: '#E9FFA2 !important;'
      },
      '& .svg-inline--fa': {
        color: '#3B538B !important'
      },
      padding: '1.5rem',
      background: '#FFFFFF',
      borderRadius: 0
    },
    welcomeContent: {
      backgroundColor: '#3c5489',
      alignSelf: 'stretch',
      flexGrow: 1,
      color: '#FFFFFF',
      padding: '3.75rem',
      '& h1': {
        marginBottom: 50
      }
    },
    welcomeIllustration: {
      margin: 'auto auto',
      '& img': {
        width: '60%',
        margin: '0 auto',
        display: 'block'
      }
    },
    list: {
      paddingLeft: '1rem',
      '& li': {
        marginTop: '1rem'
      }
    },
    welcomeButton: {
      marginTop: '1rem'
    }
  });

interface IHomePageProps {
  classes: Classes;
  onCreateWeek: (newWeek: IWeek) => any;
  onCreateDay: (newDay: IDay) => any;
}

interface IHomePageState {
  openStartDialog: boolean;
  createDay: boolean;
  createWeek: boolean;
  addDefaultActivities: boolean;
  newDay?: IDay;
  newWeek?: IWeek;
}

const WelcomeCardHeader = withStyles({
  root: {
    padding: '1rem 1.5rem !important'
  },
  title: {
    fontSize: '1.25rem'
  },
  content: {
    wordBreak: 'break-word',
    hyphens: 'auto',
    '& .svg-inline--fa': {
      marginRight: '.5rem'
    }
  }
})(CardHeader);

function HomePage(props: IHomePageProps) {
  const { onCreateDay, onCreateWeek } = props;
  const [createDayDialogIsOpen, setCreateDayDialogIsOpen] = useState<IHomePageState['createDay']>(false);
  const [createWeekDialogIsOpen, setCreateWeekDialogIsOpen] = useState<IHomePageState['createWeek']>(false);
  const [defaultActivitiesDialogIsOpen, addDefaultActivitiesDialogIsOpen] = useState<IHomePageState['addDefaultActivities']>(false);
  const [newDay, setNewDay] = useState<IHomePageState['newDay']>(null);
  const [newWeek, setNewWeek] = useState<IHomePageState['newWeek']>(null);
  const [openStartDialog, setOpenStartDialog] = useState<IHomePageState['openStartDialog']>(false);

  const handleClose = () => {
    setCreateDayDialogIsOpen(false);
    setCreateWeekDialogIsOpen(false);
    addDefaultActivitiesDialogIsOpen(false);
  };

  const handleCreateDay = (day: IDay) => {
    setNewDay(day);
    setNewWeek(null);
    setCreateDayDialogIsOpen(false);
    addDefaultActivitiesDialogIsOpen(true);
  };

  const handleCreateWeek = (week: IWeek) => {
    setNewWeek(week);
    setNewDay(null);
    setCreateWeekDialogIsOpen(false);
    addDefaultActivitiesDialogIsOpen(true);
  };

  const handleAddDefaultActivitiesResponse = (withDefaultActivities: boolean) => {
    if (newDay) {
      if (withDefaultActivities) {
        newDay.activities = defaultActivities.map((x: IActivity) => ({ ...x }));
      }
      onCreateDay(newDay);
      handleClose();
    } else if (newWeek) {
      if (withDefaultActivities) {
        newWeek.days = newWeek.days.map((weekDay: IDay) => {
          const filledDay: IDay = JSON.parse(JSON.stringify(weekDay));
          filledDay.activities = defaultActivities.map((x: IActivity) => ({ ...x }));
          return filledDay;
        });
      }
      onCreateWeek(newWeek);
      handleClose();
    }
  };

  const { classes } = props;

  return (
    <>
      <VerticalContainer>
        {!openStartDialog &&
          !createDayDialogIsOpen &&
          !createWeekDialogIsOpen &&
          !defaultActivitiesDialogIsOpen && (
            <Grid container justify="center">
              <Grid item xs={10} sm={8} md={6} lg={4}>
                <Card className={classes.welcomeCard} elevation={24}>
                  <WelcomeCardHeader title={<>Nytt schema</>} />

                  <CardContent className={classes.welcomeCardContent}>
                    <Button
                      onClick={() => {
                        setCreateDayDialogIsOpen(true);
                        setCreateWeekDialogIsOpen(false);
                      }}
                      startIcon={<FontAwesomeIcon icon={'day-sun' as IconProp} />}
                      className={classes.welcomeCardButton}
                      fullWidth
                      disableFocusRipple
                    >
                      Dag
                    </Button>
                    <Button
                      onClick={() => {
                        setCreateDayDialogIsOpen(false);
                        setCreateWeekDialogIsOpen(true);
                      }}
                      startIcon={<FontAwesomeIcon icon={'week-sun' as IconProp} />}
                      className={classes.welcomeCardButton}
                      fullWidth
                      disableFocusRipple
                    >
                      Vecka
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
      </VerticalContainer>
      <CreateDayDialog open={createDayDialogIsOpen} onClose={() => setCreateDayDialogIsOpen(false)} onSubmit={handleCreateDay} noBackdrop />
      <CreateWeekDialog open={createWeekDialogIsOpen} onClose={() => setCreateWeekDialogIsOpen(false)} onSubmit={handleCreateWeek} noBackdrop />
      {(newWeek || newDay) && (
        <DefaultActivitiesDialog
          open={defaultActivitiesDialogIsOpen}
          timePlan={newWeek || newDay}
          onClose={() => addDefaultActivitiesDialogIsOpen(false)}
          onSubmit={handleAddDefaultActivitiesResponse}
          noBackdrop
        />
      )}
    </>
  );
}

export default withStyles(HomePageStyles)(HomePage);
