import axios from 'axios';

import { TranslatorContext, Storage } from 'react-jhipster';

export const ACTION_TYPES = {
  SET_LOCALE: 'locale/SET_LOCALE'
};

const initialState = {
  currentLocale: undefined
};

export type LocaleState = Readonly<typeof initialState>;

export default (state: LocaleState = initialState, action): LocaleState => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOCALE:
      const currentLocale = action.locale;
      if (state.currentLocale !== currentLocale) {
        Storage.session.set('locale', currentLocale);
        TranslatorContext.setLocale(currentLocale);
      }
      return {
        currentLocale
      };
    default:
      return state;
  }
};

export const setLocale = locale => async dispatch => {
  if (!Object.keys(TranslatorContext.context.translations).includes(locale)) {
    const response = await axios.get(`i18n/${locale}.json?buildTimestamp=${process.env.BUILD_TIMESTAMP}`);
    TranslatorContext.registerTranslations(locale, response.data);
  }
  dispatch({
    type: ACTION_TYPES.SET_LOCALE,
    locale
  });
};

export const setApplicationLocale = () => async dispatch => {
  const hostName = window.location.hostname.split('.');
  const hostEnding = hostName[hostName.length - 1];
  let applicationLocale = 'sv';
  switch (hostEnding) {
    case 'se':
      applicationLocale = 'sv';
      break;
    case 'no':
      applicationLocale = 'no';
      break;
    case 'com':
      applicationLocale = 'en';
      break;
    default:
  }
  if (!Object.keys(TranslatorContext.context.translations).includes(applicationLocale)) {
    const response = await axios.get(`i18n/${applicationLocale}.json?buildTimestamp=${process.env.BUILD_TIMESTAMP}`);
    TranslatorContext.registerTranslations(applicationLocale, response.data);
  }
  dispatch({
    type: ACTION_TYPES.SET_LOCALE,
    locale: applicationLocale
  });
};
