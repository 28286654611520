import axios from 'axios';
import {
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IUserData, defaultValue } from 'app/shared/model/user-data.model';
import { IApp } from 'app/shared/model/app.model';
import { IUser, defaultValue as userDefaultValue } from 'app/shared/model/user.model';
import { takeScreenshotJpegBlob } from 'app/shared/util/screenshot-utils';

export const ACTION_TYPES = {
  FETCH_USERDATA_LIST: 'userData/FETCH_USERDATA_LIST',
  FETCH_COMMUNITY_ADMINS: 'userData/FETCH_COMMUNITY_ADMINS',
  FETCH_COMMUNITY_LIST: 'userData/FETCH_COMMUNITY_LIST',
  FETCH_USERDATA: 'userData/FETCH_USERDATA',
  FETCH_USER: 'userData/FETCH_USER',
  FETCH_COMMUNITY_MEMBER_DATA: 'userData/FETCH_COMMUNITY_MEMBER_DATA',
  FETCH_LOGGED_USERDATA: 'userData/FETCH_LOGGED_USERDATA',
  FETCH_FAVORITE_APPS: 'userData/FETCH_FAVORITE_APPS',
  CREATE_USERDATA: 'userData/CREATE_USERDATA',
  UPDATE_USERDATA: 'userData/UPDATE_USERDATA',
  DELETE_USERDATA: 'userData/DELETE_USERDATA',
  RESET: 'userData/RESET',
  INVITE_HELPER: 'userData/INVITE_HELPER',
  CALL_FOR_HELP: 'userData/CALL_FOR_HELP'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IUserData>,
  communityAdmins: [] as ReadonlyArray<IUserData>,
  entity: defaultValue,
  communityMember: userDefaultValue,
  communityMemberData: defaultValue,
  favorites: [] as ReadonlyArray<IApp>,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type UserDataState = Readonly<typeof initialState>;

// Reducer

export default (state: UserDataState = initialState, action): UserDataState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_USERDATA_LIST):
    case REQUEST(ACTION_TYPES.FETCH_COMMUNITY_ADMINS):
    case REQUEST(ACTION_TYPES.FETCH_COMMUNITY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_USERDATA):
    case REQUEST(ACTION_TYPES.FETCH_COMMUNITY_MEMBER_DATA):
    case REQUEST(ACTION_TYPES.FETCH_USER):
    case REQUEST(ACTION_TYPES.FETCH_LOGGED_USERDATA):
    case REQUEST(ACTION_TYPES.FETCH_FAVORITE_APPS):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_USERDATA):
    case REQUEST(ACTION_TYPES.UPDATE_USERDATA):
    case REQUEST(ACTION_TYPES.DELETE_USERDATA):
    case REQUEST(ACTION_TYPES.INVITE_HELPER):
      return {
        ...state,
        loading: true,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.CALL_FOR_HELP):
      return {
        ...state,
        loading: true,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_USERDATA_LIST):
    case FAILURE(ACTION_TYPES.FETCH_COMMUNITY_ADMINS):
    case FAILURE(ACTION_TYPES.FETCH_COMMUNITY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_USERDATA):
    case FAILURE(ACTION_TYPES.FETCH_COMMUNITY_MEMBER_DATA):
    case FAILURE(ACTION_TYPES.FETCH_USER):
    case FAILURE(ACTION_TYPES.FETCH_LOGGED_USERDATA):
    case FAILURE(ACTION_TYPES.FETCH_FAVORITE_APPS):
    case FAILURE(ACTION_TYPES.CREATE_USERDATA):
    case FAILURE(ACTION_TYPES.UPDATE_USERDATA):
    case FAILURE(ACTION_TYPES.DELETE_USERDATA):
    case FAILURE(ACTION_TYPES.INVITE_HELPER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.CALL_FOR_HELP):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        updating: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_COMMUNITY_ADMINS):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        communityAdmins: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERDATA_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_COMMUNITY_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        errorMessage: null,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_FAVORITE_APPS):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        favorites: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERDATA):
    case SUCCESS(ACTION_TYPES.FETCH_LOGGED_USERDATA):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_COMMUNITY_MEMBER_DATA):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        communityMemberData: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        communityMember: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_USERDATA):
    case SUCCESS(ACTION_TYPES.UPDATE_USERDATA):
      return {
        ...state,
        errorMessage: null,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_USERDATA):
      return {
        ...state,
        errorMessage: null,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.INVITE_HELPER):
      return {
        ...state,
        errorMessage: null,
        updating: false,
        updateSuccess: true
      };
    case SUCCESS(ACTION_TYPES.CALL_FOR_HELP):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: true,
        updating: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/user-data';

// Actions

export const getEntities: ICrudGetAllAction<IUserData> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERDATA_LIST,
    payload: axios.get<IUserData>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const searchEntities = (searchterm, page, size, sort) => {
  const requestUrl = `${apiUrl}/search/${searchterm}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERDATA_LIST,
    payload: axios.get<IUserData>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllCommunityAdmins = () => {
  const requestUrl = `${apiUrl}/communityadmins`;
  return {
    type: ACTION_TYPES.FETCH_COMMUNITY_ADMINS,
    payload: axios.get<IUserData>(`${requestUrl}?cacheBuster=${new Date().getTime()}`)
  };
};

export const getFavoriteApps = () => {
  const requestUrl = `${apiUrl}/favorites`;
  return {
    type: ACTION_TYPES.FETCH_FAVORITE_APPS,
    payload: axios.get<IUserData>(`${requestUrl}?cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesByCommunity = (communityId, page, size, sort) => {
  const requestUrl = `${apiUrl}/community/${communityId}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_COMMUNITY_LIST,
    payload: axios.get<IUserData>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const searchEntitiesByCommunity = (communityId, searchterm, page, size, sort) => {
  const requestUrl = `${apiUrl}/community/${communityId}/search/${searchterm}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_COMMUNITY_LIST,
    payload: axios.get<IUserData>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IUserData> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USERDATA,
    payload: axios.get<IUserData>(requestUrl)
  };
};

export const getEntityByUserId: ICrudGetAction<IUserData> = userId => {
  const requestUrl = `${apiUrl}/user/${userId}`;
  return {
    type: ACTION_TYPES.FETCH_COMMUNITY_MEMBER_DATA,
    payload: axios.get<IUserData>(requestUrl)
  };
};

export const getCommunityMember: ICrudGetAction<IUser> = userId => {
  const requestUrl = `api/user/${userId}`;
  return {
    type: ACTION_TYPES.FETCH_USER,
    payload: axios.get<IUser>(requestUrl)
  };
};

export const getCurrentUserData = () => {
  const requestUrl = `${apiUrl}/current`;
  return {
    type: ACTION_TYPES.FETCH_LOGGED_USERDATA,
    payload: axios.get<IUserData>(requestUrl)
  };
};

export const inviteHelper: ICrudPutAction<string> = email => async dispatch => {
  const requestUrl = `${apiUrl}/invite-helper?email=${email}`;
  const result = await dispatch({
    type: ACTION_TYPES.INVITE_HELPER,
    payload: axios.post(requestUrl)
  });
  return result;
};

export const callForHelp: ICrudPutAction<string> = url => async dispatch => {
  const pathElements = url.split('#');
  const path = encodeURIComponent(pathElements[pathElements.length - 1]); // Getting the "dashboard/apps/1" part from "www.digijag.se/#/dashboard/apps/1"
  const requestUrl = `${apiUrl}/call-for-help?path=${path}`;
  const result = await dispatch({
    type: ACTION_TYPES.CALL_FOR_HELP,
    payload: axios.put(requestUrl, {})
  });
  return result;
};

export const callForHelpWithScreenshot = url => async dispatch => {
  const pathElements = url.split('#');
  const path = encodeURIComponent(pathElements[pathElements.length - 1]); // Getting the "dashboard/apps/1" part from "www.digijag.se/#/dashboard/apps/1"
  const requestUrl = `${apiUrl}/call-for-help?path=${path}`;

  const screenshot: Blob = await takeScreenshotJpegBlob();
  const formData = new FormData();
  formData.append('screenshot', screenshot);
  formData.append('url', url);

  const result = await dispatch({
    type: ACTION_TYPES.CALL_FOR_HELP,
    payload: axios.post(requestUrl, formData)
  });
  return result;
};

export const createEntity: ICrudPutAction<IUserData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USERDATA,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IUserData> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USERDATA,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IUserData> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USERDATA,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
