import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogBackButton from '../DialogBackButton';
import { IWeek, emptyWeek } from 'app/shared/model/fixatiden/week.model';
import CustomVoiceInput from '../../../shared-components/CustomVoiceInput';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ICreateWeekDialogProps {
  open: boolean;
  onClose: any;
  onSubmit: (newWeek: IWeek) => any;
  noBackdrop?: boolean;
}

interface ICreateWeekDialogState {
  name: string;
}

function CreateWeekDialog(props: ICreateWeekDialogProps) {
  const { open, onClose, onSubmit, noBackdrop } = props;
  const [name, setName] = useState<ICreateWeekDialogState['name']>('');

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newWeek: IWeek = { ...JSON.parse(JSON.stringify(emptyWeek)), name };
    onSubmit(newWeek);
    setName('');
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="create-week-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{ open: !noBackdrop }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="create-week-dialog-title">
          <FontAwesomeIcon icon={'week-sun' as IconProp} />
          Ge schemat namn
        </DialogTitle>
        <DialogContent>
          <CustomVoiceInput value={name} handleChange={handleChangeName} extra={{ placeholder: 'Ge veckan namn' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} startIcon={<FontAwesomeIcon icon="thumbs-up" />} disabled={!name} fullWidth disableFocusRipple>
            Okej
          </Button>
          <DialogBackButton onClick={onClose} startIcon={<FontAwesomeIcon icon="arrow-circle-left" />} fullWidth>
            Tillbaka
          </DialogBackButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateWeekDialog;
