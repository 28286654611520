import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getAllCommunityAdmins } from 'app/entities/user-data/user-data.reducer';
import { getEntity, getEntities, updateEntity, createEntity, reset } from './app.reducer';
import { getAllEntities as getAllCommunities } from 'app/entities/community/community.reducer';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer } from 'app/shared/util/date-time-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import ImageInput from 'app/custom-components/form-inputs/imageInput';
import {
  GAME_CATEGORY,
  KIDS_CATEGORY,
  SCHOOL_CATEGORY,
  FOOD_CATEGORY,
  ECONOMY_CATEGORY,
  NEWS_CATEGORY,
  VIDEO_CATEGORY,
  HEALTH_CATEGORY,
  SCHOOL_2_CATEGORY,
  SOCIETY_CATEGORY
} from 'app/shared/model/category';

export interface IAppUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAppUpdateState {
  isNew: boolean;
  authorId: string;
  communityId: string;
  imgUrl: string;
}

export class AppUpdate extends React.Component<IAppUpdateProps, IAppUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      authorId: '0',
      communityId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      imgUrl: ''
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAllCommunityAdmins();
    this.props.getAllCommunities();
    this.props.getEntities();
  }

  saveEntity = (event, errors, values) => {
    values.auditedAt = new Date(values.auditedAt);

    if (errors.length === 0) {
      const { appEntity } = this.props;
      const entity = {
        ...appEntity,
        ...values,
        communities: mapIdList(values.communities),
        imgUrl: this.state.imgUrl || appEntity.imgUrl
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
      this.handleClose();
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/app');
  };

  validateName = (value: string) => {
    const { apps } = this.props;
    if (!value || value.length < 1) {
      return false;
    } else if (this.state.isNew && apps.find(a => a.name.toLowerCase() === value.toLowerCase())) {
      return false;
    }
    return true;
  };

  handleImageChange = value => {
    this.setState({ imgUrl: value });
  };

  render() {
    const { appEntity, loading, updating, communityAdmins, communities } = this.props;
    const { isNew, imgUrl } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="digijagApp.app.home.createOrEditLabel">
              <Translate contentKey="digijagApp.app.home.createOrEditLabel">Create or edit a App</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : appEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="app-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="app-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="app-name">
                    <Translate contentKey="digijagApp.app.name">Name</Translate>
                  </Label>
                  <AvField id="app-name" type="text" name="name" validate={{ asynch: this.validateName }} />
                </AvGroup>
                <AvGroup>
                  <Label id="urlLabel" for="app-url">
                    <Translate contentKey="digijagApp.app.url">Url</Translate>
                  </Label>
                  <AvField
                    id="app-url"
                    type="text"
                    name="url"
                    validate={{
                      required: { value: true, errorMessage: translate('entity.validation.required') },
                      maxLength: { value: 1000, errorMessage: translate('entity.validation.maxlength', { max: 1000 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="app-description">
                    <Translate contentKey="digijagApp.app.description">Description</Translate>
                  </Label>
                  <AvField
                    id="app-description"
                    type="text"
                    name="description"
                    validate={{
                      maxLength: { value: 10000, errorMessage: translate('entity.validation.maxlength', { max: 10000 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="imgUrlLabel" for="app-imgUrl">
                    <Translate contentKey="digijagApp.app.image.imgUrl">Img Url</Translate>
                  </Label>
                  <ImageInput id="app-imgUrl" imgUrl={imgUrl || appEntity.imgUrl} handleChange={this.handleImageChange} required={false} />
                </AvGroup>
                <legend>
                  <Translate contentKey={'digijagApp.appStore.categories'}>Kategorier</Translate>
                </legend>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={GAME_CATEGORY} value={appEntity.categoryGame} />{' '}
                    <Translate contentKey="digijagApp.app.category.game">Spel</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={KIDS_CATEGORY} value={appEntity.categoryKids} />{' '}
                    <Translate contentKey="digijagApp.app.category.kids">För barn</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={SCHOOL_CATEGORY} value={appEntity.categorySchool} />{' '}
                    <Translate contentKey="digijagApp.app.category.school">Skola</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={SCHOOL_2_CATEGORY} value={appEntity.categorySchool2} />{' '}
                    <Translate contentKey="digijagApp.app.category.school2">Anpassad gymnasieskola</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={FOOD_CATEGORY} value={appEntity.categoryFood} />{' '}
                    <Translate contentKey="digijagApp.app.category.food">Mat</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={HEALTH_CATEGORY} value={appEntity.categoryHealth} />{' '}
                    <Translate contentKey="digijagApp.app.category.health">Hälsa</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={SOCIETY_CATEGORY} value={appEntity.categorySociety} />{' '}
                    <Translate contentKey="digijagApp.app.category.society">Samhälle</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={ECONOMY_CATEGORY} value={appEntity.categoryEconomy} />{' '}
                    <Translate contentKey="digijagApp.app.category.economy">Ekonomi</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={VIDEO_CATEGORY} value={appEntity.categoryVideo} />{' '}
                    <Translate contentKey="digijagApp.app.category.video">Video</Translate>
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name={NEWS_CATEGORY} value={appEntity.categoryNews} />{' '}
                    <Translate contentKey="digijagApp.app.category.news">Nyheter</Translate>
                  </Label>
                </AvGroup>
                <hr />
                <Label>
                  <Translate contentKey="digijagApp.app.iframeInfo">Om appen inte kan öppnas inuti...</Translate>
                </Label>
                <AvGroup check>
                  <Label>
                    <AvInput type="checkbox" name="openInNewWindow" value={appEntity.openInNewWindow} />{' '}
                    <Translate contentKey="digijagApp.app.openInNewWindow">openInNewWindowLabel</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="globalLabel" check for="app-global">
                    <AvInput id="app-global" type="checkbox" className="form-control" name="global" />
                    <Translate contentKey="digijagApp.content.global">Global</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="auditAcceptedLabel" check for="app-auditAccepted">
                    <AvInput id="app-auditAccepted" type="checkbox" className="form-control" name="auditAccepted" />
                    <Translate contentKey="digijagApp.content.auditAccepted">Audit Accepted</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="auditedAtLabel" for="app-auditedAt">
                    <Translate contentKey="digijagApp.content.auditedAt">Audited At</Translate>
                  </Label>
                  <AvInput
                    id="app-auditedAt"
                    type="datetime-local"
                    className="form-control"
                    name="auditedAt"
                    value={isNew ? null : convertDateTimeFromServer(this.props.appEntity.auditedAt)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="app-author">
                    <Translate contentKey="digijagApp.content.author">Author</Translate>
                  </Label>
                  <AvInput id="app-author" type="select" className="form-control" name="authorId">
                    <option value="" key="0" />
                    {communityAdmins
                      ? communityAdmins.map(userData => (
                          <option value={userData.id} key={userData.id}>
                            {userData.userLogin}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="app-communities">
                    <Translate contentKey="digijagApp.content.communities">Communities</Translate>
                  </Label>
                  <AvInput
                    id="app-communities"
                    type="select"
                    multiple
                    className="form-control"
                    name="communities"
                    value={appEntity.communities && appEntity.communities.map(e => e.id)}
                  >
                    <option value="" key="0" />
                    {communities
                      ? communities.map(community => (
                          <option value={community.id} key={community.id}>
                            {community.displayName ? community.displayName : community.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/app" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  communityAdmins: storeState.userData.communityAdmins,
  communities: storeState.community.entities,
  appEntity: storeState.app.entity,
  apps: storeState.app.entities,
  loading: storeState.app.loading,
  updating: storeState.app.updating
});

const mapDispatchToProps = {
  getAllCommunityAdmins,
  getEntity,
  getAllCommunities,
  updateEntity,
  createEntity,
  reset,
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppUpdate);
