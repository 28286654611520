import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IWeek } from 'app/shared/model/fixatiden/week.model';
import CreateDayDialog from './CreateDayDialog';
import CreateWeekDialog from './CreateWeekDialog';
import DefaultActivitiesDialog from './DefaultActivitiesDialog';
import { defaultActivities, IActivity } from 'app/shared/model/fixatiden/activity.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ICreateTimeplanDialogProps {
  open: boolean;
  onClose: any;
  onCreateWeek: (week: IWeek) => any;
  onCreateDay: (day: IDay) => any;
  dialogBackButton: (closeMethod: () => any) => any;
}

interface ICreateTimeplanDialogState {
  createDay: boolean;
  createWeek: boolean;
  addDefaultActivities: boolean;
  newDay?: IDay;
  newWeek?: IWeek;
}

function CreateTimeplanDialog(props: ICreateTimeplanDialogProps) {
  const { open, onClose, onCreateWeek, onCreateDay, dialogBackButton } = props;
  const [createDayDialogIsOpen, setCreateDayDialogIsOpen] = useState<ICreateTimeplanDialogState['createDay']>(false);
  const [createWeekDialogIsOpen, setCreateWeekDialogIsOpen] = useState<ICreateTimeplanDialogState['createWeek']>(false);
  const [defaultActivitiesDialogIsOpen, addDefaultActivitiesDialogIsOpen] = useState<ICreateTimeplanDialogState['addDefaultActivities']>(false);
  const [newDay, setNewDay] = useState<ICreateTimeplanDialogState['newDay']>(null);
  const [newWeek, setNewWeek] = useState<ICreateTimeplanDialogState['newWeek']>(null);

  const handleClose = () => {
    setCreateDayDialogIsOpen(false);
    setCreateWeekDialogIsOpen(false);
    addDefaultActivitiesDialogIsOpen(false);
    onClose();
  };

  const handleCreateDay = (day: IDay) => {
    setNewDay(day);
    setNewWeek(null);
    setCreateDayDialogIsOpen(false);
    addDefaultActivitiesDialogIsOpen(true);
  };

  const handleCreateWeek = (week: IWeek) => {
    setNewWeek(week);
    setNewDay(null);
    setCreateWeekDialogIsOpen(false);
    addDefaultActivitiesDialogIsOpen(true);
  };

  const handleAddDefaultActivitiesResponse = (withDefaultActivities: boolean) => {
    if (newDay) {
      if (withDefaultActivities) {
        newDay.activities = defaultActivities.map((x: IActivity) => ({ ...x }));
      }
      onCreateDay(newDay);
      handleClose();
    } else if (newWeek) {
      if (withDefaultActivities) {
        newWeek.days = newWeek.days.map((weekDay: IDay) => {
          const filledDay: IDay = JSON.parse(JSON.stringify(weekDay));
          filledDay.activities = defaultActivities.map((x: IActivity) => ({ ...x }));
          return filledDay;
        });
      }
      onCreateWeek(newWeek);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open && !createDayDialogIsOpen && !createWeekDialogIsOpen && !defaultActivitiesDialogIsOpen}
        onClose={handleClose}
        aria-labelledby="start-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="start-dialog-title">Nytt schema</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setCreateDayDialogIsOpen(true);
              setCreateWeekDialogIsOpen(false);
            }}
            startIcon={<FontAwesomeIcon icon={'day-sun' as IconProp} />}
            fullWidth
            disableFocusRipple
          >
            Dag
          </Button>
          <Button
            onClick={() => {
              setCreateDayDialogIsOpen(false);
              setCreateWeekDialogIsOpen(true);
            }}
            startIcon={<FontAwesomeIcon icon={'week-sun' as IconProp} />}
            fullWidth
            disableFocusRipple
          >
            Vecka
          </Button>
          {dialogBackButton(handleClose)}
        </DialogActions>
      </Dialog>
      <CreateDayDialog open={open && createDayDialogIsOpen} onClose={() => setCreateDayDialogIsOpen(false)} onSubmit={handleCreateDay} />
      <CreateWeekDialog open={open && createWeekDialogIsOpen} onClose={() => setCreateWeekDialogIsOpen(false)} onSubmit={handleCreateWeek} />
      {(newDay || newWeek) && (
        <DefaultActivitiesDialog
          open={open && defaultActivitiesDialogIsOpen}
          timePlan={newWeek || newDay}
          onClose={() => addDefaultActivitiesDialogIsOpen(false)}
          onSubmit={handleAddDefaultActivitiesResponse}
        />
      )}
    </>
  );
}

export default CreateTimeplanDialog;
