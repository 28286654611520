import { customPalette } from 'app/custom-components/public-app/mittvuxenliv/fixatiden/theme';
import { IActivity } from './activity.model';

export interface IDay {
  activities?: IActivity[];
  color?: string;
  id?: number;
  name?: string;
  weekId?: number;
  order?: number;
}

export const emptyDay = {
  name: '',
  color: customPalette.secondary.main,
  activities: []
};

export const defaultValue: Readonly<IDay> = {};
