import React, { useEffect, useState } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';
import ActivityForm from './ActivityForm';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';
import ActivityView from './ActivityView';

const SideBarActivityStyles = (theme: Theme) => createStyles({});

interface ISideBarActivityProps {
  classes: Classes;
  onCancel: any;
  onSubmit: any;
  onDelete?: (id: number) => void;
  activeActivity?: IActivity;
  isAuthenticated: boolean;
}

interface ISideBarActivityState {
  activityName: string;
  imgUrl: string;
  startTime: number;
  endTime: number;
  extraStartTime: number;
  extraEndTime: number;
  editMode: boolean;
}

function SideBarActivity({ classes, onCancel, onSubmit, onDelete, activeActivity, isAuthenticated }: React.PropsWithChildren<ISideBarActivityProps>) {
  const [activityName, setActivityName] = useState<ISideBarActivityState['activityName']>('');
  const [imgUrl, setImgUrl] = useState<ISideBarActivityState['imgUrl']>('');
  const [startTime, setStartTime] = useState<ISideBarActivityState['startTime']>(0);
  const [endTime, setEndTime] = useState<ISideBarActivityState['endTime']>(0);
  const [extraStartTime, setextraStartTime] = useState<ISideBarActivityState['extraStartTime']>(0);
  const [extraEndTime, setextraEndTime] = useState<ISideBarActivityState['extraEndTime']>(0);
  const [editMode, setEditMode] = useState<ISideBarActivityState['editMode']>(false);

  const handleChangeActivityName = (name: string) => {
    if (name === activityName) return;
    setActivityName(name);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setActivityName(!activeActivity ? '' : activeActivity.name);
    setImgUrl(!activeActivity ? '' : activeActivity.imgUrl);
    setStartTime(!activeActivity ? 0 : activeActivity.startTime);
    setEndTime(!activeActivity ? 0 : activeActivity.endTime);
    setextraStartTime(!activeActivity ? 0 : activeActivity.extraStartTime);
    setextraEndTime(!activeActivity ? 0 : activeActivity.extraEndTime);
  };

  const handleSubmitActivity = (activity: IActivity) => {
    if (activeActivity) {
      activity.id = activeActivity.id;
      activity.icon = activeActivity.icon;
    }
    onSubmit(activity);
  };

  useEffect(
    () => {
      setActivityName(!activeActivity ? '' : activeActivity.name);
      setImgUrl(!activeActivity ? '' : activeActivity.imgUrl);
      setStartTime(!activeActivity ? 0 : activeActivity.startTime);
      setEndTime(!activeActivity ? 0 : activeActivity.endTime);
      setextraStartTime(!activeActivity ? 0 : activeActivity.extraStartTime);
      setextraEndTime(!activeActivity ? 0 : activeActivity.extraEndTime);
    },
    [activeActivity]
  );

  return (
    <>
      {!activeActivity || editMode ? (
        <ActivityForm
          activityName={activityName}
          onChangeActivityName={handleChangeActivityName}
          imgUrl={imgUrl}
          onChangeActivityImgUrl={setImgUrl}
          startTime={startTime}
          onChangeStartTime={setStartTime}
          endTime={endTime}
          onChangeEndTime={setEndTime}
          extraStartTime={extraStartTime}
          onChangeextraStartTime={setextraStartTime}
          extraEndTime={extraEndTime}
          onChangeextraEndTime={setextraEndTime}
          onSubmit={handleSubmitActivity}
          onCancel={!activeActivity ? onCancel : handleCancelEdit}
          isAuthenticated={isAuthenticated}
          editMode={editMode}
        />
      ) : (
        <ActivityView activity={activeActivity} onEdit={() => setEditMode(true)} onCancel={onCancel} onDelete={onDelete} />
      )}
    </>
  );
}

export default withStyles(SideBarActivityStyles)(SideBarActivity);
