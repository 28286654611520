import * as React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';

const SrOnlyStyles = (theme: Theme) =>
  createStyles({
    root: {
      clip: 'rect(1px, 1px, 1px, 1px)',
      clipPath: 'inset(50%)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: '0',
      position: 'absolute',
      width: '1px'
    }
  });

interface ISrOnlyProps {
  classes: Classes;
  className?: string;
}

export class SrOnly extends React.Component<ISrOnlyProps> {
  render() {
    const { classes, children, className } = this.props;

    return <div className={className ? `${classes.root} ${className}` : classes.root}>{children}</div>;
  }
}

export default withStyles(SrOnlyStyles)(SrOnly);
