import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getUserData } from 'app/entities/user-data/user-data.reducer';
import ImageInput from 'app/custom-components/form-inputs/imageInput';

import { AUTHORITIES } from 'app/config/constants';
import { getEntity, updateEntity, createEntity, reset } from './community.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getEntities as getOrganizations, reset as resetOrganization } from '../organization/organization.reducer';
import { Typography } from '@material-ui/core';

export interface ICommunityUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICommunityUpdateState {
  isNew: boolean;
  userDataId: string;
  logoUrl: string;
}

export class CommunityUpdate extends React.Component<ICommunityUpdateProps, ICommunityUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userDataId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      logoUrl: ''
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }
    this.props.getOrganizations();
    this.props.getUserData();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { communityEntity } = this.props;
      const entity = {
        ...communityEntity,
        ...values,
        logoUrl: this.state.logoUrl || communityEntity.logoUrl
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
      this.handleClose();
    }
  };

  handleImageChange = value => {
    this.setState({ logoUrl: value });
  };

  handleClose = () => {
    this.props.history.push('/entity/community');
  };

  render() {
    const { communityEntity, isAdmin, loading, updating, organizations } = this.props;
    const { isNew, logoUrl } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="digijagApp.community.home.createOrEditLabel">
              <Translate contentKey="digijagApp.community.home.createOrEditLabel">Create or edit a Community</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : communityEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="community-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="community-name">
                    <Translate contentKey="digijagApp.community.name">Name</Translate>
                  </Label>
                  <AvField id="community-name" type="text" name="name" />
                </AvGroup>
                <AvGroup>
                  <Label id="nameLabel" for="community-displayname">
                    <Translate contentKey="digijagApp.community.displayName">Display name</Translate>
                  </Label>
                  <AvField id="community-displayname" type="text" name="displayName" />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="community-description">
                    <Translate contentKey="digijagApp.community.description">Description</Translate>
                  </Label>
                  <AvField
                    id="community-description"
                    type="text"
                    name="description"
                    validate={{
                      maxLength: { value: 10000, errorMessage: translate('entity.validation.maxlength', { max: 10000 }) }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="logoUrlLabel" for="community-logoUrl">
                    <Translate contentKey="digijagApp.community.logoUrl">Logo Url</Translate>
                  </Label>
                  <ImageInput id="community-logoUrl" imgUrl={logoUrl || communityEntity.logoUrl} handleChange={this.handleImageChange} required={false} />
                </AvGroup>
                {isAdmin && (
                  <>
                    <AvGroup>
                      <Label id="zoomApiKeyLabel" for="community-zoomApiKey">
                        <Translate contentKey="digijagApp.community.zoomApiKey">Zoom API key</Translate>
                      </Label>
                      <AvField id="community-zoomApiKey" type="text" name="zoomApiKey" />
                    </AvGroup>
                    <AvGroup>
                      <Label id="zoomSecretLabel" for="community-zoomSecret">
                        <Translate contentKey="digijagApp.community.zoomSecret">Zoom Secret</Translate>
                      </Label>
                      <AvField id="community-zoomSecret" type="text" name="zoomSecret" />
                    </AvGroup>
                    {/*<Typography aria-describedby="community-organization"></Typography>*/}
                  </>
                )}
                <AvGroup>
                  <Label for="community-organization">
                    <Translate contentKey="digijagApp.community.organization">Author</Translate>
                  </Label>
                  <AvInput id="community-organization" type="select" className="form-control" name="organizationId">
                    <option value={communityEntity.organizationName ? communityEntity.organizationName : ''} key="0" />
                    {organizations
                      ? organizations.map(org => (
                          <option value={org.id} key={org.id}>
                            {org.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <AvInput id="community-hideMembers" type="checkbox" className="form-control" name="hideMembers" />
                  <Label id="hideMembersLabel" for="community-hideMembers">
                    <Translate contentKey="digijagApp.community.hideMembers">Hide members from each other</Translate>
                  </Label>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/community" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  userData: storeState.userData.entities,
  communityEntity: storeState.community.entity,
  loading: storeState.community.loading,
  updating: storeState.community.updating,
  organizations: storeState.organization.entities,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SUPERADMIN])
});

const mapDispatchToProps = {
  getUserData,
  getEntity,
  updateEntity,
  createEntity,
  getOrganizations,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunityUpdate);
