import { Moment } from 'moment';
import { IUserData } from './user-data.model';

export interface IZoomMeeting {
  id?: number;
  apiKeyUsed?: string;
  meetingId?: number;
  type?: number;
  duration?: number;
  guests?: IUserData[];
  hostId?: number;
  hostName?: string;
  hostImgUrl?: string;
  startDate?: Moment;
  password?: string;
  topic?: string;
  joinUrl?: string;
  startUrl?: string;
  communityId?: number;
}

export const defaultValue: Readonly<IZoomMeeting> = {};
