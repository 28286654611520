import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUserData } from 'app/shared/model/user-data.model';
import { getEntities as getUserData } from 'app/entities/user-data/user-data.reducer';
import { getEntity, updateEntity, createEntity, reset } from './connection.reducer';
import { IConnection } from 'app/shared/model/connection.model';
// tslint:disable-next-line:no-unused-variable
import { convertDateTimeFromServer } from 'app/shared/util/date-time-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IConnectionUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IConnectionUpdateState {
  isNew: boolean;
  studentId: string;
  helperId: string;
}

export class ConnectionUpdate extends React.Component<IConnectionUpdateProps, IConnectionUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      studentId: '0',
      helperId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUserData();
  }

  saveEntity = (event, errors, values) => {
    values.remoteControlTokenCreatedDate = new Date(values.remoteControlTokenCreatedDate);

    if (errors.length === 0) {
      const { connectionEntity } = this.props;
      const entity = {
        ...connectionEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
      this.handleClose();
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/connection');
  };

  render() {
    const { connectionEntity, userData, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="digijagApp.connection.home.createOrEditLabel">
              <Translate contentKey="digijagApp.connection.home.createOrEditLabel">Create or edit a Connection</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : connectionEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="connection-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                {/* <AvGroup>
                  <Label id="inviteOthersLabel" check>
                    <AvInput id="connection-inviteOthers" type="checkbox" className="form-control" name="inviteOthers" />
                    <Translate contentKey="digijagApp.connection.inviteOthers">Invite Others</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="notifyFullSpaceLabel" check>
                    <AvInput id="connection-notifyFullSpace" type="checkbox" className="form-control" name="notifyFullSpace" />
                    <Translate contentKey="digijagApp.connection.notifyFullSpace">Notify Full Space</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="notifyAbsentLabel" check>
                    <AvInput id="connection-notifyAbsent" type="checkbox" className="form-control" name="notifyAbsent" />
                    <Translate contentKey="digijagApp.connection.notifyAbsent">Notify Absent</Translate>
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label id="remoteControlTokenLabel" for="remoteControlToken">
                    <Translate contentKey="digijagApp.connection.remoteControlToken">Remote Control Token</Translate>
                  </Label>
                  <AvField id="connection-remoteControlToken" type="text" name="remoteControlToken" />
                </AvGroup>
                <AvGroup>
                  <Label id="remoteControlTokenCreatedDateLabel" for="remoteControlTokenCreatedDate">
                    <Translate contentKey="digijagApp.connection.remoteControlTokenCreatedDate">
                      Remote Control Token Created Date
                    </Translate>
                  </Label>
                  <AvInput
                    id="connection-remoteControlTokenCreatedDate"
                    type="datetime-local"
                    className="form-control"
                    name="remoteControlTokenCreatedDate"
                    value={isNew ? null : convertDateTimeFromServer(this.props.connectionEntity.remoteControlTokenCreatedDate)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="remoteControlJwtLabel" for="remoteControlJwt">
                    <Translate contentKey="digijagApp.connection.remoteControlJwt">Remote Control Jwt</Translate>
                  </Label>
                  <AvField id="connection-remoteControlJwt" type="text" name="remoteControlJwt" />
                </AvGroup> */}
                <AvGroup>
                  <Label for="student.id">
                    <Translate contentKey="digijagApp.connection.student">Student</Translate>
                  </Label>
                  <AvInput id="connection-student" type="select" className="form-control" name="studentId">
                    <option value="" key="0" />
                    {userData
                      ? userData.map(studentData => (
                          <option value={studentData.id} key={studentData.id}>
                            {studentData.userLogin}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="helper.id">
                    <Translate contentKey="digijagApp.connection.helper">Helper</Translate>
                  </Label>
                  <AvInput id="connection-helper" type="select" className="form-control" name="helperId">
                    <option value="" key="0" />
                    {userData
                      ? userData.map(helperData => (
                          <option value={helperData.id} key={helperData.id}>
                            {helperData.userLogin}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/connection" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  userData: storeState.userData.entities,
  connectionEntity: storeState.connection.entity,
  loading: storeState.connection.loading,
  updating: storeState.connection.updating
});

const mapDispatchToProps = {
  getUserData,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionUpdate);
