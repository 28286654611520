import React from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Classes } from 'jss';
import classNames from 'classnames';
import AppItemContainerActionBar from './AppContainerActionBar';
import { PageTitle } from 'app/shared/layout/document';

const AppItemContainerStyles = ({ zIndex, breakpoints }: Theme) =>
  createStyles({
    appContainer: {
      position: 'absolute',
      zIndex: zIndex.drawer + 1,
      top: '4rem',
      left: '0',
      height: 'calc((var(--vh, 1vh) * 100) - 4rem)',
      width: '100vw',
      background: '#fff'
    },

    appContent: {
      width: '100%',
      height: 'calc(100% - 53px)',
      overflow: 'auto',
      '& iframe': {
        width: '100%',
        height: '100%',
        border: 'none'
      }
    },

    noNavigation: {
      height: '100%'
    },

    noScroll: {
      overflow: 'hidden'
    }
  });

interface IAppItemContainerProps {
  appName?: string;
  noScroll?: boolean;
  classes: Classes;
  noNavigation?: boolean;
}

interface IAppItemContainerState {
  loading: boolean;
}

export class AppItemContainer extends React.Component<IAppItemContainerProps, IAppItemContainerState> {
  handleBackNavigation = event => {
    event.preventDefault();
    window.history.back();
  };

  handleForwardNavigation = event => {
    event.preventDefault();
    window.history.forward();
  };

  render() {
    const { appName, noScroll, children, classes, noNavigation = false } = this.props;
    const { handleBackNavigation, handleForwardNavigation } = this;

    return (
      <React.Fragment>
        <div className={classes.appContainer}>
          <PageTitle title={appName} />
          <div
            className={classNames({
              [classes.appContent]: true,
              [classes.noNavigation]: noNavigation,
              [classes.noScroll]: noScroll
            })}
          >
            {children}
          </div>
          {noNavigation || (
            <AppItemContainerActionBar onBackNavigation={handleBackNavigation} onForwardNavigation={handleForwardNavigation} />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(AppItemContainerStyles)(AppItemContainer);
