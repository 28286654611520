import { translate } from 'react-jhipster';
import { IApp } from './app.model';
import { IContent } from './content.model';

export const GAME_LABEL = 'digijagApp.categories.categoryGame';
export const GAME_CATEGORY = 'categoryGame';
export const KIDS_LABEL = 'digijagApp.categories.categoryKids';
export const KIDS_CATEGORY = 'categoryKids';
export const SCHOOL_LABEL = 'digijagApp.categories.categorySchool';
export const SCHOOL_CATEGORY = 'categorySchool';
export const SCHOOL_2_LABEL = 'digijagApp.categories.categorySchool2';
export const SCHOOL_2_CATEGORY = 'categorySchool2';
export const FOOD_LABEL = 'digijagApp.categories.categoryFood';
export const FOOD_CATEGORY = 'categoryFood';
export const HEALTH_LABEL = 'digijagApp.categories.categoryHealth';
export const HEALTH_CATEGORY = 'categoryHealth';
export const SOCIETY_LABEL = 'digijagApp.categories.categorySociety';
export const SOCIETY_CATEGORY = 'categorySociety';
export const ECONOMY_LABEL = 'digijagApp.categories.categoryEconomy';
export const ECONOMY_CATEGORY = 'categoryEconomy';
export const VIDEO_LABEL = 'digijagApp.categories.categoryVideo';
export const VIDEO_CATEGORY = 'categoryVideo';
export const NEWS_LABEL = 'digijagApp.categories.categoryNews';
export const NEWS_CATEGORY = 'categoryNews';

export const NO_CATEGORY_LABEL = 'digijagApp.categories.none';
export const NO_CATEGORY = 'none';

export const getCategoryByLabel = label => {
  switch (label) {
    case translate(GAME_LABEL):
      return GAME_CATEGORY;
    case translate(KIDS_LABEL):
      return KIDS_CATEGORY;
    case translate(SCHOOL_LABEL):
      return SCHOOL_CATEGORY;
    case translate(SCHOOL_2_LABEL):
      return SCHOOL_2_CATEGORY;
    case translate(FOOD_LABEL):
      return FOOD_CATEGORY;
    case translate(HEALTH_LABEL):
      return HEALTH_CATEGORY;
    case translate(SOCIETY_LABEL):
      return SOCIETY_CATEGORY;
    case translate(ECONOMY_LABEL):
      return ECONOMY_CATEGORY;
    case translate(VIDEO_LABEL):
      return VIDEO_CATEGORY;
    case translate(NEWS_LABEL):
      return NEWS_CATEGORY;
    case translate(NO_CATEGORY_LABEL):
      return NO_CATEGORY;
    default:
      return '';
  }
};

export const filterOnCategory = category => (element: IApp | IContent) => {
  if (category === NO_CATEGORY) {
    return !(
      element.categoryGame ||
      element.categoryKids ||
      element.categorySchool ||
      element.categoryFood ||
      element.categoryEconomy ||
      element.categoryVideo ||
      element.categoryNews ||
      element.categorySchool2 ||
      element.categoryHealth ||
      element.categorySociety
    );
  } else {
    return category ? element[category] : true;
  }
};

const categoryLabels = [
  SCHOOL_LABEL,
  SCHOOL_2_LABEL,
  GAME_LABEL,
  VIDEO_LABEL,
  FOOD_LABEL,
  HEALTH_LABEL,
  SOCIETY_LABEL,
  ECONOMY_LABEL,
  NEWS_LABEL,
  KIDS_LABEL,
  NO_CATEGORY_LABEL
];

export const filterCategoryList = (apps: IApp[]) => {
  const categoryList = [];
  apps.map(app => {
    let found = false;
    if (app.categorySchool === true) {
      found = true;
      if (categoryList.indexOf(SCHOOL_LABEL) === -1) categoryList.push(SCHOOL_LABEL);
    }
    if (app.categorySchool2 === true) {
      found = true;
      if (categoryList.indexOf(SCHOOL_2_LABEL) === -1) categoryList.push(SCHOOL_2_LABEL);
    }
    if (app.categoryGame === true) {
      found = true;
      if (categoryList.indexOf(GAME_LABEL) === -1) categoryList.push(GAME_LABEL);
    }
    if (app.categoryVideo === true) {
      found = true;
      if (categoryList.indexOf(VIDEO_LABEL) === -1) categoryList.push(VIDEO_LABEL);
    }
    if (app.categoryFood === true) {
      found = true;
      if (categoryList.indexOf(FOOD_LABEL) === -1) categoryList.push(FOOD_LABEL);
    }
    if (app.categoryHealth === true) {
      found = true;
      if (categoryList.indexOf(HEALTH_LABEL) === -1) categoryList.push(HEALTH_LABEL);
    }
    if (app.categorySociety === true) {
      found = true;
      if (categoryList.indexOf(SOCIETY_LABEL) === -1) categoryList.push(SOCIETY_LABEL);
    }
    if (app.categoryEconomy === true) {
      found = true;
      if (categoryList.indexOf(ECONOMY_LABEL) === -1) categoryList.push(ECONOMY_LABEL);
    }
    if (app.categoryNews === true) {
      found = true;
      if (categoryList.indexOf(NEWS_LABEL) === -1) categoryList.push(NEWS_LABEL);
    }
    if (app.categoryKids === true) {
      found = true;
      if (categoryList.indexOf(KIDS_LABEL) === -1) categoryList.push(KIDS_LABEL);
    }
    if (found === false) {
      if (categoryList.indexOf(NO_CATEGORY_LABEL) === -1) categoryList.push(NO_CATEGORY_LABEL);
    }
  });
  // Always move NO_CATEGORY_LABEL to be the last category
  categoryList.push(categoryList.splice(categoryList.indexOf(NO_CATEGORY_LABEL), 1)[0]);
  return categoryList;
};

export const getImage = categoryLabel => {
  switch (categoryLabel) {
    case GAME_LABEL:
      return '/content/images/category_games.svg';
    case KIDS_LABEL:
      return '/content/images/category_kids.svg';
    case SCHOOL_LABEL:
      return '/content/images/category_school.svg';
    case SCHOOL_2_LABEL:
      return '/content/images/category_school.svg';
    case FOOD_LABEL:
      return '/content/images/category_food.svg';
    case HEALTH_LABEL:
      return '/content/images/category_health.svg';
    case SOCIETY_LABEL:
      return '/content/images/category_society.svg';
    case ECONOMY_LABEL:
      return '/content/images/category_economy.svg';
    case VIDEO_LABEL:
      return '/content/images/category_videos.svg';
    case NEWS_LABEL:
      return '/content/images/category_news.svg';
    case NO_CATEGORY_LABEL:
      return '';
    default:
      return '';
  }
};

export const categories = [
  {
    category: ECONOMY_CATEGORY,
    label: ECONOMY_LABEL
  },
  {
    category: GAME_CATEGORY,
    label: GAME_LABEL
  },
  {
    category: KIDS_CATEGORY,
    label: KIDS_LABEL
  },
  {
    category: FOOD_CATEGORY,
    label: FOOD_LABEL
  },
  {
    category: NEWS_CATEGORY,
    label: NEWS_LABEL
  },
  {
    category: NO_CATEGORY,
    label: NO_CATEGORY_LABEL
  },
  {
    category: SCHOOL_CATEGORY,
    label: SCHOOL_LABEL
  },
  {
    category: VIDEO_CATEGORY,
    label: VIDEO_LABEL
  },
  {
    category: HEALTH_CATEGORY,
    label: HEALTH_LABEL
  },
  {
    category: SCHOOL_2_CATEGORY,
    label: SCHOOL_2_LABEL
  },
  {
    category: SOCIETY_CATEGORY,
    label: SOCIETY_LABEL
  }
];
