import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogBackButton from '../DialogBackButton';
import CustomVoiceInput from '../../../shared-components/CustomVoiceInput';

interface IEditTimePlanNameDialogProps {
  open: boolean;
  name: string;
  onClose: any;
  onChange: any;
  onSubmit: any;
}

function EditTimePlanNameDialog(props: IEditTimePlanNameDialogProps) {
  const { open, name, onClose, onChange, onSubmit } = props;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="edit-time-plan-name-dialog-title" disableBackdropClick disableEscapeKeyDown maxWidth="sm" fullWidth>
      <form onSubmit={onSubmit}>
        <DialogTitle id="edit-time-plan-name-dialog-title">
          <FontAwesomeIcon icon="pen-square" />
          Ändra namn
        </DialogTitle>
        <DialogContent>
          <CustomVoiceInput value={name} handleChange={onChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit} startIcon={<FontAwesomeIcon icon="thumbs-up" />} disabled={!name} fullWidth>
            Okej
          </Button>
          <DialogBackButton onClick={onClose} startIcon={<FontAwesomeIcon icon="times-circle" />} fullWidth>
            Avbryt
          </DialogBackButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditTimePlanNameDialog;
