import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { translate, Translate } from 'react-jhipster';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import { Classes } from 'jss';

export const CloseButtonStyles = ({ breakpoints }: Theme) =>
  createStyles({
    xButton: {
      padding: 0,
      minHeight: 40,
      minWidth: 40
    }
  });

interface ICloseButtonProps extends ButtonProps {
  classes: Classes;
  onClick?: any;
}

export class CloseButton extends React.Component<ICloseButtonProps> {
  render() {
    const { onClick, classes } = this.props;
    return (
      <Button onClick={onClick} color="primary" aria-label={translate('global.entities.action.close')} className={classes.xButton}>
        <img src="content/icons/x-icon.svg" alt="" />
      </Button>
    );
  }
}

export default withStyles(CloseButtonStyles)(CloseButton);
