import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { setDeferredPrompt } from '../../shared/reducers/authentication';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { Grid, createStyles, withStyles } from '@material-ui/core';
import { Classes } from 'jss';
import CommunityHome from './CommunityHome';
import GeneralHome from './GeneralHome';

/**
 * The setup for the progressive web app "add to homescreen" button largely follows these two guides,
 * https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen#A2HS_on_desktop
 * https://developers.google.com/web/fundamentals/app-install-banners/
 */
export interface IHomeProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  classes: Classes;
  community: any;
}

export const HomeStyles = () =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '-6.5rem',
      '& h1, & h2, & p': {
        color: '#000'
      },
      '& h2': {
        fontSize: '2rem',
        fontWeight: 'bold'
      },
      '& p': {
        margin: '1.5rem 0',
        lineHeight: '2rem'
      },
      '& section': {
        width: '100%'
      }
    }
  });

export class Home extends React.Component<IHomeProps> {
  componentDidMount = () => {
    // Adding EventListener
    window.addEventListener('beforeinstallprompt', e => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.props.setDeferredPrompt(e);
      // tslint:disable-next-line:no-console
      // Beforeinstallpropmt event was sent
    });
    // Added EventListener
  };

  render() {
    const { community, account, isAuthenticated, classes, loading } = this.props;
    if (account.authorities && hasAnyAuthority(account.authorities, [AUTHORITIES.STUDENT])) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <Grid container className={classes.root}>
          {!loading && (
            <>
              {community && community.id ? (
                <CommunityHome community={community} isAuthenticated={isAuthenticated} />
              ) : (
                <GeneralHome isAuthenticated={isAuthenticated} />
              )}
            </>
          )}
        </Grid>
      );
    }
  }
}

const mapStateToProps = ({ community, authentication }: IRootState) => ({
  community: community.current,
  loading: community.loading,
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated
});

const mapDispatchToProps = {
  setDeferredPrompt
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles)(Home));
