import * as React from 'react';
import { withStyles, createStyles, Theme, Backdrop, CircularProgress } from '@material-ui/core';
import { Classes } from 'jss';

const LoadingBackdropStyles = (theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      background: 'none !important',
      backdropFilter: 'blur(15px)'
    }
  });

interface ILoadingBackdropProps {
  classes: Classes;
  open?: boolean;
  size?: string | number;
}

export class LoadingBackdrop extends React.Component<ILoadingBackdropProps> {
  render() {
    const { classes, open, size } = this.props;

    return (
      <Backdrop className={classes.root} open={!!open}>
        <CircularProgress size={size || 100} />
      </Backdrop>
    );
  }
}

export default withStyles(LoadingBackdropStyles)(LoadingBackdrop);
