import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Classes } from 'jss';
import { RouteComponentProps } from 'react-router-dom';
import publicApps from '../public-app';

const PublicAppItemViewStyles = () => createStyles({});

interface IPublicAppItemViewProps extends RouteComponentProps<{ id: string }> {
  classes: Classes;
}

export class PublicAppItemView extends React.Component<IPublicAppItemViewProps> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  render() {
    const { classes } = this.props;
    const app = publicApps[this.props.match.params.id];
    const AppComponent = app.component;

    return <AppComponent />;
  }
}

export default withStyles(PublicAppItemViewStyles)(PublicAppItemView);
