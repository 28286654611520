import React from 'react';
import { Classes } from 'jss';
import { withStyles, createStyles, Theme, Button } from '@material-ui/core';
import classNames from 'classnames';
import { ButtonProps } from '@material-ui/core/Button';

interface ICustomButtonProps extends ButtonProps {
  classes?: Classes;
  onClick?: any;
}

export const CustomButtonStyles = ({ breakpoints }: Theme) =>
  createStyles({
    customButton: {
      minHeight: 45,
      borderRadius: 25,
      textTransform: 'none',
      fontWeight: 600,
      fontSize: '1rem',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      [breakpoints.down('xs')]: {
        fontSize: 11,
        minHeight: 32,
        marginRight: 0
      },
      '& img': {
        width: 25,
        height: 25,
        marginRight: 10,
        [breakpoints.down('xs')]: {
          height: 18,
          width: 18
        }
      }
    },

    redButton: {
      background: 'linear-gradient(#9a1750, #741840)',
      color: '#FFFFFF',
      '&:hover': {
        background: 'linear-gradient(#ad1b5a, #901e50)'
      }
    },

    blackButton: {
      background: 'linear-gradient(to bottom, #4f4f4f, #272727);',
      color: '#FFFFFF',
      '&:hover': {
        background: 'linear-gradient(#6f6f6f, #444444)'
      }
    }
  });

export class CustomButton extends React.Component<ICustomButtonProps> {
  render() {
    const { classes, children, onClick, color = 'primary', ...otherProps } = this.props;
    return (
      <Button
        onClick={onClick}
        className={classNames({
          [classes.customButton]: true,
          [classes.blackButton]: color === 'secondary',
          [classes.redButton]: color === 'primary'
        })}
        disableFocusRipple
        {...otherProps}
      >
        {children}
      </Button>
    );
  }
}

export default withStyles(CustomButtonStyles)(CustomButton);
