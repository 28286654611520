import React, { Component } from 'react';
import { Grid, Typography, Card, createStyles, withStyles } from '@material-ui/core';
import { Classes } from 'jss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import AppFormDialog from '../digijag-dialogs/apps/AppFormDialog';
import { IApp, defaultValue } from 'app/shared/model/app.model';
import { createEntity } from 'app/entities/app/app.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { translate, Translate } from 'react-jhipster';

const AppsAddStyles = () =>
  createStyles({
    templateCard: {
      backgroundColor: '#fff',
      height: '18.75rem',
      padding: '2.5rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      borderRadius: '10px',
      '&:hover': {
        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.5),0px 8px 10px 1px rgba(0, 0, 0, 0.3),0px 3px 14px 2px rgba(0, 0, 0, 0.3)'
      }
    },

    inactiveTemplateCard: {
      backgroundColor: '#fdecf5',
      height: '18.75rem',
      padding: '2.5rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '10px'
    },

    templateTitle: {
      color: '#000',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      display: 'block'
    }
  });

interface IAppsAddProps extends StateProps, DispatchProps, RouteComponentProps {
  classes: Classes;
}

interface IAppsAddState {
  formDialogOpen: boolean;
  newApp: IApp;
}

export class AppsAdd extends Component<IAppsAddProps, IAppsAddState> {
  readonly state = {
    formDialogOpen: false,
    newApp: { ...defaultValue, name: '', url: '', description: '', imgUrl: '' }
  };

  componentDidUpdate = prevProps => {
    const { app } = this.props;
    if (app && app.id && app.id !== prevProps.app.id) {
      this.setState(
        {
          formDialogOpen: false
        },
        () => {
          this.props.history.push(translate('digijagApp.routes.app.myFull'));
        }
      );
    }
  };

  handleTemplateClick = () => {
    this.setState({
      formDialogOpen: true
    });
  };

  handleCloseDialog = () => {
    this.setState({
      formDialogOpen: false
    });
  };

  handleChange = (attrName, value) => {
    this.setState({
      newApp: {
        ...this.state.newApp,
        [attrName]: value
      }
    });
  };

  onCreateApp = ev => {
    ev.preventDefault();
    this.props.createApp({
      ...this.state.newApp,
      authorId: this.props.currentUserData.id
    });
  };

  render() {
    const { classes, app } = this.props;
    const { formDialogOpen, newApp } = this.state;

    return (
      <>
        <Grid container direction="row" justify="flex-start" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="inherit">
              <img src="content/icons/information.svg" alt="" />
              &nbsp;
              <strong>
                <Translate contentKey="digijagApp.app.create.description">Välj "Ny app" för att skapa en ny app.</Translate>
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card raised className={classes.templateCard} onClick={this.handleTemplateClick}>
              <Typography variant="h2" align="center" className={classes.templateTitle}>
                <Translate contentKey="digijagApp.app.create.new">Ny app</Translate>
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <AppFormDialog
          btnIcon="plus_icon"
          btnText={translate('digijagApp.app.create.btnText')}
          dialogTitle={translate('digijagApp.app.create.new')}
          dialogOpen={formDialogOpen}
          handleChange={this.handleChange}
          handleCloseDialog={this.handleCloseDialog}
          onSaveApp={this.onCreateApp}
          app={newApp}
        />
      </>
    );
  }
}

const mapStateToProps = ({ userData, app }: IRootState) => ({
  currentUserData: userData.entity,
  app: app.entity
});

const mapDispatchToProps = {
  createApp: createEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AppsAddStyles)(AppsAdd));
