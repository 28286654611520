import * as React from 'react';
import { Card, CardContent, Grid, Button, CardHeader, Box, Theme, createStyles, withStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Classes } from 'jss';
import VerticalContainer from '../../shared-components/VerticalContainer';

const HomePageStyles = (theme: Theme) =>
  createStyles({
    cardForm: {
      margin: '0 auto',
      maxWidth: theme.breakpoints.width('sm'),
      width: '100%'
    }
  });

interface IHomePageProps {
  classes: Classes;
  onSubmit: Function;
}

export class HomePage extends React.Component<IHomePageProps> {
  handleOnClick = (mode: string) => () => this.props.onSubmit(mode);

  render() {
    const { classes } = this.props;

    return (
      <VerticalContainer>
        <Grid item style={{ padding: '0 20px' }}>
          <Card className={classes.cardForm}>
            <CardHeader
              title={
                <>
                  <FontAwesomeIcon icon="sack-dollar" />
                  Välj ett alternativ
                </>
              }
              disableTypography
            />
            <CardContent>
              <Button variant="contained" onClick={this.handleOnClick('custom')} fullWidth>
                Skapa en egen budget
              </Button>
              <Box my={2}>
                <Typography variant="body1" align="center">
                  eller
                </Typography>
              </Box>
              <Button variant="contained" onClick={this.handleOnClick('sample')} fullWidth>
                Välj ett färdigt exempel
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </VerticalContainer>
    );
  }
}

export default withStyles(HomePageStyles)(HomePage);
