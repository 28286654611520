import React from 'react';
import { withStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import TimeCard from './TimeCard';
import SrOnly from 'app/custom-components/sronly/SrOnly';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActivityFormStep2Styles = (theme: Theme) => createStyles({});

interface IActivityFormStep2Props {
  classes: Classes;
  startTime: number;
  onChangeStartTime: any;
  endTime: number;
  onChangeEndTime: any;
}

function ActivityFormStep2(props: React.PropsWithChildren<IActivityFormStep2Props>) {
  const { startTime, onChangeStartTime, endTime, onChangeEndTime } = props;

  return (
    <>
      <SrOnly>Steg 3 av 5</SrOnly>

      <TimeCard
        label={
          <>
            <FontAwesomeIcon icon="play-circle" style={{ color: '#54A500' }} /> Starttid
          </>
        }
        time={startTime}
        onChangeTime={onChangeStartTime}
        type="Starttid"
        direction="row"
      />

      <TimeCard
        label={
          <>
            <FontAwesomeIcon icon="stop-circle" style={{ color: '#F70085' }} /> Sluttid
          </>
        }
        time={endTime}
        onChangeTime={onChangeEndTime}
        type="Sluttid"
        direction="row"
      />
    </>
  );
}

export default withStyles(ActivityFormStep2Styles)(ActivityFormStep2);
