import React from 'react';
import { withStyles, createStyles, Theme, Drawer, AppBar, Toolbar, Typography, Hidden } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const drawerWidth = 350;

const SideBarStyles = (theme: Theme) =>
  createStyles({
    logo: {
      marginRight: 15,
      verticalAlign: 'middle'
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerPaper: {
      width: drawerWidth
    },
    appBar: {
      width: drawerWidth,
      left: 0
    },
    content: {
      flexGrow: 1
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar
  });

interface ISideBarProps {
  classes: Classes;
  mobileOpen: boolean;
  handleDrawerToggle: any;
  hidden?: boolean;
}

function SideBar(props: React.PropsWithChildren<ISideBarProps>) {
  const { classes, mobileOpen, handleDrawerToggle, children, hidden } = props;

  const drawer = (
    <div>
      <AppBar position="fixed" color="secondary" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" component="h1">
            <FontAwesomeIcon icon="clock" size="2x" className={classes.logo} />
            Fixa tiden
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </div>
    </div>
  );

  return (
    <nav className={classes.drawer} style={{ display: hidden ? 'none' : 'block' }}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default withStyles(SideBarStyles)(SideBar);
