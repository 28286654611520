import './header.scss';

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavbarToggler, Collapse, Container } from 'reactstrap';

import { Brand, OmDigiJag, Contact, DashboardLink } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu } from './menus';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import ZoomNotification from 'app/custom-components/zoom/zoom-notification';

export interface IHeaderProps extends RouteComponentProps {
  isCommunityHeader?: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isGroupAdmin: boolean;
  isHelper: boolean;
  isCommunityAdmin: boolean;
  isOrganizationAdmin: boolean;
  isSupportCoach: boolean;
  isStudent: boolean;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  onLocaleChange: Function;
}

export interface IHeaderState {
  menuOpen: boolean;
  darkNavbar: boolean;
  isJHipsterPage: boolean;
  showNavbar: boolean;
}

export function Header({
  isCommunityHeader,
  isAuthenticated,
  isAdmin,
  isGroupAdmin,
  isHelper,
  isCommunityAdmin,
  isOrganizationAdmin,
  isSupportCoach,
  isStudent,
  isInProduction,
  isSwaggerEnabled,
  currentLocale,
  onLocaleChange
}: IHeaderProps) {
  const [menuOpen, setMenuOpen] = useState<IHeaderState['menuOpen']>(false);
  const [darkNavbar, setDarkNavbar] = useState<IHeaderState['darkNavbar']>(false);
  const [isJHipsterPage, setIsJHipsterPage] = useState<IHeaderState['isJHipsterPage']>(false);
  const [showNavbar, setShowNavbar] = useState<IHeaderState['showNavbar']>(false);

  const handleLocaleChange = event => {
    onLocaleChange(event.target.value);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavbarTheme = () => {
    const windowsScrollTop = window.pageYOffset;
    const windowWidth = window.outerWidth;

    if (windowsScrollTop >= 40 || windowWidth < 960) {
      setDarkNavbar(true);
    } else {
      setDarkNavbar(false);
    }
  };

  useEffect(() => {
    handleNavbarTheme();
    window.addEventListener('scroll', handleNavbarTheme);
    window.addEventListener('resize', handleNavbarTheme);
    setIsJHipsterPage(window.location.hash !== '#/');
    return () => {
      window.removeEventListener('scroll', handleNavbarTheme);
      window.removeEventListener('resize', handleNavbarTheme);
    };
  }, []);

  return (
    <React.Fragment>
      <div id="app-header" style={{ marginBottom: '6.5rem' }}>
        <Navbar
          expand="lg"
          fixed="top"
          light={!darkNavbar}
          dark={darkNavbar}
          className={'jh-navbar' + (isJHipsterPage ? ' jh-page-navbar' : '') + (darkNavbar ? ' bg-dark' : '')}
        >
          <Container fluid>
            <Brand darkMode={darkNavbar} />
            <div className="ml-auto">
              <ZoomNotification />
            </div>
            <NavbarToggler aria-label="Menu" onClick={toggleMenu} className="ml-4" />
            <Collapse isOpen={menuOpen} navbar style={{ flexGrow: 'inherit' }}>
              <Nav id="header-tabs" navbar>
                {!isAuthenticated && <OmDigiJag useAnchor={!isCommunityHeader} />}
                {isAuthenticated && !isStudent && <DashboardLink />}
                {isAuthenticated &&
                  !isStudent && (
                    <EntitiesMenu
                      isStudent={isStudent}
                      isAdmin={isAdmin}
                      isGroupAdmin={isGroupAdmin}
                      isHelper={isHelper}
                      isSupportCoach={isSupportCoach}
                      isCommunityAdmin={isCommunityAdmin}
                      isOrganizationAdmin={isOrganizationAdmin}
                    />
                  )}
                {isAuthenticated && isAdmin && <AdminMenu showSwagger={isSwaggerEnabled} showDatabase={!isInProduction} />}
                {!isCommunityHeader && !isAuthenticated && <Contact />}
                {(!isCommunityHeader || isAuthenticated) && <AccountMenu isAuthenticated={isAuthenticated} />}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Header);
