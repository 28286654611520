import React from 'react';
import { withStyles, createStyles, Theme, Dialog, DialogTitle, Grid } from '@material-ui/core';
import { Classes } from 'jss';
import { DialogProps } from '@material-ui/core/Dialog';
import CloseButton from '../../form-inputs/CloseButton';

export const DigiJagBaseDialogStyles = ({ breakpoints }: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'transparent'
    },

    paper: {
      backgroundColor: '#FFFFFF',
      maxWidth: 680,
      height: 'auto',
      padding: '1.5rem 0',
      borderRadius: 10,
      justifyContent: 'center',
      [breakpoints.down('xs')]: {
        marginLeft: 16,
        marginRight: 16,
        height: 'auto'
      }
    },

    dialogTopBar: {
      paddingTop: 0,
      [breakpoints.down('xs')]: {
        paddingBottom: 0,
        paddingRight: 0
      }
    }
  });

interface IDigiJagBaseDialogProps extends DialogProps {
  classes: Classes;
  onClose: any;
  withCloseButton?: boolean;
}

class DigiJagBaseDialog extends React.Component<IDigiJagBaseDialogProps> {
  handleClose = () => {
    this.props.onClose();
  };

  handleExit = () => {
    this.props.onClose();
  };

  render() {
    const { classes, children, withCloseButton = false, ...otherProps } = this.props;

    return (
      <Dialog
        fullWidth
        onClose={this.handleClose}
        onExit={this.handleExit}
        PaperProps={{
          classes: {
            root: classes.paper
          }
        }}
        BackdropProps={{
          classes: {
            root: classes.root
          }
        }}
        {...otherProps}
        className="dialog-container"
      >
        {withCloseButton && (
          <DialogTitle disableTypography className={classes.dialogTopBar}>
            <Grid container justify="flex-end">
              <CloseButton onClick={this.handleClose} />
            </Grid>
          </DialogTitle>
        )}
        {children}
      </Dialog>
    );
  }
}

export default withStyles(DigiJagBaseDialogStyles)(DigiJagBaseDialog);
