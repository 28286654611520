import React from 'react';
import DigiJagBaseDialog from './DigiJagBaseDialog';
import DigiJagConfirmationContent from './DigiJagConfirmationContent';

interface IDigiJagConfirmationDialogProps {
  isOpen: boolean;
  onClose: Function;
  onConfirm: Function;
  title?: string;
  icon?: string;
  text?: any;
  acceptButtonText?: string;
  declineButtonText?: string;
  acceptButtonIcon?: string;
  updating?: boolean;
}

export class DigiJagConfirmationDialog extends React.Component<IDigiJagConfirmationDialogProps> {
  render() {
    return (
      <DigiJagBaseDialog open={this.props.isOpen} onClose={this.props.onClose} withCloseButton aria-labelledby="dialog-description">
        <DigiJagConfirmationContent {...this.props} />
      </DigiJagBaseDialog>
    );
  }
}

export default DigiJagConfirmationDialog;
