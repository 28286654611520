import React from 'react';

import ChangePasswordForm from './ChangePasswordForm';
import DigiJagBaseDialog from '../base-dialogs/DigiJagBaseDialog';

interface IChangePasswordDialogProps {
  isOpen: boolean;
  onClose: Function;
}

interface IChangePasswordDialogState {
  isOpen: boolean;
}

export class ChangePasswordDialog extends React.Component<IChangePasswordDialogProps, IChangePasswordDialogState> {
  render() {
    const { onClose, isOpen } = this.props;

    return (
      <DigiJagBaseDialog open={isOpen} onClose={onClose} aria-labelledby="dialog-description">
        <ChangePasswordForm handleCloseDialog={onClose} />
      </DigiJagBaseDialog>
    );
  }
}

export default ChangePasswordDialog;
