import React, { useEffect, useRef, useState } from 'react';
import { withStyles, createStyles, Theme, AppBar, Toolbar, Typography, IconButton, Grid, Button, Box, Backdrop, CircularProgress } from '@material-ui/core';
import { Classes } from 'jss';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IWeek } from 'app/shared/model/fixatiden/week.model';
import SideBar, { drawerWidth } from '../components/SideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActivitiesList from '../components/ActivitiesList';
import DaysList from '../components/DaysList';
import SideBarTimePlans from '../components/SideBarTimePlans';
import SideBarActivity from '../components/SideBarActivity';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';
import CreateTimeplanDialog from '../components/dialogs/CreateTimeplanDialog';
import DialogBackButton from '../components/DialogBackButton';
import ActivitiesListContainer from '../components/ActivitiesListContainer';
import ActivitiesListTime from '../components/ActivitiesListTime';
import ViewModeSwitch from '../components/ViewModeSwitch';
import FrejaButton from 'app/shared/auth/freja-button';
import { AuthMode } from 'app/shared/auth/auth_modes';
import customTheme from '../theme';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export enum ViewMode {
  HOURS,
  QUARTERS,
  LIST
}

export enum SideBarMode {
  PLANS_LIST,
  NEW_ACTIVITY,
  VIEW_ACTIVITY
}

const TimePlanningPageStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '@media print': {
        'color-adjust': 'exact',
        '-webkit-print-color-adjust': 'exact',
        'print-color-adjust': 'exact',
        '& > *:not(.to-print), & .to-hide': {
          display: 'none !important'
        }
      }
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth
      }
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    logoutIcon: {
      marginRight: theme.spacing(2)
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      width: 'calc(100vw - 550px)',
      height: '100vh',
      overflow: 'auto',
      backgroundColor: '#f8f8f8',
      '@media print': {
        height: 'auto',
        overflow: 'unset'
      }
    },
    weekContent: {
      flexGrow: 1,
      minHeight: 'calc(100vh - 80px)',
      width: 'calc(100vw - 550px)'
    },
    planHeading: {
      '@media print': {
        top: 0
      }
    },
    planTitle: {
      '& .svg-inline--fa': {
        marginRight: 20,
        color: theme.palette.secondary.main
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      background: '#3b538b60',
      color: '#000'
    }
  });

export const ActionBarButton = withStyles((theme: Theme) => ({
  root: {
    color: 'inherit',
    marginLeft: 'auto',
    '&:hover, &:focus': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.secondary.main,
      '& svg': {
        color: '#FFFFFF'
      }
    }
  }
}))(Button);

interface ITimePlanningPageProps {
  classes: Classes;
  timePlans: Array<IDay | IWeek>;
  onCreateWeek: (newWeek: IWeek) => any;
  onCreateDay: (newDay: IDay) => any;
  onUpdateWeek: (newWeek: IWeek) => any;
  onUpdateDay: (newDay: IDay) => any;
  onClickTimePlan: (timePlan: IDay | IWeek) => any;
  onDeleteWeek: (id) => any;
  onDeleteDay: (id) => any;
  onDeleteActivity: (id: number, parent: IDay) => any;
  isAuthenticated: boolean;
  onLogOut: () => any;
  onLogin: () => any;
  currentTimePlan: IDay | IWeek;
}

interface ITimePlanningPageState {
  drawerMobileOpen: boolean;
  openCreateTimePlanDialog: boolean;
  viewMode: ViewMode;
  sideBarMode: SideBarMode;
  resetDialogOpen: boolean;
  isFullscreen: boolean;
  currentDayOfWeek?: IDay;
  activeActivityIdx?: number;
  activeActivity?: IActivity;
  loadingOpen: boolean;
}

function TimePlanningPage(props: ITimePlanningPageProps) {
  const {
    classes,
    timePlans,
    isAuthenticated,
    currentTimePlan,
    onLogOut,
    onLogin,
    onCreateWeek,
    onCreateDay,
    onUpdateWeek,
    onUpdateDay,
    onDeleteWeek,
    onDeleteDay,
    onDeleteActivity,
    onClickTimePlan
  } = props;
  const [drawerMobileOpen, setDrawerMobileOpen] = useState<ITimePlanningPageState['drawerMobileOpen']>(false);
  const [currentDayOfWeek, setCurrentDayOfWeek] = useState<ITimePlanningPageState['currentDayOfWeek']>(null);
  const [openCreateTimePlanDialog, setOpenCreateTimePlantDialog] = useState<ITimePlanningPageState['openCreateTimePlanDialog']>(false);
  const [viewMode, setViewMode] = useState<ITimePlanningPageState['viewMode']>(ViewMode.HOURS);
  const [sideBarMode, setSideBarMode] = useState<ITimePlanningPageState['sideBarMode']>(SideBarMode.PLANS_LIST);
  const [activeActivityIdx, setActiveActivityIdx] = useState<ITimePlanningPageState['activeActivityIdx']>(null);
  const [activeActivity, setActiveActivity] = useState<ITimePlanningPageState['activeActivity']>(null);
  const [isFullscreen, setIsFullscreen] = useState<ITimePlanningPageState['isFullscreen']>(false);
  const [loadingOpen, setLoadingOpen] = useState<ITimePlanningPageState['loadingOpen']>(false);

  useEffect(
    () => {
      setActiveActivity(getActiveActivity());
    },
    [activeActivityIdx]
  );

  useEffect(
    () => {
      if (sideBarMode !== SideBarMode.PLANS_LIST) {
        setIsFullscreen(false);
      }
    },
    [sideBarMode]
  );

  const handleViewModeChange = (newMode: ViewMode) => {
    if (newMode === viewMode) return;
    setViewMode(newMode);
  };

  const handleSideBarMode = (newMode: SideBarMode) => {
    if (newMode === sideBarMode) return;
    setSideBarMode(newMode);
  };

  const handleActiveActivityIdx = (newActivityIdx: number) => {
    if (newActivityIdx !== activeActivityIdx) {
      setActiveActivityIdx(newActivityIdx);
      setSideBarMode(SideBarMode.VIEW_ACTIVITY);
    }
  };

  const handleOpenCreateTimePlanDialog = () => {
    setOpenCreateTimePlantDialog(true);
  };

  const handleCloseCreateTimePlanDialog = () => {
    setOpenCreateTimePlantDialog(false);
  };

  const handleDrawerToggle = () => {
    setDrawerMobileOpen(!drawerMobileOpen);
  };

  const handleClickTimePlan = (plan: IDay | IWeek) => {
    onClickTimePlan(plan);
    setCurrentDayOfWeek(null);
  };

  const handleSubmitActivity = (newActivity: IActivity) => {
    const day = isDay(currentTimePlan) ? (currentTimePlan as IDay) : (currentTimePlan as IWeek).days.find(d => d.id === currentDayOfWeek.id);

    const newActivitiesList = [].concat(day.activities);
    const updatedTimePlan: any = JSON.parse(JSON.stringify(currentTimePlan));

    if (activeActivityIdx !== null) {
      newActivitiesList[activeActivityIdx] = newActivity;
    } else {
      newActivitiesList.push(newActivity);
    }

    newActivitiesList.sort((a, b) => a.startTime - b.startTime);

    if (isDay(currentTimePlan)) {
      updatedTimePlan.activities = newActivitiesList;
      onUpdateDay(updatedTimePlan);
    } else {
      updatedTimePlan.days.find(d => d.id === currentDayOfWeek.id).activities = newActivitiesList;
      onUpdateWeek(updatedTimePlan);
    }

    handleCloseActivityView();
  };

  const handleEditTimePlanName = newName => {
    const updatedTimePlan: any = JSON.parse(JSON.stringify(currentTimePlan));
    updatedTimePlan.name = newName;
    if (isDay(updatedTimePlan)) {
      onUpdateDay(updatedTimePlan);
    } else {
      onUpdateWeek(updatedTimePlan);
    }
  };

  const handleDeleteTimePlan = () => {
    setCurrentDayOfWeek(null);
    if (isDay(currentTimePlan)) {
      onDeleteDay(currentTimePlan.id);
    } else {
      onDeleteWeek(currentTimePlan.id);
    }
  };

  const handleCloseActivityView = () => {
    handleSideBarMode(SideBarMode.PLANS_LIST);
    setActiveActivityIdx(null);
  };

  const handleDeleteActivity = (id: number) => {
    setSideBarMode(SideBarMode.PLANS_LIST);
    setActiveActivityIdx(null);
    onDeleteActivity(id, currentTimePlan);
  };

  const isDay = (plan: IDay | IWeek): boolean => 'activities' in plan;
  const isWeekDay = () => currentDayOfWeek !== null;
  const isDayOrWeekDay = () => isDay(currentTimePlan) || isWeekDay();
  const isWeek = (plan: IDay | IWeek): boolean => !isDay(plan);

  const getActiveActivity = () => {
    if (activeActivityIdx === null) return null;

    if (isDay(currentTimePlan)) {
      return (currentTimePlan as IDay).activities[activeActivityIdx] || null;
    } else if (isWeekDay() && (currentTimePlan as IWeek).days.find(day => day.id === currentDayOfWeek.id)) {
      return (currentTimePlan as IWeek).days.find(day => day.id === currentDayOfWeek.id).activities[activeActivityIdx] || null;
    }

    return null;
  };

  const handleMoveActivity = (origDayIdx: string, destDayIdx: string, activityIdx: string) => {
    const newWeek: IWeek = JSON.parse(JSON.stringify(currentTimePlan)) as IWeek;
    const activity: IActivity[] = newWeek.days[parseInt(origDayIdx, 10)].activities.splice(parseInt(activityIdx, 10), 1);
    const newActivitiesList: IActivity[] = [].concat(newWeek.days[parseInt(destDayIdx, 10)].activities, activity);
    newWeek.days[parseInt(destDayIdx, 10)].activities = newActivitiesList.sort((a, b) => a.endTime - b.endTime);
    onUpdateWeek(newWeek);
  };

  const handleCreateWeek = (newWeek: IWeek) => {
    onCreateWeek(newWeek);
  };

  const handleCreateDay = (newDay: IDay) => {
    onCreateDay(newDay);
  };

  const backButton = (closeMethod: () => any) => (
    <DialogBackButton onClick={closeMethod} startIcon={<FontAwesomeIcon icon="times-circle" />} fullWidth disableFocusRipple>
      Avbryt
    </DialogBackButton>
  );

  const handleChangeIsFullscreen = (value: boolean) => e => {
    setIsFullscreen(value);
  };

  const appBarEl = useRef<HTMLDivElement>(null);
  const topBarEl = useRef<HTMLDivElement>(null);

  return timePlans.length && currentTimePlan ? (
    <div className={classes.root}>
      <AppBar ref={appBarEl} position="fixed" color="default" elevation={0} className={isFullscreen ? '' : classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <FontAwesomeIcon icon="bars" />
          </IconButton>
          <Grid container justify="space-between" alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item style={{ whiteSpace: 'nowrap' }}>
              {isFullscreen ? (
                <Button
                  color="default"
                  variant="contained"
                  size="small"
                  startIcon={<FontAwesomeIcon icon="arrow-circle-left" color={customTheme.palette.secondary.main} />}
                  onClick={handleChangeIsFullscreen(false)}
                  onFocus={e => e.target.blur()}
                >
                  Stäng helskärmsläge
                </Button>
              ) : (
                <Button
                  color="default"
                  variant="contained"
                  size="small"
                  startIcon={<FontAwesomeIcon icon="expand-arrows-alt" color={customTheme.palette.secondary.main} />}
                  onClick={handleChangeIsFullscreen(true)}
                  onFocus={e => e.target.blur()}
                  disabled={sideBarMode !== SideBarMode.PLANS_LIST}
                >
                  Visa i helskärmsläge
                </Button>
              )}
              {viewMode === ViewMode.LIST && (
                <Button
                  color="default"
                  variant="contained"
                  size="small"
                  startIcon={<FontAwesomeIcon icon="print" color={customTheme.palette.secondary.main} />}
                  style={{ marginLeft: '.5rem' }}
                  onClick={() => window.print()}
                >
                  Skriva ut
                </Button>
              )}
            </Grid>
            <Grid item>
              <ViewModeSwitch viewMode={viewMode} onChange={handleViewModeChange} />
            </Grid>
            <Grid item>
              {isAuthenticated ? (
                <Button
                  startIcon={<FontAwesomeIcon icon="door-open" color={customTheme.palette.secondary.main} />}
                  color="default"
                  variant="contained"
                  size="small"
                  onClick={() => onLogOut()}
                >
                  Logga ut
                </Button>
              ) : (
                <FrejaButton labelBeforeLogo="Spara via" authMode={AuthMode.Tidsplanering} onClick={() => setLoadingOpen(true)} />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <SideBar handleDrawerToggle={handleDrawerToggle} mobileOpen={drawerMobileOpen} hidden={isFullscreen}>
        {
          {
            [SideBarMode.NEW_ACTIVITY]: (
              <SideBarActivity
                onCancel={() => handleSideBarMode(SideBarMode.PLANS_LIST)}
                onSubmit={handleSubmitActivity}
                isAuthenticated={props.isAuthenticated}
              />
            ),
            [SideBarMode.VIEW_ACTIVITY]: (
              <SideBarActivity
                onCancel={handleCloseActivityView}
                onSubmit={handleSubmitActivity}
                onDelete={handleDeleteActivity}
                activeActivity={activeActivity}
                isAuthenticated={props.isAuthenticated}
              />
            ),
            [SideBarMode.PLANS_LIST]: (
              <SideBarTimePlans
                timePlans={timePlans}
                current={currentTimePlan}
                currentDay={currentDayOfWeek}
                onClickTimePlan={handleClickTimePlan}
                onClickDayOfWeek={setCurrentDayOfWeek}
                onClickCreateTimePlan={handleOpenCreateTimePlanDialog}
                onEditTimePlanName={handleEditTimePlanName}
                onDeleteTimePlan={handleDeleteTimePlan}
              />
            )
          }[sideBarMode]
        }
      </SideBar>

      <main className={classes.content + ' to-print'}>
        <div className={classes.toolbar + ' to-hide'} />

        {isDayOrWeekDay() && (
          <Box className={classes.planHeading} px={3} py={2} mb={4} position="sticky" top={80} zIndex={1} boxShadow={4} bgcolor="#ffffff">
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.timePlanHeader} ref={topBarEl} spacing={2}>
              <Grid item>
                <Typography variant="h6" component="h2" className={classes.planTitle}>
                  <FontAwesomeIcon icon={'day-sun' as IconProp} />
                  {isWeek(currentTimePlan) && isWeekDay() ? `${(currentTimePlan as IWeek).days.find(day => day.id === currentDayOfWeek.id).name} - ` : ''}
                  {currentTimePlan.name}
                </Typography>
              </Grid>

              <Grid item className="to-hide">
                <Button
                  startIcon={<FontAwesomeIcon icon="plus-circle" />}
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    setActiveActivityIdx(null);
                    handleSideBarMode(SideBarMode.NEW_ACTIVITY);
                  }}
                  disabled={sideBarMode === SideBarMode.NEW_ACTIVITY}
                  disableFocusRipple
                >
                  Ny aktivitet
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}

        {isDayOrWeekDay() ? (
          <Box px={3}>
            <ActivitiesListContainer gridTemplate="min-content">
              {(viewMode === ViewMode.QUARTERS || viewMode === ViewMode.HOURS) && <ActivitiesListTime viewMode={viewMode as any} offsetTop={180} />}
              <ActivitiesList
                day={isWeek(currentTimePlan) ? (currentTimePlan as IWeek).days.find(day => day.id === currentDayOfWeek.id) : (currentTimePlan as IDay)}
                viewMode={viewMode as any}
                setActiveActivityIdx={handleActiveActivityIdx}
                activeActivityIdx={activeActivityIdx}
              />
            </ActivitiesListContainer>
          </Box>
        ) : (
          <DaysList week={currentTimePlan as IWeek} viewMode={viewMode} onMoveActivity={handleMoveActivity} />
        )}
      </main>

      <CreateTimeplanDialog
        open={openCreateTimePlanDialog}
        onClose={handleCloseCreateTimePlanDialog}
        onCreateWeek={handleCreateWeek}
        onCreateDay={handleCreateDay}
        dialogBackButton={backButton}
      />

      <Backdrop open={loadingOpen} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  ) : (
    <></>
  );
}

export default withStyles(TimePlanningPageStyles)(TimePlanningPage);
