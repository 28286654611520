import React, { Component } from 'react';
import { Classes } from 'jss';
import { Theme, createStyles, DialogContent, Typography, withStyles, Button, Grid } from '@material-ui/core';
import { IZoomMeeting } from 'app/shared/model/zoom-meeting.model';
import DigiJagBaseDialog from '../base-dialogs/DigiJagBaseDialog';
import { RouteComponentProps, withRouter } from 'react-router';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const ZoomMultipleMeetingsDialogStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogContent: {
      padding: '0 5rem 2rem',
      [breakpoints.down('xs')]: {
        padding: '0 2rem 1rem'
      }
    },
    dialogTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      letterSpacing: '1.23px',
      marginBottom: '2.5rem'
    },
    dialogText: {
      marginBottom: '2.5rem'
    },
    zoomButton: {
      width: '100%',
      padding: 0,
      borderRadius: 10,
      background: 'linear-gradient(#F2AE40, #D59936)',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      '&:hover': {
        background: 'linear-gradient(#c57b00, #ab6b00)'
      }
    },
    avatarContainer: {
      textAlign: 'left'
    },
    avatar: {
      width: 96,
      height: 96,
      margin: 0,
      objectFit: 'contain',
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      [breakpoints.down('xs')]: {
        width: 54,
        height: 54
      }
    },
    zoomIconContainer: {
      alignSelf: 'center',
      textAlign: 'right'
    },
    zoomIcon: {
      height: 60,
      width: 60,
      marginRight: 10,
      [breakpoints.down('xs')]: {
        height: 30,
        width: 30
      }
    },
    icon: {
      width: '1.5rem !important',
      fontSize: '1.5rem',
      verticalAlign: 'middle',
      margin: '0 10px',
      [breakpoints.down('xs')]: {
        width: '1.2rem !important',
        fontSize: '1rem',
        margin: '0 6px'
      }
    },
    detailsContainer: {
      alignSelf: 'center',
      '& p': {
        fontSize: '1rem !important',
        fontWeight: 500,
        [breakpoints.down('xs')]: {
          fontSize: '0.8rem !important'
        }
      }
    },
    hostName: {
      marginBottom: 12,
      [breakpoints.down('xs')]: {
        marginBottom: 0
      }
    }
  });

interface IZoomMultipleMeetingsDialogProps extends RouteComponentProps<{}> {
  classes: Classes;
  meetings: IZoomMeeting[];
  onClose: any;
  isOpen: boolean;
}

export class ZoomMultipleMeetingsDialog extends Component<IZoomMultipleMeetingsDialogProps> {
  onJoin = meetingId => {
    const { onClose } = this.props;
    onClose();
    this.props.history.push(`/dashboard${translate('digijagApp.routes.meeting')}/${meetingId}`);
  };

  render() {
    const { classes, meetings, onClose, isOpen } = this.props;

    return (
      <>
        <DigiJagBaseDialog open={isOpen} onClose={onClose} withCloseButton>
          <DialogContent className={classes.dialogContent}>
            <Typography variant="h2" align="center" className={classes.dialogTitle}>
              <Translate contentKey="digijagApp.zoomMeeting.meetings">Möten</Translate>
            </Typography>
            <Typography variant="body1" align="inherit" className={classes.dialogText}>
              <Translate contentKey="digijagApp.zoomMeeting.chooseMeeting">Välj ett möte att gå med i</Translate>
            </Typography>
            <Grid container direction="column" alignItems="stretch" spacing={2}>
              {meetings.map(meeting => (
                <Grid item key={meeting.id}>
                  <Button onClick={() => this.onJoin(meeting.id)} className={classes.zoomButton}>
                    <Grid container direction="row" alignItems="stretch">
                      <Grid item xs={10} sm={9} container direction="row" wrap="nowrap" spacing={0}>
                        {/* Host image*/}
                        <Grid item className={classes.avatarContainer}>
                          <img
                            src={
                              meeting.hostImgUrl ||
                              'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                            }
                            alt=""
                            className={classes.avatar}
                          />
                        </Grid>

                        {/* Host name & topic*/}
                        <Grid item className={classes.detailsContainer}>
                          {/* Host name */}
                          <Typography align="left" className={classes.hostName} noWrap>
                            <FontAwesomeIcon icon="user" className={classes.icon} />
                            {meeting.hostName}
                          </Typography>

                          {/* Topic */}
                          <Typography align="left" noWrap>
                            <FontAwesomeIcon icon="comments" className={classes.icon} />
                            {meeting.topic || translate('digijagApp.zoomMeeting.defaultTopic')}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Zoom logo */}
                      <Grid item xs={2} sm={3} className={classes.zoomIconContainer}>
                        <img src="content/icons/zoom-logo.svg" alt="" className={classes.zoomIcon} />
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </DigiJagBaseDialog>
      </>
    );
  }
}

export default withStyles(ZoomMultipleMeetingsDialogStyles)(withRouter(ZoomMultipleMeetingsDialog));
