import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import { Translate, getUrlParameter } from 'react-jhipster';

import { IRootState } from 'app/shared/reducers';
import { acceptInvitationAction, reset } from './accept.reducer';

const successAlert = (
  <Alert color="success">
    <Translate contentKey="accept.messages.success">
      <strong>You have accepted the invitation</strong>
    </Translate>
    <Link to="/login" className="alert-link">
      <Translate contentKey="global.messages.info.authenticated.link">sign in</Translate>
    </Link>
  </Alert>
);

const failureAlert = message => (
  <Alert color="danger">
    <p>
      <Translate contentKey="accept.messages.error">
        <strong>We failed to connect you to Student.</strong>
        Please contact support.
      </Translate>
    </p>
    <p>{message}</p>
  </Alert>
);

export interface IAcceptProps extends StateProps, DispatchProps, RouteComponentProps<{ key: any }> {}

export class AcceptPage extends React.Component<IAcceptProps> {
  componentWillUnmount() {
    this.props.reset();
  }

  componentDidMount() {
    const token = getUrlParameter('invitationToken', this.props.location.search);
    this.props.acceptInvitationAction(token);
  }

  render() {
    const { acceptSuccess, acceptFailure, message } = this.props;

    return (
      <div className="container-fluid" style={{ padding: '1rem' }}>
        <Row className="justify-content-center">
          <Col md="8">
            <h1>
              <Translate contentKey="accept.title">Accept Invitation</Translate>
            </h1>
            {acceptSuccess ? successAlert : undefined}
            {acceptFailure ? failureAlert(message) : undefined}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ accept }: IRootState) => ({
  acceptSuccess: accept.acceptSuccess,
  acceptFailure: accept.acceptFailure,
  message: accept.message
});

const mapDispatchToProps = { acceptInvitationAction, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptPage);
