import React from 'react';

import ChangeStudentsPasswordForm from './ChangeStudentsPasswordForm';
import DigiJagBaseDialog from '../base-dialogs/DigiJagBaseDialog';

interface IChangeStudentsPasswordDialogProps {
  userId?: number;
  isOpen: boolean;
  onClose: Function;
}

interface IChangeStudentsPasswordDialogState {
  isOpen: boolean;
}

export class ChangeStudentsPasswordDialog extends React.Component<IChangeStudentsPasswordDialogProps, IChangeStudentsPasswordDialogState> {
  render() {
    const { userId, onClose, isOpen } = this.props;

    return (
      <DigiJagBaseDialog open={isOpen} onClose={onClose} aria-labelledby="dialog-description">
        <ChangeStudentsPasswordForm handleCloseDialog={onClose} userId={userId} />
      </DigiJagBaseDialog>
    );
  }
}

export default ChangeStudentsPasswordDialog;
