import React from 'react';

import { withStyles, createStyles, Theme, DialogContent, DialogActions, Grid, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import AcceptButton from '../../form-inputs/AcceptButton';
import { translate } from 'react-jhipster';

export const DigiJagAlertContentStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogText: {
      fontSize: 18,
      color: '#000000',
      marginTop: 30,
      textAlign: 'center',
      fontWeight: 600,
      lineHeight: 1.8,
      [breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: 1.5
      }
    },

    dialogContent: {
      flex: 'none',
      paddingBottom: 50,
      [breakpoints.down('xs')]: {
        padding: 24
      }
    },

    dialogIcon: {
      [breakpoints.down('xs')]: {
        height: 90
      }
    },

    buttonGrid: {
      paddingBottom: '1.5rem',
      [breakpoints.down('xs')]: {
        justifyContent: 'space-evenly'
      }
    }
  });

interface IDigiJagAlertContentProps {
  classes: Classes;
  icon: string;
  text: any;
  buttonText?: string;
  onClose: Function;
}

export class DigiJagAlertContent extends React.Component<IDigiJagAlertContentProps> {
  render() {
    const { classes, icon, text, buttonText, onClose } = this.props;

    return (
      <React.Fragment>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column" justify="center" alignItems="center">
            <img src={`content/icons/${icon}.svg`} alt="" className={classes.dialogIcon} />
            <Typography id="dialog-description" variant="h1" color="inherit" className={classes.dialogText}>
              {text}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justify="center" alignItems="center" className={classes.buttonGrid}>
            <AcceptButton onClick={() => onClose()}>{buttonText || translate('entity.action.ok')}</AcceptButton>
          </Grid>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default withStyles(DigiJagAlertContentStyles)(DigiJagAlertContent);
