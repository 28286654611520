import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Col, Row, Table, Alert } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAllAction, getSortState, IPaginationBaseState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities, reset as resetOrganization, searchEntities } from './organization.reducer';
import { IOrganization } from 'app/shared/model/organization.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { getCurrentUserData } from 'app/entities/user-data/user-data.reducer';

// tslint:disable-next-line:no-unused-variable
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { createStyles, Grid, Typography, Zoom } from '@material-ui/core';

export interface IOrganizationProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IOrganizationState extends IPaginationBaseState {
  searchStr: string;
}

export class Organization extends React.Component<IOrganizationProps, IOrganizationState> {
  state: IOrganizationState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    searchStr: ''
  };

  componentDidMount() {
    this.props.getCurrentUserData();
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateSuccess) {
      this.reset();
    }
  }

  reset = () => {
    this.props.resetOrganization();
    this.setState({ activePage: 1 }, () => {
      this.getEntities();
    });
  };

  sliceText = (text: string, length: number): string => (text ? text.slice(0, length) + '...' : '');

  handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      this.setState({ activePage: this.state.activePage + 1 }, () => this.getEntities());
    }
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => {
        this.reset();
      }
    );
  };

  getEntities = () => {
    event.preventDefault();
    const { activePage, itemsPerPage, sort, order, searchStr } = this.state;
    if (searchStr) {
      this.props.searchEntities(searchStr, activePage - 1, itemsPerPage, `${sort}`);
    } else {
      this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    }
  };

  onChange = e => {
    this.setState({
      searchStr: e.currentTarget.value
    });
  };

  render() {
    const { organizationList, match } = this.props;
    return (
      <div>
        <Grid container direction="row" justify="space-between" alignItems="flex-end" alignContent="center" className={'mb-3'}>
          <h2 id="organization-heading">
            <Translate contentKey="digijagApp.organization.home.title">Organizations</Translate>
          </h2>
          {/* <Grid item>
            <form onSubmit={this.getEntities}>
              <div>
                <label htmlFor="searchbar">
                  <Translate contentKey="digijagApp.organization.searchBarLabel">Search for Organization</Translate>
                </label>
                <div className={'d-flex'}>
                  <input id="searchbar" autoFocus type="text" className={'form-control'} value={this.state.searchStr} onChange={this.onChange} />
                  <div className={'input-group-append'}>
                    <button className={'btn btn-outline-secondary'}>
                      <Translate contentKey="entity.action.search"> Search</Translate>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Grid> */}
          {this.props.isAdmin && (
            <Grid item>
              <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="digijagApp.organization.home.createLabel">Create new Organization</Translate>
              </Link>
            </Grid>
          )}
        </Grid>
        <div className="table-responsive">
          <InfiniteScroll
            pageStart={this.state.activePage}
            loadMore={this.handleLoadMore}
            hasMore={this.state.activePage - 1 < this.props.links.next}
            loader={<div className="loader">Loading ...</div>}
            threshold={0}
            initialLoad={false}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={this.sort('id')}>
                    <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('name')}>
                    <Translate contentKey="digijagApp.organization.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('address.addresLine1 ')}>
                    <Translate contentKey="digijagApp.organization.legalName">Legal Name</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('organizatinNumber')}>
                    <Translate contentKey="digijagApp.organization.orgNr">Org Nr</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('licenses')}>
                    <Translate contentKey="digijagApp.organization.licenses">Licenses</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('availableLicenses')}>
                    <Translate contentKey="digijagApp.organization.availableLicenses">Available licenses</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('address.addresLine1 ')}>
                    <Translate contentKey="digijagApp.organization.address">Address</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {organizationList &&
                  organizationList.map((organization, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <Button tag={Link} to={`${match.url}/${organization.id}`} color="link" size="sm">
                          {organization.id}
                        </Button>
                      </td>
                      <td>{organization.name}</td>
                      <td>{organization.legalName}</td>
                      <td>{organization.organizationNumber}</td>
                      <td>{organization.licenses}</td>
                      <td>{organization.availableLicenses}</td>
                      <td>
                        {organization.billingAddress.addressLine1}, {organization.billingAddress.city}
                      </td>
                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button tag={Link} to={`${match.url}/${organization.id}/edit`} color="primary" size="sm">
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${organization.id}/delete`} color="danger" size="sm">
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ organization, authentication, userData }: IRootState) => ({
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPERADMIN]),
  isOrganizationAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMIN]),
  organizationList: organization.entities,
  totalItems: organization.totalItems,
  links: organization.links,
  entity: organization.entity,
  updateSuccess: organization.updateSuccess,
  currentUserData: userData.entity
});

const mapDispatchToProps = {
  getEntities,
  resetOrganization,
  searchEntities,
  getCurrentUserData
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Organization));
