import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getMeeting } from 'app/entities/zoom-meeting/zoom-meeting.reducer';

export interface IZoomMeetingDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ZoomMeetingDetail extends React.Component<IZoomMeetingDetailProps> {
  componentDidMount() {
    this.props.getMeeting(this.props.match.params.id);
  }

  render() {
    const { zoomMeetingEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            <Translate contentKey="digijagApp.zoomMeeting.detail.title">Zoom Meeting</Translate> [<b>{zoomMeetingEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="meetingId">
                <Translate contentKey="digijagApp.zoomMeeting.meetingId">Zoom meeting ID</Translate>
              </span>
            </dt>
            <dd>{zoomMeetingEntity.meetingId}</dd>
            <dt>
              <span id="type">
                <Translate contentKey="digijagApp.zoomMeeting.type">Type</Translate>
              </span>
            </dt>
            <dd>{zoomMeetingEntity.type}</dd>
            <dt>
              <span id="topic">
                <Translate contentKey="digijagApp.zoomMeeting.topic">Topic</Translate>
              </span>
            </dt>
            <dd>{zoomMeetingEntity.topic}</dd>
            <dt>
              <span id="hostName">
                <Translate contentKey="digijagApp.zoomMeeting.hostName">Host Name</Translate>
              </span>
            </dt>
            <dd>{zoomMeetingEntity.hostName}</dd>
            <dt>
              <span id="joinUrl">
                <Translate contentKey="digijagApp.zoomMeeting.joinUrl">Join url</Translate>
              </span>
            </dt>
            <dd>{zoomMeetingEntity.joinUrl}</dd>
            <dt>
              <span id="startUrl">
                <Translate contentKey="digijagApp.zoomMeeting.startUrl">startUrl</Translate>
              </span>
            </dt>
            <dd>{zoomMeetingEntity.startUrl}</dd>
            <dt>
              <span id="password">
                <Translate contentKey="digijagApp.zoomMeeting.password">password</Translate>
              </span>
            </dt>
            <dd>{zoomMeetingEntity.password}</dd>
          </dl>
          <Button tag={Link} to="/entity/zoom" replace color="info">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/entity/zoom/${zoomMeetingEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ zoom }: IRootState) => ({
  zoomMeetingEntity: zoom.zoomMeeting
});

const mapDispatchToProps = { getMeeting };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomMeetingDetail);
