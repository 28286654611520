import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { IRootState } from 'app/shared/reducers';
import { savePasswordForUser, reset } from 'app/modules/account/password/password.reducer';
import InputField from '../../form-inputs/InputField';
import { createStyles, Theme, withStyles, Typography, Grid, Button, DialogContent, DialogTitle } from '@material-ui/core';
import { Classes } from 'jss';
import DigiJagAlertContent from '../base-dialogs/DigiJagAlertContent';
import { APP_PASSWORD_LENGTH } from 'app/config/constants';
import AcceptButton from 'app/custom-components/form-inputs/AcceptButton';

export interface IChangeStudentsPasswordFormProps extends StateProps, DispatchProps {
  classes: Classes;
  handleCloseDialog: Function;
  userId: number;
}

export interface IChangeStudentsPasswordFormState {
  password: string;
}

export const ChangeStudentsPasswordFormStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogContent: {
      padding: '0 6rem 1.5rem',
      marginBottom: '-1.5rem',
      fontSize: '1.125rem',
      letterSpacing: '0.78px',
      [breakpoints.down('xs')]: {
        padding: '1.5rem'
      },
      '& p': {
        color: 'inherit',
        textAlign: 'center',
        fontSize: 'inherit',
        fontWeight: 500,
        marginBottom: '1rem'
      }
    },

    formTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      marginBottom: '1em',
      textAlign: 'center',
      letterSpacing: '1.23px',
      [breakpoints.down('xs')]: {
        fontSize: '1rem',
        lineHeight: 1.5
      }
    },

    dialogTopBar: {
      paddingTop: 0,
      [breakpoints.down('xs')]: {
        paddingBottom: 0,
        paddingRight: 0
      }
    },

    xButton: {
      padding: 0,
      minHeight: 40,
      minWidth: 40
    }
  });

export class ChangeStudentsPasswordForm extends React.Component<IChangeStudentsPasswordFormProps, IChangeStudentsPasswordFormState> {
  state: IChangeStudentsPasswordFormState = {
    password: ''
  };

  componentDidMount() {
    this.props.reset();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = (event, values) => {
    this.props.savePasswordForUser(this.props.userId, values.newPassword);
  };

  updatePassword = event => {
    this.setState({ password: event.target.value });
  };

  render() {
    const { classes, updateSuccess, updateFailure, handleCloseDialog } = this.props;

    return (
      <React.Fragment>
        {updateSuccess ? (
          <DigiJagAlertContent buttonText="Okey!" icon="smile" text="Härligt! Du har bytt lösernordet." onClose={handleCloseDialog} />
        ) : (
          <React.Fragment>
            <DialogTitle disableTypography className={classes.dialogTopBar}>
              <Grid container justify="flex-end">
                <Button onClick={() => handleCloseDialog()} color="primary" aria-label="Stäng" className={classes.xButton}>
                  <img src="content/icons/x-icon.svg" alt="" />
                </Button>
              </Grid>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <Grid container direction="column" alignItems="stretch">
                <Typography id="dialog-description" variant="h2" color="inherit" className={classes.formTitle}>
                  <img src="content/icons/key.svg" alt="" /> Byt Lösenord
                </Typography>
                <Typography>
                  Byt lösenord åt en student här.
                  <br />
                  Det måste innehålla minst {APP_PASSWORD_LENGTH.min} tecken.
                </Typography>
                <AvForm id="password-form" onValidSubmit={this.handleValidSubmit}>
                  <AvField
                    name="newPassword"
                    label={translate('global.form.newpassword')}
                    type="password"
                    tag={InputField}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate('global.messages.validate.newpassword.required')
                      },
                      minLength: {
                        value: APP_PASSWORD_LENGTH.min,
                        errorMessage: translate('global.messages.validate.newpassword.minlength', { length: APP_PASSWORD_LENGTH.min })
                      },
                      maxLength: {
                        value: APP_PASSWORD_LENGTH.max,
                        errorMessage: translate('global.messages.validate.newpassword.maxlength', { length: APP_PASSWORD_LENGTH.max })
                      }
                    }}
                    onChange={this.updatePassword}
                  />
                  <AvField
                    name="confirmPassword"
                    label={translate('global.form.confirmpassword')}
                    type="password"
                    tag={InputField}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate('global.messages.validate.confirmpassword.required')
                      },
                      minLength: {
                        value: APP_PASSWORD_LENGTH.min,
                        errorMessage: translate('global.messages.validate.confirmpassword.minlength', { length: APP_PASSWORD_LENGTH.min })
                      },
                      maxLength: {
                        value: APP_PASSWORD_LENGTH.max,
                        errorMessage: translate('global.messages.validate.confirmpassword.maxlength', { length: APP_PASSWORD_LENGTH.max })
                      },
                      match: {
                        value: 'newPassword',
                        errorMessage: translate('global.messages.error.dontmatch')
                      }
                    }}
                  />
                  <Typography align="center">
                    <AcceptButton type="submit" icon="send-form">
                      Byt Lösenord
                    </AcceptButton>
                  </Typography>
                </AvForm>
                {updateFailure && (
                  <Typography variant="body1" color="inherit">
                    <Translate contentKey="password.messages.error" />
                  </Typography>
                )}
              </Grid>
            </DialogContent>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ password }: IRootState) => ({
  updateSuccess: password.updateSuccess,
  updateFailure: password.updateFailure
});

const mapDispatchToProps = { savePasswordForUser, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ChangeStudentsPasswordFormStyles)(ChangeStudentsPasswordForm));
