import React, { useEffect, useState } from 'react';
import { withStyles, createStyles, Theme, Typography, Paper, Box, Grid, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { Classes } from 'jss';
import VerticalContainer from './VerticalContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FrejaButton from 'app/shared/auth/freja-button';
import { AuthMode } from 'app/shared/auth/auth_modes';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';

export interface IStyleProps {
  backdropBgColor?: string;
}

const StartPageStyles = (theme: Theme) =>
  createStyles({
    mainPaper: {
      backgroundColor: 'rgba(255, 255, 255, .8)',
      padding: theme.spacing(3),
      width: '100%'
    },
    secondaryPaper: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
      margin: `${theme.spacing(3)}px 0`,
      '& > p': {
        margin: `${theme.spacing(1)}px 0`
      },
      '& .svg-inline--fa': {
        marginRight: '.5rem',
        border: '2px solid #000000',
        borderRadius: '50%',
        background: '#000000'
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      background: (props: IStyleProps) => props.backdropBgColor || '#00000060',
      color: '#000'
    }
  });

interface IStartPageProps extends StateProps {
  classes: Classes;
  title: any;
  guestSubtitle: any;
  guestButtonLabel: any;
  loginSubtitle: any;
  onStartApp: any;
  projectName?: string;
  backdropBgColor?: string;
}

interface IStartPageState {
  loadingOpen: boolean;
}

function StartPage(props: IStartPageProps) {
  const { classes, onStartApp, title, guestSubtitle, guestButtonLabel, loginSubtitle, projectName } = props;

  const [loadingOpen, setLoadingOpen] = useState<IStartPageState['loadingOpen']>(false);

  useEffect(() => {
    if (props.account && props.isAuthenticated) {
      onStartApp();
    }
  }, []);

  const features = ['Gratis', 'Skriv ut', 'Spara', `Öppna tidigare ${projectName || 'projekt'}`];

  const getFeaturesList = (statusList: boolean[]) => {
    const keyPrefix = Date.now();

    return statusList.map((status, idx) => (
      <Typography key={`feat-${keyPrefix}-${idx}`} variant="body1">
        <FontAwesomeIcon size="lg" icon={status ? 'check-circle' : 'times-circle'} color={status ? '#96df62' : '#ee657c'} /> {features[idx]}
      </Typography>
    ));
  };

  return (
    <VerticalContainer>
      <Grid container spacing={5} justify="center" style={{ maxWidth: '100vw' }}>
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Box px="20px">
            <Paper elevation={3} className={classes.mainPaper}>
              <Typography variant="h5" component="h1" align="center">
                {title}
              </Typography>
            </Paper>
          </Box>
        </Grid>
        <Box width="100%" />
        <Grid container item xs={12} md={10} lg={8} xl={6} justify="space-between" spacing={5}>
          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.mainPaper}>
              <Typography variant="body1">{guestSubtitle}</Typography>
              <Paper className={classes.secondaryPaper}>{getFeaturesList([true, true, false, false])}</Paper>
              <Button variant="contained" size="small" onClick={onStartApp} startIcon={<FontAwesomeIcon icon="plus-circle" />}>
                {guestButtonLabel}
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.mainPaper}>
              <Typography variant="body1">{loginSubtitle}</Typography>
              <Paper className={classes.secondaryPaper}>{getFeaturesList([true, true, true, true])}</Paper>
              <FrejaButton
                labelBeforeLogo="Fortsätt med"
                authMode={projectName === 'budget' ? AuthMode.Budgettavlan : AuthMode.Tidsplanering}
                onClick={() => setLoadingOpen(true)}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <Backdrop open={loadingOpen} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </VerticalContainer>
  );
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(withStyles(StartPageStyles)(StartPage));
