import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntities as getUserData } from 'app/entities/user-data/user-data.reducer';
import ImageInput from 'app/custom-components/form-inputs/imageInput';

import { AUTHORITIES } from 'app/config/constants';
import { getEntity, updateEntity, createEntity, reset } from './organization.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { convertDateTimeFromServer } from 'app/shared/util/date-time-utils';

export interface IOrganizationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IOrganizationUpdateState {
  isNew: boolean;
  userDataId: string;
  imgUrl: string;
}

export class OrganizationUpdate extends React.Component<IOrganizationUpdateProps, IOrganizationUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userDataId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      imgUrl: ''
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUserData();
  }

  saveEntity = (event, errors, values) => {
    values.lastInvoiceSent = new Date(values.lastInvoiceSent);

    if (errors.length === 0) {
      const { organizationEntity } = this.props;
      const entity = {
        ...organizationEntity,
        ...values,
        imgUrl: this.state.imgUrl || organizationEntity.imgUrl
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
      this.handleClose();
    }
  };

  handleImageChange = value => {
    this.setState({ imgUrl: value });
  };

  handleClose = () => {
    this.props.history.push('/entity/organization');
  };

  render() {
    const { organizationEntity, isAdmin, loading, updating } = this.props;
    const { isNew, imgUrl } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="digijagApp.organization.home.createOrEditLabel">
              <Translate contentKey="digijagApp.organization.home.createOrEditLabel">Create or edit a Organization</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : organizationEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="organization-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="organization-name">
                    <Translate contentKey="digijagApp.organization.name">Name</Translate>
                  </Label>
                  <AvField id="organization-name" type="text" name="name" required />
                </AvGroup>
                <AvGroup>
                  <Label id="nameLabel" for="organization-legal-name">
                    <Translate contentKey="digijagApp.organization.legalName">Legal Name</Translate>
                  </Label>
                  <AvField id="organization-legal-name" type="text" name="legalName" required />
                </AvGroup>
                <AvGroup>
                  <Label id="address1Label" for="organization-address1">
                    <Translate contentKey="digijagApp.organization.addressLine1">Address Line 1</Translate>
                  </Label>
                  <AvField
                    id="organization-address1"
                    type="text"
                    name="billingAddress.addressLine1"
                    value={organizationEntity.billingAddress && organizationEntity.billingAddress.addressLine1}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="address2Label" for="organization-address2">
                    <Translate contentKey="digijagApp.organization.addressLine2">Address Line 2</Translate>
                  </Label>
                  <AvField
                    id="organization-address2"
                    type="text"
                    name="billingAddress.addressLine2"
                    value={organizationEntity.billingAddress && organizationEntity.billingAddress.addressLine2}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="zipCodeLabel" for="organization-zipCode">
                    <Translate contentKey="digijagApp.organization.zipCode">Zip Code</Translate>
                  </Label>
                  <AvField
                    id="organization-zipCode"
                    type="text"
                    name="billingAddress.zipCode"
                    value={organizationEntity.billingAddress && organizationEntity.billingAddress.zipCode}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="cityLabel" for="organization-city">
                    <Translate contentKey="digijagApp.organization.city">City</Translate>
                  </Label>
                  <AvField
                    id="organization-city"
                    type="text"
                    name="billingAddress.city"
                    value={organizationEntity.billingAddress && organizationEntity.billingAddress.city}
                    required
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="regionLabel" for="organization-region">
                    <Translate contentKey="digijagApp.organization.region">Region</Translate>
                  </Label>
                  <AvField
                    id="organization-region"
                    type="text"
                    name="billingAddress.region"
                    value={organizationEntity.billingAddress && organizationEntity.billingAddress.region}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="licensesLabel" for="organization-orgnr">
                    <Translate contentKey="digijagApp.organization.orgNr">Org Nr</Translate>
                  </Label>
                  <AvField id="organization-orgnr" type="text" name="organizationNumber" required />
                </AvGroup>
                <AvGroup>
                  <Label id="referenceInfoLabel" for="organization-referenceinfo">
                    <Translate contentKey="digijagApp.organization.referenceInformation">Referensinformation</Translate>
                  </Label>
                  <AvField id="organization-referenceinfo" type="text" name="referenceInformation" />
                </AvGroup>
                <AvGroup>
                  <Label id="lastInvoiceNumberLabel" for="organization-lastinvoicenumber">
                    <Translate contentKey="digijagApp.organization.lastInvoiceNumber">Senaste fakturanummer</Translate>
                  </Label>
                  <AvField id="organization-lastinvoicenumber" type="text" name="lastInvoiceNumber" />
                </AvGroup>
                <AvGroup>
                  <Label id="lastInvoiceSentLabel" for="organization-lastinvoicesent">
                    <Translate contentKey="digijagApp.organization.lastInvoiceSent">Audited At</Translate>
                  </Label>
                  <AvInput
                    id="organization-lastinvoicesent"
                    type="datetime-local"
                    className="form-control"
                    name="lastInvoiceSent"
                    value={isNew ? null : convertDateTimeFromServer(this.props.organizationEntity.lastInvoiceSent)}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="descriptionLabel" for="organization-licenses">
                    <Translate contentKey="digijagApp.organization.licenses">Licenser</Translate>
                  </Label>
                  <AvField id="organization-licenses" type="text" name="licenses" required readOnly={!isAdmin} />
                </AvGroup>
                <AvGroup>
                  <Label id="logoUrlLabel" for="organization-logoUrl">
                    <Translate contentKey="digijagApp.organization.imgUrl">Img Url</Translate>
                  </Label>
                  <ImageInput id="organization-logoUrl" imgUrl={imgUrl || organizationEntity.imgUrl} handleChange={this.handleImageChange} required={false} />
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/organization" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  userData: storeState.userData.entities,
  organizationEntity: storeState.organization.entity,
  loading: storeState.organization.loading,
  updating: storeState.organization.updating,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SUPERADMIN])
});

const mapDispatchToProps = {
  getUserData,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationUpdate);
