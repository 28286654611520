import React from 'react';
import { withStyles, createStyles, Theme, ButtonGroup, Button } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewMode } from '../pages/TimePlanningPage';
import customTheme from '../theme';

const ViewModeSwitchStyles = (theme: Theme) =>
  createStyles({
    activeButton: {
      color: '#FFFFFF',
      backgroundColor: theme.palette.secondary.main,
      '& svg': {
        color: 'currentColor !important'
      },
      '&:hover, &:focus': {
        color: `${theme.palette.secondary.main} !important`,
        '& .mode-icon': {
          color: `${theme.palette.secondary.main} !important`
        }
      },
      '& .mode-icon': {
        color: '#FFFFFF'
      }
    },
    '@global': {
      '.mode-icon': {
        width: 'var(--w)',
        aspectRatio: '1',
        position: 'relative',
        display: 'inline-grid',
        color: `${theme.palette.secondary.main}`,
        border: '2px solid currentColor',
        borderRadius: '50%',
        transform: 'scaleX(-1)',
        '&:before': {
          content: '""',
          position: 'absolute',
          borderRadius: '50%',
          inset: '1px',
          background: 'conic-gradient(currentColor calc(var(--p)*1%), #0000 0)'
        }
      }
    }
  });

interface IViewModeSwitchProps {
  classes: Classes;
  viewMode: ViewMode;
  onChange: Function;
}

function ViewModeSwitch(props: React.PropsWithChildren<IViewModeSwitchProps>) {
  const { classes, viewMode, onChange } = props;

  return (
    <ButtonGroup aria-label="Välj visningsläge mellan timmar, kvartar, eller enkel lista" size="small" color="default" disableFocusRipple>
      <Button
        startIcon={
          <div
            className="mode-icon"
            style={
              {
                '--w': '25px',
                '--p': 100
              } as any
            }
          />
        }
        variant="contained"
        size="small"
        onClick={() => onChange(ViewMode.HOURS)}
        aria-label="Visa listan i timmar"
        className={viewMode === ViewMode.HOURS && classes.activeButton}
        onFocus={e => e.target.blur()}
      >
        <span aria-hidden="true">Timmar</span>
      </Button>
      <Button
        startIcon={
          <div
            className="mode-icon"
            style={
              {
                '--w': '25px',
                '--p': 25
              } as any
            }
          />
        }
        variant="contained"
        size="small"
        onClick={() => onChange(ViewMode.QUARTERS)}
        aria-label="Visa listan i kvartar"
        className={viewMode === ViewMode.QUARTERS && classes.activeButton}
        onFocus={e => e.target.blur()}
      >
        <span aria-hidden="true">Kvartar</span>
      </Button>
      <Button
        startIcon={<FontAwesomeIcon icon="list-ul" color={customTheme.palette.secondary.main} />}
        variant="contained"
        size="small"
        onClick={() => onChange(ViewMode.LIST)}
        aria-label="Visa i en enkel lista"
        className={viewMode === ViewMode.LIST && classes.activeButton}
        onFocus={e => e.target.blur()}
      >
        <span aria-hidden="true">Lista</span>
      </Button>
    </ButtonGroup>
  );
}

export default withStyles(ViewModeSwitchStyles)(ViewModeSwitch);
