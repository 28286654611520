import React from 'react';
import { endAndDeleteMeeting } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';

interface IZoomAppLeaveEndProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  updateSuccess;
}

export class ZoomAppLeaveEnd extends React.Component<IZoomAppLeaveEndProps> {
  getLeaveUrl = () => window.location.protocol + '//' + window.location.host;

  componentDidMount = () => {
    this.props.endAndDeleteMeeting(this.props.match.params.id);
  };

  componentDidUpdate = prevProps => {
    if (!prevProps.updateSuccess && this.props.updateSuccess) {
      // The deleteZoom backend call is done, leave the route:
      window.top.location.href = this.getLeaveUrl();
    }
  };

  render() {
    return <Translate contentKey="digijagApp.zoomMeeting.ending">Ending and deleting meeting...</Translate>;
  }
}

const mapStateToProps = ({ zoom }: IRootState) => ({
  updateSuccess: zoom.updateSuccess
});

const mapDispatchToProps = {
  endAndDeleteMeeting
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomAppLeaveEnd);
