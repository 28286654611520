import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IScenario } from 'app/shared/model/budgettavlan/expense.model';

interface IScenarioInfoDialogProps {
  open: boolean;
  onClose: any;
  scenario: IScenario;
}

export class ScenarioInfoDialog extends React.Component<IScenarioInfoDialogProps> {
  render() {
    const { open, onClose, scenario } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="reset-dialog-title" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="reset-dialog-title">
          <FontAwesomeIcon icon={scenario.expense.icon as any} />
          {scenario.expense.name}
        </DialogTitle>

        <DialogContent>
          {scenario.additionalInfo && (
            <Typography variant="body1" gutterBottom style={{ whiteSpace: 'pre-line' }}>
              {scenario.additionalInfo}
              <br />
              &nbsp;
            </Typography>
          )}

          <TableContainer component={Paper}>
            <Table aria-label={`detaljerade kostnader för ${scenario.expense.name}`}>
              <TableHead style={{ backgroundColor: '#434343' }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="body1" style={{ color: '#ffffff' }}>
                      Kostnad
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body1" style={{ color: '#ffffff' }}>
                      Kronor
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scenario.detailedCosts.map((cost, idx) => (
                  <TableRow key={`${scenario.expense.scenarioId}-cost-${idx}`}>
                    <TableCell component="th" scope="row">
                      {cost.title}
                    </TableCell>
                    <TableCell align="right">{cost.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={onClose} color="primary" startIcon={<FontAwesomeIcon icon="thumbs-up" />}>
            Okej
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ScenarioInfoDialog;
