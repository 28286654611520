import React from 'react';
import { withStyles, createStyles, Theme, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const ActivityFormButtonsStyles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1
    },
    formBtn: {
      backgroundColor: 'transparent !important',
      transition: 'all 250ms ease',
      borderBottom: '1px solid #fff',
      '&:first-child': {
        borderTop: '1px solid #fff'
      },
      '&:hover, &:focus': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        '& $formBtnIcon': {
          color: `${theme.palette.secondary.main} !important`
        },
        '& $formBtnText': {
          color: '#000 !important'
        }
      }
    },
    formBtnIcon: {
      color: '#fff !important'
    },
    formBtnText: {
      color: '#fff !important'
    }
  });

interface IActivityFormButtonsProps {
  classes: Classes;
  submitButtonIcon: IconProp;
  cancelButtonIcon: IconProp;
  submitButtonText: string;
  cancelButtonText: string;
  onSubmit: any;
  onCancel: any;
  disabled?: boolean;
}

function ActivityFormButtons(props: React.PropsWithChildren<IActivityFormButtonsProps>) {
  const { classes, submitButtonIcon, cancelButtonIcon, submitButtonText, cancelButtonText, onSubmit, onCancel, disabled } = props;

  return (
    <List className={classes.root}>
      <ListItem button onClick={onSubmit} className={classes.formBtn} disabled={disabled}>
        <ListItemIcon className={classes.formBtnIcon}>
          <FontAwesomeIcon icon={submitButtonIcon} />
        </ListItemIcon>
        <ListItemText className={classes.formBtnText}>{submitButtonText}</ListItemText>
      </ListItem>

      <ListItem button onClick={onCancel} className={classes.formBtn}>
        <ListItemIcon className={classes.formBtnIcon}>
          <FontAwesomeIcon icon={cancelButtonIcon} />
        </ListItemIcon>
        <ListItemText className={classes.formBtnText}>{cancelButtonText}</ListItemText>
      </ListItem>
    </List>
  );
}

export default withStyles(ActivityFormButtonsStyles)(ActivityFormButtons);
