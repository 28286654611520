import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, FormLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomVoiceInput from '../../../shared-components/CustomVoiceInput';
import { IExpense } from 'app/shared/model/budgettavlan/expense.model';
import ImageInput from 'app/custom-components/form-inputs/imageInput';

interface IEditExpenseDialogProps {
  open: boolean;
  onClose: any;
  value: IExpense;
  handleChange: any;
  onSubmit: any;
  isAuthenticated?: boolean;
}

export class EditExpenseDialog extends React.Component<IEditExpenseDialogProps> {
  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit();
  };

  render() {
    const { open, onClose, value, handleChange, isAuthenticated } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="budget-dialog-title" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="budget-dialog-title">
          <FontAwesomeIcon icon="dollar-sign" />
          Ändra kostnaden
        </DialogTitle>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <div>
              <FormLabel htmlFor="expenseName">Namn:</FormLabel>
              <CustomVoiceInput value={value.name} handleChange={name => handleChange({ ...value, name })} />
            </div>
            <Box mt={4}>
              <FormLabel htmlFor="imgUrl">Bild:</FormLabel>
              <ImageInput
                id="imgUrl"
                imgUrl={value.imgUrl}
                handleChange={imgUrl => handleChange({ ...value, imgUrl })}
                required={false}
                offline={!isAuthenticated}
                originalRatio
                borderedPlaceholder
              />
            </Box>
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            color="primary"
            disabled={value.name.length === 0}
            startIcon={<FontAwesomeIcon icon="thumbs-up" />}
          >
            Okej
          </Button>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditExpenseDialog;
