import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Classes } from 'jss';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Badge, Table, Alert } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { createZoomUser, createZoomUserForCommunity, create1on1ZoomMeeting, reset as resetZoom } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { getAllEntities as getAllCommunities } from 'app/entities/community/community.reducer';
import {
  getEntities,
  getEntitiesByCommunity,
  searchEntities,
  searchEntitiesByCommunity,
  getCurrentUserData,
  reset as resetUserData
} from './user-data.reducer';
// tslint:disable-next-line:no-unused-variable
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ZoomUserOwnerDialog from '../../custom-components/digijag-dialogs/zoom/ZoomUserOwnerDialog';
import { ICommunity } from 'app/shared/model/community.model';
import ChangeStudentsPasswordDialog from 'app/custom-components/digijag-dialogs/change-password/ChangeStudentsPasswordDialog';
import { createStyles, Grid, IconButton, Input, InputAdornment, TextField, withStyles } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { values } from 'lodash';

const UserDataStyles = () =>
  createStyles({
    pointer: {
      cursor: 'pointer'
    }
  });

export interface IUserDataProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
  classes: Classes;
}
export interface IUserDataState extends IPaginationBaseState {
  zoomUserOwnerDialogOpen: boolean;
  studentPasswordDialogOpen: boolean;
  selectedCommunity: ICommunity;
  zoomUserShouldBelongToDigijag: boolean;
  selectedUserDataId?: number;
  selectedUserId?: number;
  searchStr: string;
}

export class UserData extends React.Component<IUserDataProps, IUserDataState> {
  state: IUserDataState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    zoomUserOwnerDialogOpen: false,
    studentPasswordDialogOpen: false,
    selectedCommunity: undefined,
    zoomUserShouldBelongToDigijag: false,
    searchStr: ''
  };

  componentDidMount() {
    this.reset();
    this.props.getCurrentUserData();
    this.props.getAllCommunities();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateSuccess) {
      this.reset();
    }

    // After starting zoom meeting, show link to use later and offer to join meeting directly.
    if (prevProps.zoomMeeting !== this.props.zoomMeeting && this.props.zoomMeeting.joinUrl && this.props.zoomMeeting.startUrl) {
      if (
        window.confirm(
          `${translate('digijagApp.zoomMeeting.meetingLink')} ${this.props.zoomMeeting.joinUrl} ${translate('digijagApp.zoomMeeting.pressOkToStart')}`
        )
      ) {
        window.open(this.props.zoomMeeting.startUrl, '_blank');
      }
    }
  }

  reset = () => {
    this.props.resetUserData();
    this.props.resetZoom();
    this.setState({ activePage: 1 }, () => {
      this.getEntities();
    });
  };

  handleLoadMore = () => {
    if (window.pageYOffset > 0) {
      this.setState({ activePage: this.state.activePage + 1 }, () => this.getEntities());
    }
  };

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => {
        this.reset();
      }
    );
  };

  filterOnCommunity = communityId => () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.history.push(`/entity/user-data?community=${communityId}`);
    this.props.getEntitiesByCommunity(communityId, activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  isFilteredByCommunity = () => {
    const params = new URLSearchParams(this.props.location.search);
    return params.get('community');
  };
  getEntities = () => {
    event.preventDefault();
    const { activePage, itemsPerPage, sort, order, searchStr } = this.state;
    const params = new URLSearchParams(this.props.location.search);
    const community = params.get('community');
    if (community) {
      searchStr
        ? this.props.searchEntitiesByCommunity(community, searchStr, activePage - 1, itemsPerPage, `${sort},${order}`)
        : this.props.getEntitiesByCommunity(community, activePage - 1, itemsPerPage, `${sort},${order}`);
    } else {
      searchStr
        ? this.props.searchEntities(searchStr, activePage - 1, itemsPerPage, `${sort},${order}`)
        : this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
    }
  };

  startZoomMeeting = userData => {
    if (this.props.currentUserData.zoomUserId) {
      this.props.create1on1ZoomMeeting(userData);
    } else {
      alert(translate('entity.action.askForZoomAccount'));
    }
  };

  onCreateZoomUserAsAdmin = () => {
    const { zoomUserShouldBelongToDigijag, selectedCommunity, selectedUserDataId } = this.state;
    if (zoomUserShouldBelongToDigijag) {
      this.props.createZoomUser(selectedUserDataId);
    } else {
      this.props.createZoomUserForCommunity(selectedUserDataId, selectedCommunity.id);
    }
  };

  hasZoom(community: any): boolean {
    return community.zoomApiKey ? true : false;
  }

  onChange = e => {
    this.setState({
      searchStr: e.currentTarget.value
    });
  };

  render() {
    const { userDataList, match, isAdmin, isCommunityAdmin, isOrganizationAdmin, communities, zoomError, zoomErrorDetails, classes, currentUser } = this.props;
    const { selectedCommunity, zoomUserOwnerDialogOpen, studentPasswordDialogOpen, zoomUserShouldBelongToDigijag, selectedUserId } = this.state;
    return (
      <div>
        <Grid container direction="row" justify="space-between" alignItems="center" alignContent="center" className={'mb-3'}>
          <Grid item>
            <h2 id="user-data-heading">
              <Translate contentKey="digijagApp.userData.home.title">User Data</Translate>
              {this.isFilteredByCommunity() && <Translate contentKey="digijagApp.userData.home.in">i gruppen</Translate>}
            </h2>
          </Grid>
          {!isOrganizationAdmin && (
            <Grid item>
              <form onSubmit={this.getEntities}>
                <div className={'input-group'}>
                  <input autoFocus type="text" className={'form-control'} value={this.state.searchStr} onChange={this.onChange} />
                  <div className={'input-group-append'}>
                    <button className={'btn btn-outline-secondary'}>
                      {this.isFilteredByCommunity() ? (
                        <Translate contentKey="entity.action.searchInGroup">Sök i gruppen</Translate>
                      ) : (
                        <Translate contentKey="entity.action.search"> Search</Translate>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </Grid>
          )}
          <Grid item>
            {(isCommunityAdmin || isAdmin || isOrganizationAdmin) && (
              <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
                <FontAwesomeIcon icon="plus" />
                &nbsp;
                <Translate contentKey="digijagApp.userData.home.createLabel">Create new User Data</Translate>
              </Link>
            )}
          </Grid>
        </Grid>
        {zoomError && (
          <Alert color="danger">
            <strong>{zoomError}</strong>
            {zoomErrorDetails}
          </Alert>
        )}
        <div className="table-responsive">
          <InfiniteScroll
            pageStart={this.state.activePage}
            loadMore={this.handleLoadMore}
            hasMore={this.state.activePage - 1 < this.props.links.next}
            loader={<div className="loader">Loading ...</div>}
            threshold={0}
            initialLoad={false}
          >
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <Translate contentKey="userManagement.login">Login</Translate>
                  </th>
                  <th>
                    <Translate contentKey="userManagement.email">Email</Translate>
                  </th>
                  <th>
                    <Translate contentKey="digijagApp.community.home.title">Communities</Translate>
                  </th>
                  <th>
                    <Translate contentKey="userManagement.profiles">Roles</Translate>
                  </th>
                  <th className="hand" onClick={this.sort('id')}>
                    <Translate contentKey="userManagement.createdDate">Created date</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="digijagApp.userData.zoomUserId">Zoom ID</Translate>
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {userDataList &&
                  userDataList.map((userData, i) => (
                    <tr key={`entity-${i}`}>
                      <td>{userData.userLogin}</td>
                      <td>{userData.userEmail}</td>
                      <td>
                        {userData.communities
                          ? userData.communities.map((community, j) => (
                              <div key={`community-${i}-${j}`}>
                                <Badge className={classes.pointer} color="primary" onClick={this.filterOnCommunity(community.id)}>
                                  {community.name}
                                </Badge>
                              </div>
                            ))
                          : null}
                      </td>
                      <td>
                        {userData.userAuthorities
                          ? userData.userAuthorities.map((authority, j) => (
                              <div key={`user-auth-${i}-${j}`}>
                                <Badge color="info">{authority}</Badge>
                              </div>
                            ))
                          : null}
                      </td>
                      <td>
                        <TextFormat type="date" value={userData.userCreatedDate} format={APP_DATE_FORMAT} />
                      </td>
                      <td>
                        {userData.zoomUserId ? (
                          <Button onClick={() => this.startZoomMeeting(userData)} color="primary" size="sm">
                            <FontAwesomeIcon icon="video" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.startZoomMeeting">Start Zoom Meeting</Translate>
                            </span>
                          </Button>
                        ) : isAdmin ? (
                          <Button
                            onClick={() =>
                              this.setState({
                                selectedUserDataId: userData.id,
                                zoomUserOwnerDialogOpen: true
                              })
                            }
                            color="secondary"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="unlock-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.enableZoom">Enable zoom</Translate>
                            </span>
                          </Button>
                        ) : isCommunityAdmin ? (
                          <Button onClick={() => this.props.createZoomUser(userData.id)} color="secondary" size="sm">
                            <FontAwesomeIcon icon="unlock-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.enableZoom">Enable zoom</Translate>
                            </span>
                          </Button>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="text-right">
                        <div className="btn-group flex-btn-group-container">
                          <Button
                            onClick={() =>
                              this.setState({
                                selectedUserId: userData.userId,
                                studentPasswordDialogOpen: true
                              })
                            }
                            disabled={
                              !isOrganizationAdmin &&
                              userData.userAuthorities.includes(AUTHORITIES.SUPERADMIN) &&
                              (!isAdmin &&
                                (userData.userAuthorities.includes(AUTHORITIES.COMMUNITY_ADMIN) || userData.userAuthorities.includes(AUTHORITIES.SUPERADMIN)) &&
                                currentUser.id !== userData.userId)
                            }
                            color="info"
                            size="sm"
                          >
                            <FontAwesomeIcon icon="key" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.changePassword">Change password</Translate>
                            </span>
                          </Button>
                          <Button
                            tag={Link}
                            to={`${match.url}/${userData.id}/edit`}
                            color="primary"
                            size="sm"
                            disabled={
                              !isOrganizationAdmin &&
                              userData.userAuthorities.includes(AUTHORITIES.SUPERADMIN) &&
                              (!isAdmin &&
                                (userData.userAuthorities.includes(AUTHORITIES.COMMUNITY_ADMIN) || userData.userAuthorities.includes(AUTHORITIES.SUPERADMIN)) &&
                                currentUser.id !== userData.userId)
                            }
                          >
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                          <Button
                            tag={Link}
                            to={`${match.url}/${userData.id}/delete`}
                            color="danger"
                            size="sm"
                            disabled={
                              !isOrganizationAdmin &&
                              userData.userAuthorities.includes(AUTHORITIES.SUPERADMIN) &&
                              (!isAdmin &&
                                (userData.userAuthorities.includes(AUTHORITIES.COMMUNITY_ADMIN) || userData.userAuthorities.includes(AUTHORITIES.SUPERADMIN)) &&
                                currentUser.id !== userData.userId)
                            }
                          >
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>

        <ZoomUserOwnerDialog
          communities={(communities as ICommunity[]).filter(community => this.hasZoom(community))}
          selectedCommunity={selectedCommunity}
          dialogOpen={zoomUserOwnerDialogOpen}
          handleCloseDialog={() => this.setState({ zoomUserOwnerDialogOpen: false })}
          handleCommunitiesChange={event => this.setState({ selectedCommunity: event.target.value })}
          handleIsGlobalChange={event => this.setState({ zoomUserShouldBelongToDigijag: event.target.checked })}
          isGlobal={zoomUserShouldBelongToDigijag}
          onCreateZoomUser={this.onCreateZoomUserAsAdmin}
        />

        <ChangeStudentsPasswordDialog
          userId={selectedUserId}
          onClose={() => this.setState({ studentPasswordDialogOpen: false })}
          isOpen={studentPasswordDialogOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ userData, authentication, zoom, community }: IRootState) => ({
  userDataList: userData.entities,
  totalItems: userData.totalItems,
  links: userData.links,
  currentUserData: userData.entity,
  updateSuccess: userData.updateSuccess,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPERADMIN]),
  isCommunityAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.COMMUNITY_ADMIN]),
  isOrganizationAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMIN]),
  currentUser: authentication.account,
  zoomMeeting: zoom.zoomMeeting,
  zoomError: zoom.error,
  zoomErrorDetails: zoom.errorDetails,
  communities: community.entities
});

const mapDispatchToProps = {
  getEntities,
  getEntitiesByCommunity,
  searchEntities,
  searchEntitiesByCommunity,
  getCurrentUserData,
  createZoomUser,
  createZoomUserForCommunity,
  create1on1ZoomMeeting,
  getAllCommunities,
  resetUserData,
  resetZoom
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(UserDataStyles)(UserData));
