import { Moment } from 'moment';
import { ICommunity } from 'app/shared/model/community.model';
import { IContentPage } from './content-page.model';

export interface IContent {
  id?: number;
  name?: string;
  description?: string;
  imgUrl?: string;
  pages?: IContentPage[];
  global?: boolean;
  auditAccepted?: boolean;
  published?: boolean;
  createdAt?: Moment;
  updatedAt?: Moment;
  auditedAt?: Moment;
  authorId?: number;
  authorName?: string;
  communities?: ICommunity[];
  categoryGame?: boolean;
  categoryKids?: boolean;
  categorySchool?: boolean;
  categorySchool2?: boolean;
  categoryFood?: boolean;
  categoryHealth?: boolean;
  categorySociety?: boolean;
  categoryEconomy?: boolean;
  categoryVideo?: boolean;
  categoryNews?: boolean;
  hidePageNavigation?: boolean;
}

export const defaultValue: Readonly<IContent> = {
  global: false,
  auditAccepted: false,
  published: false,
  description: '',
  imgUrl: ''
};
