export interface IAddress {
  id?: number;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  zipCode?: string;
  userDataId?: number;
}

export const defaultValue: Readonly<IAddress> = {};
