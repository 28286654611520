import React, { Component, RefObject } from 'react';
import { withStyles, createStyles, Typography, Grid, Theme } from '@material-ui/core';
import { Container } from 'reactstrap';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RedButton from './RedButton';
import MainSection from './MainSection';
import { contact } from 'app/config/constants';
import { translate, Translate } from 'react-jhipster';

interface IGeneralHomeProps {
  classes: Classes;
  isAuthenticated: boolean;
}

const GeneralHomeStyles = createStyles(({ breakpoints }: Theme) => ({
  contentContainer: {
    width: '75%'
  },
  mainContainer: {
    minHeight: '90%',
    paddingBottom: '2rem',
    [breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  homeTitle: {
    fontSize: '2.4rem',
    lineHeight: '2.75rem',
    fontWeight: 'bold'
  },
  mainText: {
    margin: '1.9rem 0',
    lineHeight: '1.9rem'
  },
  rightImage: {
    '& img': {
      width: '100%',
      [breakpoints.down('sm')]: {
        width: '80%',
        marginTop: '2rem'
      }
    }
  },
  darkSection: {
    backgroundColor: '#272727',
    padding: '6.25rem 0',
    '& h2, & p, & span': {
      color: '#fff !important'
    }
  },
  darkSectionNarrow: {
    backgroundColor: '#272727',
    padding: '1.6rem 0',
    '& h2, & p, & span': {
      color: '#fff !important'
    }
  },
  lightSection: {
    backgroundColor: 'white',
    padding: '6.25rem 0',
    borderTop: '2px solid #272727'
  },
  sectionImg: {
    textAlign: 'center',
    '& img': {
      width: '9.75rem',
      height: 'auto'
    },
    [breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  companiesSection: {
    '& a': {
      lineHeight: '8rem',
      height: '8rem'
    },
    '& h2': {
      marginBottom: '2rem'
    },
    '& img': {
      maxWidth: '70%',
      maxHeight: '3rem',
      margin: '1rem 0',
      transition: 'all 200ms ease',
      '&:hover': {
        maxWidth: '90%',
        maxHeight: '4rem'
      }
    }
  },
  companyLarge: {
    maxHeight: '5rem !important',
    margin: '0 !important',
    '&:hover': {
      maxHeight: '6.5rem !important'
    }
  },
  companySmall: {
    maxHeight: '2rem !important',
    '&:hover': {
      maxHeight: '2.8rem !important'
    }
  },
  linkToTop: {
    display: 'inline-block',
    color: '#fff',
    padding: '.66rem',
    marginBottom: '.66rem',
    backgroundColor: 'rgba(154, 23, 80, 0)',
    transition: 'background-color 200ms ease',
    textDecoration: 'underline',
    lineHeight: '1.5rem',
    '& svg': {
      fontSize: '1.5rem',
      textDecoration: 'none'
    },
    '&:hover': {
      color: '#fff',
      textDecoration: 'underline',
      backgroundColor: 'rgba(154, 23, 80, 1)'
    }
  }
}));

class GeneralHome extends Component<IGeneralHomeProps> {
  moreSection = React.createRef<HTMLElement>();
  whoSection = React.createRef<HTMLElement>();
  howSection = React.createRef<HTMLElement>();
  contactSection = React.createRef<HTMLElement>();

  setFocusOnTitle = (titleElement, currentPosition: number, previousPosition = -1) => {
    if (currentPosition === previousPosition) {
      titleElement.tabIndex = -1;
      titleElement.focus();
    } else {
      window.setTimeout((() => this.setFocusOnTitle(titleElement, window.scrollY, currentPosition)).bind(this), 30);
    }
  };

  scrollToSection = (sectionRef: RefObject<any>) => {
    const sectionTitleElement = sectionRef.current.querySelector('h2');
    const scrollToPosition = sectionRef.current.offsetTop;

    window.scrollTo({
      top: scrollToPosition,
      behavior: 'smooth'
    });

    if (sectionTitleElement) {
      window.setTimeout((() => this.setFocusOnTitle(sectionTitleElement, window.scrollY)).bind(this), 30);
    }
  };

  scrollToTop = event => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <MainSection>
          <Container fluid className={classes.contentContainer}>
            <Grid container className={classes.mainContainer} justify="space-between" alignItems="center" direction="row">
              <Grid item xs={12} md={4}>
                <Typography variant="h1" className={classes.homeTitle}>
                  <Translate contentKey="home.welcome.title">
                    Välkommen till
                    <br />
                    DigiJag!
                  </Translate>
                </Typography>
                <Typography variant="body1" className={classes.mainText}>
                  <Translate contentKey="home.welcome.description">
                    DigiJag kan du använda både i skolan, på ditt arbete och på din fritid. DigiJag är extra bra för dig som kanske tycker att det är svårt att
                    läsa och skriva.
                  </Translate>
                </Typography>
                <RedButton
                  onClick={e => {
                    e.preventDefault();
                    this.scrollToSection(this.moreSection);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-circle-down" />
                  <Translate contentKey="home.welcome.linkText"> Läs mer om DigiJag</Translate>
                </RedButton>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="center" className={classes.rightImage} variant="inherit">
                  <img src="content/images/home-gadgets.png" alt="" />
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </MainSection>
        <section ref={this.moreSection} className={classes.darkSection} id="more">
          <Container>
            <Grid container justify="space-between" alignItems="center" direction="row">
              <Grid item xs={12} md={4} className={classes.sectionImg}>
                <img src="content/images/brain-left.svg" alt="" />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">
                  <Translate contentKey="home.whatIs.title">Vad är DigiJag?</Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.whatIs.paragraph1">
                    DigiJag är en digital skol- och livsplattform utvecklad för att fungera bra för deltagare i anpassad skola, särvux, daglig verksamhet och
                    särskilt boende.
                  </Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.whatIs.paragraph2">
                    Här får användarna stöd för att använda funktioner på internet, speciellt utvecklade applikationer för DigiJag och stödfunktioner för
                    exempelvis hantering av tid, veta vad det är för väder etc.
                  </Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.whatIs.paragraph3">
                    DigiJag utvecklas av FUB, Mora folkhögskola, Begripsam och Axess Lab i samarbete med en rad experter på nätpedagogik för användare som
                    behöver enkla och användbara gränssnitt. DigiJag utvecklas med medel från Allmänna arvsfonden.
                  </Translate>
                </Typography>
                <RedButton
                  onClick={e => {
                    e.preventDefault();
                    this.scrollToSection(this.whoSection);
                  }}
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <Translate contentKey="home.whatIs.linkText"> Vem använder DigiJag?</Translate>
                </RedButton>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section ref={this.whoSection} className={classes.lightSection} id="who">
          <Container>
            <Grid container justify="space-between" alignItems="center" direction="row-reverse">
              <Grid item xs={12} md={4} className={classes.sectionImg}>
                <img src="content/images/brain-right.svg" alt="" />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">
                  <Translate contentKey="home.whoUses.title">Vem använder DigiJag?</Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.whoUses.paragraph1">
                    DigiJag används av personer som går i anpassad skola, deltar i daglig verksamhet, bor på gruppboende eller deltar i andra aktiviteter inom
                    LSS.
                  </Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.whoUses.paragraph2">
                    Det är antingen kommunen, en verksamhet inom kommunen eller privata anordnare som tecknar avtal om att få använda DigiJag. DigiJag kan också
                    användas av föreningar.
                  </Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.whoUses.paragraph3">
                    Föreningar inom FUB kan också avtala om att använda DigiJag. Även andra organisationer kan teckna avtal. Kontakta oss så berättar vi mer.
                  </Translate>
                </Typography>
                <RedButton
                  onClick={e => {
                    e.preventDefault();
                    this.scrollToSection(this.howSection);
                  }}
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <Translate contentKey="home.whoUses.linkText"> Hur kan jag få DigiJag?</Translate>
                </RedButton>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section ref={this.howSection} className={classes.darkSection} id="how">
          <Container>
            <Grid container justify="space-between" alignItems="center" direction="row">
              <Grid item xs={12} md={4} className={classes.sectionImg}>
                <img src="content/images/brain-left.svg" alt="" />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="h2">
                  <Translate contentKey="home.howToGet.title">Hur kan jag få DigiJag?</Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.howToGet.paragraph1">
                    När ni har tecknat ett avtal om att använda DigiJag kommer ni att få en kort introduktion. Det kan ske via webbmöte eller telefon.
                  </Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.howToGet.paragraph2">
                    Därefter får ni inloggningsuppgifter och kan börja lägga in användare. Ni kan själva hantera de personer som ska använda DigiJag, upp till
                    det antal användare som ingår i ert avtal.
                  </Translate>
                </Typography>
                <Typography variant="body1">
                  <Translate contentKey="home.howToGet.paragraph3">Om ni behöver hjälp så kan vi på högkvarteret för DigiJag hjälpa er.</Translate>
                </Typography>
                <RedButton
                  onClick={e => {
                    e.preventDefault();
                    this.scrollToSection(this.contactSection);
                  }}
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <Translate contentKey="home.howToGet.linkText"> Hur kan jag testa DigiJag?</Translate>
                </RedButton>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section ref={this.contactSection} className={classes.lightSection} id="contact">
          <Container>
            <Typography variant="h2" align="center">
              <Translate contentKey="home.contact.title">Kontakta oss</Translate>
            </Typography>
            <Typography variant="body1" align="center">
              <Translate contentKey="home.contact.paragraph1">Hör av dig till oss om du vill testa DigiJag!</Translate>
            </Typography>
            <Typography variant="inherit" align="center" display="block">
              <RedButton component="a" href={`mailto:${contact.EMAIL}?subject=Digijag`}>
                <FontAwesomeIcon icon="envelope" />
                <Translate contentKey="home.contact.linkText"> Skicka e-post</Translate>
              </RedButton>
            </Typography>
          </Container>
        </section>
        <section className={classes.lightSection}>
          <Container fluid className={classes.companiesSection}>
            <Typography variant="h2" align="center">
              <Translate contentKey="home.partners.title">Samarbetspartners</Translate>
            </Typography>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item xs={12} md={4}>
                <Typography variant="inherit" align="center" display="block">
                  <a href="http://www.begripsam.se/">
                    <img src="content/images/begripsam-logo.png" alt={translate('home.partners.altTextBegripsam')} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="inherit" align="center" display="block">
                  <a href="https://axesslab.com/">
                    <img src="content/images/axesslab-logo.svg" alt={translate('home.partners.altTextAxessLab')} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="inherit" align="center" display="block">
                  <a href="http://www.fub.se/">
                    <img src="content/images/fub-logo.jpeg" alt={translate('home.partners.altTextFUB')} className={classes.companyLarge} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="inherit" align="center" display="block">
                  <a href="https://www.morafolkhogskola.se/">
                    <img src="content/images/mora-logo.png" alt={translate('home.partners.altTextMora')} className={classes.companySmall} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="inherit" align="center" display="block">
                  <a href="http://www.nordicag.com/">
                    <img src="content/images/nag_logo.jpeg" alt={translate('home.partners.altTextNAG')} className={classes.companyLarge} />
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="inherit" align="center" display="block">
                  <a href="https://www.arvsfonden.se/">
                    <img src="content/images/stod_arvsfonden.png" alt={translate('home.partners.altTextArvsfonden')} />
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.darkSectionNarrow}>
          <Typography variant="inherit" align="center" display="block">
            <a href="#top" onClick={this.scrollToTop} className={classes.linkToTop}>
              <FontAwesomeIcon icon="arrow-up" />
              <br />
              <Translate contentKey="home.footer.toTop">Gå till toppen</Translate>
            </a>
          </Typography>
          <Typography variant="inherit" align="center" display="block">
            © 2019. All rights reserved
          </Typography>
        </section>
      </React.Fragment>
    );
  }
}

export default withStyles(GeneralHomeStyles)(GeneralHome);
