import React, { Component } from 'react';
import { RouteComponentProps, Switch, withRouter, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import './app.scss';
import Login from 'app/modules/login/login';
import Freja from 'app/modules/freja/freja';
import Register from 'app/modules/account/register/register';
import RegisterHelper from 'app/modules/account/register/register-helper';
import Activate from 'app/modules/account/activate/activate';
import Accept from 'app/modules/account/accept-invitation/accept';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/custom-components/home/Home';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import { AUTHORITIES } from 'app/config/constants';
import RegisterSuccessful from './modules/account/register/register-successful';
import Apps from './custom-components/apps/Apps';
import ZoomAppView from './custom-components/native-app/zoom/ZoomAppView';
import ZoomAppFrame from './custom-components/native-app/zoom/ZoomAppFrame';
import ZoomAppLeave from './custom-components/native-app/zoom/ZoomAppLeave';
import ZoomAppLeaveEnd from './custom-components/native-app/zoom/ZoomAppLeaveEnd';
import PublicAppItemView from './custom-components/app-item/PublicAppItemView';
import { translate } from 'react-jhipster';
import Header from './shared/layout/header/header';

const Loading = () => <div className="loading">laddar...</div>;

// tslint:disable:space-in-parens
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: Loading
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: Loading
});

const DashBoard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */ 'app/custom-components/dashboard/DashBoard'),
  loading: Loading
});

const NativeContent = Loadable({
  loader: () => import(/* webpackChunkName: "native-content" */ 'app/custom-components/native-content/NativeContent'),
  loading: Loading
});

// tslint:enable

export interface IRoutesProps extends RouteComponentProps {
  isCommunityHeader?: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isGroupAdmin: boolean;
  isHelper: boolean;
  isCommunityAdmin: boolean;
  isOrganizationAdmin: boolean;
  isSupportCoach: boolean;
  isStudent: boolean;
  isVuxenlivUser: boolean;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
  currentLocale: string;
  onLocaleChange: Function;
}

export const Routes = (props: IRoutesProps) => {
  const encloseWithHeader = RouteComponent => componentProps => {
    return (
      <>
        <Header
          isCommunityHeader={props.isCommunityHeader}
          isAuthenticated={props.isAuthenticated}
          isAdmin={props.isAdmin}
          isGroupAdmin={props.isGroupAdmin}
          isHelper={props.isHelper}
          isCommunityAdmin={props.isCommunityAdmin}
          isOrganizationAdmin={props.isOrganizationAdmin}
          isSupportCoach={props.isSupportCoach}
          isStudent={props.isStudent}
          currentLocale={props.currentLocale}
          onLocaleChange={props.onLocaleChange}
          isInProduction={props.isInProduction}
          isSwaggerEnabled={props.isSwaggerEnabled}
        />
        <RouteComponent {...componentProps} />
      </>
    );
  };

  return (
    <>
      <Switch>
        <ErrorBoundaryRoute exact path="/" component={encloseWithHeader(Home)} />
        <ErrorBoundaryRoute path="/login" component={encloseWithHeader(Login)} />
        <ErrorBoundaryRoute path="/callback/freja" component={encloseWithHeader(Freja)} />
        <ErrorBoundaryRoute path="/logout" component={encloseWithHeader(Logout)} />
        <ErrorBoundaryRoute path="/register" component={encloseWithHeader(Register)} />
        <ErrorBoundaryRoute path="/register-successful" component={encloseWithHeader(RegisterSuccessful)} />
        <ErrorBoundaryRoute path="/registerhelper" component={encloseWithHeader(RegisterHelper)} />
        <ErrorBoundaryRoute path="/activate/:key?" component={encloseWithHeader(Activate)} />
        <ErrorBoundaryRoute path="/reset/request" component={encloseWithHeader(PasswordResetInit)} />
        <ErrorBoundaryRoute path="/reset/finish/:key?" component={encloseWithHeader(PasswordResetFinish)} />
        <PrivateRoute path="/admin" component={encloseWithHeader(Admin)} hasAnyAuthorities={[AUTHORITIES.SUPERADMIN]} />
        <PrivateRoute
          path="/acceptInvitation/:invitationToken?"
          component={encloseWithHeader(Accept)}
          hasAnyAuthorities={[AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.SUPPORT_COACH, AUTHORITIES.GROUP_ADMIN, AUTHORITIES.HELPER]}
        />
        <PrivateRoute
          path="/account"
          component={encloseWithHeader(Account)}
          hasAnyAuthorities={[
            AUTHORITIES.SUPERADMIN,
            AUTHORITIES.COMMUNITY_ADMIN,
            AUTHORITIES.STUDENT,
            AUTHORITIES.SUPPORT_COACH,
            AUTHORITIES.GROUP_ADMIN,
            AUTHORITIES.HELPER,
            AUTHORITIES.ORGANIZATION_ADMIN
          ]}
        />
        <PrivateRoute
          path="/entity"
          component={encloseWithHeader(Entities)}
          hasAnyAuthorities={[
            AUTHORITIES.GROUP_ADMIN,
            AUTHORITIES.SUPERADMIN,
            AUTHORITIES.COMMUNITY_ADMIN,
            AUTHORITIES.SUPPORT_COACH,
            AUTHORITIES.HELPER,
            AUTHORITIES.ORGANIZATION_ADMIN
          ]}
        />

        {/* no header routes */}
        <PrivateRoute
          path={`/dashboard${translate('digijagApp.routes.meeting')}/leave`}
          component={ZoomAppLeave}
          hasAnyAuthorities={[
            AUTHORITIES.SUPERADMIN,
            AUTHORITIES.HELPER,
            AUTHORITIES.STUDENT,
            AUTHORITIES.GROUP_ADMIN,
            AUTHORITIES.SUPPORT_COACH,
            AUTHORITIES.COMMUNITY_ADMIN
          ]}
        />
        <PrivateRoute
          path={`/dashboard${translate('digijagApp.routes.meeting')}/:id/leave`}
          component={ZoomAppLeaveEnd}
          hasAnyAuthorities={[
            AUTHORITIES.SUPERADMIN,
            AUTHORITIES.HELPER,
            AUTHORITIES.STUDENT,
            AUTHORITIES.GROUP_ADMIN,
            AUTHORITIES.SUPPORT_COACH,
            AUTHORITIES.COMMUNITY_ADMIN
          ]}
        />
        <PrivateRoute
          path={`/dashboard${translate('digijagApp.routes.meeting')}/:id/frame`}
          component={ZoomAppFrame}
          hasAnyAuthorities={[
            AUTHORITIES.SUPERADMIN,
            AUTHORITIES.HELPER,
            AUTHORITIES.STUDENT,
            AUTHORITIES.GROUP_ADMIN,
            AUTHORITIES.SUPPORT_COACH,
            AUTHORITIES.COMMUNITY_ADMIN
          ]}
        />
        <PrivateRoute
          path={`/dashboard${translate('digijagApp.routes.meeting')}/:id`}
          component={ZoomAppView}
          hasAnyAuthorities={[
            AUTHORITIES.SUPERADMIN,
            AUTHORITIES.HELPER,
            AUTHORITIES.STUDENT,
            AUTHORITIES.GROUP_ADMIN,
            AUTHORITIES.SUPPORT_COACH,
            AUTHORITIES.COMMUNITY_ADMIN
          ]}
        />
        <PrivateRoute
          path="/dashboard"
          component={DashBoard}
          hasAnyAuthorities={[
            AUTHORITIES.SUPERADMIN,
            AUTHORITIES.COMMUNITY_ADMIN,
            AUTHORITIES.STUDENT,
            AUTHORITIES.SUPPORT_COACH,
            AUTHORITIES.GROUP_ADMIN,
            AUTHORITIES.HELPER
          ]}
        />
        <PrivateRoute
          path={translate('digijagApp.routes.content.root')}
          component={NativeContent}
          hasAnyAuthorities={[AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.GROUP_ADMIN]}
        />

        <ErrorBoundaryRoute path={translate('digijagApp.routes.app.publicFull')} component={PublicAppItemView} />
        <PrivateRoute
          path={translate('digijagApp.routes.app.root')}
          component={Apps}
          hasAnyAuthorities={[AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.GROUP_ADMIN, AUTHORITIES.STUDENT, AUTHORITIES.SUPERADMIN]}
        />
      </Switch>
    </>
  );
};

export default withRouter(Routes);
