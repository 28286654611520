import React, { useEffect } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';
import { baseFontSize } from '../theme';
import { convertPxToRem } from '../../../../../shared/util/number-utils';
import { ViewMode } from '../pages/TimePlanningPage';
import { gridRowHeight, hourlyGridRowHeight } from './ActivitiesList';
import { minuteStep } from './TimeCard';

export interface IStyleProps {
  viewMode: any;
}

const ActivitiesListTimeStyles = (theme: Theme) =>
  createStyles({
    timeList: {
      listStyle: 'none',
      '& li': {
        '&:last-child': {
          minHeight: '0 !important'
        },
        '&:after': {
          width: 'calc(100% - 100px)',
          background: '#00000026',
          content: '""',
          height: '1px',
          position: 'absolute',
          zIndex: 0
        }
      }
    },
    time: {
      background: '#fff',
      padding: '0.3rem 0.8rem',
      borderRadius: '1rem',
      border: '1px dashed black',
      position: 'relative',
      top: '-.7rem'
    }
  });

interface IActivitiesListTimeProps {
  classes: Classes;
  viewMode: ViewMode.HOURS | ViewMode.QUARTERS;
  offsetTop: number;
}

function ActivitiesListTime(props: IActivitiesListTimeProps) {
  const { classes, viewMode, offsetTop } = props;

  const hours = Array(24 + 1)
    .fill(null)
    .map((_, idx) => new Date(60000 * 60 * idx).toUTCString().substring(17, 22));

  const quarters = Array(24 * 4 + 1)
    .fill(null)
    .map((_, idx) => new Date(60000 * 15 * idx).toUTCString().substring(17, 22));

  useEffect(
    () => {
      const elTime = viewMode === ViewMode.QUARTERS ? document.getElementById('time-0630') : document.getElementById('time-0500');
      const elContainer = document.getElementsByTagName('main')[0];
      const y = elTime.getBoundingClientRect().top + elContainer.scrollTop - offsetTop;
      elContainer.scrollTo({ top: y, behavior: 'smooth' });
    },
    [viewMode]
  );

  return (
    <div className={classes.timeList}>
      <ul>
        {viewMode === ViewMode.HOURS
          ? hours.map((time, idx) => (
              <li
                id={`time-${time.replace(':', '')}`}
                key={`hour-${idx}`}
                style={{ minHeight: convertPxToRem(hourlyGridRowHeight * Math.floor(60 / minuteStep), baseFontSize) }}
              >
                <span className={classes.time}>{time}</span>
              </li>
            ))
          : quarters.map((time, idx) => (
              <li
                id={`time-${time.replace(':', '')}`}
                key={`quarter-${idx}`}
                style={{ minHeight: convertPxToRem(gridRowHeight * Math.floor(60 / minuteStep), baseFontSize) }}
              >
                <span className={classes.time}>{time}</span>
              </li>
            ))}
      </ul>
    </div>
  );
}

export default withStyles(ActivitiesListTimeStyles)(ActivitiesListTime);
