import React from 'react';
import { withStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';
import ActivityCard from './ActivityCard';
import { convertPxToRem } from 'app/shared/util/number-utils';
import { baseFontSize } from '../theme';
import SrOnly from 'app/custom-components/sronly/SrOnly';

const ActivityFormStep4Styles = (theme: Theme) =>
  createStyles({
    gutter: {
      marginBottom: `${convertPxToRem(20, baseFontSize)}`
    }
  });

interface IActivityFormStep4Props {
  classes: Classes;
  activity: IActivity;
}

function ActivityFormStep4(props: React.PropsWithChildren<IActivityFormStep4Props>) {
  const { classes, activity } = props;

  return (
    <>
      <SrOnly>Steg 5 av 5</SrOnly>

      <Typography classes={{ gutterBottom: classes.gutter }} gutterBottom>
        Det här är aktiviteten du ska skapa:
      </Typography>

      <ActivityCard activity={activity} typeList nonInteractive />
    </>
  );
}

export default withStyles(ActivityFormStep4Styles)(ActivityFormStep4);
