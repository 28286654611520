import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomVoiceInput from '../../../shared-components/CustomVoiceInput';

interface ICreateCustomBudgetDialogProps {
  open: boolean;
  onClose: any;
  value: string;
  handleChange: any;
  onSubmit: any;
}

export class CreateCustomBudgetDialog extends React.Component<ICreateCustomBudgetDialogProps> {
  render() {
    const { open, onClose, value, handleChange, onSubmit } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="budget-dialog-title" disableBackdropClick disableEscapeKeyDown maxWidth="sm" fullWidth>
        <DialogTitle id="budget-dialog-title">
          <FontAwesomeIcon icon="sack-dollar" />
          Ge budgeten namn
        </DialogTitle>

        <DialogContent>
          <form onSubmit={onSubmit}>
            <CustomVoiceInput value={value} handleChange={handleChange} />
          </form>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            onClick={onSubmit}
            color="primary"
            disabled={!value || value.length === 0}
            startIcon={<FontAwesomeIcon icon="thumbs-up" />}
          >
            Okej
          </Button>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateCustomBudgetDialog;
