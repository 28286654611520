import axios from 'axios';
import { parseHeaderForLinks, loadMoreDataWhenScrolled, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IContent, defaultValue } from 'app/shared/model/content.model';
import { filterOnCategory, NO_CATEGORY } from 'app/shared/model/category';

export const ACTION_TYPES = {
  FETCH_CONTENT_LIST: 'content/FETCH_CONTENT_LIST',
  FETCH_CONTENT_LIST_BY_AUTHOR: 'content/FETCH_CONTENT_LIST_BY_AUTHOR',
  FETCH_CONTENT_LIST_BY_COMMUNITY: 'content/FETCH_CONTENT_LIST_BY_COMMUNITY',
  FETCH_CONTENT_LIST_GLOBAL: 'content/FETCH_CONTENT_LIST_GLOBAL',
  FETCH_CONTENT: 'content/FETCH_CONTENT',
  FILTER_CONTENT: 'content/FILTER_CONTENT',
  CREATE_CONTENT: 'content/CREATE_CONTENT',
  UPDATE_CONTENT: 'content/UPDATE_CONTENT',
  DELETE_CONTENT: 'content/DELETE_CONTENT',
  SET_BLOB: 'content/SET_BLOB',
  RESET: 'content/RESET',
  SET_LAST_FETCHED_COMMUNITY: 'content/SET_LAST_FETCHED_COMMUNITY'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IContent>,
  globalEntities: [] as ReadonlyArray<IContent>,
  filterCategory: '',
  filteredEntities: [] as ReadonlyArray<IContent>,
  entity: defaultValue,
  lastFetchedCommunityId: null,
  links: { next: 0 },
  updating: false,
  totalEntities: 0,
  totalGlobalEntities: 0,
  totalItems: 0,
  updateSuccess: false
};

export type contentState = Readonly<typeof initialState>;

// Reducer

export default (state: contentState = initialState, action): contentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CONTENT_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CONTENT_LIST_BY_AUTHOR):
    case REQUEST(ACTION_TYPES.FETCH_CONTENT_LIST_BY_COMMUNITY):
    case REQUEST(ACTION_TYPES.FETCH_CONTENT_LIST_GLOBAL):
    case REQUEST(ACTION_TYPES.FETCH_CONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CONTENT):
    case REQUEST(ACTION_TYPES.UPDATE_CONTENT):
    case REQUEST(ACTION_TYPES.DELETE_CONTENT):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CONTENT_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CONTENT_LIST_BY_AUTHOR):
    case FAILURE(ACTION_TYPES.FETCH_CONTENT_LIST_BY_COMMUNITY):
    case FAILURE(ACTION_TYPES.FETCH_CONTENT_LIST_GLOBAL):
    case FAILURE(ACTION_TYPES.FETCH_CONTENT):
    case FAILURE(ACTION_TYPES.CREATE_CONTENT):
    case FAILURE(ACTION_TYPES.UPDATE_CONTENT):
    case FAILURE(ACTION_TYPES.DELETE_CONTENT):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONTENT_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_CONTENT_LIST_BY_AUTHOR):
    case SUCCESS(ACTION_TYPES.FETCH_CONTENT_LIST_BY_COMMUNITY):
      const links = parseHeaderForLinks(action.payload.headers.link);
      const numberOfEntities = parseInt(action.payload.headers['x-total-count'], 10);
      return {
        ...state,
        links,
        loading: false,
        totalEntities: numberOfEntities,
        totalItems: state.totalGlobalEntities + numberOfEntities,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        filteredEntities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONTENT_LIST_GLOBAL):
      const numberOfGlobalEntities = parseInt(action.payload.headers['x-total-count'], 10);
      return {
        ...state,
        loading: false,
        totalGlobalEntities: numberOfGlobalEntities,
        totalItems: state.totalEntities + numberOfGlobalEntities,
        globalEntities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CONTENT):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CONTENT):
    case SUCCESS(ACTION_TYPES.UPDATE_CONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CONTENT):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB:
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
        lastFetchedCommunityId: state.lastFetchedCommunityId
      };
    case ACTION_TYPES.FILTER_CONTENT:
      return {
        ...state,
        filterCategory: action.payload,
        filteredEntities: state.entities.filter(filterOnCategory(action.payload))
      };
    case ACTION_TYPES.SET_LAST_FETCHED_COMMUNITY:
      return {
        ...state,
        lastFetchedCommunityId: action.payload
      };
    default:
      return state;
  }
};

const apiUrl = 'api/contents';

// Actions

export const getEntities: ICrudGetAllAction<IContent> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CONTENT_LIST,
    payload: axios.get<IContent>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}?page=0&size=10000&sort=name,asc`;
  return {
    type: ACTION_TYPES.FETCH_CONTENT_LIST,
    payload: axios.get<IContent>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesByCommunityId = (communityId, global) => {
  let requestUrl = `${apiUrl}/community/${communityId}?size=10000`;
  if (global) {
    requestUrl = requestUrl + '&global=true';
  }
  return {
    type: ACTION_TYPES.FETCH_CONTENT_LIST_BY_COMMUNITY,
    payload: axios.get<IContent>(requestUrl),
    lastFetchedCommunityId: communityId
  };
};

export const getGlobalEntities = () => {
  const requestUrl = `${apiUrl}/global`;
  return {
    type: ACTION_TYPES.FETCH_CONTENT_LIST_GLOBAL,
    payload: axios.get<IContent>(requestUrl)
  };
};

export const filterEntities = category => ({
  type: ACTION_TYPES.FILTER_CONTENT,
  payload: category
});

export const getEntity: ICrudGetAction<IContent> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CONTENT,
    payload: axios.get<IContent>(requestUrl)
  };
};

export const createEntity = (entity, template) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONTENT,
    payload: axios.post(apiUrl + '/' + template, cleanEntity(entity))
  });
  return result;
};

export const copyEntity = (contentId, newName) => async dispatch => {
  const requestUrl = `${apiUrl}/copy/${contentId}?newName=${newName}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CONTENT,
    payload: axios.post(requestUrl, {})
  });
  return result;
};

export const updateEntity: ICrudPutAction<IContent> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CONTENT,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IContent> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CONTENT,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const setLastFetchedCommunity = communityId => ({
  type: ACTION_TYPES.SET_LAST_FETCHED_COMMUNITY,
  payload: communityId
});
