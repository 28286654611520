import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBudget } from 'app/shared/model/budgettavlan/budget.model';

interface ICreateBudgetDialogProps {
  open: boolean;
  onClose: any;
  onSubmit: any;
}

export class CreateBudgetDialog extends React.Component<ICreateBudgetDialogProps> {
  handleOnClick = (mode: 'custom' | 'sample') => () => this.props.onSubmit(mode);

  render() {
    const { open, onClose } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="budget-dialog-title" disableBackdropClick disableEscapeKeyDown maxWidth="sm" fullWidth>
        <DialogTitle id="budget-dialog-title">
          <FontAwesomeIcon icon="sack-dollar" />
          Välj
        </DialogTitle>

        <DialogContent>
          <Button variant="contained" onClick={this.handleOnClick('custom')} fullWidth>
            Skapa en egen budget
          </Button>
          <Box my={2}>
            <Typography variant="body1" align="center">
              eller
            </Typography>
          </Box>
          <Button variant="contained" onClick={this.handleOnClick('sample')} fullWidth>
            Välj ett färdigt exempel
          </Button>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateBudgetDialog;
