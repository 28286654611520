import React from 'react';
import { withStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import CustomVoiceInput from '../../shared-components/CustomVoiceInput';
import SrOnly from 'app/custom-components/sronly/SrOnly';

const ActivityFormStep1Styles = (theme: Theme) => createStyles({});

interface IActivityFormStep1Props {
  classes: Classes;
  activityName: string;
  onChangeActivityName: any;
}

function ActivityFormStep1(props: React.PropsWithChildren<IActivityFormStep1Props>) {
  const { activityName, onChangeActivityName } = props;

  return (
    <>
      <SrOnly>Steg 1 av 5</SrOnly>

      <CustomVoiceInput value={activityName} extra={{ placeholder: 'Skriv här', fullWidth: true }} handleChange={onChangeActivityName} />
    </>
  );
}

export default withStyles(ActivityFormStep1Styles)(ActivityFormStep1);
