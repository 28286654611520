const config = {
  VERSION: process.env.VERSION
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  SUPERADMIN: 'ROLE_SUPERADMIN',
  HELPER: 'ROLE_HELPER',
  STUDENT: 'ROLE_STUDENT',
  GROUP_ADMIN: 'ROLE_GROUP_ADMIN',
  SUPPORT_COACH: 'ROLE_SUPPORT_COACH',
  COMMUNITY_ADMIN: 'ROLE_COMMUNITY_ADMIN',
  ORGANIZATION_ADMIN: 'ROLE_ORGANIZATION_ADMIN',
  VUXENLIV_USER: 'ROLE_VUXENLIV_USER'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error'
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

interface IMinMax {
  min: number;
  max: number;
}

export const APP_EMAIL_LENGTH: IMinMax = { min: 5, max: 254 };
export const APP_PASSWORD_LENGTH: IMinMax = { min: 4, max: 50 };
export const APP_USERNAME_LENGTH: IMinMax = { min: 1, max: 50 };
export const APP_NAME_LENGTH: IMinMax = { min: 1, max: 50 };

export const contact = {
  EMAIL: 'info@digijag.se'
};
