import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Classes } from 'jss';
import { Grid, Typography } from '@material-ui/core';

const AppContainerActionBarStyles = () =>
  createStyles({
    actionBar: {
      position: 'absolute',
      bottom: '0',
      left: '0',
      height: '53px',
      width: '100%',
      background: '#272727',
      padding: '0 1em'
    },
    icon: {
      height: 35,
      cursor: 'pointer'
    }
  });

interface IAppContainerActionBarProps {
  classes: Classes;
  onBackNavigation: (event) => void;
  onForwardNavigation: (event) => void;
}

export class AppContainerActionBar extends React.Component<IAppContainerActionBarProps> {
  render() {
    const { classes, onBackNavigation, onForwardNavigation } = this.props;

    return (
      <Grid container direction="row" justify="space-between" alignItems="center" className={classes.actionBar}>
        <Grid item xs>
          <Typography>
            <img src="content/icons/left_arrow_light.svg" className={classes.icon} onClick={onBackNavigation} />
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography align="right">
            <img src="content/icons/right_arrow_light.svg" className={classes.icon} onClick={onForwardNavigation} />
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(AppContainerActionBarStyles)(AppContainerActionBar);
