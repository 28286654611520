import * as React from 'react';

export class ZoomAppLeave extends React.Component {
  getLeaveUrl = () => window.location.protocol + '//' + window.location.host;

  componentDidMount() {
    window.top.location.href = this.getLeaveUrl();
  }

  render() {
    return null;
  }
}

export default ZoomAppLeave;
