import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const toSeconds = (hours?: number, minutes?: number, seconds?: number) => (hours || 0) * 60 * 60 + (minutes || 0) * 60 + (seconds || 0);

export const secondsToHourMinute = (seconds: number) =>
  new Date((seconds || 0) * 1000)
    .toISOString()
    .split('T')[1]
    .substring(0, 5);

export const secondsToHours = (seconds: number) =>
  new Date((seconds || 0) * 1000)
    .toISOString()
    .split('T')[1]
    .substring(0, 2);

export const secondsToMinutes = (seconds: number) =>
  new Date((seconds || 0) * 1000)
    .toISOString()
    .split('T')[1]
    .substring(3, 5);
