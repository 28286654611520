import React, { useEffect, useRef } from 'react';
import { Theme, createStyles, List, ListItem, Grid, Typography, Divider, withStyles } from '@material-ui/core';
import { Classes } from 'jss';
import IDashBoardMenuDropdown from './DashBoardMenuDropdown.model';
import { Translate } from 'react-jhipster';

export const DashBoardTopBarMenuDropdownStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dropdownMenu: {
      position: 'absolute',
      right: 0,
      padding: 0,
      backgroundColor: '#790f3d',
      width: 330,
      boxShadow: 'inset 0 20px 6px -20px rgba(0,0,0,0.8)',
      [breakpoints.down('xs')]: {
        width: '100vw',
        position: 'fixed'
      }
    },

    dropdownMenuItem: {
      height: 94,
      [breakpoints.down('xs')]: {
        height: 60
      }
    },

    icon: {
      width: 35,
      height: 35,
      [breakpoints.down('sm')]: {
        width: 32,
        height: 32
      },
      [breakpoints.down('xs')]: {
        width: 22,
        height: 22
      }
    },

    dropDownMenuItemText: {
      fontWeight: 600,
      marginLeft: 15,
      [breakpoints.down('xs')]: {
        fontSize: 11
      }
    },

    listDivider: {
      backgroundColor: '#FFFFFF'
    }
  });

interface IDashBoardTopBarMenuDropdownProps {
  classes: Classes;
  dropdownItems: IDashBoardMenuDropdown[];
  handleDropdownAction: Function;
  handleCloseMenu: Function;
  menuButtonRef: React.RefObject<HTMLDivElement>;
  isGroupAdmin: boolean;
}

export function DashBoardTopBarMenuDropdown({
  classes,
  dropdownItems,
  handleDropdownAction,
  handleCloseMenu,
  menuButtonRef,
  isGroupAdmin
}: IDashBoardTopBarMenuDropdownProps) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = event => {
    if (dropdownRef && dropdownRef.current && !dropdownRef.current.contains(event.target) && (menuButtonRef && !menuButtonRef.current.contains(event.target))) {
      handleCloseMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <List component="ul" className={classes.dropdownMenu}>
        {dropdownItems.map(
          (item: IDashBoardMenuDropdown, index: number) =>
            (!isGroupAdmin || item.visibleToGroupAdmin) && (
              <li key={index}>
                <ListItem
                  button
                  className={classes.dropdownMenuItem}
                  onClick={() => {
                    handleDropdownAction(item.action);
                    handleCloseMenu();
                  }}
                  aria-haspopup="true"
                >
                  <Grid container direction="row" alignItems="center">
                    <img src={`content/icons/${item.icon}.svg`} alt="" className={classes.icon} />
                    <Typography variant="body1" color="inherit" className={classes.dropDownMenuItemText}>
                      <Translate contentKey={item.name} />
                    </Typography>
                  </Grid>
                </ListItem>
                <Divider className={classes.listDivider} role="separator" />
              </li>
            )
        )}
      </List>
    </div>
  );
}

export default withStyles(DashBoardTopBarMenuDropdownStyles)(DashBoardTopBarMenuDropdown);
