export interface INote {
  value?: number;
  color?: string;
  img?: string;
}

export interface INotesDistribution {
  [key: number]: number;
}

export type NoteType = 'shape' | 'cash';

export const notes: INote[] = [
  { value: 1000, color: '#ab5d21', img: '/content/images/cash-1000.jpeg' },
  { value: 500, color: '#a94a45', img: '/content/images/cash-500.jpeg' },
  { value: 200, color: '#687940', img: '/content/images/cash-200.jpeg' },
  { value: 100, color: '#465379', img: '/content/images/cash-100.jpeg' },
  { value: 50, color: '#cb7d69', img: '/content/images/cash-50.jpeg' },
  { value: 20, color: '#856f9e', img: '/content/images/cash-20.jpeg' }
];
