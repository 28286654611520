import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, Theme, useTheme, withStyles } from '@material-ui/core';
import { Classes } from 'jss';
import OutlinedVoiceInput from 'app/custom-components/form-inputs/OutlinedVoiceInput';

const CustomVoiceInputStyles = (theme: Theme) =>
  createStyles({
    micButton: {
      padding: '1rem 1.5rem !important'
    },
    input: {
      minHeight: '2.6rem'
    }
  });

interface ICustomVoiceInputProps {
  classes: Classes;
  value: string;
  handleChange: (value: string, isSpoken: boolean) => any;
  extra?: any;
  className?: string;
  id?: string;
  placeholder?: string;
}

function CustomVoiceInput(props: ICustomVoiceInputProps) {
  const { classes, extra, handleChange, value, id, placeholder } = props;
  const theme = useTheme();

  return (
    <OutlinedVoiceInput
      placeholder={placeholder}
      value={value}
      id={id ? id : null}
      handleChange={handleChange}
      buttonColor="primary"
      buttonExtra={{
        disableRipple: true,
        disableFocusRipple: true,
        disableElevation: true,
        className: classes.micButton
      }}
      buttonLabel={<FontAwesomeIcon icon="microphone" size="lg" color={theme.palette.secondary.main} />}
      extra={{
        ...extra,
        className: extra && extra.className ? `${extra.className} ${classes.input} ${classes}` : `${classes.input} ${classes}`
      }}
    />
  );
}

export default withStyles(CustomVoiceInputStyles)(CustomVoiceInput);
