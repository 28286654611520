import React from 'react';
import { withStyles, createStyles, Theme, DialogContent, Button, Grid, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import { Label, Form } from 'reactstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import VoiceInput from 'app/custom-components/form-inputs/VoiceInput';
import AcceptButton from 'app/custom-components/form-inputs/AcceptButton';
import { Translate } from 'react-jhipster';

export const InviteHelperFormStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogContent: {
      flex: 'none',
      paddingBottom: 24,
      [breakpoints.down('xs')]: {
        padding: 24
      }
    },

    dialogIcon: {
      marginRight: 10
    },

    dialogHeading: {
      fontSize: 25,
      color: '#000000',
      textAlign: 'center',
      fontWeight: 600,
      [breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: 1.5
      }
    },

    dialogText: {
      fontSize: 18,
      fontWeight: 600,
      [breakpoints.down('xs')]: {
        fontSize: 15,
        lineHeight: 1.5
      }
    },

    dialogTextGrid: {
      textAlign: 'center',
      marginTop: 30
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    formGrid: {
      marginTop: 40
    },

    formLabel: {
      fontSize: 18,
      fontWeight: 600,
      width: '100%',
      [breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: 1.5
      }
    },

    root: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      color: '#9b2f50'
    },

    emailError: {
      fontSize: 15,
      color: '#9b2f50'
    },

    sendFormButton: {
      color: '#FFFFFF',
      height: 45,
      background: 'linear-gradient(#4f4f4f, #272727)',
      borderRadius: 25,
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 16,
      marginTop: 40,
      width: '50%',
      [breakpoints.down('xs')]: {
        width: '70%',
        fontSize: 11,
        height: 32
      }
    }
  });

interface IInviteHelperFormProps {
  classes: Classes;
  handleSubmit: Function;
  userDataUpdating: boolean;
  emailError: boolean;
  email: string;
  handleEmailChange(value: string, isSpoken: boolean): any;
}

export const InviteHelperForm: React.SFC<IInviteHelperFormProps> = ({
  classes,
  handleSubmit,
  userDataUpdating,
  emailError,
  email,
  handleEmailChange
}) => (
  <React.Fragment>
    <DialogContent className={classes.dialogContent}>
      <Grid container direction="row" justify="center" alignItems="center">
        <img src="content/icons/invite-helpers-small.svg" alt="" className={classes.dialogIcon} />
        <Typography variant="h1" color="inherit" className={classes.dialogHeading}>
          <Translate contentKey="digijagApp.inviteHelper.form.info1" />
        </Typography>
      </Grid>
      <Grid container direction="row" justify="center" className={classes.dialogTextGrid}>
        <Typography id="dialog-description" variant="body1" color="inherit" className={classes.dialogText}>
          <Translate contentKey="digijagApp.inviteHelper.form.info2" />
        </Typography>
      </Grid>
      <Grid container direction="row" justify="center" className={classes.formGrid}>
        <Grid item lg={8} xs={11}>
          <Form id="email-form" className={classes.form} onSubmit={e => handleSubmit(e)} noValidate>
            {userDataUpdating ? <LinearProgress className={classes.root} color="primary" /> : null}
            <Label for="email" className={classes.formLabel}>
              <Translate contentKey="digijagApp.inviteHelper.form.email" />
              <br />
              <span aria-live="polite" className={classes.emailError}>
                {emailError ? <Translate contentKey="digijagApp.inviteHelper.form.emailError" /> : null}
              </span>
            </Label>
            <VoiceInput type="email" id="email" value={email} handleChange={handleEmailChange} autoFocus required />
            <AcceptButton form="email-form" type="submit" icon="send-form" className={classes.sendFormButton}>
              <Translate contentKey="digijagApp.inviteHelper.form.sendInvitation" />
            </AcceptButton>
          </Form>
        </Grid>
      </Grid>
    </DialogContent>
  </React.Fragment>
);

export default withStyles(InviteHelperFormStyles)(InviteHelperForm as any);
