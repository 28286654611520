import React, { useEffect, useState } from 'react';
import { withStyles, createStyles, Theme, MuiThemeProvider, CssBaseline, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import customTheme from './theme';
import HomePage from './pages/HomePage';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import TimePlanningPage from './pages/TimePlanningPage';
import { IWeek } from 'app/shared/model/fixatiden/week.model';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import {
  getAllEntities as getWeeks,
  getEntitiesNoBackend as getWeeksNoBackend,
  createEntity as createWeek,
  createEntityNoBackend as createWeekNoBackend,
  updateEntity as updateWeek,
  updateEntityNoBackend as updateWeekNoBackend,
  deleteEntity as deleteWeek,
  deleteEntityNoBackend as deleteWeekNoBackend,
  transferWeeksFromSession,
  hasWeeksInSessionStorage,
  reset as resetWeeks
} from 'app/entities/fixatiden/week/week.reducer';
import {
  getAllEntities as getDays,
  getEntitiesNoBackend as getDaysNoBackend,
  createEntity as createDay,
  createEntityNoBackend as createDayNoBackend,
  updateEntity as updateDay,
  updateEntityNoBackend as updateDayNoBackend,
  deleteEntity as deleteDay,
  deleteEntityNoBackend as deleteDayNoBackend,
  transferDaysFromSession,
  hasDaysInSessionStorage,
  reset as resetDays
} from 'app/entities/fixatiden/day/day.reducer';
import StartPage from '../shared-components/StartPage';
import useScript from '../shared-components/hooks/useScript';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FixatidenStyles = (theme: Theme) =>
  createStyles({
    header: {
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'
    },
    logo: {
      padding: theme.spacing(2),
      '& img': {
        width: 44,
        marginRight: theme.spacing(2)
      }
    }
  });

interface IFixatidenProps extends StateProps, DispatchProps {
  classes: Classes;
}

interface IFixatidenState {
  currentTimePlan: IDay | IWeek;
  currentPage: number;
  timePlans: Array<IDay | IWeek>;
  started: boolean;
}

function Fixatiden(props: IFixatidenProps) {
  const [currentTimePlan, setCurrentTimePlan] = useState<IFixatidenState['currentTimePlan']>(null);
  const [currentPage, setCurrentPage] = useState<IFixatidenState['currentPage']>(0);
  const [timePlans, setTimePlans] = useState<IFixatidenState['timePlans']>([]);
  const [started, setStarted] = useState<IFixatidenState['started']>(false);

  const concat = (...arrays) => [].concat(...arrays.filter(Array.isArray));

  const handleStartApp = () => {
    setStarted(true);
  };

  useScript('/content/scripts/DragDropTouch.js');

  useEffect(
    () => {
      if (props.isAuthenticated) {
        props.getDays();
        props.getWeeks();
        handleStartApp();
        props.transferring || transferObjectsFromSessionToAccount();
      } else {
        props.getDaysNoBackend();
        props.getWeeksNoBackend();
      }
    },
    [props.isAuthenticated]
  );

  useEffect(
    () => {
      const newTimePlans = concat(props.days, props.weeks);
      setTimePlans(newTimePlans);

      if (newTimePlans.length) {
        // When parent updates weeks or days state, refresh currentTimePlan since it may have changed.
        if (currentTimePlan) {
          let currentPlan = newTimePlans.find(plan => plan.id === currentTimePlan.id && isDay(plan) === isDay(currentTimePlan));

          if (!currentPlan) {
            currentPlan = newTimePlans[0];
          }

          setCurrentTimePlan(currentPlan);
        } else {
          setCurrentTimePlan(newTimePlans[0]);
        }

        setCurrentPage(1);
        handleStartApp();
      } else if (!props.isAuthenticated) {
        setCurrentPage(0);
        setStarted(false);
      } else {
        // Logged in, but no timePlans exist:
        setCurrentPage(0);
        setStarted(true);
      }
    },
    [props.days, props.weeks]
  );

  useEffect(
    () => {
      if (props.createDaySuccess) {
        setCurrentTimePlan(props.day);
      }
    },
    [props.createDaySuccess]
  );

  useEffect(
    () => {
      if (props.createWeekSuccess) {
        setCurrentTimePlan(props.week);
      }
    },
    [props.createWeekSuccess]
  );

  const transferObjectsFromSessionToAccount = () => {
    props.hasWeeksInSessionStorage && props.transferWeeksFromSession();
    props.hasDaysInSessionStorage && props.transferDaysFromSession();
  };

  const handleClickTimePlan = (plan: IDay | IWeek) => {
    setCurrentTimePlan(plan);
  };

  const handleCreateWeek = (newWeek: IWeek) => {
    props.isAuthenticated ? props.createWeek(newWeek) : props.createWeekNoBackend(newWeek);
  };

  const handleUpdateWeek = (updatedWeek: IWeek) => {
    props.isAuthenticated ? props.updateWeek(updatedWeek) : props.updateWeekNoBackend(updatedWeek);
  };

  const handleCreateDay = (newDay: IDay) => {
    props.isAuthenticated ? props.createDay(newDay) : props.createDayNoBackend(newDay);
  };

  const handleUpdateDay = (updatedDay: IDay) => {
    props.isAuthenticated ? props.updateDay(updatedDay) : props.updateDayNoBackend(updatedDay);
  };

  const handleDeleteWeek = (id: number) => {
    props.isAuthenticated ? props.deleteWeek(id) : props.deleteWeekNoBackend(id);
  };

  const handleDeleteDay = (id: number) => {
    props.isAuthenticated ? props.deleteDay(id) : props.deleteDayNoBackend(id);
  };

  const handleDeleteActivity = (id: number, parent: IDay | IWeek) => {
    if (isDay(parent)) {
      const day = { ...(parent as IDay) };
      day.activities = day.activities.filter(activity => activity.id !== id);
      handleUpdateDay(day);
    } else {
      const week = { ...(parent as IWeek) };
      week.days = week.days.map(day => {
        day.activities = day.activities.filter(activity => activity.id !== id);
        return day;
      });
      handleUpdateWeek(week);
    }
  };

  const resetDaysAndWeeks = () => {
    props.resetDays();
    props.resetWeeks();
  };

  const handleLogout = () => {
    setStarted(false);
    resetDaysAndWeeks();
    props.logout();
  };

  const isDay = (plan: IDay | IWeek): boolean => 'activities' in plan;

  return (
    <MuiThemeProvider theme={customTheme}>
      <CssBaseline />
      {started ? (
        {
          0: <HomePage onCreateWeek={handleCreateWeek} onCreateDay={handleCreateDay} />,
          1: (
            <TimePlanningPage
              timePlans={timePlans}
              onDeleteActivity={handleDeleteActivity}
              onCreateWeek={handleCreateWeek}
              onCreateDay={handleCreateDay}
              onUpdateWeek={handleUpdateWeek}
              onUpdateDay={handleUpdateDay}
              onDeleteWeek={handleDeleteWeek}
              onDeleteDay={handleDeleteDay}
              isAuthenticated={props.isAuthenticated}
              onLogOut={handleLogout}
              onLogin={resetDaysAndWeeks}
              currentTimePlan={currentTimePlan}
              onClickTimePlan={handleClickTimePlan}
            />
          )
        }[currentPage]
      ) : (
        <StartPage
          title={
            <Typography variant="h6" component="h1">
              <FontAwesomeIcon icon="clock" size="2x" style={{ marginRight: '1rem', verticalAlign: 'middle' }} color={customTheme.palette.secondary.main} />
              Välkommen till Fixa tiden
            </Typography>
          }
          guestSubtitle="Skapa ditt schema utan att logga in"
          loginSubtitle="Skapa ditt schema och logga in med Freja eID"
          guestButtonLabel="Skapa ditt schema"
          projectName="schema"
          onStartApp={handleStartApp}
          backdropBgColor="#3b538b60"
        />
      )}
    </MuiThemeProvider>
  );
}

const mapStateToProps = ({ authentication, week, day }: IRootState) => ({
  week: week.entity,
  weeks: week.entities,
  day: day.entity,
  days: day.entities,
  isAuthenticated: authentication.isAuthenticated,
  loadingAuth: authentication.loading,
  loadingContent: week.loading || day.loading,
  transferring: week.transferring,
  createDaySuccess: day.createSuccess,
  createWeekSuccess: week.createSuccess
});

const mapDispatchToProps = {
  getWeeks,
  createWeek,
  updateWeek,
  deleteWeek,
  getDays,
  createDay,
  updateDay,
  deleteDay,
  getWeeksNoBackend,
  createWeekNoBackend,
  transferWeeksFromSession,
  hasWeeksInSessionStorage,
  updateWeekNoBackend,
  deleteWeekNoBackend,
  getDaysNoBackend,
  createDayNoBackend,
  transferDaysFromSession,
  hasDaysInSessionStorage,
  updateDayNoBackend,
  deleteDayNoBackend,
  logout,
  resetDays,
  resetWeeks
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(FixatidenStyles)(Fixatiden));
