import React from 'react';
import { upload, reset } from 'app/shared/reducers/file-upload';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { withStyles, createStyles, Button, FormControl, Grid, Typography, Avatar, Theme, LinearProgress } from '@material-ui/core';
import { Classes } from 'jss';
import { translate, Translate } from 'react-jhipster';

const ImageInputStyles = ({ spacing }: Theme) =>
  createStyles({
    container: {
      marginBottom: '0.5rem'
    },
    fileInput: {
      display: 'none'
    },
    title: {
      marginBottom: '0.5rem',
      display: 'inline-block',
      color: 'inherit'
    },
    largeSquare: {
      width: '15rem',
      height: '15rem',
      objectFit: 'cover',
      marginBottom: '0.5rem',
      borderRadius: 3,
      display: 'inline-flex'
    },
    largeOriginal: {
      width: '15rem',
      height: 'auto',
      marginBottom: '0.5rem',
      borderRadius: 3,
      display: 'inline-flex'
    },
    borderedPlaceholder: {
      border: '4px solid #646464',
      borderRadius: '.5rem'
    }
  });

interface IImageInputProps extends StateProps, DispatchProps {
  classes: Classes;
  id: string;
  imgUrl?: string;
  title?: string;
  required?: boolean;
  offline?: boolean;
  originalRatio?: boolean;
  borderedPlaceholder?: boolean;
  handleChange?(imgUrl: string): any;
}

interface IImageInputState {
  imageFile: File;
  imagePreviewUrl: string;
}

class ImageInput extends React.Component<IImageInputProps, IImageInputState> {
  imageInputRef = React.createRef<HTMLInputElement>();

  readonly state = {
    imageFile: null,
    imagePreviewUrl: ''
  };

  componentDidUpdate = prevProps => {
    const { downloadUrl, uploadSuccess, handleChange } = this.props;
    if (uploadSuccess !== prevProps.uploadSuccess && uploadSuccess && downloadUrl !== prevProps.downloadUrl && handleChange) {
      this.props.handleChange(this.props.downloadUrl);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.upload(this.state.imageFile);
    this.reset();
  };

  handleImageChange = (e: any) => {
    e.preventDefault();
    this.props.reset();
    const reader = new FileReader();
    const file = e.target.files[0] as File;
    if (!file) {
      this.props.handleChange(null);
      this.setState({
        imageFile: null,
        imagePreviewUrl: ''
      });
    } else if (file.size > 1024 * 1024 * 2) {
      alert('Filen är för stor (Max 2MB)');
      e.target.value = '';
      this.setState({
        imageFile: null,
        imagePreviewUrl: ''
      });
    } else {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        if (this.props.offline) {
          this.props.handleChange(reader.result.toString());

          this.setState({
            imageFile: null,
            imagePreviewUrl: ''
          });
        } else {
          this.setState({
            imageFile: file,
            imagePreviewUrl: reader.result.toString()
          });
        }
      };
    }
  };

  reset = () => {
    this.setState({
      imagePreviewUrl: ''
    });
  };

  cancelUpload = () => {
    this.reset();
    this.imageInputRef.current.value = '';
  };

  deleteImage = e => {
    e.preventDefault();
    this.props.handleChange(null);
  };

  render() {
    const { imgUrl, error, classes, id, loading, title, originalRatio, borderedPlaceholder } = this.props;
    const { imagePreviewUrl } = this.state;
    return (
      <Grid container className={classes.container}>
        {title && (
          <Grid item xs={12}>
            <Typography className={classes.title} variant="body1">
              {title}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Avatar
            src={imgUrl || 'https://cdn1.iconfinder.com/data/icons/image-manipulations/100/13-512.png'}
            alt="Profilbild"
            className={[originalRatio ? classes.largeOriginal : classes.largeSquare, borderedPlaceholder && !imgUrl ? classes.borderedPlaceholder : ''].join(
              ' '
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl>
            <label htmlFor={id}>
              <Button variant="contained" component="span" style={{ marginRight: 10 }}>
                {!!imgUrl ? (
                  <Translate contentKey="digijagApp.app.image.changeImgUploadText">Ändra bild</Translate>
                ) : (
                  <Translate contentKey="digijagApp.app.image.imgUploadText">Välj bild att ladda upp</Translate>
                )}
              </Button>
              {!!imgUrl && (
                <Button variant="contained" color="secondary" onClick={this.deleteImage}>
                  <Translate contentKey="digijagApp.app.image.delete">Ta bort</Translate>
                </Button>
              )}
            </label>
            <input className={classes.fileInput} id={id} type="file" onChange={this.handleImageChange} ref={this.imageInputRef} accept="image/*" />
          </FormControl>
        </Grid>

        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          imagePreviewUrl && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.title}>
                  <Translate contentKey="digijagApp.app.image.new">Ny bild</Translate>
                </Typography>
                <br />
                <Avatar
                  src={imagePreviewUrl}
                  alt={translate('digijagApp.app.image.new')}
                  className={originalRatio ? classes.largeOriginal : classes.largeSquare}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={this.handleSubmit} style={{ marginRight: 10 }}>
                  <Translate contentKey="digijagApp.app.image.use">Använd</Translate>
                </Button>
                <Button variant="contained" color="secondary" onClick={this.cancelUpload}>
                  <Translate contentKey="digijagApp.app.image.cancel">Avbryt</Translate>
                </Button>
              </Grid>
            </>
          )
        )}
        {/* todo: Add better feedback for screen reader user when a file has been uploaded and errors occur. */}
        {error && <Grid item>{error}</Grid>}
      </Grid>
    );
  }
}

const mapStateToProps = ({ fileUpload }: IRootState) => ({
  downloadUrl: fileUpload.downloadUrl,
  loading: fileUpload.loading,
  error: fileUpload.error,
  uploadSuccess: fileUpload.uploadSuccess
});

const mapDispatchToProps = {
  upload,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ImageInputStyles)(ImageInput));
