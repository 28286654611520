import * as React from 'react';
import { withStyles, createStyles, Theme, Typography, Button, Box } from '@material-ui/core';
import { Classes } from 'jss';
import { NoteType } from 'app/shared/model/budgettavlan/note.model';
import ExpenseCard from './ExpenseCard';
import { IBudget } from 'app/shared/model/budgettavlan/budget.model';
import { IExpense } from 'app/shared/model/budgettavlan/expense.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExpensesListStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridGap: 20,
      gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))'
    }
  });

interface IExpensesListProps {
  classes: Classes;
  budget: IBudget;
  noteType: NoteType;
  getChildPayload: any;
  onDrop: any;
  editExpenseAmount: any;
  onCreateExpense: any;
  onClickExpense?: Function;
  onClickScenario?: Function;
  onClickMenu: Function;
  onClickEditMenu: Function;
  onClickDeleteMenu: Function;
  onCloseMenu: any;
  menus: any;
}

export class ExpensesList extends React.Component<IExpensesListProps> {
  render() {
    const {
      classes,
      budget,
      noteType,
      getChildPayload,
      onDrop,
      editExpenseAmount,
      onCreateExpense,
      onClickExpense,
      onClickScenario,
      onClickMenu,
      onClickEditMenu,
      onClickDeleteMenu,
      onCloseMenu,
      menus
    } = this.props;

    return budget.expenses && budget.expenses.length ? (
      <div className={classes.root}>
        {budget.expenses
          .filter((expense: IExpense) => !!expense.scenarioId)
          .sort((a, b) => a.id - b.id)
          .map((expense: IExpense) => (
            <ExpenseCard
              key={`budget-${budget.id}-expense-${expense.id}`}
              expense={expense}
              budgetId={budget.id}
              noteType={noteType}
              getChildPayload={getChildPayload}
              onDrop={null}
              onClick={onClickScenario}
              editExpenseAmount={null}
              onClickMenu={null}
              onClickEditMenu={null}
              onClickDeleteMenu={null}
              onCloseMenu={null}
              menuAnchorEl={null}
              isScenario
            />
          ))}

        {budget.expenses
          .filter((expense: IExpense) => !expense.scenarioId)
          .sort((a, b) => a.id - b.id)
          .map((expense: IExpense) => (
            <ExpenseCard
              key={`budget-${budget.id}-expense-${expense.id}`}
              expense={expense}
              budgetId={budget.id}
              noteType={noteType}
              getChildPayload={getChildPayload}
              onDrop={onDrop(expense)}
              onClick={onClickExpense}
              editExpenseAmount={editExpenseAmount(expense)}
              onClickMenu={onClickMenu(expense.id)}
              onClickEditMenu={onClickEditMenu(expense)}
              onClickDeleteMenu={onClickDeleteMenu(expense)}
              onCloseMenu={onCloseMenu}
              menuAnchorEl={menus[`${budget.id}_${expense.id}`]}
            />
          ))}
      </div>
    ) : (
      <Box py={4}>
        <Typography align="center" variant="body1">
          Ingen kostnad att visa ännu.
          <br />
          <br />
          <Button variant="contained" onClick={onCreateExpense} startIcon={<FontAwesomeIcon icon="plus-circle" />}>
            Skapa din första kostnad
          </Button>
        </Typography>
      </Box>
    );
  }
}

export default withStyles(ExpensesListStyles)(ExpensesList);
