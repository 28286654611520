import React, { Component } from 'react';
import { Grid, Paper, withStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { Container } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { ICommunity } from 'app/shared/model/community.model';
import { Classes } from 'jss';
import LoginForm from './LoginForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RedButton from './RedButton';
import MainSection from './MainSection';
import { contact } from 'app/config/constants';

interface ICommunityHomeProps {
  classes: Classes;
  community: ICommunity;
  isAuthenticated: boolean;
}

const CommunityHomeStyles = createStyles(({ breakpoints }: Theme) => ({
  contentContainer: {
    width: '75%'
  },
  leftColumn: {
    minHeight: 'calc((var(--vh, 1vh) * 100) - 25rem)',
    [breakpoints.down('md')]: {
      minHeight: 'auto',
      marginBottom: '2rem'
    }
  },
  logoPaper: {
    backgroundColor: 'white',
    padding: '1.8rem 0',
    textAlign: 'center',
    marginBottom: '2.5rem',
    '& img': {
      width: '80%'
    },
    [breakpoints.down('sm')]: {
      marginBottom: '1.5rem'
    }
  },
  welcome: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  communityName: {
    fontSize: '2.25rem',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  communityDescriptionPaper: {
    textAlign: 'left',
    marginTop: '2.5rem'
  },
  communityDescription: {
    padding: '10px'
  },
  loginPaper: {
    backgroundColor: 'white',
    padding: '3.8rem 3.125rem',
    [breakpoints.down('sm')]: {
      marginBottom: '2rem'
    }
  },
  contactButton: {
    margin: '1rem 0 2rem'
  },
  questionsText: {
    marginBottom: '0.5rem !important'
  }
}));

class CommunityHome extends Component<ICommunityHomeProps> {
  render() {
    const { community, classes, isAuthenticated } = this.props;
    return (
      <MainSection>
        <Container fluid className={classes.contentContainer}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item xs={12} lg={5} className={classes.leftColumn}>
              <Paper elevation={2} className={classes.logoPaper}>
                <img src={community.logoUrl} alt="" />
              </Paper>
              <Typography variant="h1" className={classes.welcome}>
                <Translate contentKey="digijagApp.community.home.welcomeTo">Välkommen till</Translate>
              </Typography>
              <Typography variant="h2" className={classes.communityName}>
                {community.displayName ? community.displayName : community.name}
              </Typography>
              <Paper className={classes.communityDescriptionPaper}>
                <Typography className={classes.communityDescription} variant="body1">
                  {community.description}
                </Typography>
              </Paper>
            </Grid>
            {!isAuthenticated && (
              <Grid item xs={12} lg={5}>
                <Paper elevation={2} className={classes.loginPaper}>
                  <LoginForm />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.questionsText}>
                <Translate contentKey="digijagApp.community.home.contactUs">Har ni frågor? Kontakta oss:</Translate>
              </Typography>
              <RedButton href={`mailto:${contact.EMAIL}`} component="a" className={classes.contactButton}>
                <FontAwesomeIcon icon="envelope" /> {contact.EMAIL}
              </RedButton>
            </Grid>
          </Grid>
        </Container>
      </MainSection>
    );
  }
}

export default withStyles(CommunityHomeStyles)(CommunityHome);
