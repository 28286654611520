import * as React from 'react';
import { withStyles, createStyles, Theme, Typography, Box } from '@material-ui/core';
import { Classes } from 'jss';
import { formatNumber } from 'app/shared/util/number-utils';
import { IBudget } from 'app/shared/model/budgettavlan/budget.model';
import { calculateRemainingBudget } from 'app/entities/budgettavlan/budget/budget.reducer';

const AmountBarStyles = (theme: Theme) =>
  createStyles({
    incomeAmountBar: {
      minWidth: '9rem',
      maxWidth: '11rem',
      border: '3px solid #000000',
      borderRadius: 10,
      overflow: 'hidden'
    },
    fullBar: {
      height: 800,
      width: '100%',
      backgroundColor: '#FFFFFF',
      position: 'relative'
    },
    greenBar: {
      backgroundColor: theme.palette.secondary.main,
      position: 'absolute',
      bottom: 400,
      left: 0,
      width: '100%',
      borderBottom: '3px dashed #000000'
    },
    redBar: {
      backgroundColor: '#F4343D',
      position: 'absolute',
      top: 400,
      left: 0,
      width: '100%'
    },
    positiveText: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      right: theme.spacing(1)
    },
    negativeText: {
      position: 'absolute',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      right: theme.spacing(1)
    }
  });

interface IAmountBarProps {
  classes: Classes;
  budget: IBudget;
}

export class AmountBar extends React.Component<IAmountBarProps> {
  render() {
    const { classes, budget } = this.props;
    const barLimit = 20000;
    const amountLeft = calculateRemainingBudget(budget);
    return (
      <div className={classes.incomeAmountBar}>
        <Box p={1} bgcolor="#FFFFFF" boxShadow="0px 3px 6px #00000029" position="relative" zIndex={1}>
          <Typography variant="body1">
            Inkomst:
            <br />
            {formatNumber(budget.incomeAmount)} kronor
          </Typography>
        </Box>
        <div className={classes.fullBar}>
          <div
            className={classes.greenBar}
            style={{
              height: budget.incomeAmount > 0 && amountLeft >= 0 ? 400 * (amountLeft / budget.incomeAmount) : 0,
              backgroundColor: amountLeft <= 0 && 'transparent'
            }}
          >
            {amountLeft >= 0 && (
              <Typography
                variant="body1"
                className={classes.positiveText}
                style={{
                  top: amountLeft / budget.incomeAmount <= 0.25 ? -56 : null
                }}
              >
                {formatNumber(amountLeft)} kronor
                <br />
                kvar
              </Typography>
            )}
          </div>
          <div
            className={classes.redBar}
            style={{
              height: amountLeft < 0 && -amountLeft <= barLimit ? 400 * (-amountLeft / barLimit) : -amountLeft >= barLimit ? 400 : 0,
              backgroundColor: amountLeft >= 0 && 'transparent'
            }}
          >
            {amountLeft < 0 && (
              <Typography
                variant="body1"
                className={classes.negativeText}
                style={{
                  bottom: -amountLeft / barLimit <= 0.25 ? -56 : null
                }}
              >
                Du ligger back
                <br />
                {formatNumber(-amountLeft)} kronor
              </Typography>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(AmountBarStyles)(AmountBar);
