import axios from 'axios';

import { SUCCESS, REQUEST, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudPutAction } from 'react-jhipster';

export const ACTION_TYPES = {
  UPLOAD_FILE: 'fileUpload/UPLOAD_FILE',
  SET_DEFAULT_PROFILE_IMAGE: 'fileUpload/SET_DEFAULT_PROFILE_IMAGE',
  RESET: 'fileUpload/RESET'
};

const initialState = {
  loading: false,
  uploadSuccess: false,
  downloadUrl: '',
  error: ''
};

export type FileUploadState = Readonly<typeof initialState>;

export default (state: FileUploadState = initialState, action): FileUploadState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPLOAD_FILE):
      return {
        ...state,
        loading: true,
        uploadSuccess: false,
        downloadUrl: '',
        error: ''
      };
    case SUCCESS(ACTION_TYPES.UPLOAD_FILE):
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
        downloadUrl: action.payload.data,
        error: ''
      };
    case FAILURE(ACTION_TYPES.UPLOAD_FILE):
      const errorMessage = 'Kunde inte ladda upp filen, prova med en annan fil.';
      return {
        ...state,
        loading: false,
        uploadSuccess: false,
        downloadUrl: '',
        error: errorMessage
      };
    case ACTION_TYPES.SET_DEFAULT_PROFILE_IMAGE:
      return {
        ...initialState,
        downloadUrl: action.payload
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/upload';

export const upload: ICrudPutAction<File> = file => async dispatch => {
  const formData = new FormData();
  formData.append('file', file);
  const result = await dispatch({
    type: ACTION_TYPES.UPLOAD_FILE,
    payload: axios.post(apiUrl, formData)
  });
  return result;
};

export const setDefaultProfileImage = () => ({
  // If we want to set different based on male/female:
  // const defaultUrl = ssn && (Number.parseInt(ssn.charAt(ssn.length-2)) % 2 == 0) // Is female?
  //   ? 'https://freesvg.org/img/female-user-icon.png'
  //   : 'http://res.publicdomainfiles.com/pdf_view/68/13927384215784.png';
  // return {
  type: ACTION_TYPES.SET_DEFAULT_PROFILE_IMAGE,
  payload: 'https://upload.wikimedia.org/wikipedia/commons/7/70/User_icon_BLACK-01.png'
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
