import { IDay } from './day.model';

export interface IWeek {
  id?: number;
  name?: string;
  days?: IDay[];
}

export const emptyWeek: IWeek = {
  name: '',
  days: [
    {
      name: 'Måndag',
      color: '#8ECB4D',
      activities: [],
      order: 0
    },
    {
      name: 'Tisdag',
      color: '#8EC7D4',
      activities: [],
      order: 1
    },
    {
      name: 'Onsdag',
      color: '#E1E1E1',
      activities: [],
      order: 2
    },
    {
      name: 'Torsdag',
      color: '#C18A56',
      activities: [],
      order: 3
    },
    {
      name: 'Fredag',
      color: '#F7F769',
      activities: [],
      order: 4
    },
    {
      name: 'Lördag',
      color: '#F3A1D0',
      activities: [],
      order: 5
    },
    {
      name: 'Söndag',
      color: '#F6403F',
      activities: [],
      order: 6
    }
  ]
};

export const defaultValue: Readonly<IWeek> = {};
