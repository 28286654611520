import axios from 'axios';

import { SUCCESS, REQUEST, FAILURE } from 'app/shared/reducers/action-type.util';
import { getEntities as getAllUserData } from 'app/entities/user-data/user-data.reducer';
import { defaultValue, IZoomMeeting } from '../../shared/model/zoom-meeting.model';
import { ICrudDeleteAction, ICrudGetAction, ICrudPutAction, translate } from 'react-jhipster';
import { cleanEntity } from '../../shared/util/entity-utils';
import { IUserData } from 'app/shared/model/user-data.model';
import { IZoomSignatureRequest } from 'app/shared/model/zoom-signature-request.model';

export const ACTION_TYPES = {
  GET_MEETING: 'zoom/GET_MEETING',
  GET_MEETINGS: 'zoom/GET_MEETINGS',
  POLL_MEETINGS: 'zoom/POLL_MEETINGS',
  GET_HOSTED_MEETINGS: 'zoom/GET_HOSTED_MEETINGS',
  CREATE_MEETING: 'zoom/CREATE_MEETING',
  UPDATE_MEETING: 'zoom/UPDATE_MEETING',
  CREATE_USER: 'zoom/CREATE_USER',
  CREATE_SIGNATURE: 'zoom/CREATE_SIGNATURE',
  DELETE_MEETING: 'zoom/DELETE_MEETING',
  RESET: 'zoom/RESET',
  START_POLLING: 'zoom/START_POLLING',
  STOP_POLLING: 'zoom/STOP_POLLING'
};

const initialState = {
  loading: false,
  polling: false,
  zoomMeeting: defaultValue,
  zoomMeetings: [],
  hostedZoomMeetings: [],
  zoomUserId: '',
  signature: '',
  error: '',
  errorDetails: '',
  pollingTimer: undefined,
  pollingError: '',
  pollingErrorDetails: '',
  updateSuccess: false
};

export type ZoomState = Readonly<typeof initialState>;

export default (state: ZoomState = initialState, action): ZoomState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_MEETING):
    case REQUEST(ACTION_TYPES.UPDATE_MEETING):
    case REQUEST(ACTION_TYPES.DELETE_MEETING):
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        zoomMeeting: defaultValue,
        error: ''
      };
    case REQUEST(ACTION_TYPES.GET_MEETING):
    case REQUEST(ACTION_TYPES.GET_MEETINGS):
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        error: ''
      };
    case REQUEST(ACTION_TYPES.POLL_MEETINGS):
      return {
        ...state,
        polling: true,
        pollingError: '',
        pollingErrorDetails: ''
      };
    case REQUEST(ACTION_TYPES.GET_HOSTED_MEETINGS):
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        hostedZoomMeetings: [],
        error: ''
      };
    case REQUEST(ACTION_TYPES.CREATE_USER):
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        zoomUserId: '',
        error: '',
        errorDetails: ''
      };
    case REQUEST(ACTION_TYPES.CREATE_SIGNATURE):
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        signature: '',
        error: '',
        errorDetails: ''
      };
    case SUCCESS(ACTION_TYPES.GET_MEETING):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        zoomMeeting: action.payload.data,
        error: '',
        errorDetails: ''
      };
    case SUCCESS(ACTION_TYPES.GET_MEETINGS):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        zoomMeetings: action.payload.data,
        error: '',
        errorDetails: ''
      };
    case SUCCESS(ACTION_TYPES.POLL_MEETINGS):
      return {
        ...state,
        polling: false,
        zoomMeetings: action.payload.data,
        pollingError: '',
        pollingErrorDetails: ''
      };
    case SUCCESS(ACTION_TYPES.GET_HOSTED_MEETINGS):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        hostedZoomMeetings: action.payload.data,
        error: '',
        errorDetails: ''
      };
    case SUCCESS(ACTION_TYPES.CREATE_MEETING):
    case SUCCESS(ACTION_TYPES.UPDATE_MEETING):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        zoomMeeting: action.payload.data,
        error: '',
        errorDetails: ''
      };
    case SUCCESS(ACTION_TYPES.CREATE_USER):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        zoomUserId: action.payload.data,
        error: '',
        errorDetails: ''
      };
    case SUCCESS(ACTION_TYPES.DELETE_MEETING):
      return {
        ...state,
        error: '',
        loading: false,
        updateSuccess: true,
        zoomMeeting: {}
      };
    case SUCCESS(ACTION_TYPES.CREATE_SIGNATURE):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        signature: action.payload.data,
        error: '',
        errorDetails: ''
      };
    case FAILURE(ACTION_TYPES.GET_MEETING):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        error: translate('digijagApp.zoomMeeting.error.getMeeting'),
        errorDetails: action.payload.response.data.detail
      };
    case FAILURE(ACTION_TYPES.GET_MEETINGS):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        error: translate('digijagApp.zoomMeeting.error.getMeetings'),
        errorDetails: action.payload.response.data.detail
      };
    case FAILURE(ACTION_TYPES.POLL_MEETINGS):
      return {
        ...state,
        polling: false,
        pollingError: translate('digijagApp.zoomMeeting.error.getMeetings'),
        pollingErrorDetails: action.payload.response.data.detail
      };
    case FAILURE(ACTION_TYPES.GET_HOSTED_MEETINGS):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        error: translate('digijagApp.zoomMeeting.error.getHosted'),
        errorDetails: action.payload.response.data.detail
      };
    case FAILURE(ACTION_TYPES.CREATE_MEETING):
    case FAILURE(ACTION_TYPES.UPDATE_MEETING):
    case FAILURE(ACTION_TYPES.DELETE_MEETING):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        error: translate('digijagApp.zoomMeeting.error.crud'),
        errorDetails: action.payload.response.data.detail
      };
    case FAILURE(ACTION_TYPES.CREATE_USER):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        error: translate('digijagApp.zoomMeeting.error.createUser'),
        errorDetails: action.payload.response.data.detail
      };
    case FAILURE(ACTION_TYPES.CREATE_SIGNATURE):
      return {
        ...state,
        loading: false,
        updateSuccess: false,
        error: translate('digijagApp.zoomMeeting.error.signature'),
        errorDetails: action.payload.response.data.detail
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.START_POLLING:
      return {
        ...state,
        pollingTimer: action.payload.timer
      };
    case ACTION_TYPES.STOP_POLLING:
      clearInterval(state.pollingTimer);
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = '/api/zoom';

export const createMeeting: ICrudPutAction<IZoomMeeting> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/meeting`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MEETING,
    payload: axios.post(requestUrl, cleanEntity(entity))
  });
  return result;
};

export const create1on1ZoomMeeting = (guest: IUserData) => async dispatch => {
  const requestUrl = `${apiUrl}/meeting`;
  const entity = {
    guests: [guest],
    topic: `${translate('digijagApp.zoomMeeting.meetingWith')} ${guest.userFirstName}`
  };
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MEETING,
    payload: axios.post(requestUrl, cleanEntity(entity))
  });
  return result;
};

export const createCommunityZoomMeeting = communityId => async dispatch => {
  const requestUrl = `${apiUrl}/meeting/community?targetCommunityId=${communityId}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_MEETING,
    payload: axios.post(requestUrl)
  });
  return result;
};

export const generateSignature: ICrudPutAction<IZoomSignatureRequest> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/signature`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_SIGNATURE,
    payload: axios.post(requestUrl, entity)
  });
  return result;
};

export const updateMeeting: ICrudPutAction<IZoomMeeting> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/meeting`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_MEETING,
    payload: axios.put(requestUrl, cleanEntity(entity))
  });
  return result;
};

export const createZoomUser = userId => async dispatch => {
  const requestUrl = apiUrl + `/user/${userId}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.post(requestUrl)
  });
  dispatch(getAllUserData());
  return result;
};

export const createZoomUserForCommunity = (userId, communityId) => async dispatch => {
  const requestUrl = apiUrl + `/user/${userId}/community/${communityId}`;
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.post(requestUrl)
  });
  dispatch(getAllUserData());
  return result;
};

export const getMeeting: ICrudGetAction<IZoomMeeting> = id => {
  const requestUrl = `${apiUrl}/meeting/${id}`;
  return {
    type: ACTION_TYPES.GET_MEETING,
    payload: axios.get<IZoomMeeting>(requestUrl)
  };
};

export const getMeetingsByCurrentHost = () => {
  const requestUrl = `${apiUrl}/meeting/host`;
  return {
    type: ACTION_TYPES.GET_HOSTED_MEETINGS,
    payload: axios.get<IZoomMeeting>(`${requestUrl}?cacheBuster=${new Date().getTime()}`)
  };
};

export const getMeetingsByCurrentGuest = () => {
  const requestUrl = `${apiUrl}/meeting/guest`;
  return {
    type: ACTION_TYPES.POLL_MEETINGS,
    payload: axios.get<IZoomMeeting>(`${requestUrl}?cacheBuster=${new Date().getTime()}`)
  };
};

export const getMeetingsByGuest = userDataId => {
  const requestUrl = `${apiUrl}/meeting/guest/${userDataId}`;
  return {
    type: ACTION_TYPES.GET_MEETINGS,
    payload: axios.get<IZoomMeeting>(`${requestUrl}?cacheBuster=${new Date().getTime()}`)
  };
};

export const deleteMeeting: ICrudDeleteAction<IZoomMeeting> = id => async dispatch => {
  const requestUrl = `${apiUrl}/meeting/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MEETING,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const endAndDeleteMeeting: ICrudDeleteAction<IZoomMeeting> = id => async dispatch => {
  const requestUrl = `${apiUrl}/meeting/end/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_MEETING,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const registerPollingTimer = timer => ({
  type: ACTION_TYPES.START_POLLING,
  payload: {
    timer
  }
});

export const stopPolling = () => ({
  type: ACTION_TYPES.STOP_POLLING
});
