import React, { useState } from 'react';
import { withStyles, createStyles, Theme, Typography, Grid, Button, Card } from '@material-ui/core';
import { Classes } from 'jss';
import { YoutubeDataAPI } from 'youtube-v3-api';
import { Label } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VoiceInput from 'app/custom-components/form-inputs/VoiceInput';

const YoutubeAppStyles = ({ breakpoints }: Theme) =>
  createStyles({
    appMainContainer: {
      backgroundColor: '#f7f7f7',
      minHeight: '100%'
    },

    appLogo: {
      width: '12.5rem'
    },

    borderRadius: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    },

    fieldContainer: {
      padding: '0 1.6rem',
      '& label': {
        margin: 0,
        fontSize: '.8rem'
      }
    },

    formSearch: {
      backgroundColor: '#FFFFFF',
      padding: '2rem 0',
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), ' + '0px 4px 5px 0px rgba(0, 0, 0, 0.14), ' + '0px 1px 10px 0px rgba(0, 0, 0, 0.12)'
    },

    sendFormButton: {
      color: '#FFFFFF',
      height: 45,
      background: 'linear-gradient(#4f4f4f, #272727)',
      borderRadius: 25,
      textTransform: 'none',
      fontWeight: 400,
      fontSize: '.8rem',
      width: 'auto',
      padding: '0 1rem',
      '& .svg-inline--fa': {
        marginRight: '.6rem',
        fontSize: '1.2rem',
        marginTop: '-.2rem'
      },
      [breakpoints.down('xs')]: {
        fontSize: 11,
        height: 32
      }
    },

    cardContainer: {
      width: '100%'
    },

    videoTitle: {
      color: '#000000 !important',
      fontSize: '1.125rem',
      fontWeight: 600,
      marginBottom: '1.125rem'
    },

    videoDescription: {
      color: '#000000 !important',
      fontSize: '1rem',
      fontWeight: 400
    },

    cardDescription: {
      padding: '1.25rem'
    },

    card: {
      width: '100%',
      borderRadius: 8,
      margin: '1.25rem 0',
      background: '#f3ccdd',
      '&:hover': {
        background: '#790f3d',
        '& h4': {
          color: '#FFFFFF !important'
        },
        '& p': {
          color: '#FFFFFF !important'
        }
      },
      [breakpoints.down('xs')]: {
        textAlign: 'center'
      },
      cursor: 'pointer',
      transition: '0.2s',
      '& img': {
        height: '11.25rem',
        width: 'auto'
      }
    },

    contentContainer: {
      padding: '2.25rem 0',
      '& iframe': {
        marginBottom: '2.5rem',
        height: '37rem !important',
        width: '100%',
        [breakpoints.down('md')]: {
          height: '32rem !important'
        },
        [breakpoints.down('sm')]: {
          height: '24rem !important'
        },
        [breakpoints.down('xs')]: {
          height: '16rem !important'
        }
      }
    },

    formLogoContainer: {
      textAlign: 'right',
      [breakpoints.down('md')]: {
        textAlign: 'center',
        marginBottom: '1rem'
      }
    },

    formButtonContainer: {
      textAlign: 'left',
      [breakpoints.down('sm')]: {
        marginTop: '1rem',
        textAlign: 'center'
      }
    }
  });

interface IYoutubeProps {
  classes: Classes;
}

interface IYoutubeState {
  searchResults: ReadonlyArray<any>;
  video?: any;
  error: string;
  searchStr: string;
}

export const YoutubeApp = (props: IYoutubeProps) => {
  const [searchResults, setSearchResults] = useState<IYoutubeState['searchResults']>([]);
  const [video, setVideo] = useState<IYoutubeState['video']>(null);
  const [error, setError] = useState<IYoutubeState['error']>('');
  const [searchStr, setSearchStr] = useState<IYoutubeState['searchStr']>('');

  const search = (event, errors) => {
    if (errors.length === 0 && searchStr.trim().length > 0) {
      // TODO: Move this into a Youtube reducer!
      const api = new YoutubeDataAPI('AIzaSyC4jbeiVTosl3Q_PWgjAATDdA-UywgBkns');
      api
        .searchAll(searchStr, 25, {
          type: 'video',
          videoEmbeddable: true,
          safeSearch: 'strict'
        })
        .then(
          data => {
            setSearchResults((data as any).items);
            setVideo(null);
          },
          err => {
            setSearchResults([]);
            setVideo(null);
            setError(err);
          }
        );
    }
  };

  const handleSearchChange = (value: string, isSpoken: boolean) => {
    setSearchStr(value);
  };

  const { classes } = props;

  return (
    <Grid className={classes.appMainContainer}>
      <AvForm model={{}} onSubmit={search} className={classes.formSearch}>
        <Grid container direction="row" justify="center" alignItems="flex-end">
          <Grid item xs={12} lg className={classes.formLogoContainer}>
            <img src="content/images/youtube-logo.png" alt="" className={classes.appLogo} />
          </Grid>
          <Grid item xs={12} md={10} lg={6} className={classes.fieldContainer}>
            <Label id="searchTermLabel" for="searchTerm">
              <Translate contentKey="digijagApp.youTube.searchLabel" />
            </Label>
            <VoiceInput id="searchTerm" type="text" value={searchStr} handleChange={handleSearchChange} autoFocus required />
          </Grid>
          <Grid item xs={12} md={2} lg className={classes.formButtonContainer}>
            <Button color="primary" type="submit" className={classes.sendFormButton}>
              <FontAwesomeIcon icon="search" />
              <Translate contentKey="entity.action.search">Sök</Translate>
            </Button>
          </Grid>
        </Grid>
      </AvForm>
      <Grid className={classes.contentContainer}>
        {video ? (
          <Grid container direction="row" justify="center">
            <Grid item xs={11} sm={10} md={8} xl={6}>
              <iframe
                src={`https://www.youtube.com/embed/${video.id.videoId}?iv_load_policy=3&cc_load_policy=1&modestbranding=1&rel_0`}
                frameBorder="0"
                title="YouTube"
                allowFullScreen
              />
              <Typography variant="h4" className={classes.videoTitle}>
                {video.snippet.title}
              </Typography>
              <Typography variant="body1" className={classes.videoDescription}>
                {video.snippet.description}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" alignItems="center">
            {searchResults.map((item, i) => (
              <Grid key={i} item xs={11} sm={10} md={8} xl={6} className={classes.cardContainer}>
                <Card onClick={() => setVideo(item)} className={classes.card}>
                  <Grid container direction="row">
                    <Grid item style={{ margin: '0 auto' }}>
                      <img src={item.snippet.thumbnails.medium.url} alt="" />
                    </Grid>
                    <Grid item xs={12} lg className={classes.cardDescription}>
                      <Typography variant="h4" className={classes.videoTitle}>
                        {item.snippet.title}
                      </Typography>
                      <Typography variant="body1" className={classes.videoDescription}>
                        {item.snippet.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(YoutubeAppStyles)(YoutubeApp);
