import React, { useEffect, useState } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';
import ActivityCard from './ActivityCard';
import { baseFontSize } from '../theme';
import { convertPxToRem } from '../../../../../shared/util/number-utils';
import { ViewMode } from '../pages/TimePlanningPage';
import { minuteStep } from './TimeCard';

export interface IStyleProps {
  viewMode: any;
}

export const hourlyGridRowHeight = Math.ceil(100 / (60 / minuteStep));
export const gridRowHeight = Math.ceil(100 / (60 / minuteStep));

const getTimeSlot = (time: number) => Math.floor(time / (minuteStep * 60));

export const checkConflicts = (day: IDay) => {
  const activitiesByTimeSlot = Array(24 * Math.floor(60 / minuteStep))
    .fill(null)
    .map(() => []);

  day.activities.forEach((activity, idx) => {
    if (activity.endTime > 0 && activity.startTime > activity.endTime) {
      const activitySplit1 = JSON.parse(JSON.stringify(activity));
      activitySplit1.startTime = 0;
      activitySplit1.endTime = activity.endTime;

      const activitySplit2 = JSON.parse(JSON.stringify(activity));
      activitySplit2.startTime = activity.startTime;
      activitySplit2.endTime = 24 * 60 * 60;

      const startSlot1 = activitySplit1.extraStartTime
        ? getTimeSlot(activitySplit1.startTime - activitySplit1.extraStartTime)
        : getTimeSlot(activitySplit1.startTime);
      const endSlot1 = activitySplit1.extraEndTime ? getTimeSlot(activitySplit1.extraEndTime + activitySplit1.endTime) : getTimeSlot(activitySplit1.endTime);

      for (let i = startSlot1; i < endSlot1; i++) {
        activitiesByTimeSlot[i].push(idx);
      }

      const startSlot2 = activitySplit2.extraStartTime
        ? getTimeSlot(activitySplit2.startTime - activitySplit2.extraStartTime)
        : getTimeSlot(activitySplit2.startTime);
      const endSlot2 = activitySplit2.extraEndTime ? getTimeSlot(activitySplit2.extraEndTime + activitySplit2.endTime) : getTimeSlot(activitySplit2.endTime);

      for (let i = startSlot2; i < endSlot2; i++) {
        activitiesByTimeSlot[i].push(idx);
      }
    } else {
      const startSlot = activity.extraStartTime ? getTimeSlot(activity.startTime - activity.extraStartTime) : getTimeSlot(activity.startTime);

      let endTime = activity.endTime + (activity.extraEndTime ? activity.extraEndTime : 0);

      if (endTime === 0) {
        endTime = 24 * 60 * 60;
      }

      const endSlot = getTimeSlot(endTime);

      for (let i = startSlot; i < endSlot; i++) {
        activitiesByTimeSlot[i].push(idx);
      }
    }
  });

  const conflictingActivities = [];

  activitiesByTimeSlot.forEach(timeSlot => {
    if (timeSlot.length > 1) {
      timeSlot.forEach(activityIdx => {
        if (conflictingActivities.indexOf(activityIdx) === -1) {
          conflictingActivities.push(activityIdx);
        }
      });
    }
  });

  return conflictingActivities;
};

const ActivitiesListStyles = (theme: Theme) =>
  createStyles({
    activityCard: {
      marginBottom: 25
    },
    activitiesContainer: {
      display: 'flex',
      position: 'relative'
    },
    activitiesList: {
      display: 'grid',
      listStyle: 'none',
      columnGap: '1.5rem',
      gridTemplateRows: (props: IStyleProps) =>
        props.viewMode === ViewMode.HOURS
          ? `repeat(${24 * Math.floor(60 / minuteStep)}, ${convertPxToRem(hourlyGridRowHeight, baseFontSize)})`
          : `repeat(${24 * Math.floor(60 / minuteStep)}, ${convertPxToRem(gridRowHeight * 4, baseFontSize)})`
    }
  });

interface IActivitiesListProps {
  classes: Classes;
  day: IDay;
  viewMode: ViewMode.HOURS | ViewMode.QUARTERS;
  setActiveActivityIdx?: Function;
  activeActivityIdx?: number;
  draggable?: boolean;
  onDragStart?: any;
}

interface IActivitiesListState {
  earliestActivity?: IActivity;
  conflicts?: number[];
}

function ActivitiesList(props: IActivitiesListProps) {
  const { classes, day, viewMode, activeActivityIdx, draggable, onDragStart } = props;
  const [conflicts, setConflicts] = useState<IActivitiesListState['conflicts']>([]);

  const getRowFromTime = (time: number) => parseInt(String(time / (minuteStep * 60)), 10) + 1;

  useEffect(
    () => {
      setConflicts(checkConflicts(day));
    },
    [day]
  );

  const handleSetActiveActivity = (idx: number) => event => {
    const { setActiveActivityIdx } = props;
    event.preventDefault();
    if (setActiveActivityIdx) {
      setActiveActivityIdx(idx);
    }
  };

  const handleDragStart = idx => e => {
    if (onDragStart) {
      onDragStart(e, idx);
    }
  };

  switch (viewMode) {
    case ViewMode.QUARTERS:
    case ViewMode.HOURS:
      return (
        <div className={classes.activitiesContainer}>
          <ul className={classes.activitiesList}>
            {day.activities.sort((a: IActivity, b: IActivity) => a.startTime - b.startTime).map((activity: IActivity, idx: number) => {
              if (activity.endTime > 0 && activity.startTime > activity.endTime) {
                const activitySplit1 = JSON.parse(JSON.stringify(activity));
                activitySplit1.startTime = 0;
                activitySplit1.endTime = activity.endTime;

                const activitySplit2 = JSON.parse(JSON.stringify(activity));
                activitySplit2.startTime = activity.startTime;
                activitySplit2.endTime = 24 * 60 * 60;

                return (
                  <React.Fragment key={activity.id + '_1' + idx}>
                    <li
                      style={{
                        gridRowStart: activitySplit1.extraStartTime
                          ? getRowFromTime(activitySplit1.startTime - activitySplit1.extraStartTime)
                          : getRowFromTime(activitySplit1.startTime),
                        gridRowEnd: activitySplit1.extraEndTime
                          ? getRowFromTime(activitySplit1.extraEndTime + activitySplit1.endTime)
                          : getRowFromTime(activitySplit1.endTime)
                      }}
                    >
                      <ActivityCard
                        selected={activeActivityIdx === idx}
                        setActiveActivity={handleSetActiveActivity(idx)}
                        conflicting={conflicts.indexOf(idx) !== -1}
                        draggable={draggable}
                        onDragStart={draggable ? handleDragStart(idx) : null}
                        activity={activity}
                        typeList={false}
                        viewMode={viewMode}
                      />
                    </li>
                    <li
                      style={{
                        gridRowStart: activitySplit2.extraStartTime
                          ? getRowFromTime(activitySplit2.startTime - activitySplit2.extraStartTime)
                          : getRowFromTime(activitySplit2.startTime),
                        gridRowEnd: activitySplit2.extraEndTime
                          ? getRowFromTime(activitySplit2.extraEndTime + activitySplit2.endTime)
                          : getRowFromTime(activitySplit2.endTime)
                      }}
                    >
                      <ActivityCard
                        selected={activeActivityIdx === idx}
                        setActiveActivity={handleSetActiveActivity(idx)}
                        conflicting={conflicts.indexOf(idx) !== -1}
                        draggable={draggable}
                        onDragStart={draggable ? handleDragStart(idx) : null}
                        activity={activity}
                        typeList={false}
                        viewMode={viewMode}
                      />
                    </li>
                  </React.Fragment>
                );
              } else {
                const gridRowStart = activity.extraStartTime
                  ? getRowFromTime(activity.startTime - activity.extraStartTime)
                  : getRowFromTime(activity.startTime);

                let finalEndTime = activity.endTime + (activity.extraEndTime ? activity.extraEndTime : 0);

                if (finalEndTime === 0) {
                  finalEndTime = 24 * 60 * 60;
                }

                const gridRowEnd = getRowFromTime(finalEndTime);

                return (
                  <li
                    key={activity.id + '_2' + idx}
                    style={{
                      gridRowStart,
                      gridRowEnd
                    }}
                  >
                    <ActivityCard
                      selected={activeActivityIdx === idx}
                      setActiveActivity={handleSetActiveActivity(idx)}
                      conflicting={conflicts.indexOf(idx) !== -1}
                      draggable={draggable}
                      onDragStart={draggable ? handleDragStart(idx) : null}
                      activity={activity}
                      typeList={false}
                      viewMode={viewMode}
                    />
                  </li>
                );
              }
            })}
          </ul>
        </div>
      );
    default:
      return (
        <div>
          {day.activities.sort((a: IActivity, b: IActivity) => a.startTime - b.startTime).map((activity: IActivity, idx: number) => (
            <div key={`${day.name}-activities-${idx}`} className={classes.activityCard}>
              <ActivityCard
                setActiveActivity={handleSetActiveActivity(idx)}
                activity={activity}
                selected={activeActivityIdx === idx}
                draggable={draggable}
                onDragStart={draggable ? handleDragStart(idx) : null}
                conflicting={conflicts.indexOf(idx) !== -1}
                typeList
              />
            </div>
          ))}
        </div>
      );
  }
}

export default withStyles(ActivitiesListStyles)(ActivitiesList);
