import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IncomeVoiceInput from '../IncomeVoiceInput';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IExpenseAmountDialogProps {
  open: boolean;
  onClose: any;
  titleText: string;
  value: number;
  onChange: any;
  onSubmit: any;
  inputStep: number;
  maxValue: number;
  excludeAmounts: number[];
}

export class ExpenseAmountDialog extends React.Component<IExpenseAmountDialogProps> {
  render() {
    const { open, onClose, titleText, value, onChange, onSubmit, inputStep, maxValue, excludeAmounts } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="expense-amount-dialog-title" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="expense-amount-dialog-title">
          <FontAwesomeIcon icon="sack-dollar" />
          {titleText}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <IncomeVoiceInput amount={value} onChange={onChange} inputStep={inputStep} maxValue={maxValue} excludeAmounts={excludeAmounts} />
          </form>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onSubmit} color="primary" startIcon={<FontAwesomeIcon icon="thumbs-up" />}>
            Okej
          </Button>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ExpenseAmountDialog;
