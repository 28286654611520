import * as React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';

const AppHeaderStyles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FFFFFF'
    }
  });

interface IAppHeaderProps {
  classes: Classes;
  className?: string;
}

export class AppHeader extends React.Component<IAppHeaderProps> {
  render() {
    const { classes, children, className } = this.props;

    return <header className={className ? `${classes.root} ${className}` : classes.root}>{children}</header>;
  }
}

export default withStyles(AppHeaderStyles)(AppHeader);
