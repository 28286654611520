import { INotesDistribution } from './note.model';

export interface IExpense {
  amount?: number;
  budgetId?: number;
  id?: number;
  name?: string;
  icon?: string;
  imgUrl?: string;
  notesDistribution?: INotesDistribution;
  scenarioId?: string;
}

export const emptyExpense = {
  name: '',
  icon: '',
  amount: 0,
  notesDistribution: {},
  scenarioId: null
};

const defaultExpenses: IExpense[] = [
  {
    name: 'Boende',
    icon: 'home',
    amount: 0,
    notesDistribution: {},
    scenarioId: null
  },
  {
    name: 'Mat',
    icon: 'utensils',
    amount: 0,
    notesDistribution: {},
    scenarioId: null
  }
];

export interface IScenario {
  expense: IExpense;
  extraIncome: number;
  detailedCosts: any[];
  additionalInfo?: string;
}

export const scenarios: { [id: string]: IScenario } = {
  child: {
    expense: {
      name: 'Barn',
      icon: 'child',
      amount: 4200,
      notesDistribution: { '1000': 4, '100': 2 },
      scenarioId: 'child'
    },
    extraIncome: 1250,
    additionalInfo: 'Vi har lagt till barnbidrag på din inkomst.\n Din inkomst har därför ökat med 1 250 kronor.',
    detailedCosts: [
      {
        title: 'Mat',
        amount: 1040
      },
      {
        title: 'Kläder',
        amount: 845
      },
      {
        title: 'Blöjor och hygien',
        amount: 310
      },
      {
        title: 'Leksaker, fritid',
        amount: 265
      },
      {
        title: 'Försäkringar',
        amount: 190
      },
      {
        title: 'Medicin och vård',
        amount: 200
      },
      {
        title: 'Förbrukningsartiklar',
        amount: 450
      },
      {
        title: 'Barnomsorg',
        amount: 600
      },
      {
        title: 'Övrigt',
        amount: 300
      }
    ]
  },
  dog: {
    expense: {
      name: 'Hund',
      icon: 'dog',
      amount: 1400,
      notesDistribution: { '1000': 1, '100': 4 },
      scenarioId: 'dog'
    },
    extraIncome: 0,
    detailedCosts: [
      {
        title: 'Hundmat',
        amount: 700
      },
      {
        title: 'Försäkring',
        amount: 300
      },
      {
        title: 'Veterinärbesök',
        amount: 300
      },
      {
        title: 'Tillbehör',
        amount: 100
      }
    ]
  },
  car: {
    expense: {
      name: 'Bil',
      icon: 'car',
      amount: 3290,
      notesDistribution: { '1000': 3, '100': 2, '50': 1, '20': 2 },
      scenarioId: 'car'
    },
    extraIncome: 0,
    detailedCosts: [
      {
        title: 'Försäkring och skatt',
        amount: 650
      },
      {
        title: 'Service, reparationer',
        amount: 500
      },
      {
        title: 'Underhåll, däck',
        amount: 340
      },
      {
        title: 'Parkering, garage',
        amount: 300
      },
      {
        title: 'Bensin/diesel',
        amount: 1500
      }
    ]
  }
};

export default defaultExpenses;
export const defaultValue: Readonly<IExpense> = {};
