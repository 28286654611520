import { Moment } from 'moment';

export interface IConnection {
  id?: number;
  inviteOthers?: boolean;
  notifyFullSpace?: boolean;
  notifyAbsent?: boolean;
  remoteControlToken?: string;
  remoteControlTokenCreatedDate?: Moment;
  remoteControlJwt?: string;
  studentId?: number;
  studentLogin?: string;
  helperId?: number;
  helperLogin?: string;
}

export const defaultValue: Readonly<IConnection> = {
  inviteOthers: false,
  notifyFullSpace: false,
  notifyAbsent: false
};
