import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { defaultValue, IBudget } from 'app/shared/model/budgettavlan/budget.model';
import { addIdsToExpensesBeforeSavingBudget } from '../expense/expense.reducer';

export const ACTION_TYPES = {
  FETCH_BUDGET_LIST: 'budget/FETCH_BUDGET_LIST',
  FETCH_BUDGET: 'budget/FETCH_BUDGET',
  CREATE_BUDGET: 'budget/CREATE_BUDGET',
  TRANSFER_BUDGETS: 'budget/TRANSFER_BUDGETS',
  UPDATE_BUDGET: 'budget/UPDATE_BUDGET',
  DELETE_BUDGET: 'budget/DELETE_BUDGET',
  UPDATE_BUDGET_LOCAL_STATE: 'budget/UPDATE_BUDGET_LOCAL_STATE',
  RESET: 'budget/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IBudget>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  transferSuccess: false,
  transferring: false
};

export type BudgetState = Readonly<typeof initialState>;

// Reducer

export default (state: BudgetState = initialState, action): BudgetState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_BUDGET_LIST):
    case REQUEST(ACTION_TYPES.FETCH_BUDGET):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_BUDGET):
    case REQUEST(ACTION_TYPES.UPDATE_BUDGET):
    case REQUEST(ACTION_TYPES.DELETE_BUDGET):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.TRANSFER_BUDGETS):
      return {
        ...state,
        errorMessage: null,
        transferSuccess: false,
        transferring: true
      };
    case FAILURE(ACTION_TYPES.FETCH_BUDGET_LIST):
    case FAILURE(ACTION_TYPES.FETCH_BUDGET):
    case FAILURE(ACTION_TYPES.CREATE_BUDGET):
    case FAILURE(ACTION_TYPES.UPDATE_BUDGET):
    case FAILURE(ACTION_TYPES.DELETE_BUDGET):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.TRANSFER_BUDGETS):
      return {
        ...state,
        transferring: false,
        transferSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_BUDGET_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_BUDGET):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_BUDGET): {
      const newEntitiesArray = state.entities.slice();
      newEntitiesArray.push(action.payload.data);
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        entities: newEntitiesArray
      };
    }
    case SUCCESS(ACTION_TYPES.TRANSFER_BUDGETS): {
      // Remove saved entities from sessionStorage so they don't get transferred again.
      window.sessionStorage.removeItem(storageKey);
      return {
        ...state,
        transferring: false,
        transferSuccess: true,
        errorMessage: null,
        entities: action.payload.data
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_BUDGET):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        entities: state.entities.map(entity => (entity.id === action.payload.data.id ? { ...entity, ...action.payload.data } : entity))
      };
    case SUCCESS(ACTION_TYPES.DELETE_BUDGET):
      const newEntitiesList = state.entities.filter(entity => entity.id !== action.meta.id);

      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: newEntitiesList.length > 0 ? { ...newEntitiesList[0] } : {},
        entities: newEntitiesList
      };
    case ACTION_TYPES.UPDATE_BUDGET_LOCAL_STATE: {
      // When set locally without failure/success status
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        loading: false,
        errorMessage: null,
        entity: action.payload.entity,
        entities: action.payload.updatedEntities
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/budgettavlan/budget';
export const storageKey = 'budgettavlan/budgets';

// Actions

export const getEntities: ICrudGetAllAction<IBudget> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_BUDGET_LIST,
    payload: axios.get<IBudget>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getMyEntities = () => {
  const requestUrl = `${apiUrl}/allbycurrent?page=0&size=10000&sort=id,asc`;
  return {
    type: ACTION_TYPES.FETCH_BUDGET_LIST,
    payload: axios.get<IBudget>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntitiesForAdmin = () => {
  const requestUrl = `${apiUrl}?page=0&size=10000`;
  return {
    type: ACTION_TYPES.FETCH_BUDGET_LIST,
    payload: axios.get<IBudget>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IBudget> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_BUDGET,
    payload: axios.get<IBudget>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IBudget> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_BUDGET,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IBudget> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_BUDGET,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IBudget> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_BUDGET,
    payload: axios.delete(requestUrl),
    meta: { id }
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const transferBudgetsFromSession = () => async dispatch => {
  const entities: IBudget[] = getEntitiesFromLocalStorage();
  const result = await dispatch({
    type: ACTION_TYPES.TRANSFER_BUDGETS,
    payload: axios.post<IBudget[]>(
      `${apiUrl}/transfer?page=0&size=10000&sort=id,asc&cacheBuster=${new Date().getTime()}`,
      entities.map(entity => cleanEntity(entity))
    )
  });
  return result;
};

//
// Actions for non-logged in users:
//
function getEntitiesFromLocalStorage(): IBudget[] {
  const storedObject = window.sessionStorage.getItem(storageKey);
  return storedObject ? (JSON.parse(storedObject) as IBudget[]) : ([] as IBudget[]);
}

export const hasBudgetsInSessionStorage = (): boolean => !!window.sessionStorage.getItem(storageKey);

function storeEntities(entities: IBudget[]) {
  entities = entities.map(budget => addIdsToExpensesBeforeSavingBudget(budget));
  window.sessionStorage.setItem(storageKey, JSON.stringify(entities));
}

export const createEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  entity.id = entities.length ? Math.max(...entities.map(o => o.id)) + 1 : 1;
  entities.push(entity);
  storeEntities(entities);
  return {
    type: ACTION_TYPES.UPDATE_BUDGET_LOCAL_STATE,
    payload: { entity, updatedEntities: entities }
  };
};

export const updateEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.map(storedEntity => (storedEntity.id === entity.id ? { ...storedEntity, ...entity } : storedEntity));
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_BUDGET_LOCAL_STATE,
    payload: { entity, updatedEntities }
  };
};

export const deleteEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.filter(e => e.id !== id);
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_BUDGET_LOCAL_STATE,
    payload: {
      entity: updatedEntities.length > 0 ? { ...updatedEntities[0] } : null,
      updatedEntities
    }
  };
};

export const getEntitiesNoBackend = () => {
  const entities = getEntitiesFromLocalStorage();
  return {
    type: ACTION_TYPES.UPDATE_BUDGET_LOCAL_STATE,
    payload: {
      entity: entities && entities.length ? entities[0] : null,
      updatedEntities: entities
    }
  };
};

export const getEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const entity = entities.find(e => e.id === id);
  return {
    type: ACTION_TYPES.UPDATE_BUDGET_LOCAL_STATE,
    payload: {
      entity,
      updatedEntities: entities
    }
  };
};

export const calculateRemainingBudget = (budget: IBudget) => {
  if (!budget) {
    return 0;
  } else if (!budget.expenses || !budget.expenses.length) {
    return budget.incomeAmount || 0;
  } else {
    return budget.incomeAmount - calculateTotalCosts(budget);
  }
};

export const calculateTotalCosts = (budget: IBudget) => {
  if (!budget || !budget.expenses || !budget.expenses.length) {
    return 0;
  } else {
    return budget.expenses.reduce((a, b) => a + b.amount, 0);
  }
};
