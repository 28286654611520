import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { Classes } from 'jss';
import AdminSideBarItem, { IAdminSideBarItemData } from './AdminSideBarItem';

const AdminSideBarStyles = () => createStyles({});

interface IAdminSideBarProps {
  classes: Classes;
  sidebarItems: IAdminSideBarItemData[];
  currentPath?: string;
}

export const AdminSideBar: React.SFC<IAdminSideBarProps> = ({ classes, sidebarItems, currentPath }) => (
  <Grid container direction="row" justify="flex-start" className={classes.sidebarContainer}>
    {sidebarItems.map((item, idx) => (
      <AdminSideBarItem key={idx} itemData={item} active={currentPath === item.url} />
    ))}
  </Grid>
);

export default withStyles(AdminSideBarStyles)(AdminSideBar as any);
