import React from 'react';
import DigiJagAlertContent from './DigiJagAlertContent';
import DigiJagBaseDialog from './DigiJagBaseDialog';

interface IDigiJagAlertDialogProps {
  isOpen: boolean;
  icon: string;
  text: any;
  buttonText: string;
  onClose: Function;
}

export class DigiJagAlertDialog extends React.Component<IDigiJagAlertDialogProps> {
  render() {
    const { isOpen, icon, text, buttonText, onClose } = this.props;

    return (
      <DigiJagBaseDialog open={isOpen} onClose={onClose} aria-labelledby="dialog-description">
        <DigiJagAlertContent buttonText={buttonText} icon={icon} text={text} onClose={onClose} />
      </DigiJagBaseDialog>
    );
  }
}

export default DigiJagAlertDialog;
