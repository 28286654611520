import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IncomeVoiceInput from '../IncomeVoiceInput';
import CustomSlider from 'app/custom-components/form-inputs/CustomSlider';

interface IChangeIncomeDialogProps {
  open: boolean;
  onClose: any;
  value: number;
  onChange: any;
  onSubmit: any;
  inputStep: number;
  sliderStep: number;
  maxValue: number;
  excludeAmounts: number[];
}

export class ChangeIncomeDialog extends React.Component<IChangeIncomeDialogProps> {
  render() {
    const { open, onClose, value, onChange, onSubmit, inputStep, sliderStep, maxValue, excludeAmounts } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="income-dialog-title"
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="income-dialog-title">
          <FontAwesomeIcon icon="plus-circle" />
          Lägg till inkomst
        </DialogTitle>

        <DialogContent style={{ overflow: 'hidden' }}>
          <form onSubmit={onSubmit}>
            <IncomeVoiceInput amount={value} onChange={onChange} inputStep={inputStep} maxValue={maxValue} excludeAmounts={excludeAmounts} />
            <CustomSlider value={value} min={0} max={maxValue} step={sliderStep} handleChange={onChange} />
          </form>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onSubmit} color="primary" startIcon={<FontAwesomeIcon icon="thumbs-up" />}>
            Okej
          </Button>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ChangeIncomeDialog;
