import React, { PropsWithChildren, useEffect, useState } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';
import { DASH_BOARD_DROP_DOWN_ACTIONS } from './DashBoardDropdownActions';
import DashBoardTopBar from './DashBoardTopBar';
import AskForHelpDialog from '../digijag-dialogs/help/AskForHelpDialog';
import InviteHelperDialog from '../digijag-dialogs/invite-helper/InviteHelperDialog';
import ChangePasswordDialog from '../digijag-dialogs/change-password/ChangePasswordDialog';
import DigiJagConfirmationDialog from '../digijag-dialogs/base-dialogs/DigiJagConfirmationDialog';
import { Redirect } from 'react-router-dom';
import { translate } from 'react-jhipster';

const DashBoardBaseStyles = ({ breakpoints }: Theme) =>
  createStyles({
    board: {
      width: '100vw',
      height: 'calc(var(--vh, 1vh) * 100)',
      overflow: 'hidden'
    },

    sidebarGrid: {
      [breakpoints.up('lg')]: {
        maxWidth: '12rem'
      },
      [breakpoints.down('md')]: {
        order: 2
      }
    }
  });

interface IDashBoardBaseProps {
  classes: Classes;
  navigate: Function;
  boardBgColor?: string;
  deferredPrompt?: any;
  previousUrl?: string;
  disabledMenuButtons?: string[];
  handleBrandClick?: (event: any) => void;
}

interface IDashBoardBaseState {
  isOpen: boolean;
  activeDialog: DASH_BOARD_DROP_DOWN_ACTIONS;
  successfullyInstalled: boolean;
  acceptedInstall: boolean;
  declinedInstall: boolean;
}

export function DashBoardBase({
  classes,
  navigate,
  boardBgColor,
  deferredPrompt,
  previousUrl,
  disabledMenuButtons,
  handleBrandClick,
  children
}: PropsWithChildren<IDashBoardBaseProps>) {
  const [isOpen, setIsOpen] = useState<IDashBoardBaseState['isOpen']>(false);
  const [activeDialog, setActiveDialog] = useState<IDashBoardBaseState['activeDialog']>(undefined);
  const [successfullyInstalled, setSuccessfullyInstalled] = useState<IDashBoardBaseState['successfullyInstalled']>(false);
  const [acceptedInstall, setAcceptedInstall] = useState<IDashBoardBaseState['acceptedInstall']>(false);
  const [declinedInstall, setDeclinedInstall] = useState<IDashBoardBaseState['declinedInstall']>(false);

  useEffect(() => {
    // check if user is already running app from home screen
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setSuccessfullyInstalled(true);
    } else {
      // this event fires only when app is installed
      window.addEventListener('appinstalled', () => {
        setSuccessfullyInstalled(true);
      });
    }
  });

  const isEmpty = (obj: any): boolean => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const addToHomeScreen = () => {
    if (isEmpty(deferredPrompt)) {
      // tslint:disable-next-line:no-console
      console.log('No deferred prompt found');
      return;
    }
    // tslint:disable-next-line:no-console
    console.log('Deferred prompt found, running it!');
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === 'accepted') {
        setAcceptedInstall(true);
      } else {
        setDeclinedInstall(true);
      }
    });
  };

  const handleDropdownAction = (dropdownAction: DASH_BOARD_DROP_DOWN_ACTIONS) => {
    switch (dropdownAction) {
      case DASH_BOARD_DROP_DOWN_ACTIONS.ADD_TO_HOME_SCREEN:
        addToHomeScreen();
        break;
      case DASH_BOARD_DROP_DOWN_ACTIONS.INVITE_HELPER:
      case DASH_BOARD_DROP_DOWN_ACTIONS.ASK_FOR_HELP:
      case DASH_BOARD_DROP_DOWN_ACTIONS.CHANGE_PASSWORD:
      case DASH_BOARD_DROP_DOWN_ACTIONS.LOGOUT:
        setActiveDialog(dropdownAction);
        setIsOpen(true);
        addBackdrop();
        break;
      default:
        setActiveDialog(dropdownAction);
        break;
    }
  };

  const handleCloseDialog = () => {
    setActiveDialog(undefined);
    setIsOpen(false);
    removeBackdrop();
  };

  const handleLogoutRedirect = () => {
    navigate('/logout');
  };

  const handleInviteHelper = () => {
    setActiveDialog(DASH_BOARD_DROP_DOWN_ACTIONS.INVITE_HELPER);
    setIsOpen(true);
    addBackdrop();
  };

  const addBackdrop = () => {
    const container = window.document.getElementById('dashboard-content-area');
    if (container && !container.className.includes('blur-backdrop')) {
      container.className += ' blur-backdrop';
    }
  };

  const removeBackdrop = () => {
    const container = window.document.getElementById('dashboard-content-area');
    if (container) {
      container.className = container.className.replace(/blur-backdrop/g, '');
    }
  };

  return (
    <div className={classes.board} style={{ backgroundColor: boardBgColor || 'transparent' }}>
      <DashBoardTopBar disabledMenuButtons={disabledMenuButtons} handleDropdownAction={handleDropdownAction} />

      <div id="dashboard-content-area">{children}</div>

      {
        {
          ASK_FOR_HELP: <AskForHelpDialog onClose={handleCloseDialog} handleInviteHelper={handleInviteHelper} isOpen={isOpen} />,
          INVITE_HELPER: <InviteHelperDialog onClose={handleCloseDialog} isOpen={isOpen} />,
          CHANGE_PASSWORD: <ChangePasswordDialog onClose={handleCloseDialog} isOpen={isOpen} />,
          LOGOUT: (
            <DigiJagConfirmationDialog
              icon={'logout_big'}
              text={translate('digijagApp.dashboard.logOutDialog.message')}
              acceptButtonText={translate('digijagApp.dashboard.logOutDialog.yes')}
              declineButtonText={translate('digijagApp.dashboard.logOutDialog.no')}
              onClose={handleCloseDialog}
              onConfirm={handleLogoutRedirect}
              isOpen={isOpen}
            />
          ),
          ENTITY_APPS: <Redirect to={translate('digijagApp.routes.app.root')} />,
          ENTITY_CONTENT: <Redirect to={translate('digijagApp.routes.content.root')} />,
          ENTITY_USERS: <Redirect to="/entity/user-data" />,
          ENTITY_HELPERS: <Redirect to="/entity/connection" />,
          ENTITY_COMMUNITIES: <Redirect to="/entity/community" />,
          ENTITY_MEETINGS: <Redirect to="/entity/zoom" />
        }[activeDialog]
      }
    </div>
  );
}

export default withStyles(DashBoardBaseStyles)(DashBoardBase);
