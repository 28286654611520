import { IconDefinition, IconName, IconPrefix, library } from '@fortawesome/fontawesome-svg-core';
import { faSkype } from '@fortawesome/free-brands-svg-icons/faSkype';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowCircleLeft,
  faBars,
  faBookOpen,
  faBuilding,
  faBus,
  faCircle,
  faCog,
  faCreditCard,
  faEdit,
  faEllipsisV,
  faExclamationTriangle,
  faExpand,
  faExternalLinkAlt,
  faImage,
  faMicrophone,
  faMoneyBillAlt,
  faPenSquare,
  faPills,
  faPlane,
  faPlusCircle,
  faShoppingBag,
  faShoppingCart,
  faSquare,
  faThLarge,
  faThumbsUp,
  faTicketAlt,
  faTrashAlt,
  faUmbrellaBeach,
  faUndoAlt,
  faUserCircle,
  faUtensils,
  faWallet,
  faListUl,
  faAngleUp,
  faAngleDown,
  faPlayCircle,
  faStopCircle,
  faEye,
  faSort,
  faSync,
  faBan,
  faTrash,
  faArrowLeft,
  faSave,
  faPlus,
  faPencilAlt,
  faUser,
  faHdd,
  faTachometerAlt,
  faHeart,
  faList,
  faTasks,
  faBook,
  faClock as fasClock,
  faSignInAlt,
  faSignOutAlt,
  faThList,
  faUserPlus,
  faWrench,
  faAsterisk,
  faFlag,
  faBell,
  faHome,
  faTimesCircle,
  faSearch,
  faRoad,
  faCloud,
  faInfoCircle,
  faLocationArrow,
  faArrowCircleDown,
  faQuestionCircle,
  faChevronLeft,
  faEnvelope,
  faBirthdayCake,
  faPhoneSquare,
  faSpinner,
  faArrowUp,
  faUsers,
  faWindowRestore,
  faUserFriends,
  faFolder,
  faVideo,
  faUnlockAlt,
  faKey,
  faCopy,
  faComments,
  faColumns,
  faCar,
  faTshirt,
  faFileInvoiceDollar,
  faCoins,
  faGamepad,
  faDollarSign,
  faChild,
  faShieldAlt,
  faBusAlt,
  faExpandArrowsAlt,
  faDog,
  faSoap,
  faCalendarDay,
  faCalendarWeek,
  faCheckCircle,
  faDoorOpen,
  faCalendar,
  faCalendarAlt,
  faClock,
  faPrint
} from '@fortawesome/free-solid-svg-icons';

const faSackDollar: IconDefinition = {
  prefix: 'fas' as IconPrefix,
  iconName: 'sack-dollar' as IconName,
  icon: [
    512,
    512,
    [],
    'f81d',
    // tslint:disable:max-line-length
    'M192 96h128l47.4-71.12A16 16 0 0 0 354.09 0H157.94a16 16 0 0 0-13.31 24.88zm128 32H192C-10.38 243.4.09 396.64.09 416c0 53 49.11 96 109.68 96h292.48c60.58 0 109.68-43 109.68-96 0-19 9.35-173.24-191.93-288zm-46.58 278v17.34a8.69 8.69 0 0 1-8.7 8.62h-17.41a8.69 8.69 0 0 1-8.71-8.62v-17.51a63.19 63.19 0 0 1-34.16-12.17 8.55 8.55 0 0 1-.66-13l12.84-12.06a8.92 8.92 0 0 1 11-.76 26.72 26.72 0 0 0 13.93 4h30.58c7.07 0 12.84-6.35 12.84-14.22 0-6.46-3.92-12.06-9.58-13.67l-49-14.54c-20.24-6-34.39-25.2-34.39-46.74 0-26.38 20.68-47.82 46.46-48.57v-17.48a8.69 8.69 0 0 1 8.74-8.62h17.41a8.68 8.68 0 0 1 8.7 8.62v17.55a63.12 63.12 0 0 1 34.17 12.17 8.55 8.55 0 0 1 .65 13l-12.73 12.2a8.92 8.92 0 0 1-11 .75 26.78 26.78 0 0 0-13.93-4h-30.56c-7.07 0-12.84 6.35-12.84 14.21 0 6.46 3.92 12.06 9.57 13.68l49 14.54c20.24 6 34.38 25.2 34.38 46.74-.14 26.4-20.92 47.94-46.6 48.54z'
    // tslint:enable:max-line-length
  ]
};

const faDaySun: IconDefinition = {
  prefix: 'fas' as IconPrefix,
  iconName: 'day-sun' as IconName,
  icon: [
    60,
    60,
    [],
    'f81e',
    // tslint:disable:max-line-length
    'm 13.588,17.859 a 3,3 0 0 0 4.24,-4.24 L 15,10.79 a 3,3 0 1 0 -4.24,4.24 z m -10e-4,28.293 -2.829,2.829 A 3,3 0 1 0 15,53.221 l 2.829,-2.829 a 3,3 0 0 0 -4.24,-4.24 z m 36.774,10e-4 a 3,3 0 1 0 -4.24,4.24 l 2.829,2.829 a 3,3 0 1 0 4.24,-4.24 z m 0.001,-28.294 2.829,-2.829 a 3,3 0 1 0 -4.24,-4.24 l -2.829,2.829 a 3,3 0 0 0 4.24,4.24 z M 31.975,12 a 3,3 0 0 0 3,-3 V 5 a 3,3 0 0 0 -6,0 v 4 a 3,3 0 0 0 3,3 z M 11.993,32.006 a 3,3 0 0 0 -3,-3 H 5 a 3,3 0 1 0 0,6 h 4 a 3,3 0 0 0 3,-3 z M 31.975,52 a 3,3 0 0 0 -3,3 v 4 a 3,3 0 0 0 6,0 v -4 a 3,3 0 0 0 -3,-3 z M 59,29.005 h -4 a 3,3 0 1 0 0,6 h 4 a 3,3 0 0 0 0,-6 z M 31.974563,15.000047 a 17,17 0 1 0 16.999999,17 17,17 0 0 0 -16.999999,-17 z m 0.644531,9.507812 a 1.2,1.2 0 0 1 0.90039,0.375 1.285,1.285 0 0 1 0.363282,0.931641 v 12.384766 a 1.216,1.216 0 0 1 -0.40625,0.933593 1.431,1.431 0 0 1 -1.007813,0.375 1.355,1.355 0 0 1 -0.984375,-0.375 1.247,1.247 0 0 1 -0.386719,-0.933593 v -10.15625 l -1.328125,0.814453 a 1.311,1.311 0 0 1 -0.685546,0.193359 1.192,1.192 0 0 1 -0.91211,-0.408203 1.3,1.3 0 0 1 -0.375,-0.900391 1.217,1.217 0 0 1 0.181641,-0.642578 1.326,1.326 0 0 1 0.482422,-0.470703 l 3.236328,-1.929687 a 1.958,1.958 0 0 1 0.921875,-0.191407 z'
    // tslint:enable:max-line-length
  ]
};

const faWeekSun: IconDefinition = {
  prefix: 'fas' as IconPrefix,
  iconName: 'week-sun' as IconName,
  icon: [
    60,
    60,
    [],
    'f81f',
    // tslint:disable:max-line-length
    'm 13.588,17.859 a 3,3 0 0 0 4.24,-4.24 L 15,10.79 a 3,3 0 1 0 -4.24,4.24 z m -10e-4,28.293 -2.829,2.829 A 3,3 0 1 0 15,53.221 l 2.829,-2.829 a 3,3 0 0 0 -4.24,-4.24 z m 36.774,10e-4 a 3,3 0 1 0 -4.24,4.24 l 2.829,2.829 a 3,3 0 1 0 4.24,-4.24 z m 0.001,-28.294 2.829,-2.829 a 3,3 0 1 0 -4.24,-4.24 l -2.829,2.829 a 3,3 0 0 0 4.24,4.24 z M 31.975,12 a 3,3 0 0 0 3,-3 V 5 a 3,3 0 0 0 -6,0 v 4 a 3,3 0 0 0 3,3 z M 11.993,32.006 a 3,3 0 0 0 -3,-3 H 5 a 3,3 0 1 0 0,6 h 4 a 3,3 0 0 0 3,-3 z M 31.975,52 a 3,3 0 0 0 -3,3 v 4 a 3,3 0 0 0 6,0 v -4 a 3,3 0 0 0 -3,-3 z M 59,29.005 h -4 a 3,3 0 1 0 0,6 h 4 a 3,3 0 0 0 0,-6 z M 31.974563,15.000047 a 17,17 0 1 0 16.999999,17 17,17 0 0 0 -16.999999,-17 z m -3.800782,9.505859 h 7.408203 a 1.453,1.453 0 0 1 0.96875,0.351563 1.13,1.13 0 0 1 0.414063,0.90039 1.456,1.456 0 0 1 -0.126953,0.572266 L 31.236281,38.697313 A 1.23,1.23 0 0 1 30.769484,39.296922 1.239,1.239 0 0 1 30.068313,39.509813 1.323,1.323 0 0 1 29.142531,39.144578 1.143,1.143 0 0 1 28.748,38.271531 1.356,1.356 0 0 1 28.876906,37.697313 L 33.833937,26.84575 h -5.660156 a 1.155,1.155 0 0 1 -0.851562,-0.339844 1.155,1.155 0 0 1 -0.339844,-0.851562 1.094,1.094 0 0 1 0.339844,-0.81836 1.174,1.174 0 0 1 0.851562,-0.330078 z'
    // tslint:enable:max-line-length
  ]
};

export const loadIcons = () => {
  library.add(
    faDoorOpen,
    faComments,
    faCopy,
    faKey,
    faUnlockAlt,
    faVideo,
    faFolder,
    faUserFriends,
    faWindowRestore,
    faUsers,
    faSpinner,
    faSort,
    faEye,
    faSync,
    faBan,
    faTrash,
    faArrowLeft,
    faSave,
    faPlus,
    faPencilAlt,
    faUser,
    faTachometerAlt,
    faList,
    faTasks,
    faBook,
    faHdd,
    fasClock,
    faSignInAlt,
    faSignOutAlt,
    faWrench,
    faThList,
    faUserPlus,
    faAsterisk,
    faFlag,
    faBell,
    faHome,
    faRoad,
    faCloud,
    faTimesCircle,
    faSearch,
    faInfoCircle,
    faLocationArrow,
    faArrowCircleDown,
    faQuestionCircle,
    faChevronLeft,
    faBirthdayCake,
    faPhoneSquare,
    faEnvelope,
    faArrowUp,
    faSkype,
    faUtensils,
    faBus,
    faHome,
    faShoppingBag,
    faPills,
    faBookOpen,
    faTicketAlt,
    faShoppingCart,
    faUmbrellaBeach,
    faPlane,
    faThumbsUp,
    faTrashAlt,
    faInfoCircle,
    faPlusCircle,
    faArrowCircleLeft,
    faUserCircle,
    faUndoAlt,
    faImage,
    faSquare,
    faTimesCircle,
    faCreditCard,
    faSave,
    faMicrophone,
    faWallet,
    faCog,
    faExternalLinkAlt,
    faMoneyBillAlt,
    faColumns,
    faCoins,
    faGamepad,
    faHeart,
    faEllipsisV,
    faEdit,
    faExclamationTriangle,
    faCircle,
    faDollarSign,
    faThLarge,
    faExpand,
    faBars,
    faPenSquare,
    faListUl,
    faAngleUp,
    faAngleDown,
    faPlayCircle,
    faStopCircle,
    faStickyNote,
    faExclamationTriangle,
    faCar,
    faChild,
    faTshirt,
    faFileInvoiceDollar,
    faShieldAlt,
    faBusAlt,
    faSoap,
    faExpandArrowsAlt,
    faDog,
    faCalendar,
    faCalendarAlt,
    faCalendarDay,
    faClock,
    faCalendarWeek,
    faCheckCircle,
    faSackDollar,
    faDaySun,
    faWeekSun,
    faPrint,
    faBuilding
  );
};
