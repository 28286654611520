import React from 'react';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { Typography, withStyles, createStyles, Button, Theme, TextField } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import { login } from 'app/shared/reducers/authentication';
import { connect } from 'react-redux';
import FrejaButton from 'app/shared/auth/freja-button';
import { AuthMode } from 'app/shared/auth/auth_modes';

interface ILoginFormProps extends StateProps, DispatchProps {
  classes: Classes;
  errorMessage: any;
  loginError: boolean;
}

interface ILoginFormState {
  username: string;
  password: string;
}

const LoginFormStyles = ({ breakpoints }: Theme) =>
  createStyles({
    loginTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      textAlign: 'center'
    },
    loginText: {
      margin: '2rem 0',
      lineHeight: '1.8rem',
      textAlign: 'center'
    },
    submitButton: {
      color: '#FFFFFF',
      height: 45,
      background: 'linear-gradient(#4f4f4f, #272727)',
      borderRadius: 25,
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 16,
      marginTop: '1.5rem',
      [breakpoints.down('xs')]: {
        fontSize: 11,
        height: 32
      },
      '& svg': {
        marginRight: '0.25rem'
      }
    }
  });

class LoginForm extends React.Component<ILoginFormProps, ILoginFormState> {
  state: ILoginFormState = {
    username: '',
    password: ''
  };

  handleSubmit = event => {
    event.preventDefault();
    const { username, password } = this.state;
    const rememberMe = false;
    this.props.login(username, password, rememberMe);
  };

  onChange = (propertyName: string) => ev => {
    this.setState({
      [propertyName]: ev.target.value
    } as any);
  };

  render() {
    const { classes, errorMessage, loginError } = this.props;
    const { username, password } = this.state;

    const errorTranslationKey =
      errorMessage && errorMessage.response && errorMessage.response.status
        ? 'login.messages.error.' + errorMessage.response.status
        : 'login.messages.error.authentication';

    return (
      <React.Fragment>
        <Typography className={classes.loginTitle}>
          <FontAwesomeIcon icon="sign-in-alt" /> <Translate contentKey="login.title">Logga in</Translate>
        </Typography>
        <Typography className={classes.loginText}>
          <Translate contentKey="login.messages.description">Skriv ditt användarnamn och lösenord för att logga in på DigiJag.</Translate>
        </Typography>
        <form onSubmit={this.handleSubmit} className={classes.form}>
          {loginError ? (
            <Alert color="danger">
              <Translate contentKey={errorTranslationKey}>
                <strong>Failed to sign in!</strong> Please check your credentials and try again.
              </Translate>
            </Alert>
          ) : null}
          <Typography component="div" gutterBottom>
            <TextField
              variant="outlined"
              label={translate('global.form.username')}
              value={username}
              onChange={this.onChange('username')}
              InputProps={{
                onInvalid: ev =>
                  (ev.target as HTMLInputElement).setCustomValidity(translate('error.NotNull', { fieldName: translate('digijagApp.loginVM.username') })),
                onInput: ev => (ev.target as HTMLInputElement).setCustomValidity('')
              }}
              InputLabelProps={{
                shrink: true
              }}
              required
              fullWidth
            />
          </Typography>
          <Typography component="div" gutterBottom>
            <TextField
              variant="outlined"
              label={translate('login.form.password')}
              value={password}
              onChange={this.onChange('password')}
              type="password"
              InputProps={{
                onInvalid: ev =>
                  (ev.target as HTMLInputElement).setCustomValidity(translate('error.NotNull', { fieldName: translate('digijagApp.loginVM.password') })),
                onInput: ev => (ev.target as HTMLInputElement).setCustomValidity('')
              }}
              InputLabelProps={{
                shrink: true
              }}
              required
              fullWidth
            />
          </Typography>
          <Typography align="center">
            <Button color="primary" type="submit" className={classes.submitButton}>
              <FontAwesomeIcon icon="location-arrow" />
              <Translate contentKey="login.form.button">Sign in</Translate>
            </Button>
          </Typography>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  loginError: authentication.loginError,
  errorMessage: authentication.errorMessage
});

const mapDispatchToProps = { login };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(LoginFormStyles)(LoginForm));
