import React from 'react';

import { withStyles, createStyles, Theme, DialogContent, DialogActions, Grid, Typography, LinearProgress } from '@material-ui/core';
import { Classes } from 'jss';
import AcceptButton from '../../form-inputs/AcceptButton';
import { translate } from 'react-jhipster';
import DeclineButton from '../../form-inputs/DeclineButton';

export const DigiJagConfirmationContentStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      letterSpacing: '1.23px',
      marginBottom: '2.5rem'
    },

    dialogText: {
      fontSize: 18,
      color: '#000000',
      marginTop: 30,
      textAlign: 'center',
      fontWeight: 600,
      lineHeight: 1.8,
      [breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: 1.5
      }
    },

    dialogContent: {
      flex: 'none',
      paddingBottom: 50,
      [breakpoints.down('xs')]: {
        padding: 24
      }
    },

    dialogIcon: {
      maxWidth: 160,
      maxHeight: 160,
      [breakpoints.down('xs')]: {
        maxHeight: 90,
        maxWidth: 90
      }
    },

    buttonContainer: {
      margin: 0,
      paddingBottom: '1.5rem'
    },

    buttonGrid: {
      [breakpoints.down('xs')]: {
        justifyContent: 'space-evenly'
      }
    },

    acceptButton: {
      marginRight: '1.5rem'
    }
  });

interface IDigiJagConfirmationContentProps {
  classes: Classes;
  onClose: Function;
  onConfirm: Function;
  title?: string;
  icon?: string;
  text?: any;
  acceptButtonText?: string;
  declineButtonText?: string;
  acceptButtonIcon?: string;
}

export class DigiJagConfirmationContent extends React.Component<IDigiJagConfirmationContentProps> {
  render() {
    const { classes, title, icon, text, acceptButtonText, acceptButtonIcon, declineButtonText, onClose, onConfirm, children } = this.props;

    return (
      <React.Fragment>
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column" justify="center" alignItems="center">
            {title && (
              <Typography variant="h2" align="center" className={classes.dialogTitle}>
                {title}
              </Typography>
            )}
            {icon && <img src={`content/icons/${icon}.svg`} alt="" className={classes.dialogIcon} />}
            {text && (
              <Typography id="dialog-description" variant="body1" color="inherit" className={classes.dialogText}>
                {text}
              </Typography>
            )}
            {children}
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Grid container direction="row" justify="center" alignItems="center" className={classes.buttonGrid}>
            <Grid item className={classes.acceptButton}>
              <AcceptButton onClick={() => onConfirm(true)} icon={acceptButtonIcon}>
                {acceptButtonText}
              </AcceptButton>
            </Grid>
            <Grid item>
              <DeclineButton onClick={onClose}>{declineButtonText}</DeclineButton>
            </Grid>
          </Grid>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default withStyles(DigiJagConfirmationContentStyles)(DigiJagConfirmationContent);
