import React, { useEffect, useState } from 'react';
import { withStyles, createStyles, Theme, Button, Typography, Grid, GridJustification } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertPxToRem } from 'app/shared/util/number-utils';
import { baseFontSize } from '../theme';

const TimeSelectStyles = (theme: Theme) =>
  createStyles({
    buttonRoot: {
      height: `${convertPxToRem(26, baseFontSize)}`,
      display: 'flex',
      backgroundColor: '#E5E9F7',
      borderRadius: '0 !important',
      '&:hover, &:focus': {
        backgroundColor: '#3B538B',
        color: '#fff'
      }
    },
    buttonLabel: {
      display: 'inline-block',
      textAlign: 'center'
    },
    timeSeparator: {
      paddingTop: `${convertPxToRem(45, baseFontSize)}`,
      margin: '0 .2rem',
      '& p': {
        fontSize: '1.5rem'
      }
    },
    timeFieldGroup: {
      border: '2px solid #3B538B',
      borderRadius: '.5rem',
      width: '4.4rem',
      textAlign: 'center',
      overflow: 'hidden',
      backgroundColor: '#FFFFFF'
    },
    typography: {
      fontSize: `${convertPxToRem(24, baseFontSize)}`,
      padding: `${convertPxToRem(24, baseFontSize)} 0`,
      color: '#000000'
    }
  });

interface ITimeSelectProps {
  classes: Classes;
  time: number;
  minuteStep?: number;
  onChangeTime: any;
  disabled?: boolean;
  align?: 'left' | 'center' | 'right';
  timeType: string;
}

interface ITimeSelectState {
  timeHour: number;
  timeMinute: number;
}

function TimeSelect(props: React.PropsWithChildren<ITimeSelectProps>) {
  const { classes, time, onChangeTime, minuteStep, timeType, align, disabled } = props;

  const [timeHour, setTimeHour] = useState<ITimeSelectState['timeHour']>(0);
  const [timeMinute, setTimeMinute] = useState<ITimeSelectState['timeMinute']>(0);

  const step = minuteStep > 0 ? minuteStep : 1;

  useEffect(() => {
    setTimeMinute((time / 60) % 60);
    setTimeHour(Math.floor(time / (60 * 60)));
  }, []);

  const handleChangeHour = (amount: number) => {
    const newTimeHour = timeHour + amount;
    if (newTimeHour < 0) {
      setTimeHour(24 + newTimeHour);
    } else if (newTimeHour >= 24) {
      setTimeHour(newTimeHour - 24);
    } else {
      setTimeHour(newTimeHour);
    }

    onChangeTime((newTimeHour * 60 + timeMinute) * 60);
  };

  const handleChangeMinute = (amount: number) => {
    const newTimeMinute = timeMinute + amount;
    if (newTimeMinute < 0) {
      setTimeMinute(60 + newTimeMinute);
    } else if (newTimeMinute >= 60) {
      setTimeMinute(newTimeMinute - 60);
    } else {
      setTimeMinute(newTimeMinute);
    }

    onChangeTime((timeHour * 60 + newTimeMinute) * 60);
  };

  return (
    <Grid
      container
      justify={
        align
          ? ({
              left: 'flex-start',
              center: 'center',
              right: 'flex-end'
            }[align] as GridJustification)
          : null
      }
    >
      <Grid item>
        <div className={classes.timeFieldGroup}>
          <Button
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            onClick={() => handleChangeHour(1)}
            disabled={disabled}
            fullWidth
            aria-label={`${timeType}. Tryck för att öka med en timme.`}
            role="button"
            disableFocusRipple
          >
            <FontAwesomeIcon icon="angle-up" />
          </Button>
          <Typography align="center" className={classes.typography} aria-live="polite">
            {timeHour < 10 ? '0' + timeHour : timeHour}
          </Typography>
          <Button
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            onClick={() => handleChangeHour(-1)}
            disabled={disabled}
            fullWidth
            aria-label={`${timeType}. Tryck för att minska med en timme.`}
            role="button"
            disableFocusRipple
          >
            <FontAwesomeIcon icon="angle-down" />
          </Button>
        </div>
        <Typography align="center" aria-hidden="true">
          Timme
        </Typography>
      </Grid>

      <Grid item className={classes.timeSeparator}>
        <Typography aria-hidden="true">:</Typography>
      </Grid>

      <Grid item>
        <div className={classes.timeFieldGroup}>
          <Button
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            onClick={() => handleChangeMinute(step)}
            disabled={disabled}
            fullWidth
            aria-label={`${timeType}. Tryck för att öka med 15 minuter.`}
            role="button"
            disableFocusRipple
          >
            <FontAwesomeIcon icon="angle-up" />
          </Button>
          <Typography align="center" className={classes.typography} aria-live="polite">
            {timeMinute < 10 ? '0' + timeMinute : timeMinute}
          </Typography>
          <Button
            classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
            onClick={() => handleChangeMinute(-step)}
            disabled={disabled}
            fullWidth
            aria-label={`${timeType}. Tryck för att minska med 15 minuter.`}
            role="button"
            disableFocusRipple
          >
            <FontAwesomeIcon icon="angle-down" />
          </Button>
        </div>
        <Typography align="center" aria-hidden="true">
          Minut
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(TimeSelectStyles)(TimeSelect);
