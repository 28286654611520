import * as React from 'react';
import { Card, CardContent, Grid, Button, CardHeader, Box, Theme, createStyles, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VerticalContainer from '../../shared-components/VerticalContainer';
import { Classes } from 'jss';
import CustomSlider from 'app/custom-components/form-inputs/CustomSlider';
import IncomeVoiceInput, { validateIncome } from '../components/IncomeVoiceInput';

const BudgetIncomePageStyles = (theme: Theme) =>
  createStyles({
    cardForm: {
      margin: '0 auto',
      maxWidth: theme.breakpoints.width('sm'),
      width: '100%'
    }
  });

interface IBudgetIncomePageProps {
  classes: Classes;
  defaultAmount: number;
  maxValue: number;
  inputStep: number;
  sliderStep: number;
  excludeAmounts?: number[];
  onSubmit: Function;
  onCancel: Function;
}

interface IBudgetIncomePageState {
  amount: number;
}

export class BudgetIncomePage extends React.Component<IBudgetIncomePageProps, IBudgetIncomePageState> {
  constructor(props) {
    super(props);
    this.state = {
      amount: this.props.defaultAmount
    };
  }

  onChange = amount => {
    this.setState({ amount });
  };

  onSubmit = e => {
    e.preventDefault();
    const { inputStep, excludeAmounts, maxValue } = this.props;
    const { amount } = this.state;
    const incomeAmount = validateIncome(amount, maxValue, inputStep, excludeAmounts);
    this.props.onSubmit(incomeAmount);
  };

  onCancel = e => {
    e.preventDefault();
    this.props.onCancel();
  };

  render() {
    const { classes, maxValue, sliderStep, inputStep, excludeAmounts } = this.props;
    const { amount } = this.state;

    return (
      <VerticalContainer>
        <Grid item style={{ padding: '0 20px' }}>
          <Card className={classes.cardForm}>
            <CardHeader
              title={
                <>
                  <FontAwesomeIcon icon="plus-circle" />
                  Lägg till inkomst
                </>
              }
              disableTypography
            />
            <CardContent>
              <form onSubmit={this.onSubmit}>
                <IncomeVoiceInput amount={amount} onChange={this.onChange} inputStep={inputStep} maxValue={maxValue} excludeAmounts={excludeAmounts} />
                <CustomSlider value={amount} min={0} max={maxValue} step={sliderStep} handleChange={this.onChange} />
                <Box mt={4}>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={this.onSubmit} startIcon={<FontAwesomeIcon icon="thumbs-up" />}>
                        Okej
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" onClick={this.onCancel} startIcon={<FontAwesomeIcon icon="arrow-circle-left" />}>
                        Tillbaka
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </VerticalContainer>
    );
  }
}

export default withStyles(BudgetIncomePageStyles)(BudgetIncomePage);
