import React from 'react';
import { Switch } from 'react-router-dom';

// tslint:disable-next-line:no-unused-variable
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserData from './user-data';
import Widget from './widget';
import App from './app';
import Connection from './connection';
import Community from './community';
import Address from './address';
import Invitation from './invitation';
import Content from './content';
import Zoom from './zoom-meeting';
import Organization from './organization';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div className="container" style={{ padding: '1rem', maxWidth: 'none' }}>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}/user-data`} component={UserData} />
      <ErrorBoundaryRoute path={`${match.url}/widget`} component={Widget} />
      <ErrorBoundaryRoute path={`${match.url}/app`} component={App} />
      <ErrorBoundaryRoute path={`${match.url}/connection`} component={Connection} />
      <ErrorBoundaryRoute path={`${match.url}/community`} component={Community} />
      <ErrorBoundaryRoute path={`${match.url}/address`} component={Address} />
      <ErrorBoundaryRoute path={`${match.url}/invitation`} component={Invitation} />
      <ErrorBoundaryRoute path={`${match.url}/content`} component={Content} />
      <ErrorBoundaryRoute path={`${match.url}/zoom`} component={Zoom} />
      <ErrorBoundaryRoute path={`${match.url}/organization`} component={Organization} />
      {/* jhipster-needle-add-route-path - JHipster will routes here */}
    </Switch>
  </div>
);

export default Routes;
