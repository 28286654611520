import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { defaultValue, IDay } from 'app/shared/model/fixatiden/day.model';
import { addIdsToActivitiesBeforeSavingDay } from '../activity/activity.reducer';
import { IWeek } from 'app/shared/model/fixatiden/week.model';

export const ACTION_TYPES = {
  FETCH_DAY_LIST: 'day/FETCH_DAY_LIST',
  FETCH_DAY: 'day/FETCH_DAY',
  CREATE_DAY: 'day/CREATE_DAY',
  TRANSFER_DAYS: 'week/TRANSFER_DAYS',
  UPDATE_DAY: 'day/UPDATE_DAY',
  DELETE_DAY: 'day/DELETE_DAY',
  CREATE_DAY_LOCAL_STATE: 'day/CREATE_DAY_LOCAL_STATE',
  UPDATE_DAY_LOCAL_STATE: 'day/UPDATE_DAY_LOCAL_STATE',
  RESET: 'day/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IDay>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  createSuccess: false,
  updateSuccess: false,
  transferSuccess: false,
  transferring: false
};

export type DayState = Readonly<typeof initialState>;

// Reducer

export default (state: DayState = initialState, action): DayState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DAY_LIST):
    case REQUEST(ACTION_TYPES.FETCH_DAY):
      return {
        ...state,
        errorMessage: null,
        createSuccess: false,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_DAY):
    case REQUEST(ACTION_TYPES.UPDATE_DAY):
    case REQUEST(ACTION_TYPES.DELETE_DAY):
      return {
        ...state,
        errorMessage: null,
        createSuccess: false,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.TRANSFER_DAYS):
      return {
        ...state,
        errorMessage: null,
        transferSuccess: false,
        transferring: true
      };
    case FAILURE(ACTION_TYPES.FETCH_DAY_LIST):
    case FAILURE(ACTION_TYPES.FETCH_DAY):
    case FAILURE(ACTION_TYPES.CREATE_DAY):
    case FAILURE(ACTION_TYPES.UPDATE_DAY):
    case FAILURE(ACTION_TYPES.DELETE_DAY):
      return {
        ...state,
        loading: false,
        updating: false,
        createSuccess: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.TRANSFER_DAYS):
      return {
        ...state,
        transferring: false,
        transferSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_DAY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_DAY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_DAY): {
      const newEntitiesArray = state.entities.slice();
      newEntitiesArray.push(action.payload.data);
      return {
        ...state,
        updating: false,
        createSuccess: true,
        updateSuccess: false,
        entity: action.payload.data,
        entities: newEntitiesArray
      };
    }
    case SUCCESS(ACTION_TYPES.TRANSFER_DAYS): {
      // Remove saved entities from sessionStorage so they don't get transferred again.
      window.sessionStorage.removeItem(storageKey);
      return {
        ...state,
        transferring: false,
        transferSuccess: true,
        errorMessage: null,
        entities: action.payload.data
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_DAY): {
      return {
        ...state,
        updating: false,
        createSuccess: false,
        updateSuccess: true,
        entity: action.payload.data,
        entities: state.entities.map(entity => (entity.id === action.payload.data.id ? { ...entity, ...action.payload.data } : entity))
      };
    }
    case SUCCESS(ACTION_TYPES.DELETE_DAY):
      return {
        ...state,
        updating: false,
        createSuccess: false,
        updateSuccess: true,
        entity: {},
        entities: state.entities.filter(entity => entity.id !== action.meta.id)
      };
    case ACTION_TYPES.CREATE_DAY_LOCAL_STATE: {
      // When set locally without failure/success status
      return {
        ...state,
        updating: false,
        createSuccess: true,
        updateSuccess: false,
        loading: false,
        errorMessage: null,
        entity: action.payload.entity,
        entities: action.payload.updatedEntities
      };
    }
    case ACTION_TYPES.UPDATE_DAY_LOCAL_STATE: {
      // When set locally without failure/success status
      return {
        ...state,
        updating: false,
        createSuccess: false,
        updateSuccess: true,
        loading: false,
        errorMessage: null,
        entity: action.payload.entity,
        entities: action.payload.updatedEntities
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/tidsplanering/day';
export const storageKey = 'tidsplanering/days';

// Actions

export const getEntities: ICrudGetAllAction<IDay> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_DAY_LIST,
    payload: axios.get<IDay>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}?page=0&size=10000&sort=id,asc`;
  return {
    type: ACTION_TYPES.FETCH_DAY_LIST,
    payload: axios.get<IDay>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IDay> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_DAY,
    payload: axios.get<IDay>(requestUrl)
  };
};

export const createEntity = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DAY,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IDay> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/${entity.id}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_DAY,
    payload: axios.put(requestUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IDay> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_DAY,
    payload: axios.delete(requestUrl),
    meta: { id }
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const transferDaysFromSession = () => async dispatch => {
  const entities: IWeek[] = getEntitiesFromLocalStorage();
  const result = await dispatch({
    type: ACTION_TYPES.TRANSFER_DAYS,
    payload: axios.post<IWeek[]>(
      `${apiUrl}/transfer?page=0&size=10000&sort=id,asc&cacheBuster=${new Date().getTime()}`,
      entities.map(entity => cleanEntity(entity))
    )
  });
  return result;
};

//
// Actions for non-logged in users:
//
export function addIdsToDaysBeforeSavingWeek(week: IWeek): IWeek {
  if (!week || !week.days || !week.days.length) {
    return week;
  } else {
    let nextId = 1;
    week.days = week.days.map(d => {
      d.id = d.id || week.id + nextId++;
      return d;
    });
    return week;
  }
}

export function getNextDayId() {
  const entities = getEntitiesFromLocalStorage();
  return entities.length ? Math.max(...entities.map(o => o.id)) + 1 : 1;
}

function getEntitiesFromLocalStorage(): IDay[] {
  const storedObject = window.sessionStorage.getItem(storageKey);
  return storedObject ? (JSON.parse(storedObject) as IDay[]) : ([] as IDay[]);
}

export const hasDaysInSessionStorage = (): boolean => !!window.sessionStorage.getItem(storageKey);

function storeEntities(entities: IDay[]) {
  entities = entities.map(day => addIdsToActivitiesBeforeSavingDay(day));
  window.sessionStorage.setItem(storageKey, JSON.stringify(entities));
}

export const createEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  // Add id as max ID in sessionStorage + 1, or '1' if first object stored
  entity.id = getNextDayId();
  entities.push(entity);
  storeEntities(entities);
  return {
    type: ACTION_TYPES.CREATE_DAY_LOCAL_STATE,
    payload: { entity, updatedEntities: entities }
  };
};

export const updateEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.map(storedEntity => (storedEntity.id === entity.id ? { ...storedEntity, ...entity } : storedEntity));
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_DAY_LOCAL_STATE,
    payload: { entity, updatedEntities }
  };
};

export const deleteEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.filter(e => e.id !== id);
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_DAY_LOCAL_STATE,
    payload: {
      entity: null,
      updatedEntities
    }
  };
};

export const getEntitiesNoBackend = () => {
  const entities = getEntitiesFromLocalStorage();
  return {
    type: ACTION_TYPES.UPDATE_DAY_LOCAL_STATE,
    payload: {
      entity: entities && entities.length ? entities[0] : null,
      updatedEntities: entities
    }
  };
};

export const getEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const entity = entities.find(e => e.id === id);
  return {
    type: ACTION_TYPES.UPDATE_DAY_LOCAL_STATE,
    payload: {
      entity,
      updatedEntities: entities
    }
  };
};
