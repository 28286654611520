import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Alert, Button } from 'reactstrap';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { IRootState } from 'app/shared/reducers';
import { handleRegisterWithAuthorities, reset } from './register.reducer';
import { APP_USERNAME_LENGTH, APP_EMAIL_LENGTH, APP_PASSWORD_LENGTH } from 'app/config/constants';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IRegisterProps extends StateProps, DispatchProps {}

export interface IRegisterState {
  password: string;
}

export class RegisterPage extends React.Component<IRegisterProps, IRegisterState> {
  state: IRegisterState = {
    password: ''
  };

  alertRef = React.createRef<HTMLDivElement>();

  componentDidUpdate = prevProps => {
    const { registrationFailure, errorMessage } = this.props;
    const { focusDiv } = this;
    if (registrationFailure && !prevProps.registrationFailure) focusDiv();
    if (errorMessage && prevProps.errorMessage !== errorMessage) focusDiv();
  };

  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = (event, values) => {
    this.props.handleRegisterWithAuthorities(
      values.username,
      values.email,
      values.firstPassword,
      ['ROLE_COMMUNITY_ADMIN'],
      this.props.currentLocale
    );
    event.preventDefault();
  };

  focusDiv = () => {
    const { alertRef } = this;
    const node = alertRef.current;
    if (node) node.focus();
  };

  updatePassword = event => {
    this.setState({ password: event.target.value });
  };

  render() {
    const { registrationSuccess, registrationFailure, errorMessage, loading } = this.props;
    const { alertRef } = this;
    return (
      <div className="container-fluid" style={{ padding: '1rem' }}>
        {registrationSuccess && (
          <Redirect
            to={{
              pathname: '/register-successful'
            }}
          />
        )}
        <Row className="justify-content-center">
          <Col md="8">
            <h1 id="register-title">
              <Translate contentKey="register.title">Registration</Translate>
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <AvForm id="register-form" onValidSubmit={this.handleValidSubmit}>
              <AvField
                name="username"
                label={translate('global.form.username')}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate('register.messages.validate.login.required')
                  },
                  pattern: {
                    value: '^[_.@A-Za-z0-9-öäåÖÄÅ]*$',
                    errorMessage: translate('register.messages.validate.login.pattern')
                  },
                  minLength: {
                    value: APP_USERNAME_LENGTH.min,
                    errorMessage: translate('register.messages.validate.login.minlength', { length: APP_USERNAME_LENGTH.min })
                  },
                  maxLength: {
                    value: APP_USERNAME_LENGTH.max,
                    errorMessage: translate('register.messages.validate.login.maxlength', { length: APP_USERNAME_LENGTH.max })
                  }
                }}
              />
              <AvField
                name="email"
                label={translate('global.form.email')}
                type="email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate('global.messages.validate.email.required')
                  },
                  minLength: {
                    value: APP_EMAIL_LENGTH.min,
                    errorMessage: translate('global.messages.validate.email.minlength', { length: APP_EMAIL_LENGTH.min })
                  },
                  maxLength: {
                    value: APP_EMAIL_LENGTH.max,
                    errorMessage: translate('global.messages.validate.email.maxlength', { length: APP_EMAIL_LENGTH.max })
                  }
                }}
              />
              <AvField
                name="firstPassword"
                label={translate('global.form.newpassword')}
                type="password"
                onChange={this.updatePassword}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate('global.messages.validate.newpassword.required')
                  },
                  minLength: {
                    value: APP_PASSWORD_LENGTH.min,
                    errorMessage: translate('global.messages.validate.newpassword.minlength', { length: APP_PASSWORD_LENGTH.min })
                  },
                  maxLength: {
                    value: APP_PASSWORD_LENGTH.max,
                    errorMessage: translate('global.messages.validate.newpassword.maxlength', { length: APP_PASSWORD_LENGTH.max })
                  }
                }}
              />
              <PasswordStrengthBar password={this.state.password} />
              <AvField
                name="secondPassword"
                label={translate('global.form.confirmpassword')}
                type="password"
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate('global.messages.validate.confirmpassword.required')
                  },
                  minLength: {
                    value: APP_PASSWORD_LENGTH.min,
                    errorMessage: translate('global.messages.validate.confirmpassword.minlength', { length: APP_PASSWORD_LENGTH.min })
                  },
                  maxLength: {
                    value: APP_PASSWORD_LENGTH.max,
                    errorMessage: translate('global.messages.validate.confirmpassword.maxlength', { length: APP_PASSWORD_LENGTH.max })
                  },
                  match: {
                    value: 'firstPassword',
                    errorMessage: translate('global.messages.error.dontmatch')
                  }
                }}
              />
              {loading ? (
                <p>
                  <FontAwesomeIcon icon="spinner" spin /> <Translate contentKey="register.form.loading">Loading</Translate>
                </p>
              ) : (
                <Button id="register-submit" color="primary" type="submit">
                  <Translate contentKey="register.form.button">Register</Translate>
                </Button>
              )}
            </AvForm>
          </Col>
        </Row>
        {registrationFailure &&
          errorMessage && (
            <Row className="justify-content-center mt-3">
              <Col md="8">
                <div ref={alertRef} tabIndex={0}>
                  <Alert color="danger">
                    <Translate contentKey={`error.${errorMessage}`} />
                  </Alert>
                </div>
              </Col>
            </Row>
          )}
      </div>
    );
  }
}

const mapStateToProps = ({ locale, register }: IRootState) => ({
  currentLocale: locale.currentLocale,
  registrationSuccess: register.registrationSuccess,
  registrationFailure: register.registrationFailure,
  errorMessage: register.errorMessage,
  loading: register.loading
});

const mapDispatchToProps = {
  handleRegisterWithAuthorities,
  reset
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
