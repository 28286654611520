import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  ACCEPT_INVITATION: 'accept/ACCEPT_INVITATION',
  RESET: 'accept/RESET'
};

const initialState = {
  acceptSuccess: false,
  acceptFailure: false,
  message: null
};

export type AcceptState = Readonly<typeof initialState>;

// Reducer
export default (state: AcceptState = initialState, action): AcceptState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.ACCEPT_INVITATION):
      return {
        ...state
      };
    case FAILURE(ACTION_TYPES.ACCEPT_INVITATION):
      return {
        ...state,
        acceptFailure: true,
        message: action.payload && action.payload.response && action.payload.response.data ? action.payload.response.data.detail : null
      };
    case SUCCESS(ACTION_TYPES.ACCEPT_INVITATION):
      return {
        ...state,
        acceptSuccess: true,
        message: null
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

// Actions
export const acceptInvitationAction = token => ({
  type: ACTION_TYPES.ACCEPT_INVITATION,
  payload: axios.post('api/user-data/accept-invite?token=' + token)
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
