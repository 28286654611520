import * as React from 'react';
import { withStyles, createStyles, Theme, Card, CardHeader, CardContent, Typography, Box } from '@material-ui/core';
import { Classes } from 'jss';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';
import { secondsToHourMinute, secondsToHours, secondsToMinutes } from 'app/shared/util/date-time-utils';
import { ViewMode } from '../pages/TimePlanningPage';
import { convertPxToRem } from 'app/shared/util/number-utils';
import { baseFontSize } from '../theme';

interface IActivityCardProps {
  classes?: Classes;
  activity: IActivity;
  typeList: boolean;
  viewMode?: any;
  setActiveActivity?: any;
  maxWidth?: any;
  selected?: boolean;
  draggable?: boolean;
  onDragStart?: any;
  conflicting?: boolean;
  nonInteractive?: boolean;
}

const commonExtraTimeStyles = {
  background: '#fff',
  border: (props: IActivityCardProps) => (props.typeList ? '1px dashed #3B538B' : '2px dashed #3B538B'),
  padding: '10px 20px',
  color: '#000'
};

const commonWidthStyle = {
  minWidth: convertPxToRem(150, baseFontSize),
  maxWidth: (props: IActivityCardProps) => (props.maxWidth ? props.maxWidth : convertPxToRem(250, baseFontSize))
};

const calculateExtraTimeHeightPercentage = (activity, extraTime) => {
  const activiyDuration = activity.endTime - activity.startTime;
  const totalTimeInSeconds = activity.extraStartTime + activiyDuration + activity.extraEndTime;

  return (extraTime / totalTimeInSeconds) * 100 + '%';
};

const ActivityCardStyles = (theme: Theme) =>
  createStyles({
    root: {
      ...commonWidthStyle,
      overflow: 'inherit',
      background: (props: IActivityCardProps) => (props.typeList ? 'transparent' : '#E5E9F7CC'),
      border: (props: IActivityCardProps) => (props.typeList ? '1px solid #3B538B' : '2px solid #3B538B'),
      borderBottomLeftRadius: (props: IActivityCardProps) => (props.activity.extraEndTime ? 0 : null),
      borderBottomRightRadius: (props: IActivityCardProps) => (props.activity.extraEndTime ? 0 : null),
      borderTopLeftRadius: (props: IActivityCardProps) => (props.activity.extraStartTime ? 0 : null),
      borderTopRightRadius: (props: IActivityCardProps) => (props.activity.extraStartTime ? 0 : null),
      boxShadow: '2px 3px 15px #0000001A',
      height: (props: IActivityCardProps) =>
        props.activity.extraStartTime || props.activity.extraEndTime
          ? calculateExtraTimeHeightPercentage(props.activity, props.activity.endTime - props.activity.startTime)
          : '100%'
    },
    cardHeader: {
      backgroundColor: '#E3E6F1',
      padding: '10px 20px',
      borderTopLeftRadius: (props: IActivityCardProps) => (props.activity.extraStartTime ? 0 : 15),
      borderTopRightRadius: (props: IActivityCardProps) => (props.activity.extraStartTime ? 0 : 15),
      '& img': {
        height: 20,
        marginRight: 10
      }
    },
    cardHeaderContent: {
      color: '#000000'
    },
    cardTitle: {
      fontSize: '0.9rem',
      lineHeight: 1
    },
    cardContent: {
      backgroundColor: (props: IActivityCardProps) => (props.typeList && !props.selected && !props.conflicting ? '#fff' : 'transparent'),
      color: (props: IActivityCardProps) => (props.selected ? '#fff' : '#000'),
      display: 'flex',
      padding: '10px 15px !important',
      borderBottomLeftRadius: (props: IActivityCardProps) => (props.activity.imgUrl || props.activity.extraStartTime ? 0 : 15),
      borderBottomRightRadius: (props: IActivityCardProps) => (props.activity.imgUrl || props.activity.extraStartTime ? 0 : 15),
      '& img': {
        filter: (props: IActivityCardProps) => (props.selected ? 'invert(1)' : 'none'),
        height: (props: IActivityCardProps) => (props.typeList ? 50 : 'auto'),
        maxHeight: (props: IActivityCardProps) => (props.typeList ? 50 : 30),
        marginRight: '1rem'
      }
    },
    extraStart: {
      ...commonWidthStyle,
      ...commonExtraTimeStyles,
      height: (props: IActivityCardProps) => calculateExtraTimeHeightPercentage(props.activity, props.activity.extraStartTime),
      borderTopRightRadius: 15,
      borderTopLeftRadius: 15,
      borderBottom: '0 !important'
    },
    extraEnd: {
      ...commonWidthStyle,
      ...commonExtraTimeStyles,
      height: (props: IActivityCardProps) => calculateExtraTimeHeightPercentage(props.activity, props.activity.extraEndTime),
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 15,
      borderTop: '0 !important'
    },
    conflict: {
      backgroundColor: '#FED159CC !important',
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        right: -20,
        height: 40,
        width: 40,
        borderRadius: '50%',
        backgroundColor: '#FFFFFF',
        backgroundImage: 'url(/content/icons/conflict-icon.png)',
        backgroundSize: '26px 22px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom 11px;',
        boxShadow: '0px 3px 6px #00000029'
      }
    },
    selected: {
      backgroundColor: '#293A5F !important'
    },
    wrapper: {
      appearance: 'none',
      background: 'transparent',
      border: 0,
      borderRadius: 15,
      display: 'block',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '100%',
      fontWeight: 700,
      height: '100%',
      lineHeight: 1.15,
      margin: 0,
      padding: 0,
      textAlign: 'left',
      width: '100%',
      maxWidth: 270
    },
    activityImg: {
      width: '100%',
      borderBottomLeftRadius: (props: IActivityCardProps) => (props.activity.extraEndTime ? 0 : 15),
      borderBottomRightRadius: (props: IActivityCardProps) => (props.activity.extraEndTime ? 0 : 15)
    }
  });

const formatTime = (seconds: number) => {
  const hourCount = secondsToHours(seconds);
  const minuteCount = secondsToMinutes(seconds);

  const hours = Number(hourCount) > 0 && (Number(hourCount) > 1 ? ` ${hourCount} timmar` : ` ${hourCount} timme`);
  const minutes = Number(minuteCount) > 0 && minuteCount + ' minuter';

  let message;

  if (hours && minutes) {
    message = `${hours} och ${minutes}`;
  } else if (hours && !minutes) {
    message = hours;
  } else if (minutes && !hours) {
    message = minutes;
  }

  return message;
};

function ActivityCard(props: IActivityCardProps) {
  const { classes, activity, typeList, viewMode, setActiveActivity, selected, conflicting, draggable, onDragStart, nonInteractive } = props;

  const handleDragEnd = e => {
    e.preventDefault();
    document.querySelectorAll('.day-col').forEach((el: any) => (el.style.backgroundColor = null));
  };

  return (
    <button
      onClick={setActiveActivity}
      className={classes.wrapper}
      draggable={draggable}
      onDragStart={draggable ? onDragStart : null}
      onDragEnd={draggable ? handleDragEnd : null}
      role={!draggable && !nonInteractive ? 'button' : null}
      tabIndex={!!nonInteractive ? -1 : null}
      type="button"
      aria-label={`${activity.name} börjar klockan ${secondsToHourMinute(activity.startTime)} och slutar klockan ${secondsToHourMinute(activity.endTime)}`}
    >
      {activity.extraStartTime > 0 && (
        <div className={classes.extraStart}>
          {viewMode === ViewMode.HOURS && activity.extraStartTime < 1200 ? null : (
            <>
              <img src="content/icons/clock.svg" alt="" draggable={false} /> + {formatTime(activity.extraStartTime)}
            </>
          )}
        </div>
      )}
      <Card classes={{ root: classes.root }} className={[conflicting ? classes.conflict : '', selected ? classes.selected : ''].join(' ')}>
        {typeList && (
          <CardHeader
            classes={{
              root: classes.cardHeader,
              title: classes.cardTitle,
              content: classes.cardHeaderContent
            }}
            title={
              <>
                <img src="content/icons/clock.svg" alt="" draggable={false} />
                {secondsToHourMinute(activity.startTime)} - {secondsToHourMinute(activity.endTime)}
              </>
            }
          />
        )}
        <CardContent
          classes={{
            root: classes.cardContent
          }}
        >
          {typeList
            ? !activity.imgUrl && activity.icon && <img src={`content/icons/${activity.icon}.svg`} alt="" draggable={false} />
            : activity.icon && <img src={`content/icons/${activity.icon}.svg`} alt="" draggable={false} />}

          <Typography variant="inherit">{activity.name}</Typography>
        </CardContent>

        {typeList && activity.imgUrl && <img src={activity.imgUrl} className={classes.activityImg} alt="" draggable={false} />}
      </Card>
      {activity.extraEndTime > 0 && (
        <div className={classes.extraEnd}>
          {viewMode === ViewMode.HOURS && activity.extraEndTime < 1200 ? null : (
            <>
              <img src="content/icons/clock.svg" alt="" draggable={false} /> + {formatTime(activity.extraEndTime)}
            </>
          )}
        </div>
      )}
    </button>
  );
}

export default withStyles(ActivityCardStyles)(ActivityCard);
