export const enum DASH_BOARD_DROP_DOWN_ACTIONS {
  ASK_FOR_HELP = 'ASK_FOR_HELP',
  INVITE_HELPER = 'INVITE_HELPER',
  ADD_TO_HOME_SCREEN = 'ADD_TO_HOME_SCREEN',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  LOGOUT = 'LOGOUT',
  ENTITY_APPS = 'ENTITY_APPS',
  ENTITY_CONTENT = 'ENTITY_CONTENT',
  ENTITY_USERS = 'ENTITY_USERS',
  ENTITY_HELPERS = 'ENTITY_HELPERS',
  ENTITY_COMMUNITIES = 'ENTITY_COMMUNITIES',
  ENTITY_MEETINGS = 'ENTITY_MEETINGS'
}
