import { toSeconds } from 'app/shared/util/date-time-utils';

export interface IActivity {
  conflict?: boolean;
  endTime?: number;
  extraEndTime?: number;
  extraStartTime?: number;
  icon?: string;
  id?: number;
  name?: string;
  startTime?: number;
  imgUrl?: string;
}

export const emptyActivity: IActivity = {
  name: '',
  icon: '',
  startTime: 0,
  endTime: 0,
  extraEndTime: 0,
  extraStartTime: 0,
  conflict: false
};

export const defaultActivities: IActivity[] = [
  {
    name: 'Frukost',
    icon: 'croissant',
    startTime: toSeconds(7),
    endTime: toSeconds(7, 30),
    extraStartTime: 0,
    extraEndTime: 0,
    conflict: false
  },
  {
    name: 'Lunch',
    icon: 'meal',
    startTime: toSeconds(12),
    endTime: toSeconds(13),
    extraStartTime: 0,
    extraEndTime: 0,
    conflict: false
  },
  {
    name: 'Middag',
    icon: 'meal',
    startTime: toSeconds(18),
    endTime: toSeconds(19),
    extraStartTime: 0,
    extraEndTime: 0,
    conflict: false
  },
  {
    name: 'Sova',
    icon: 'sleeping',
    startTime: toSeconds(23),
    endTime: toSeconds(6, 45),
    extraStartTime: 0,
    extraEndTime: 0,
    conflict: false
  }
];

export const defaultValue: Readonly<IActivity> = {};
