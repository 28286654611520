import { IUserData } from 'app/shared/model//user-data.model';
import { Moment } from 'moment';
import { ICommunity } from 'app/shared/model/community.model';

export interface IApp {
  id?: number;
  name?: string;
  url?: string;
  description?: string;
  imgUrl?: string;
  userData?: IUserData[];
  openInNewWindow?: boolean;
  internal?: boolean;
  global?: boolean;
  auditAccepted?: boolean;
  auditedAt?: Moment;
  authorId?: number;
  authorName?: string;
  communities?: ICommunity[];
  categoryGame?: boolean;
  categoryKids?: boolean;
  categorySchool?: boolean;
  categorySchool2?: boolean;
  categoryFood?: boolean;
  categoryHealth?: boolean;
  categorySociety?: boolean;
  categoryEconomy?: boolean;
  categoryVideo?: boolean;
  categoryNews?: boolean;
}

export const defaultValue: Readonly<IApp> = {};
