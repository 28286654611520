import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { reset, callForHelpWithScreenshot } from 'app/entities/user-data/user-data.reducer';
import DigiJagConfirmationDialog from '../base-dialogs/DigiJagConfirmationDialog';
import DigiJagAlertDialog from '../base-dialogs/DigiJagAlertDialog';
import { Translate, translate } from 'react-jhipster';

interface IAskForHelpDialogProps extends StateProps, DispatchProps {
  isOpen: boolean;
  onClose: Function;
  handleInviteHelper: Function;
}

export class AskForHelpDialog extends React.Component<IAskForHelpDialogProps> {
  componentWillMount = () => {
    this.props.reset();
  };

  handleCallForHelp = () => {
    this.props.callForHelpWithScreenshot(window.location.href);
  };

  onInviteHelper = () => {
    this.props.onClose();
    setTimeout(() => this.props.handleInviteHelper());
  };

  getText = () => {
    if (!this.props.updateErrorMessage) {
      return <Translate contentKey="digijagApp.askForHelp.getText.noUpdateErrorMessage" />;
    } else {
      return <Translate contentKey="digijagApp.askForHelp.getText.inviteToHelp" />;
    }
  };

  getSuccessText = () => <Translate contentKey="digijagApp.askForHelp.getSuccessText" />;

  render() {
    const { userDataUpdating, userDataUpdateSuccess, onClose, isOpen } = this.props;

    const { handleCallForHelp, onInviteHelper, getText, getSuccessText } = this;

    return (
      <>
        {!userDataUpdateSuccess ? (
          <DigiJagConfirmationDialog
            isOpen={isOpen}
            onClose={onClose}
            icon={!this.props.userDataUpdateSuccess ? 'questionmark_big' : 'smile'}
            text={getText()}
            onConfirm={!this.props.updateErrorMessage ? handleCallForHelp : onInviteHelper}
            updating={userDataUpdating}
          />
        ) : (
          <DigiJagAlertDialog
            isOpen={isOpen}
            icon="smile"
            text={getSuccessText()}
            buttonText={translate('entity.action.ok')}
            onClose={onClose}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ userData }: IRootState) => ({
  userDataUpdating: userData.updating,
  userDataUpdateSuccess: userData.updateSuccess,
  updateErrorMessage: userData.errorMessage
});

const mapDispatchToProps = {
  reset,
  callForHelpWithScreenshot
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AskForHelpDialog);
