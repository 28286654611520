import { getAmountDistribution } from 'app/shared/util/amount-distribution';
import { IExpense } from './expense.model';
import { INotesDistribution, notes } from './note.model';

export interface IBudget {
  expenses?: IExpense[];
  id?: number;
  incomeAmount?: number;
  name?: string;
  ownerId?: number;
}

export const sampleBudgets: IBudget[] = [
  {
    id: null,
    incomeAmount: 10000,
    name: 'Bor hemma',
    ownerId: null,
    expenses: [
      {
        name: 'Hyra',
        icon: 'home',
        amount: 2300,
        notesDistribution: getAmountDistribution(2300, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Mat',
        icon: 'utensils',
        amount: 650,
        notesDistribution: getAmountDistribution(650, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Kläder och skor',
        icon: 'tshirt',
        amount: 690,
        notesDistribution: getAmountDistribution(690, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Fritid',
        icon: 'umbrella-beach',
        amount: 500,
        notesDistribution: getAmountDistribution(500, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'TV, mobil, internet',
        icon: 'file-invoice-dollar',
        amount: 0,
        notesDistribution: getAmountDistribution(0, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'El, vatten, värme',
        icon: 'file-invoice-dollar',
        amount: 0,
        notesDistribution: getAmountDistribution(0, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Försäkringar',
        icon: 'shield-alt',
        amount: 0,
        notesDistribution: getAmountDistribution(0, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Buss, tåg, bil',
        icon: 'bus-alt',
        amount: 550,
        notesDistribution: getAmountDistribution(550, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Hygien',
        icon: 'soap',
        amount: 300,
        notesDistribution: getAmountDistribution(300, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      }
    ]
  },
  {
    id: null,
    incomeAmount: 14000,
    name: 'Flyttat hemifrån',
    ownerId: null,
    expenses: [
      {
        name: 'Hyra',
        icon: 'home',
        amount: 4270,
        notesDistribution: getAmountDistribution(4270, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Mat',
        icon: 'utensils',
        amount: 3000,
        notesDistribution: getAmountDistribution(3000, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Kläder och skor',
        icon: 'tshirt',
        amount: 690,
        notesDistribution: getAmountDistribution(690, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Fritid',
        icon: 'umbrella-beach',
        amount: 720,
        notesDistribution: getAmountDistribution(720, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'TV, mobil, internet',
        icon: 'file-invoice-dollar',
        amount: 1760,
        notesDistribution: getAmountDistribution(1760, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'El, vatten, värme',
        icon: 'file-invoice-dollar',
        amount: 750,
        notesDistribution: getAmountDistribution(750, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Försäkringar',
        icon: 'shield-alt',
        amount: 150,
        notesDistribution: getAmountDistribution(150, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Buss, tåg, bil',
        icon: 'bus-alt',
        amount: 700,
        notesDistribution: getAmountDistribution(700, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Hygien',
        icon: 'soap',
        amount: 840,
        notesDistribution: getAmountDistribution(840, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      },
      {
        name: 'Övrigt',
        icon: 'dollar-sign',
        amount: 1040,
        notesDistribution: getAmountDistribution(1040, notes.map(variant => variant.value), 'less') as INotesDistribution,
        scenarioId: null,
        id: null
      }
    ]
  }
];

export const defaultValue: Readonly<IBudget> = {};
