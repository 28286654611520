import React, { Component } from 'react';
import { Classes } from 'jss';
import { Theme, createStyles, DialogContent, Typography, withStyles, Grid, DialogActions, Paper } from '@material-ui/core';
import { IZoomMeeting } from 'app/shared/model/zoom-meeting.model';
import DigiJagBaseDialog from '../base-dialogs/DigiJagBaseDialog';
import { Translate } from 'react-jhipster';
import { IUserData } from 'app/shared/model/user-data.model';
import AcceptButton from 'app/custom-components/form-inputs/AcceptButton';
import { text } from '@fortawesome/fontawesome-svg-core';
import DeclineButton from 'app/custom-components/form-inputs/DeclineButton';

const ZoomConfirmMeetingDialogStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogContent: {
      flex: 'none',
      padding: '0 5rem 2rem',
      [breakpoints.down('xs')]: {
        padding: '0 2rem 1rem'
      }
    },
    dialogTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      letterSpacing: '1.23px',
      marginBottom: '2.5rem'
    },
    dialogText: {
      fontSize: 18,
      color: '#000000',
      marginTop: '4rem',
      marginBottom: '1rem',
      textAlign: 'center',
      fontWeight: 600,
      lineHeight: 1.8,
      [breakpoints.down('xs')]: {
        fontSize: 16,
        lineHeight: 1.5
      }
    },
    imageContainer: {
      textAlign: 'center',
      margin: 'auto'
    },
    zoomIcon: {
      maxWidth: 160,
      maxHeight: 160,
      [breakpoints.down('xs')]: {
        maxHeight: 100,
        maxWidth: 100
      }
    },
    userImagePaper: {
      textAlign: 'center',
      margin: 'auto',
      borderRadius: 10,
      maxWidth: 160,
      maxHeight: 160,
      [breakpoints.down('xs')]: {
        maxHeight: 100,
        maxWidth: 100
      },
      '& img': {
        width: '100%',
        height: '100%'
      }
    },
    userImage: {
      borderRadius: 10
    },
    buttonContainer: {
      margin: 0,
      paddingBottom: '1.5rem'
    },
    buttonGrid: {
      [breakpoints.down('xs')]: {
        justifyContent: 'space-evenly'
      }
    },
    acceptButton: {
      marginRight: '1.5rem'
    }
  });

interface IZoomConfirmMeetingDialogProps {
  classes: Classes;
  onConfirm: Function;
  onClose: Function;
  isOpen: boolean;
  meeting?: IZoomMeeting;
  host?: boolean;
  otherUser?: IUserData;
}

export class ZoomConfirmMeetingDialog extends Component<IZoomConfirmMeetingDialogProps> {
  render() {
    const { classes, meeting, onConfirm, onClose, isOpen, host = false, otherUser } = this.props;

    return (
      <DigiJagBaseDialog open={isOpen} onClose={onClose} withCloseButton aria-labelledby="dialog-description">
        <DialogContent className={classes.dialogContent}>
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="h2" align="center" className={classes.dialogTitle}>
              <Translate contentKey={host ? 'digijagApp.zoomMeeting.startMeetingTitle' : 'digijagApp.zoomMeeting.joinMeetingTitle'} />
            </Typography>
            <Grid container direction="row" justify="center">
              {host ? (
                otherUser && (
                  <Grid item xs={6} sm={6}>
                    <Paper elevation={2} className={classes.userImagePaper}>
                      <img src={otherUser.userImageUrl || '/content/images/default_profile_img.png'} alt="" className={classes.userImage} />
                    </Paper>
                  </Grid>
                )
              ) : (
                <Grid item xs={6} sm={6}>
                  <Paper elevation={2} className={classes.userImagePaper}>
                    <img
                      src={(meeting && meeting.hostImgUrl) || '/content/images/default_profile_img.png'}
                      alt=""
                      className={classes.userImage}
                    />
                  </Paper>
                </Grid>
              )}
              <Grid item xs={6} sm={6} className={classes.imageContainer}>
                <img src="content/icons/zoom-logo-large.svg" alt="" className={classes.zoomIcon} />
              </Grid>
            </Grid>
            <Typography id="dialog-description" variant="body1" color="inherit" className={classes.dialogText}>
              {host ? (
                otherUser ? (
                  <Translate contentKey="digijagApp.zoomMeeting.startMeetingWith" interpolate={{ username: otherUser.userFirstName }} />
                ) : (
                  <Translate contentKey="digijagApp.zoomMeeting.startMeetingConfirmation" />
                )
              ) : (
                <Translate contentKey="digijagApp.zoomMeeting.joinMeetingWith" interpolate={{ username: meeting && meeting.hostName }} />
              )}
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <Grid container direction="row" justify="center" alignItems="center" className={classes.buttonGrid}>
            <Grid item className={classes.acceptButton}>
              <AcceptButton onClick={() => onConfirm(true)}>
                {host ? (
                  <Translate contentKey="digijagApp.zoomMeeting.startMeeting">Ja, Starta mötet</Translate>
                ) : (
                  <Translate contentKey="digijagApp.zoomMeeting.joinMeeting">Ja, gå med i mötet</Translate>
                )}
              </AcceptButton>
            </Grid>
            <Grid item>
              <DeclineButton onClick={onClose}>
                {host ? (
                  <Translate contentKey="entity.action.no">Nej</Translate>
                ) : (
                  <Translate contentKey="digijagApp.zoomMeeting.declineMeeting">Nej, inte nu</Translate>
                )}
              </DeclineButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DigiJagBaseDialog>
    );
  }
}

export default withStyles(ZoomConfirmMeetingDialogStyles)(ZoomConfirmMeetingDialog);
