import axios from 'axios';
import { parseHeaderForLinks, loadMoreDataWhenScrolled, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IApp, defaultValue } from 'app/shared/model/app.model';
import { filterCategoryList, filterOnCategory, NO_CATEGORY } from 'app/shared/model/category';

export const ACTION_TYPES = {
  FETCH_APP_LIST: 'app/FETCH_APP_LIST',
  FETCH_APP_LIST_BY_AUTHOR: 'app/FETCH_APP_LIST_BY_AUTHOR',
  FETCH_APP_LIST_ADMIN: 'app/FETCH_APP_LIST_ADMIN',
  FETCH_APP: 'app/FETCH_APP',
  FILTER_APPS: 'app/FILTER_APPS',
  CREATE_APP: 'app/CREATE_APP',
  UPDATE_APP: 'app/UPDATE_APP',
  DELETE_APP: 'app/DELETE_APP',
  RESET: 'app/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IApp>,
  filterCategory: '',
  filteredEntities: [] as ReadonlyArray<IApp>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  categoriesWithApps: []
};

export type AppState = Readonly<typeof initialState>;

// Reducer

export default (state: AppState = initialState, action): AppState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_APP_LIST):
    case REQUEST(ACTION_TYPES.FETCH_APP_LIST_BY_AUTHOR):
    case REQUEST(ACTION_TYPES.FETCH_APP_LIST_ADMIN):
    case REQUEST(ACTION_TYPES.FETCH_APP):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_APP):
    case REQUEST(ACTION_TYPES.UPDATE_APP):
    case REQUEST(ACTION_TYPES.DELETE_APP):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_APP_LIST):
    case FAILURE(ACTION_TYPES.FETCH_APP_LIST_BY_AUTHOR):
    case FAILURE(ACTION_TYPES.FETCH_APP_LIST_ADMIN):
    case FAILURE(ACTION_TYPES.FETCH_APP):
    case FAILURE(ACTION_TYPES.CREATE_APP):
    case FAILURE(ACTION_TYPES.UPDATE_APP):
    case FAILURE(ACTION_TYPES.DELETE_APP):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_APP_LIST_ADMIN):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_APP_LIST):
    case SUCCESS(ACTION_TYPES.FETCH_APP_LIST_BY_AUTHOR):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        filteredEntities: state.filterCategory ? action.payload.data.filter(app => app[state.filterCategory]) : action.payload.data,
        categoriesWithApps: filterCategoryList(action.payload.data)
      };
    case SUCCESS(ACTION_TYPES.FETCH_APP):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_APP):
    case SUCCESS(ACTION_TYPES.UPDATE_APP):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_APP):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.FILTER_APPS:
      return {
        ...state,
        filterCategory: action.payload,
        filteredEntities: state.entities.filter(filterOnCategory(action.payload))
      };
    default:
      return state;
  }
};

const apiUrl = 'api/apps';

// Actions

export const getEntities: ICrudGetAllAction<IApp> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_APP_LIST_ADMIN,
    payload: axios.get<IApp>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesByCommunity: ICrudGetAllAction<IApp> = communityId => {
  const requestUrl = `${apiUrl}/selectable/${communityId}`;
  return {
    type: ACTION_TYPES.FETCH_APP_LIST,
    payload: axios.get<IApp>(requestUrl)
  };
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}?page=0&size=10000`;
  return {
    type: ACTION_TYPES.FETCH_APP_LIST,
    payload: axios.get<IApp>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntitiesByCurrentAuthor = () => {
  const requestUrl = `${apiUrl}/authoredbycurrent?page=0&size=10000&sort=name,asc`;
  return {
    type: ACTION_TYPES.FETCH_APP_LIST_BY_AUTHOR,
    payload: axios.get<IApp>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const filterEntities = category => ({
  type: ACTION_TYPES.FILTER_APPS,
  payload: category
});

export const getEntity: ICrudGetAction<IApp> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_APP,
    payload: axios.get<IApp>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IApp> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_APP,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IApp> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_APP,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IApp> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_APP,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
