import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Divider
} from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IWeek } from 'app/shared/model/fixatiden/week.model';
import { convertPxToRem } from 'app/shared/util/number-utils';
import { baseFontSize } from '../theme';
import EditTimePlanNameDialog from './dialogs/EditTimePlanNameDialog';
import DeleteTimePlanDialog from './dialogs/DeleteTimePlanDialog';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const SideBarTimePlansStyles = (theme: Theme) =>
  createStyles({
    menuTitle: {
      backgroundColor: theme.palette.secondary.main,
      paddingTop: `${convertPxToRem(8, baseFontSize)} !important`,
      paddingBottom: `${convertPxToRem(8, baseFontSize)} !important`
    },
    menuTitleIcon: {
      color: '#ffffff'
    },
    menuTitleText: {
      color: '#ffffff'
    },
    planItem: {
      flexWrap: 'wrap'
    },
    daysSublist: {
      flexBasis: '100%',
      '& > *': {
        paddingLeft: '3rem !important'
      }
    },
    sunIcon: {
      marginLeft: -7,
      marginTop: -7
    },
    planType: {
      background: theme.palette.secondary.main,
      padding: '1rem 1.5rem !important',
      '& *': {
        color: '#ffffff'
      }
    }
  });

interface ISideBarTimePlansProps {
  classes: Classes;
  timePlans: Array<IDay | IWeek>;
  current: IDay | IWeek;
  currentDay?: IDay;
  onClickTimePlan: any;
  onClickDayOfWeek: any;
  onClickCreateTimePlan: any;
  onEditTimePlanName: any;
  onDeleteTimePlan: any;
}

interface ISideBarTimePlansState {
  planAnchorEl: any;
  openEditTimePlanNameDialog: boolean;
  timePlanName: string;
  openDeleteTimePlanDialog: boolean;
  dayPlans: IDay[];
  weekPlans: IWeek[];
}

function SideBarTimePlans(props: React.PropsWithChildren<ISideBarTimePlansProps>) {
  const { classes, timePlans, current, currentDay, onClickTimePlan, onClickDayOfWeek, onClickCreateTimePlan, onEditTimePlanName, onDeleteTimePlan } = props;

  const [planAnchorEl, setPlanAnchorEl] = useState<ISideBarTimePlansState['planAnchorEl']>(null);
  const [openEditTimePlanNameDialog, setOpenEditTimePlanNameDialog] = useState<ISideBarTimePlansState['openEditTimePlanNameDialog']>(false);
  const [timePlanName, setTimePlanName] = useState<ISideBarTimePlansState['timePlanName']>('');
  const [openDeleteTimePlanDialog, setOpenDeleteTimePlanDialog] = useState<ISideBarTimePlansState['openDeleteTimePlanDialog']>(false);
  const [dayPlans, setDayPlans] = useState<ISideBarTimePlansState['dayPlans']>([]);
  const [weekPlans, setWeekPlans] = useState<ISideBarTimePlansState['weekPlans']>([]);

  useEffect(
    () => {
      setDayPlans(timePlans.filter(isDay));
      setWeekPlans(
        timePlans.filter(isWeek).map((week: IWeek) => {
          week.days.sort((a, b) => a.order - b.order);
          return week;
        })
      );
    },
    [timePlans]
  );

  const handleMenuClick = event => {
    setPlanAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setPlanAnchorEl(null);
  };

  const handleClickTimePlan = (plan: IDay | IWeek) => e => {
    e.preventDefault();
    onClickTimePlan(plan);
  };

  const handleClickDayOfWeek = (day: IDay) => e => {
    e.preventDefault();
    onClickDayOfWeek(day);
  };

  const handleChangeName = (value: string) => {
    setTimePlanName(value);
  };

  const handleEditTimePlanName = e => {
    e.preventDefault();
    onEditTimePlanName(timePlanName);
    setTimePlanName('');
    setOpenEditTimePlanNameDialog(false);
  };

  const handleDeleteTimePlan = e => {
    e.preventDefault();
    onDeleteTimePlan();
    setOpenDeleteTimePlanDialog(false);
  };

  const onClickEditName = () => {
    handleCloseMenu();
    setTimePlanName(current.name);
    setOpenEditTimePlanNameDialog(true);
  };

  const onClickDelete = () => {
    handleCloseMenu();
    setOpenDeleteTimePlanDialog(true);
  };

  const isDay = (plan: IDay | IWeek): boolean => 'activities' in plan;
  const isWeek = (plan: IDay | IWeek): boolean => !isDay(plan);
  const isSelected = (plan: IDay | IWeek): boolean => current.id === plan.id;

  const getTimePlanMenu = (plan: IDay | IWeek) => (
    <>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label={`Redigera ${plan.name}`}
          color="secondary"
          aria-controls={`time-plan-${plan.id}-menu`}
          aria-haspopup="true"
          onClick={handleMenuClick}
          disableFocusRipple
        >
          <FontAwesomeIcon icon="pen-square" />
        </IconButton>
      </ListItemSecondaryAction>
      <Menu
        id={`time-plan-${plan.id}-menu`}
        anchorEl={planAnchorEl}
        keepMounted
        open={Boolean(planAnchorEl)}
        onClose={handleCloseMenu}
        variant="menu"
        disableAutoFocusItem
      >
        <ListItem className={classes.menuTitle}>
          <ListItemIcon className={classes.menuTitleIcon}>
            {isDay(plan) ? (
              <FontAwesomeIcon icon={'day-sun' as IconProp} className={classes.sunIcon} />
            ) : (
              <FontAwesomeIcon icon={'week-sun' as IconProp} className={classes.sunIcon} />
            )}
          </ListItemIcon>
          <ListItemText primary={plan.name} className={classes.menuTitleText} />
        </ListItem>
        <MenuItem onClick={onClickEditName}>
          <Typography variant="inherit">
            <FontAwesomeIcon icon="pen-square" /> Ändra namn
          </Typography>
        </MenuItem>
        <MenuItem onClick={onClickDelete}>
          <Typography variant="inherit">
            <FontAwesomeIcon icon="trash-alt" /> Ta bort {isDay(plan) ? 'dagen' : 'veckan'}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <Typography variant="inherit">
            <FontAwesomeIcon icon="times-circle" /> Avbryt
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <>
      <List>
        <ListItem button onClick={onClickCreateTimePlan}>
          <ListItemIcon>
            <FontAwesomeIcon icon="plus-circle" />
          </ListItemIcon>
          <ListItemText>Nytt schema</ListItemText>
        </ListItem>

        <Divider />
        <ListItem className={classes.planType}>
          <ListItemIcon>
            <FontAwesomeIcon icon={'day-sun' as IconProp} className={classes.sunIcon} />
          </ListItemIcon>
          <ListItemText>Dagar</ListItemText>
        </ListItem>

        {dayPlans.map((plan, idx) => (
          <React.Fragment key={`time-plan-${plan.id}`}>
            <Divider />
            <ListItem button={!isSelected(plan) ? true : undefined} selected={isSelected(plan)} onClick={handleClickTimePlan(plan)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={'day-sun' as IconProp} className={classes.sunIcon} />
              </ListItemIcon>
              <ListItemText>{plan.name}</ListItemText>
              {isSelected(plan) && getTimePlanMenu(plan)}
            </ListItem>
          </React.Fragment>
        ))}

        <Divider />
        <ListItem className={classes.planType}>
          <ListItemIcon>
            <FontAwesomeIcon icon={'week-sun' as IconProp} className={classes.sunIcon} />
          </ListItemIcon>
          <ListItemText>Veckor</ListItemText>
        </ListItem>

        {weekPlans.map((plan, idx) => (
          <React.Fragment key={`time-plan-${plan.id}`}>
            <Divider />
            <ListItem
              button={!isSelected(plan) || currentDay !== null ? true : undefined}
              selected={isSelected(plan) && currentDay === null}
              onClick={handleClickTimePlan(plan)}
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={'week-sun' as IconProp} className={classes.sunIcon} />
              </ListItemIcon>
              <ListItemText>{plan.name}</ListItemText>
              {isSelected(plan) && currentDay === null && getTimePlanMenu(plan)}
            </ListItem>

            {isSelected(plan) && (
              <ListItem disableGutters style={{ padding: 0 }}>
                <List component="div" disablePadding className={classes.daysSublist}>
                  {(plan as IWeek).days.map(dayOfWeek => (
                    <ListItem
                      button
                      onClick={handleClickDayOfWeek(dayOfWeek)}
                      selected={currentDay && currentDay.id === dayOfWeek.id}
                      key={`plan-${plan.id}-day-${dayOfWeek.id}`}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon icon={'day-sun' as IconProp} className={classes.sunIcon} style={{ color: dayOfWeek.color }} />
                      </ListItemIcon>
                      <ListItemText primary={dayOfWeek.name} />
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
      <EditTimePlanNameDialog
        open={openEditTimePlanNameDialog}
        name={timePlanName}
        onClose={() => setOpenEditTimePlanNameDialog(false)}
        onChange={handleChangeName}
        onSubmit={handleEditTimePlanName}
      />
      <DeleteTimePlanDialog open={openDeleteTimePlanDialog} plan={current} onClose={() => setOpenDeleteTimePlanDialog(false)} onDelete={handleDeleteTimePlan} />
    </>
  );
}

export default withStyles(SideBarTimePlansStyles)(SideBarTimePlans);
