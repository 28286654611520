import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import locale, { LocaleState } from './locale';
import location, { LocationState } from './location';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';
import fileUpload, { FileUploadState } from './file-upload';
import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import accept, { AcceptState } from 'app/modules/account/accept-invitation/accept.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import userData, { UserDataState } from 'app/entities/user-data/user-data.reducer';
import widget, { WidgetState } from 'app/entities/widget/widget.reducer';
import app, { AppState } from 'app/entities/app/app.reducer';
import connection, { ConnectionState } from 'app/entities/connection/connection.reducer';
import community, { CommunityState } from 'app/entities/community/community.reducer';
import address, { AddressState } from 'app/entities/address/address.reducer';
import invitation, { InvitationState } from 'app/entities/invitation/invitation.reducer';
import content, { contentState } from 'app/entities/content/content.reducer';
import zoom, { ZoomState } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import organization, { OrganizationState } from 'app/entities/organization/organization.reducer';
import week, { WeekState } from 'app/entities/fixatiden/week/week.reducer';
import day, { DayState } from 'app/entities/fixatiden/day/day.reducer';
import activity, { ActivityState } from 'app/entities/fixatiden/activity/activity.reducer';
import budget, { BudgetState } from 'app/entities/budgettavlan/budget/budget.reducer';
import expense, { ExpenseState } from 'app/entities/budgettavlan/expense/expense.reducer';

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly location: LocationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly accept: AcceptState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly userData: UserDataState;
  readonly widget: WidgetState;
  readonly app: AppState;
  readonly connection: ConnectionState;
  readonly community: CommunityState;
  readonly address: AddressState;
  readonly invitation: InvitationState;
  readonly content: contentState;
  readonly loadingBar: any;
  readonly fileUpload: FileUploadState;
  readonly zoom: ZoomState;
  readonly organization: OrganizationState;
  readonly week: WeekState;
  readonly day: DayState;
  readonly activity: ActivityState;
  readonly budget: BudgetState;
  readonly expense: ExpenseState;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  location,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  accept,
  passwordReset,
  password,
  settings,
  userData,
  widget,
  app,
  connection,
  community,
  address,
  invitation,
  content,
  loadingBar,
  fileUpload,
  zoom,
  week,
  day,
  activity,
  budget,
  expense,
  organization
});

export default rootReducer;
