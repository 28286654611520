import React from 'react';
import { withStyles, createStyles, Theme, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { Classes } from 'jss';
import TimeCard from './TimeCard';
import SrOnly from 'app/custom-components/sronly/SrOnly';

const ActivityFormStep3Styles = (theme: Theme) =>
  createStyles({
    checkbox: {
      color: '#FFFFFF'
    },
    bigText: {
      fontSize: '1.2rem',
      textTransform: 'uppercase'
    }
  });

interface IActivityFormStep3Props {
  classes: Classes;
  noExtraTime: boolean;
  onChangeNoExtraTime: any;
  extraStartTime: number;
  onChangeextraStartTime: any;
  extraEndTime: number;
  onChangeextraEndTime: any;
}

function ActivityFormStep3(props: React.PropsWithChildren<IActivityFormStep3Props>) {
  const { classes, noExtraTime, onChangeNoExtraTime, extraStartTime, onChangeextraStartTime, extraEndTime, onChangeextraEndTime } = props;

  return (
    <>
      <SrOnly>Steg 4 av 5</SrOnly>

      <FormControlLabel
        control={<Checkbox className={classes.checkbox} color="default" checked={noExtraTime} onChange={onChangeNoExtraTime} />}
        label="Jag vill inte ha mellantider"
      />

      <TimeCard
        time={extraStartTime}
        onChangeTime={onChangeextraStartTime}
        label={
          <>
            Hur mycket tid behöver du <strong className={classes.bigText}>innan</strong> aktiviteten?
          </>
        }
        type="Mellantid före"
        direction="column"
        disabled={noExtraTime}
      />

      <TimeCard
        time={extraEndTime}
        onChangeTime={onChangeextraEndTime}
        label={
          <>
            Hur mycket tid behöver du <strong className={classes.bigText}>efter</strong> aktiviteten?
          </>
        }
        type="Mellantid efter"
        direction="column"
        disabled={noExtraTime}
      />
    </>
  );
}

export default withStyles(ActivityFormStep3Styles)(ActivityFormStep3);
