import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';

export interface IStyleProps {
  noGap?: boolean;
  gridTemplate?: string;
}

const ActivitiesListContainerStyles = (theme: Theme) =>
  createStyles({
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: (props: IStyleProps) => props.gridTemplate || 'none',
      gridAutoFlow: 'column',
      // gridAutoColumns: 'minmax(0, 1fr)',
      minWidth: '100%',
      gridGap: (props: IStyleProps) => (props.noGap ? 0 : '1.5rem'),
      position: 'relative',
      '& ul': {
        listStyle: 'none',
        padding: 0
      }
    }
  });

interface IActivitiesListContainerProps {
  classes: Classes;
  noGap?: boolean;
  gridTemplate?: string;
  children?: any;
}

function ActivitiesListContainer(props: IActivitiesListContainerProps) {
  const { children, classes } = props;

  return <div className={classes.gridContainer}>{children}</div>;
}

export default withStyles(ActivitiesListContainerStyles)(ActivitiesListContainer);
