import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getMeeting, createMeeting, updateMeeting, reset } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { getEntities as getUserData } from 'app/entities/user-data/user-data.reducer';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IZoomMeetingUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IZoomMeetingUpdateState {
  isNew: boolean;
  guestsValue: number[];
}

export class ZoomMeetingUpdate extends React.Component<IZoomMeetingUpdateProps, IZoomMeetingUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      guestsValue: []
    };
  }

  componentDidMount() {
    if (!this.state.isNew) {
      this.props.getMeeting(this.props.match.params.id);
    }
    this.props.getUserData(0, 10000, 'asc');
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.isNew &&
      prevProps.loading !== this.props.loading &&
      !this.props.loading &&
      this.props.zoomMeetingEntity &&
      this.props.zoomMeetingEntity.guests
    ) {
      this.setState({
        guestsValue: this.props.zoomMeetingEntity.guests.map(guest => guest.id)
      });
    }
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { zoomMeetingEntity } = this.props;
      const entity = {
        ...zoomMeetingEntity,
        ...values,
        guests: mapIdList(values.guests)
      };

      if (this.state.isNew) {
        this.props.createMeeting(entity);
      } else {
        this.props.updateMeeting(entity);
      }
      this.handleClose();
    }
  };

  handleClose = () => {
    this.props.history.push('/entity/zoom');
  };

  render() {
    const { zoomMeetingEntity, availableUsers, loading, usersLoading } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="digijagApp.zoomMeeting.home.createOrEditLabel">
              <Translate contentKey="digijagApp.zoomMeeting.home.createOrEditLabel">Create or edit a ZoomMeeting</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <p id="digijagApp.zoomMeeting.home.createOrEditDescription">
              <Translate contentKey="digijagApp.zoomMeeting.home.createOrEditDescription">Meetings are...</Translate>
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading || usersLoading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : zoomMeetingEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="zoom-meeting-id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </Label>
                    <AvInput id="zoom-meeting-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="nameLabel" for="zoom-meeting-topic">
                    <Translate contentKey="digijagApp.zoomMeeting.topic">topic</Translate>
                  </Label>
                  <AvField
                    id="zoom-meeting-topic"
                    type="text"
                    name="topic"
                    maxLength="12"
                    helpMessage={translate('digijagApp.zoomMeeting.topicHelp')}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="durationLabel" for="zoom-meeting-duration">
                    <Translate contentKey="digijagApp.zoomMeeting.duration">duration</Translate>
                  </Label>
                  <AvField
                    id="zoom-meeting-duration"
                    type="number"
                    name="duration"
                    helpMessage={translate('digijagApp.zoomMeeting.durationHelp')}
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="zoom-meeting-guests">
                    <Translate contentKey="digijagApp.zoomMeeting.guests">Guests</Translate>
                  </Label>
                  <AvInput
                    id="zoom-meeting-guests"
                    type="select"
                    multiple
                    className="form-control"
                    name="guests"
                    style={{ minHeight: 200 }}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate('global.messages.validate.guests.required')
                      }
                    }}
                    value={this.state.guestsValue}
                  >
                    {availableUsers
                      ? availableUsers.map(userData => (
                          <option value={userData.id} key={userData.id}>
                            {userData.userLogin}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/entity/zoom" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit">
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  zoomMeetingEntity: storeState.zoom.zoomMeeting,
  loading: storeState.zoom.loading,
  availableUsers: storeState.userData.entities,
  usersLoading: storeState.userData.loading
});

const mapDispatchToProps = {
  getMeeting,
  updateMeeting,
  createMeeting,
  reset,
  getUserData
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomMeetingUpdate);
