import React, { useEffect, useState } from 'react';
import { LinearProgress } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Classes } from 'jss';
import { IRootState } from 'app/shared/reducers';
import { getEntity, reset as resetFn } from 'app/entities/app/app.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import nativeApps from 'app/custom-components/native-app';
import AppItemContainer from './AppItemContainer';
import { resetLoading } from 'react-redux-loading-bar';

const AppItemViewStyles = () =>
  createStyles({
    linearProgress: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      color: '#9b2f50'
    }
  });

interface IAppItemViewProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  classes: Classes;
}

interface IAppItemViewState {
  loading: boolean;
}

export function AppItemView({ classes, activeApp, getActiveApp, reset, match }: IAppItemViewProps) {
  const [loading, setLoading] = useState<IAppItemViewState['loading']>(true);

  const getActiveAppUrl = () => (activeApp && activeApp.url ? (activeApp.url.startsWith('http') ? activeApp.url : 'https://' + activeApp.url) : '/');

  const hideSpinner = () => {
    setLoading(false);
  };

  useEffect(() => {
    getActiveApp(match.params.id);
    return () => {
      reset();
    };
  }, []);

  let NativeAppComponent = null;
  if (activeApp && activeApp.url && activeApp.internal && nativeApps.hasOwnProperty(activeApp.url)) {
    NativeAppComponent = nativeApps[activeApp.url];
  }

  return (
    <React.Fragment>
      {activeApp &&
        activeApp.id && (
          <AppItemContainer appName={activeApp.name}>
            {NativeAppComponent ? (
              <NativeAppComponent />
            ) : (
              <>
                {loading ? <LinearProgress className={classes.linearProgress} color="primary" /> : null}
                <iframe src={getActiveAppUrl()} onLoad={hideSpinner} title={activeApp.name} />
              </>
            )}
          </AppItemContainer>
        )}
    </React.Fragment>
  );
}

const mapStateToProps = ({ app }: IRootState) => ({
  activeApp: app.entity
});

const mapDispatchToProps = {
  getActiveApp: getEntity,
  reset: resetFn
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AppItemViewStyles)(AppItemView) as any);
