import React, { Component } from 'react';
import { Classes } from 'jss';
import {
  Checkbox,
  createStyles,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Theme,
  Typography,
  withStyles
} from '@material-ui/core';
import DigiJagBaseDialog from '../base-dialogs/DigiJagBaseDialog';
import VoiceInput from '../../form-inputs/VoiceInput';
import CustomButton from '../../form-inputs/CustomButton';
import ImageInput from '../../form-inputs/imageInput';
import { IApp } from 'app/shared/model/app.model';
import { Translate, translate } from 'react-jhipster';
import {
  GAME_CATEGORY,
  KIDS_CATEGORY,
  SCHOOL_CATEGORY,
  FOOD_CATEGORY,
  ECONOMY_CATEGORY,
  NEWS_CATEGORY,
  VIDEO_CATEGORY,
  HEALTH_CATEGORY,
  SCHOOL_2_CATEGORY,
  SOCIETY_CATEGORY
} from 'app/shared/model/category';

const AppFormDialogStyles = (theme: Theme) =>
  createStyles({
    dialogContent: {
      padding: '0 6.5rem'
    },

    dialogTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      letterSpacing: '1.23px',
      marginBottom: '2rem'
    },

    categoryContainer: {
      display: 'flex'
    },

    formControl: {
      margin: 10
    },

    marginTop: {
      marginTop: 35,
      marginBottom: '0px!important'
    },

    checkLabel: {
      display: 'flex!important',
      marginBottom: '0px!important'
    },

    addForm: {
      padding: '2rem 4.5rem',
      '& label': {
        fontSize: '1.125rem',
        fontWeight: 500,
        display: 'block',
        marginBottom: '1rem'
      }
    }
  });

interface IAppFormDialogProps {
  classes: Classes;
  dialogOpen: boolean;
  handleCloseDialog: any;
  handleChange: any;
  onSaveApp: any;
  dialogTitle: string;
  app?: IApp;
  btnText: string;
  btnIcon: string;
}

interface IAppFormDialogState {
  validUrl: boolean;
}

export class AppFormDialog extends Component<IAppFormDialogProps> {
  readonly state = {
    validUrl: false
  };

  handleChange = attrName => value => {
    if (attrName === 'url') {
      this.setState({ validUrl: value.toLowerCase().startsWith('https://') });
    }
    this.props.handleChange(attrName, value);
  };

  handleCheckboxChange = attrName => event => {
    this.props.handleChange(attrName, event.target.checked);
  };

  render() {
    const { classes, dialogOpen, handleCloseDialog, onSaveApp, btnText, btnIcon, dialogTitle, app } = this.props;
    const { validUrl } = this.state;
    return (
      <>
        <DigiJagBaseDialog open={dialogOpen} onClose={handleCloseDialog} withCloseButton>
          <DialogContent className={classes.dialogContent}>
            <Typography id="dialog-description" variant="h2" align="center" className={classes.dialogTitle}>
              <img src="content/icons/information.svg" alt="" /> {dialogTitle}
            </Typography>

            <Typography variant="body1" align="center">
              <Translate contentKey="digijagApp.app.create.form.information">
                Välj ett namn, url och en beskrivning till din nya app
              </Translate>
            </Typography>

            <form className={classes.addForm} onSubmit={onSaveApp} autoComplete="off">
              <label>
                <Translate contentKey="digijagApp.app.create.form.name">Namn (Obligatorisk)</Translate>
                <VoiceInput value={app.name} handleChange={this.handleChange('name')} required autoFocus />
              </label>

              <label>
                <Translate contentKey="digijagApp.app.create.form.urlLabel">Url (Obligatorisk)</Translate>
                {!this.props.app.openInNewWindow && (
                  <Typography variant="body1">
                    <Translate contentKey="digijagApp.app.create.form.urlStart">
                      Måste börja med <strong>{'https://'}</strong>
                    </Translate>
                  </Typography>
                )}
                <VoiceInput value={app.url} handleChange={this.handleChange('url')} required valid={validUrl} />
              </label>

              <label>
                <Translate contentKey="digijagApp.app.create.form.description">Beskrivning</Translate>
                <VoiceInput type="textarea" extra={{ rows: 4 }} value={app.description} handleChange={this.handleChange('description')} />
              </label>

              <label className={classes.marginTop}>
                <Translate contentKey="digijagApp.app.openInNewWindow">Öppna i nytt fönster</Translate>
              </label>
              <div className={classes.categoryContainer}>
                <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={app.openInNewWindow} color="primary" onChange={this.handleCheckboxChange('openInNewWindow')} />
                      }
                      label={translate('digijagApp.app.openInNewWindow')}
                      className={classes.checkLabel}
                    />
                  </FormGroup>
                  <FormHelperText>
                    <Translate contentKey="digijagApp.app.iframeInfo">Om appen inte kan öppnas inuti...</Translate>
                  </FormHelperText>
                </FormControl>
              </div>

              <label className={classes.marginTop}>
                <Translate contentKey="digijagApp.app.create.form.categories">Kategorier</Translate>
              </label>
              <div className={classes.categoryContainer}>
                <FormControl component={'fieldset' as 'div'} className={classes.formControl}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={app.categoryGame} color="primary" onChange={this.handleCheckboxChange(GAME_CATEGORY)} />}
                      label={translate('digijagApp.app.category.game')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={app.categoryKids} color="primary" onChange={this.handleCheckboxChange(KIDS_CATEGORY)} />}
                      label={translate('digijagApp.app.category.kids')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={app.categorySchool} color="primary" onChange={this.handleCheckboxChange(SCHOOL_CATEGORY)} />
                      }
                      label={translate('digijagApp.app.category.school')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={app.categorySchool2} color="primary" onChange={this.handleCheckboxChange(SCHOOL_2_CATEGORY)} />
                      }
                      label={translate('digijagApp.app.category.school2')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={app.categoryFood} color="primary" onChange={this.handleCheckboxChange(FOOD_CATEGORY)} />}
                      label={translate('digijagApp.app.category.food')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={app.categoryHealth} color="primary" onChange={this.handleCheckboxChange(HEALTH_CATEGORY)} />
                      }
                      label={translate('digijagApp.app.category.health')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={app.categorySociety} color="primary" onChange={this.handleCheckboxChange(SOCIETY_CATEGORY)} />
                      }
                      label={translate('digijagApp.app.category.society')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={app.categoryEconomy} color="primary" onChange={this.handleCheckboxChange(ECONOMY_CATEGORY)} />
                      }
                      label={translate('digijagApp.app.category.economy')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={app.categoryVideo} color="primary" onChange={this.handleCheckboxChange(VIDEO_CATEGORY)} />
                      }
                      label={translate('digijagApp.app.category.video')}
                      className={classes.checkLabel}
                    />
                    <FormControlLabel
                      control={<Checkbox checked={app.categoryNews} color="primary" onChange={this.handleCheckboxChange(NEWS_CATEGORY)} />}
                      label={translate('digijagApp.app.category.news')}
                      className={classes.checkLabel}
                    />
                  </FormGroup>
                  <FormHelperText>
                    <Translate contentKey="digijagApp.app.create.form.chooseAll">Välj alla som passar</Translate>
                  </FormHelperText>
                </FormControl>
              </div>

              <label className={classes.marginTop}>
                <Translate contentKey="digijagApp.app.create.form.image">Bild</Translate>
              </label>
              <Typography variant="inherit" align="center">
                <ImageInput id="imgUrl" imgUrl={app.imgUrl} handleChange={this.handleChange('imgUrl')} required={false} />
                <CustomButton color="primary" type="submit">
                  <img src={`content/icons/${btnIcon}.svg`} alt="" />
                  {btnText}
                </CustomButton>
              </Typography>
            </form>
          </DialogContent>
        </DigiJagBaseDialog>
      </>
    );
  }
}

export default withStyles(AppFormDialogStyles)(AppFormDialog);
