import React from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate, translate } from 'react-jhipster';
import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from '../header-components';

export const EntitiesMenu = ({ isStudent, isAdmin, isGroupAdmin, isHelper, isSupportCoach, isCommunityAdmin, isOrganizationAdmin }) => (
  // tslint:disable-next-line:jsx-self-close
  <NavDropdown icon="th-list" name={translate('global.menu.entities.main')} id="entity-menu">
    {(isHelper || isSupportCoach || isCommunityAdmin || isAdmin || isGroupAdmin || isOrganizationAdmin) && (
      <DropdownItem tag={Link} to="/entity/user-data">
        <FontAwesomeIcon icon="user" />
        &nbsp;
        <Translate contentKey="global.menu.entities.userData" />
      </DropdownItem>
    )}
    {(isAdmin || isCommunityAdmin || isHelper || isSupportCoach) && (
      <DropdownItem tag={Link} to="/entity/connection">
        <FontAwesomeIcon icon="user-friends" />
        &nbsp;
        <Translate contentKey="global.menu.entities.connection" />
      </DropdownItem>
    )}
    {(isCommunityAdmin || isAdmin || isOrganizationAdmin) && (
      <DropdownItem tag={Link} to="/entity/community">
        <FontAwesomeIcon icon="users" />
        &nbsp;
        <Translate contentKey="global.menu.entities.community" />
      </DropdownItem>
    )}
    {(isCommunityAdmin || isAdmin) && (
      <DropdownItem tag={Link} to="/entity/zoom">
        <FontAwesomeIcon icon="video" />
        &nbsp;
        <Translate contentKey="global.menu.entities.zoom" />
      </DropdownItem>
    )}
    {isAdmin && (
      <DropdownItem tag={Link} to="/entity/app">
        <FontAwesomeIcon icon="window-restore" />
        &nbsp;
        <Translate contentKey="global.menu.entities.app" />
      </DropdownItem>
    )}
    {(isGroupAdmin || isCommunityAdmin) && (
      <DropdownItem tag={Link} to={translate('digijagApp.routes.app.root')}>
        <FontAwesomeIcon icon="window-restore" />
        &nbsp;
        <Translate contentKey="global.menu.entities.app" />
      </DropdownItem>
    )}
    {isAdmin && (
      <DropdownItem tag={Link} to="/entity/content">
        <FontAwesomeIcon icon="folder" />
        &nbsp;
        <Translate contentKey="global.menu.entities.content" />
      </DropdownItem>
    )}
    {(isGroupAdmin || isCommunityAdmin) && (
      <DropdownItem tag={Link} to={translate('digijagApp.routes.content.root')}>
        <FontAwesomeIcon icon="folder" />
        &nbsp;
        <Translate contentKey="global.menu.entities.content" />
      </DropdownItem>
    )}
    {(isOrganizationAdmin || isAdmin) && (
      <DropdownItem tag={Link} to="/entity/organization">
        <FontAwesomeIcon icon="building" />
        &nbsp;
        <Translate contentKey="global.menu.entities.organization" />
      </DropdownItem>
    )}
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
