import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogBackButton from '../DialogBackButton';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';

interface IDeleteActivityDialogProps {
  open: boolean;
  activity: IActivity;
  onClose: any;
  onDelete: (id: number) => void;
}

function DeleteActivityDialog(props: IDeleteActivityDialogProps) {
  const { open, activity, onClose, onDelete } = props;

  const handleDelete = e => {
    e.preventDefault();
    onDelete(activity.id);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="default-activities-dialog-title" disableBackdropClick disableEscapeKeyDown maxWidth="sm" fullWidth>
      <DialogTitle id="default-activities-dialog-title">
        <FontAwesomeIcon icon="exclamation-triangle" />
        Ta bort aktivitet
      </DialogTitle>
      <DialogContent>
        <Typography variant="inherit">
          Är du säker att du vill ta bort aktiviteten “{activity.name}
          ”?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} startIcon={<FontAwesomeIcon icon="thumbs-up" />} fullWidth>
          Ja, ta bort aktiviteten
        </Button>
        <DialogBackButton onClick={onClose} startIcon={<FontAwesomeIcon icon="times-circle" />} fullWidth>
          Nej
        </DialogBackButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteActivityDialog;
