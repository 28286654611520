import * as React from 'react';
import { withStyles, createStyles, Theme, Typography, Card, Fade } from '@material-ui/core';
import { Classes } from 'jss';
import { Draggable } from 'react-smooth-dnd';
import { NoteType, INotesDistribution, INote, notes } from 'app/shared/model/budgettavlan/note.model';

const NotesListStyles = (theme: Theme) =>
  createStyles({
    shapeCard: {
      display: 'inline-block !important',
      padding: '1rem 1.3rem',
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
      transformOrigin: 'left',
      border: '1px solid #FFFFFF',
      color: '#FFFFFF',
      borderRadius: 5,
      verticalAlign: 'top'
    },
    shape1000: { marginRight: -74 },
    shape500: { marginRight: -66 },
    shape200: { marginRight: -64 },
    shape100: { marginRight: -59 },
    shape50: { marginRight: -51 },
    shape20: { marginRight: -49 },
    shapeCardText: {
      fontSize: '1rem',
      lineHeight: 1
    },
    noteCard: {
      display: 'inline-block !important',
      height: '3.2rem',
      borderRadius: 0,
      verticalAlign: 'top',
      '& img': {
        height: '100%',
        width: 'auto'
      }
    },
    note1000: { marginRight: -116 },
    note500: { marginRight: -107 },
    note200: { marginRight: -101 },
    note100: { marginRight: -98 },
    note50: { marginRight: -91 },
    note20: { marginRight: -86 },
    pileCard: {
      width: 5,
      height: 62,
      display: 'inline-block !important',
      borderTop: '1px solid #fff',
      borderBottom: '1px solid #fff',
      borderLeft: '1px solid #fff',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      boxShadow: '0px 3px 6px rgb(0 0 0 / 20%)'
    }
  });

export interface INotesListProps {
  classes: Classes;
  id: string;
  noteType: NoteType;
  distribution: INotesDistribution;
  fadeIn?: boolean;
  draggable?: boolean;
}

export class NotesList extends React.Component<INotesListProps> {
  renderNoteContent = (note: INote, noteSize) => {
    const { classes, noteType, draggable } = this.props;
    return noteType === 'cash' ? (
      <Card className={classes.noteCard} style={{ cursor: draggable ? 'grab' : 'inherit' }} aria-label={`${note.value} kronor`} component="span">
        <img src={note.img} alt={`${note.value} kronor`} draggable="false" />
      </Card>
    ) : (
      <Card
        className={classes.shapeCard}
        style={{
          backgroundColor: note.color,
          padding: `${noteSize}rem ${1.3 * noteSize}rem`,
          fontSize: `${noteSize}rem`,
          cursor: draggable ? 'grab' : 'inherit'
        }}
        aria-label={`${note.value} kronor`}
        component="span"
      >
        <Typography variant="body1" align="center" color="inherit" className={classes.shapeCardText}>
          {note.value}
        </Typography>
      </Card>
    );
  };

  renderPileNoteContent = (note: INote, noteSize, key) => {
    const { classes, noteType } = this.props;

    return noteType === 'cash' ? (
      <Card key={key} className={`${classes.noteCard} ${classes['note' + note.value]}`} aria-label={`${note.value} kronor`} component="span">
        <img src={note.img} alt={`${note.value} kronor`} draggable="false" />
      </Card>
    ) : (
      <Card
        key={key}
        className={`${classes.shapeCard} ${classes['shape' + note.value]}`}
        style={{
          backgroundColor: note.color,
          padding: `${noteSize}rem ${1.3 * noteSize}rem`,
          fontSize: `${noteSize}rem`
        }}
        aria-label={`${note.value} kronor`}
        component="span"
      >
        <Typography variant="body1" align="center" color="inherit" className={classes.shapeCardText}>
          {note.value}
        </Typography>
      </Card>
    );
  };

  renderNote = (note: INote, noteSize) => {
    const { fadeIn } = this.props;
    return fadeIn ? (
      <Fade in timeout={500}>
        {this.renderNoteContent(note, noteSize)}
      </Fade>
    ) : (
      this.renderNoteContent(note, noteSize)
    );
  };

  renderPileNote = (note: INote, noteSize, key) => {
    const { fadeIn } = this.props;
    return fadeIn ? (
      <Fade in timeout={500}>
        {this.renderPileNoteContent(note, noteSize, key)}
      </Fade>
    ) : (
      this.renderPileNoteContent(note, noteSize, key)
    );
  };

  render() {
    const { classes, id, draggable, distribution } = this.props;

    return (
      <>
        {notes.reduce((acc, curr, noteIdx) => {
          const qtty = distribution[curr.value] || 0;

          if (qtty <= 0) {
            return acc;
          }

          const noteSize = 1 - noteIdx / 25;
          const pile = new Array(qtty > 5 ? 5 : qtty - 1).fill(null);
          const notesByValue = [
            <React.Fragment key={`${curr.value}_${noteIdx}`}>
              {pile.map((_, idx) => {
                return this.renderPileNote(curr, noteSize, `notes_${id}_${curr.value}_${noteIdx}_pile_${idx}`);
              })}

              {draggable ? (
                <Draggable render={() => <span style={{ display: 'inline-block', verticalAlign: 'top' }}>{this.renderNote(curr, noteSize)}</span>} />
              ) : (
                this.renderNote(curr, noteSize)
              )}

              <div style={{ marginTop: '.5rem' }} />
            </React.Fragment>
          ];

          return acc.concat(notesByValue);
        }, [])}
      </>
    );
  }
}

export default withStyles(NotesListStyles)(NotesList);
