import { Moment } from 'moment';
import { IConnection } from 'app/shared/model//connection.model';
import { IInvitation } from 'app/shared/model//invitation.model';
import { IAddress } from 'app/shared/model//address.model';
import { IWidget } from 'app/shared/model//widget.model';
import { IApp } from 'app/shared/model//app.model';
import { ICommunity } from 'app/shared/model//community.model';
import { IDigiviUserData } from 'app/shared/model/digivi-user-data.model';
import { IOrganization } from './organization.model';

export interface IUserData {
  id?: number;
  fontSize?: string;
  fontType?: string;
  archiveModeActive?: boolean;
  hasWidgetPanel?: boolean;
  zoomUserId?: string;
  phoneNumber?: string;
  personalInfo?: string;
  ssn?: string;
  lastLogin?: Moment;
  gridViewDimensions?: string;
  userId?: number;
  helpers?: IConnection[];
  students?: IConnection[];
  invitations?: IInvitation[];
  addresses?: IAddress[];
  widgets?: IWidget[];
  apps?: IApp[];
  communities?: ICommunity[];
  userLogin?: string;
  userFirstName?: string;
  userLastName?: string;
  userEmail?: string;
  userPassword?: string;
  userImageUrl?: string;
  userAuthorities?: string[];
  userCreatedDate?: Moment;
  zoomApiKey?: string;
  zoomApiSecret?: string;
  digiviUserData?: IDigiviUserData;
  organizations?: IOrganization[];
}

export const defaultValue: Readonly<IUserData> = {
  archiveModeActive: false,
  hasWidgetPanel: false
};
