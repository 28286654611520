import React from 'react';
import { withStyles, createStyles, Grid, Theme } from '@material-ui/core';
import { Classes } from 'jss';
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { getCurrentUserData } from 'app/entities/user-data/user-data.reducer';
import DashBoardBase from '../dashboard/DashBoardBase';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import AppsAdd from './AppsAdd';
import MyAppsList from './MyAppsList';
import MyCommunityApps from './MyCommunityApps';
import { DASH_BOARD_MENU_BUTTONS } from '../dashboard/DashBoardMenuButtons';
import { IAdminSideBarItemData } from '../layout/AdminSideBarItem';
import AdminSideBar from '../layout/AdminSideBar';
import AppItemView from '../app-item/AppItemView';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import PublicAppItemView from '../app-item/PublicAppItemView';
import { translate } from 'react-jhipster';

const AppsStyles = ({ breakpoints }: Theme) =>
  createStyles({
    sidebarGrid: {
      backgroundColor: '#272727',
      height: 'calc((var(--vh, 1vh) * 100) - 4rem)',
      overflow: 'auto'
    },
    contentGrid: {
      padding: '2.5rem',
      height: 'calc((var(--vh, 1vh) * 100) - 4rem)',
      overflow: 'auto'
    },
    fullContent: {
      height: 'calc((var(--vh, 1vh) * 100) - 4rem)',
      overflow: 'hidden'
    }
  });

interface IAppsProps extends StateProps, DispatchProps, RouteComponentProps {
  classes: Classes;
}

interface IAppsState {
  redirectToLogout: boolean;
  redirectToChangePassword: boolean;
}

export class Apps extends React.Component<IAppsProps, IAppsState> {
  readonly state = {
    redirectToLogout: false,
    redirectToChangePassword: false
  };

  sidebarItems: IAdminSideBarItemData[] = [
    {
      label: translate('digijagApp.app.create.new'),
      icon: 'plus_icon',
      url: translate('digijagApp.routes.app.newFull')
    },
    {
      label: translate('digijagApp.app.myApps.title'),
      icon: 'apps_circle',
      url: translate('digijagApp.routes.app.myFull')
    },
    {
      label: translate('digijagApp.app.communityApps.title'),
      icon: 'apps_circle',
      url: translate('digijagApp.routes.app.communityFull')
    }
  ];

  componentDidMount = () => {
    this.props.getCurrentUserData();
  };

  handleBrandClick = event => {
    event.preventDefault();
    this.props.history.push('/');
  };

  render() {
    const { redirectToLogout, redirectToChangePassword } = this.state;

    const { match, classes, location } = this.props;

    const { sidebarItems } = this;

    const encloseWithSideBar = ComponentClass => props => (
      <DashBoardBase
        navigate={url => this.props.history.push(url)}
        deferredPrompt={this.props.deferredPrompt}
        handleBrandClick={this.handleBrandClick}
        boardBgColor="#e4e4e4"
        disabledMenuButtons={[DASH_BOARD_MENU_BUTTONS.HELP]}
      >
        <Grid container direction="row">
          <Grid item xs={3} className={classes.sidebarGrid}>
            <AdminSideBar sidebarItems={sidebarItems} currentPath={location.pathname} />
          </Grid>

          <Grid item xs={9} className={classes.contentGrid}>
            <ComponentClass {...props} />
          </Grid>
        </Grid>
      </DashBoardBase>
    );

    const encloseWithoutSideBar = ComponentClass => props => (
      <DashBoardBase
        navigate={url => this.props.history.push(url)}
        deferredPrompt={this.props.deferredPrompt}
        handleBrandClick={this.handleBrandClick}
        boardBgColor="#595959"
        disabledMenuButtons={[DASH_BOARD_MENU_BUTTONS.ENTITIES]}
      >
        <Grid container direction="row">
          <Grid item xs={12} className={classes.fullContent}>
            <ComponentClass {...props} />
          </Grid>
        </Grid>
      </DashBoardBase>
    );

    return (
      <React.Fragment>
        {redirectToLogout ? (
          <Redirect to="/logout" />
        ) : redirectToChangePassword ? (
          <Redirect to="account/password" />
        ) : (
          <Switch>
            <PrivateRoute
              exact
              path={match.url + translate('digijagApp.routes.new')}
              component={encloseWithSideBar(AppsAdd)}
              hasAnyAuthorities={[AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.GROUP_ADMIN]}
            />
            <PrivateRoute
              exact
              path={match.url + translate('digijagApp.routes.app.my')}
              component={encloseWithSideBar(MyAppsList)}
              hasAnyAuthorities={[AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.GROUP_ADMIN]}
            />
            <PrivateRoute
              exact
              path={match.url + translate('digijagApp.routes.app.community')}
              component={encloseWithSideBar(MyCommunityApps)}
              hasAnyAuthorities={[AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.GROUP_ADMIN]}
            />
            <PrivateRoute
              path={match.url + translate('digijagApp.routes.id')}
              component={encloseWithoutSideBar(AppItemView)}
              hasAnyAuthorities={[AUTHORITIES.STUDENT, AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.GROUP_ADMIN, AUTHORITIES.SUPERADMIN]}
            />
            <ErrorBoundaryRoute component={() => <Redirect to={match.url + translate('digijagApp.routes.new')} />} />
          </Switch>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authentication }: IRootState) => ({
  deferredPrompt: authentication.deferredPrompt
});

const mapDispatchToProps = {
  getCurrentUserData
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AppsStyles)(Apps));
