import * as React from 'react';
import { withStyles, createStyles, Theme, Grid } from '@material-ui/core';
import { Classes } from 'jss';

const VerticalContainerStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0
    }
  });

interface IVerticalContainerProps {
  classes: Classes;
  heightDiff?: string;
  className?: string;
}

export class VerticalContainer extends React.Component<IVerticalContainerProps> {
  render() {
    const { classes, heightDiff, children, className } = this.props;
    const minHeight = heightDiff ? `calc(100vh - ${heightDiff})` : '100vh';

    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignContent="stretch"
        wrap="nowrap"
        className={`${classes.root} ${className}`}
        style={{ minHeight }}
      >
        {children}
      </Grid>
    );
  }
}

export default withStyles(VerticalContainerStyles)(VerticalContainer);
