import * as React from 'react';
import { withStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { Classes } from 'jss';

const DNDColumnContainerStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      breakInside: 'avoid',
      padding: '0px !important',
      height: '100%',
      '& .smooth-dnd-container': {
        padding: '1rem',
        minHeight: 120,
        height: '100%'
      }
    }
  });

interface IDNDColumnContainerProps {
  classes: Classes;
  as?: any;
  [x: string]: any;
}

export class DNDColumnContainer extends React.Component<IDNDColumnContainerProps> {
  render() {
    const { classes, as, children, className, ...other } = this.props;
    const ContainerComponent = as;

    return (
      <>
        {ContainerComponent ? (
          <ContainerComponent className={className ? `${className} ${classes.root}` : classes.root} {...other}>
            {children}
          </ContainerComponent>
        ) : (
          <div className={className ? `${className} ${classes.root}` : classes.root} {...other}>
            {children}
          </div>
        )}
      </>
    );
  }
}

export default withStyles(DNDColumnContainerStyles)(DNDColumnContainer);
