import React, { ReactNode } from 'react';
import { withStyles, createStyles, Theme, Typography, Grid, Card, CardContent } from '@material-ui/core';
import { Classes } from 'jss';
import TimeSelect from './TimeSelect';
import { convertPxToRem } from 'app/shared/util/number-utils';
import { baseFontSize } from '../theme';

export const minuteStep = 5;

const TimeCardStyles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: `${convertPxToRem(20, baseFontSize)}`
    },
    labelGutter: {
      marginBottom: `${convertPxToRem(20, baseFontSize)}`
    },
    disabled: {
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(0, 0, 0, 0.2)'
      }
    }
  });

interface ITimeCardProps {
  classes: Classes;
  time: number;
  type: string;
  label: ReactNode;
  onChangeTime: any;
  direction: 'row' | 'column';
  disabled?: boolean;
}

function TimeCard(props: React.PropsWithChildren<ITimeCardProps>) {
  const { classes, time, type, onChangeTime, label, direction, disabled } = props;

  return (
    <Card
      classes={{
        root: `${classes.root} ${disabled ? classes.disabled : ''}`
      }}
    >
      <CardContent>
        {direction === 'row' ? (
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={4}>
              <Typography align="center">{label}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TimeSelect time={time} onChangeTime={onChangeTime} minuteStep={minuteStep} timeType={type} align="center" />
            </Grid>
          </Grid>
        ) : (
          <>
            <Typography classes={{ gutterBottom: classes.labelGutter }} gutterBottom>
              {label}
            </Typography>
            <TimeSelect time={time} onChangeTime={onChangeTime} minuteStep={minuteStep} disabled={disabled} timeType={type} />
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default withStyles(TimeCardStyles)(TimeCard);
