import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import React from 'react';
import { connect } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { ToastContainer, ToastPosition, toast } from 'react-toastify';
import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { setLocale } from 'app/shared/reducers/locale';
import { getCurrentCommunity } from 'app/entities/community/community.reducer';
import { getMeetingsByCurrentGuest, reset as resetZoomMeetings } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';

interface IOverrides extends CoreOverrides {
  PrivateNotchedOutline?: any;
}

const overrides: IOverrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        backgroundColor: 'white',
        letterSpacing: '0.04333rem',
        fontFamily: 'Montserrat, sans-serif'
      }
    }
  },
  MuiButton: {
    text: {
      padding: '.5rem 1rem'
    }
  },
  MuiFormControl: {
    root: {
      marginTop: '2rem'
    }
  },
  MuiFormLabel: {
    root: {
      color: '#000000'
    }
  },
  MuiInputLabel: {
    outlined: {
      '&$shrink': {
        color: '#000000',
        fontSize: '1rem',
        transform: 'translate(0, -1.3rem)'
      }
    }
  },
  MuiOutlinedInput: {
    input: {
      borderRadius: 10,
      backgroundColor: '#e4e4e4'
    },
    notchedOutline: {
      borderColor: 'transparent',
      borderRadius: 10,
      boxShadow: 'inset 0 1px 3px 0 rgb(0 0 0 / 50%)'
    }
  },
  PrivateNotchedOutline: {
    legendLabelled: {
      width: 0
    }
  }
};

const theme = createMuiTheme({
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['Montserrat', 'sans-serif'].join(',')
    // useNextVariants: true
  },
  palette: {
    type: 'light',
    primary: { main: '#9a1750' },
    secondary: { main: '#343434' }
  },
  overrides
});

export interface IAppProps extends StateProps, DispatchProps {
  community;
  currentLocale;
  isAuthenticated;
  isAdmin;
  isGroupAdmin;
  isHelper;
  isCommunityAdmin;
  isSupportCoach;
  isStudent;
  isVuxenlivUser;
  ribbonEnv;
  isInProduction;
  isSwaggerEnabled;
  isOrganizationAdmin;
}

interface IAppState {
  zoomPollingTimer: any;
}

export class App extends React.Component<IAppProps, IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      zoomPollingTimer: null
    };

    const frejaCallbackHashUrl = '#/callback/freja';

    if (document.referrer && document.referrer.indexOf('frejaeid.com') >= 0 && window.location.hash !== frejaCallbackHashUrl) {
      window.location.href = window.location.origin + '/' + window.location.search + frejaCallbackHashUrl;
    }
  }

  componentDidMount() {
    this.props.getSession();
    this.props.getProfile();
    this.props.getCurrentCommunity();
  }

  componentDidUpdate = prevProps => {
    const { isAuthenticated, isAdmin, isVuxenlivUser } = this.props;
    const { zoomPollingTimer } = this.state;

    // For all logged in users except super admin, poll for meetings every 5 seconds.
    if (isAuthenticated && !isAdmin && !isVuxenlivUser && !zoomPollingTimer) {
      const pollingTimer = setInterval(() => requestAnimationFrame(this.props.getMeetingsByCurrentGuest), 5000);
      this.setState({ zoomPollingTimer: pollingTimer });
    } else if (!isAuthenticated && zoomPollingTimer) {
      clearInterval(zoomPollingTimer);
      this.setState({ zoomPollingTimer: null });
      this.props.resetZoomMeetings();
    }
  };

  componentWillUnmount = () => {
    const { zoomPollingTimer } = this.state;

    if (zoomPollingTimer) {
      clearInterval(zoomPollingTimer);
      this.setState({ zoomPollingTimer: null });
      this.props.resetZoomMeetings();
    }
  };

  toastContainer = () =>
    this.props.isInProduction === false && this.props.isAdmin ? (
      <ToastContainer position={toast.POSITION.TOP_LEFT as ToastPosition} className="toastify-container" toastClassName="toastify-toast" />
    ) : null;

  render() {
    const { community } = this.props;

    return (
      <>
        {!this.props.loading && (
          <Router>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              {this.toastContainer()}
              <ErrorBoundary>
                <AppRoutes
                  isCommunityHeader={community && community.id}
                  isAuthenticated={this.props.isAuthenticated}
                  isAdmin={this.props.isAdmin}
                  isGroupAdmin={this.props.isGroupAdmin}
                  isHelper={this.props.isHelper}
                  isCommunityAdmin={this.props.isCommunityAdmin}
                  isOrganizationAdmin={this.props.isOrganizationAdmin}
                  isSupportCoach={this.props.isSupportCoach}
                  isStudent={this.props.isStudent}
                  isVuxenlivUser={this.props.isVuxenlivUser}
                  currentLocale={this.props.currentLocale}
                  onLocaleChange={this.props.setLocale}
                  isInProduction={this.props.isInProduction}
                  isSwaggerEnabled={this.props.isSwaggerEnabled}
                />
              </ErrorBoundary>
            </MuiThemeProvider>
          </Router>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ authentication, applicationProfile, community, locale, zoom }: IRootState) => ({
  community: community.current,
  currentLocale: locale.currentLocale,
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPERADMIN]),
  isGroupAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.GROUP_ADMIN]),
  isHelper: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.HELPER]),
  isCommunityAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.COMMUNITY_ADMIN]),
  isOrganizationAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ORGANIZATION_ADMIN]),
  isSupportCoach: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPPORT_COACH]),
  isStudent: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.STUDENT]),
  isVuxenlivUser: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.VUXENLIV_USER]),
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  loading: authentication.loading
});

const mapDispatchToProps = {
  setLocale,
  getSession,
  getProfile,
  getCurrentCommunity,
  getMeetingsByCurrentGuest,
  resetZoomMeetings
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
