import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table, Alert, Container, Row, Col } from 'reactstrap';
// tslint:disable-next-line:no-unused-variable
import { Translate, getSortState, IPaginationBaseState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { IZoomMeeting } from 'app/shared/model/zoom-meeting.model';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { createMeeting, reset as resetZoomMeetings, getMeetingsByCurrentHost } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { getCurrentUserData, getEntities as getUserData } from 'app/entities/user-data/user-data.reducer';
// tslint:disable-next-line:no-unused-variable
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { Snackbar } from '@material-ui/core';

export interface IZoomMeetingProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IZoomMeetingState extends IPaginationBaseState {
  snackbarOpen: boolean;
}

export class ZoomMeeting extends React.Component<IZoomMeetingProps, IZoomMeetingState> {
  state: IZoomMeetingState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    snackbarOpen: false
  };

  componentDidMount() {
    this.reset();
    this.props.getCurrentUserData();
    this.props.getMeetingsByCurrentHost();
    this.props.getUserData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateSuccess) {
      this.reset();
    }
  }

  reset = () => {
    this.props.resetZoomMeetings();
    this.props.getMeetingsByCurrentHost();
  };

  sliceText = (text: string, length: number): string => (text ? text.slice(0, length) + '...' : '');

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => {
        this.reset();
      }
    );
  };

  copyLink = (textToCopy: string) => {
    if (navigator && (navigator as any).clipboard && (navigator as any).clipboard.writeText) {
      (navigator as any).clipboard.writeText(textToCopy);
      this.setState({ snackbarOpen: true });
    }
  };

  render() {
    const { zoomMeetingList, match, zoomError, zoomErrorDetails, currentUserData } = this.props;
    return (
      <div>
        <h2 id="zoomMeeting-heading">
          <Translate contentKey="digijagApp.zoomMeeting.home.title">Zoom Meetings</Translate>
          {currentUserData.zoomUserId && (
            <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="digijagApp.zoomMeeting.home.createLabel">Create new ZoomMeeting</Translate>
            </Link>
          )}
        </h2>
        {currentUserData.zoomUserId ? (
          <>
            {zoomError && (
              <Alert color="danger">
                <strong>{zoomError}</strong>
                {zoomErrorDetails}
              </Alert>
            )}
            <div className="table-responsive">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand" onClick={this.sort('topic')}>
                      <Translate contentKey="digijagApp.zoomMeeting.topic">Topic</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="digijagApp.zoomMeeting.duration">Duration</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="digijagApp.zoomMeeting.joinUrl">joinUrl</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="digijagApp.zoomMeeting.startUrl">startUrl</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="entity.action.startZoomMeeting">Start in digijag</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {zoomMeetingList &&
                    zoomMeetingList.map((zoomMeeting, i) => (
                      <tr key={`entity-${i}`}>
                        <td>{zoomMeeting.topic}</td>
                        <td>{zoomMeeting.duration}</td>
                        <td>
                          <div onClick={() => this.copyLink(zoomMeeting.joinUrl)} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon="copy" /> {this.sliceText(zoomMeeting.joinUrl, 25)}
                          </div>
                        </td>
                        <td>
                          <div onClick={() => this.copyLink(zoomMeeting.startUrl)} style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon icon="copy" /> {this.sliceText(zoomMeeting.startUrl, 25)}
                          </div>
                        </td>
                        <td>
                          <Link to={`/dashboard${translate('digijagApp.routes.meeting')}/${zoomMeeting.id}`}>
                            <FontAwesomeIcon icon="video" />{' '}
                            <Translate contentKey="entity.action.startZoomMeeting">Start Zoom Meeting</Translate>
                          </Link>
                        </td>
                        <td className="text-right">
                          <div className="btn-group flex-btn-group-container">
                            <Button tag={Link} to={`${match.url}/${zoomMeeting.id}`} color="info" size="sm">
                              <FontAwesomeIcon icon="eye" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.view">View</Translate>
                              </span>
                            </Button>
                            <Button tag={Link} to={`${match.url}/${zoomMeeting.id}/edit`} color="primary" size="sm">
                              <FontAwesomeIcon icon="pencil-alt" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.edit">Edit</Translate>
                              </span>
                            </Button>
                            <Button tag={Link} to={`${match.url}/${zoomMeeting.id}/delete`} color="danger" size="sm">
                              <FontAwesomeIcon icon="trash" />{' '}
                              <span className="d-none d-md-inline">
                                <Translate contentKey="entity.action.delete">Delete</Translate>
                              </span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
            <Snackbar
              open={this.state.snackbarOpen}
              autoHideDuration={6000}
              onClose={() => this.setState({ snackbarOpen: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={() => this.setState({ snackbarOpen: false })} severity="success">
                <Translate contentKey="digijagApp.zoomMeeting.joinUrlCopied" />
              </Alert>
            </Snackbar>
          </>
        ) : (
          <Container>
            <Row>
              <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                <h3 className="mt-5 h6 text-center">
                  <Translate contentKey="digijagApp.zoomMeeting.noZoomUserId" />
                </h3>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ zoom, authentication, userData }: IRootState) => ({
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.SUPERADMIN]),
  isCommunityAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.COMMUNITY_ADMIN]),
  zoomMeetingList: zoom.hostedZoomMeetings,
  entity: zoom.zoomMeeting,
  updateSuccess: zoom.updateSuccess,
  currentUserData: userData.entity,
  zoomError: zoom.error,
  zoomErrorDetails: zoom.errorDetails
});

const mapDispatchToProps = {
  getMeetingsByCurrentHost,
  resetZoomMeetings,
  createMeeting,
  getCurrentUserData,
  getUserData
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomMeeting);
