import React, { Component } from 'react';
import {
  Grid,
  Typography,
  Card,
  createStyles,
  withStyles,
  Theme,
  DialogContent,
  Select,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl
} from '@material-ui/core';
import { Classes } from 'jss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import DigiJagBaseDialog from '../digijag-dialogs/base-dialogs/DigiJagBaseDialog';
import { RouteComponentProps } from 'react-router-dom';
import DigiJagConfirmationDialog from '../digijag-dialogs/base-dialogs/DigiJagConfirmationDialog';
import { IApp } from 'app/shared/model/app.model';
import { filterEntities, deleteEntity, updateEntity, getAllEntitiesByCurrentAuthor } from 'app/entities/app/app.reducer';
import { getAllEntities as getAllCommunities } from 'app/entities/community/community.reducer';
import AppFormDialog from '../digijag-dialogs/apps/AppFormDialog';
import PublishAppDialog from '../digijag-dialogs/apps/PublishAppDialog';
import { ICommunity } from 'app/shared/model/community.model';
import { Translate, translate } from 'react-jhipster';
import {
  ECONOMY_CATEGORY,
  ECONOMY_LABEL,
  GAME_CATEGORY,
  GAME_LABEL,
  KIDS_CATEGORY,
  KIDS_LABEL,
  FOOD_CATEGORY,
  FOOD_LABEL,
  NEWS_CATEGORY,
  NEWS_LABEL,
  NO_CATEGORY,
  NO_CATEGORY_LABEL,
  SCHOOL_CATEGORY,
  SCHOOL_LABEL,
  VIDEO_CATEGORY,
  VIDEO_LABEL,
  HEALTH_CATEGORY,
  HEALTH_LABEL,
  SCHOOL_2_CATEGORY,
  SCHOOL_2_LABEL,
  SOCIETY_CATEGORY,
  SOCIETY_LABEL
} from 'app/shared/model/category';

const MyAppsListStyles = ({ breakpoints }: Theme) =>
  createStyles({
    templateCard: {
      backgroundColor: '#fff',
      height: '18.75rem',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      borderRadius: '10px',
      '&:hover': {
        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.5),0px 8px 10px 1px rgba(0, 0, 0, 0.3),0px 3px 14px 2px rgba(0, 0, 0, 0.3)'
      }
    },

    appTitle: {
      color: '#000',
      fontSize: '1.25rem',
      fontWeight: 'bold',
      marginTop: '36%'
    },

    optionsLink: {
      color: '#000',
      padding: 0,
      margin: 0,
      border: 0,
      background: 'transparent',
      display: 'inline-block',
      cursor: 'pointer',
      '& span': {
        display: 'inline-block',
        width: 11,
        height: 11,
        background: '#000000',
        borderRadius: '50%',
        marginLeft: 2
      }
    },

    altContent: {
      marginTop: '30vh'
    },

    dialogContent: {
      padding: '0 6.5rem calc(1.5rem + 20px) 6.5rem'
    },

    dialogTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      letterSpacing: '1.23px',
      marginBottom: '2rem'
    },

    contentArea: {
      height: 'calc(100% - 1.5rem)'
    },

    optionButtons: {
      '& > *': {
        fontSize: '1.125rem',
        fontWeight: 500,
        letterSpacing: 0.88,
        display: 'block',
        textAlign: 'left',
        margin: 0,
        padding: '1.25rem .5rem',
        color: '#000',
        border: 'none',
        background: 'transparent',
        cursor: 'pointer'
      },
      '& > *:hover': {
        color: 'inherit',
        fontWeight: 700
      },
      '& > *::before': {
        content: '" "',
        display: 'inline-block',
        borderRadius: '50%',
        width: 50,
        height: 50,
        marginRight: 10,
        verticalAlign: 'middle'
      },
      '& > *:nth-child(1)::before': {
        backgroundImage: 'url(content/icons/redigera.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      },
      '& > *:nth-child(2)::before': {
        backgroundImage: 'url(content/icons/publicera.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      },
      '& > *:nth-child(3)::before': {
        backgroundImage: 'url(content/icons/radera.svg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }
    },
    category: {
      minWidth: 120,
      marginTop: -14
    }
  });

interface IMyAppsListProps extends StateProps, DispatchProps, RouteComponentProps {
  classes: Classes;
}

interface IMyAppsListState {
  optionsDialogOpen: boolean;
  deleteAppDialogOpen: boolean;
  editAppDialogOpen: boolean;
  publishAppDialogOpen: boolean;
  isGlobal: boolean;
  communitiesWhereToPublish: number[];
  appCopy?: IApp;
  currentApp?: IApp;
}

export class MyAppsList extends Component<IMyAppsListProps, IMyAppsListState> {
  readonly state = {
    optionsDialogOpen: false,
    deleteAppDialogOpen: false,
    editAppDialogOpen: false,
    publishAppDialogOpen: false,
    isGlobal: false,
    communitiesWhereToPublish: [],
    appCopy: ({} as any) as IApp,
    currentApp: null
  };

  componentDidMount = () => {
    if (!this.props.loading) {
      this.loadInitialData();
    }
  };

  loadInitialData = () => {
    this.props.getAllEntitiesByCurrentAuthor();
    this.props.getAllCommunities();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.loadInitialData();
    }
    if (prevProps.updating !== this.props.updating && !this.props.updating && !this.props.loading) {
      this.props.getAllEntitiesByCurrentAuthor();
    }
  };

  onClickOptions = currentApp => event => {
    event.preventDefault();
    this.setState({
      optionsDialogOpen: true,
      currentApp,
      isGlobal: currentApp.global,
      communitiesWhereToPublish: currentApp.communities.map(community => community.id) as number[],
      appCopy: { ...currentApp }
    });
  };

  handleAppChange = (attrName, value) => {
    this.setState({
      appCopy: {
        ...this.state.appCopy,
        [attrName]: value
      }
    });
  };

  filter = event => {
    this.props.filterEntities(event.target.value);
  };

  onEditAppInfo = event => {
    event.preventDefault();
    this.props.updateEntity({
      ...this.state.currentApp,
      name: this.state.appCopy.name,
      description: this.state.appCopy.description,
      url: this.state.appCopy.url,
      imgUrl: this.state.appCopy.imgUrl,
      openInNewWindow: this.state.appCopy.openInNewWindow,
      categoryGame: this.state.appCopy.categoryGame,
      categoryKids: this.state.appCopy.categoryKids,
      categorySchool: this.state.appCopy.categorySchool,
      categorySchool2: this.state.appCopy.categorySchool2,
      categoryFood: this.state.appCopy.categoryFood,
      categoryHealth: this.state.appCopy.categoryHealth,
      categorySociety: this.state.appCopy.categorySociety,
      categoryEconomy: this.state.appCopy.categoryEconomy,
      categoryVideo: this.state.appCopy.categoryVideo,
      categoryNews: this.state.appCopy.categoryNews
    });
    this.handleCloseDialog('editAppDialogOpen')();
  };

  handleCloseDialog = dialogFlag => () => {
    this.setState({
      [dialogFlag]: false
    } as any);
  };

  openDialog = dialogFlag => () => {
    this.setState(
      {
        optionsDialogOpen: false
      },
      () => {
        this.setState({
          [dialogFlag]: true
        } as any);
      }
    );
  };

  onDeleteApp = () => {
    this.handleCloseDialog('deleteAppDialogOpen')();
    this.props.deleteEntity(this.state.currentApp.id);
  };

  onPublishApp = () => {
    const { communitiesWhereToPublish, isGlobal } = this.state;
    const { communities } = this.props;

    this.props.updateEntity({
      ...this.state.currentApp,
      global: isGlobal,
      communities: communities.filter(community => communitiesWhereToPublish.indexOf(community.id) > -1)
    });
  };

  handleCommunitiesChange = event => {
    this.setState({
      communitiesWhereToPublish: event.target.value
    });
  };

  handleIsGlobalChange = event => {
    this.setState({
      isGlobal: event.target.checked
    });
  };

  navigateTo = url => event => {
    event.preventDefault();
    this.setState(
      {
        optionsDialogOpen: false
      },
      () => {
        this.props.history.push(url);
      }
    );
  };

  render() {
    const { classes, communities, entities, filteredApps, filterCategory } = this.props;
    const {
      optionsDialogOpen,
      deleteAppDialogOpen,
      editAppDialogOpen,
      publishAppDialogOpen,
      communitiesWhereToPublish,
      isGlobal,
      currentApp,
      appCopy
    } = this.state;

    return (
      <>
        <Grid container direction="row" justify="flex-start" spacing={3}>
          <Grid item xs={6}>
            <Typography variant="inherit">
              <img src="content/icons/information.svg" alt="" />
              &nbsp;
              <strong>
                <Translate contentKey="digijagApp.app.myApps.information" />
              </strong>
            </Typography>
          </Grid>
          <Grid item container direction="row" xs={6} justify="flex-end">
            <Grid item>
              <FormControl className={classes.category}>
                <InputLabel htmlFor="category-filter">
                  <Translate contentKey="digijagApp.appStore.category">Kategori</Translate>
                </InputLabel>
                <Select
                  variant="outlined"
                  value={filterCategory}
                  inputProps={{
                    name: 'category-filter',
                    id: 'category-filter'
                  }}
                  onChange={this.filter}
                  displayEmpty
                  disabled={!entities || entities.length === 0}
                >
                  <MenuItem value="">
                    <Translate contentKey="digijagApp.categories.all" />
                  </MenuItem>
                  <MenuItem value={GAME_CATEGORY}>
                    <Translate contentKey={GAME_LABEL} />
                  </MenuItem>
                  <MenuItem value={KIDS_CATEGORY}>
                    <Translate contentKey={KIDS_LABEL} />
                  </MenuItem>
                  <MenuItem value={SCHOOL_CATEGORY}>
                    <Translate contentKey={SCHOOL_LABEL} />
                  </MenuItem>
                  <MenuItem value={SCHOOL_2_CATEGORY}>
                    <Translate contentKey={SCHOOL_2_LABEL} />
                  </MenuItem>
                  <MenuItem value={FOOD_CATEGORY}>
                    <Translate contentKey={FOOD_LABEL} />
                  </MenuItem>
                  <MenuItem value={HEALTH_CATEGORY}>
                    <Translate contentKey={HEALTH_LABEL} />
                  </MenuItem>
                  <MenuItem value={SOCIETY_CATEGORY}>
                    <Translate contentKey={SOCIETY_LABEL} />
                  </MenuItem>
                  <MenuItem value={ECONOMY_CATEGORY}>
                    <Translate contentKey={ECONOMY_LABEL} />
                  </MenuItem>
                  <MenuItem value={VIDEO_CATEGORY}>
                    <Translate contentKey={VIDEO_LABEL} />
                  </MenuItem>
                  <MenuItem value={NEWS_CATEGORY}>
                    <Translate contentKey={NEWS_LABEL} />
                  </MenuItem>
                  <MenuItem value={NO_CATEGORY}>
                    <Translate contentKey={NO_CATEGORY_LABEL} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {this.props.appLoading ? (
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.altContent} align="center" color="inherit">
                <Translate contentKey="register.form.loading" />
              </Typography>
            </Grid>
          ) : !filteredApps || filteredApps.length === 0 ? (
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.altContent} align="center">
                <Translate contentKey="digijagApp.app.myApps.noApps">Inga appar hittades.</Translate>
              </Typography>
            </Grid>
          ) : (
            filteredApps.map((app, idx) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                <Card className={classes.templateCard} onClick={this.onClickOptions(app)}>
                  <Typography variant="inherit" align="right">
                    <button className={classes.optionsLink} aria-label={app.name}>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                      <span>&nbsp;</span>
                    </button>
                  </Typography>
                  <Grid container direction="column" justify="space-between" className={classes.contentArea}>
                    <Grid item>
                      <Typography variant="h2" className={classes.appTitle} align="center">
                        {app.name}
                      </Typography>
                    </Grid>
                    {app.global && (
                      <Grid item>
                        <Typography variant="inherit">
                          <img src="content/icons/globalt.svg" alt="" /> Global
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </Grid>
            ))
          )}
        </Grid>

        <DigiJagBaseDialog open={optionsDialogOpen} onClose={this.handleCloseDialog('optionsDialogOpen')} withCloseButton>
          <DialogContent className={classes.dialogContent}>
            <Typography id="dialog-description" variant="h2" color="inherit" align="center" className={classes.dialogTitle}>
              {currentApp && currentApp.name}
            </Typography>
            <div className={classes.optionButtons}>
              <button onClick={this.openDialog('editAppDialogOpen')}>
                <Translate contentKey="digijagApp.app.edit.title">Redigera</Translate>
              </button>
              <button onClick={this.openDialog('deleteAppDialogOpen')}>
                <Translate contentKey="digijagApp.app.delete.title">Radera</Translate>
              </button>
            </div>
          </DialogContent>
        </DigiJagBaseDialog>

        <DigiJagConfirmationDialog
          isOpen={deleteAppDialogOpen}
          icon="questionmark_big"
          text={translate('digijagApp.app.delete.dialog.text')}
          acceptButtonText={translate('digijagApp.app.delete.dialog.acceptBtn')}
          declineButtonText={translate('digijagApp.app.delete.dialog.declineBtn')}
          onClose={this.handleCloseDialog('deleteAppDialogOpen')}
          onConfirm={this.onDeleteApp}
        />

        <AppFormDialog
          btnIcon="plus_icon"
          btnText={translate('digijagApp.app.edit.btnText')}
          dialogTitle={translate('digijagApp.app.edit.dialogTitle')}
          dialogOpen={editAppDialogOpen}
          handleChange={this.handleAppChange}
          handleCloseDialog={this.handleCloseDialog('editAppDialogOpen')}
          onSaveApp={this.onEditAppInfo}
          app={appCopy}
        />
      </>
    );
  }
}

const mapStateToProps = ({ userData, app, community }: IRootState) => ({
  currentUserData: userData.entity,
  loading: userData.loading,
  appLoading: app.loading,
  updating: app.updating,
  updateSuccess: app.updateSuccess,
  entities: app.entities,
  filterCategory: app.filterCategory,
  filteredApps: app.filteredEntities,
  communities: community.entities
});

const mapDispatchToProps = {
  getAllEntitiesByCurrentAuthor,
  filterEntities,
  deleteEntity,
  updateEntity,
  getAllCommunities
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(MyAppsListStyles)(MyAppsList));
