import { IApp } from 'app/shared/model/app.model';
import { IOrganization } from './organization.model';

export interface ICommunity {
  id?: number;
  name?: string;
  displayName?: string;
  description?: string;
  logoUrl?: string;
  zoomApiKey?: string;
  zoomSecret?: string;
  hideMembers?: boolean;
  apps?: IApp[];
  organizationId?: number;
  organizationName?: string;
}

export const defaultValue: Readonly<ICommunity> = {};
