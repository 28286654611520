import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { Translate } from 'react-jhipster';

class RegisterSuccessful extends React.Component {
  alertRef = React.createRef<HTMLDivElement>();

  componentDidMount = () => {
    const { alertRef } = this;
    const node = alertRef.current;
    if (node) node.focus();
  };

  render() {
    const { alertRef } = this;
    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <div ref={alertRef} tabIndex={0}>
              <Alert color="success">
                <Translate contentKey="register.messages.success">Registration successful!</Translate>
              </Alert>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RegisterSuccessful;
