import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IRootState } from 'app/shared/reducers';
import { getSession, frejaLogin } from 'app/shared/reducers/authentication';
import { getQueryParam } from 'app/shared/util/queryParamExtractor';
import { AUTHORITIES } from 'app/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IFrejaProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

export class Freja extends React.Component<IFrejaProps> {
  componentDidUpdate() {
    const { loginSuccess, loading } = this.props;

    if (!loginSuccess && !loading) {
      const authorizationCode = getQueryParam(location, 'code');
      this.props.frejaLogin(window.localStorage.getItem('frejaAuthMode') || 'digijag', authorizationCode, this.props.currentLocale);
    }
  }

  render() {
    // todo make this prettier once design is in place
    const { location, loginSuccess, account, loading } = this.props; // location from RouteComponentProps
    const { from } = location.state || { from: { pathname: '/', search: location.search } };

    if (!loginSuccess) {
      return (
        <div>
          <h1>FREJA LOGIN</h1>
          {loading && <FontAwesomeIcon icon="spinner" size="lg" spin />}
        </div>
      );
    } else if (account.authorities && account.authorities.includes(AUTHORITIES.STUDENT) && window.localStorage.getItem('frejaAuthMode') === 'digijag') {
      return <Redirect to="/dashboard" />;
    } else if (
      account.authorities &&
      account.authorities.includes(AUTHORITIES.COMMUNITY_ADMIN, AUTHORITIES.GROUP_ADMIN, AUTHORITIES.SUPERADMIN) &&
      window.localStorage.getItem('frejaAuthMode') === 'digijag'
    ) {
      return <Redirect to="/" />;
    } else if (window.localStorage.getItem('frejaAuthMode') === 'budgettavlan') {
      return <Redirect to="/appar/oppet/budgettavlan" />;
    } else if (window.localStorage.getItem('frejaAuthMode') === 'tidsplanering') {
      return <Redirect to="/appar/oppet/fixatiden" />;
    } else {
      return <Redirect to={from} />;
    }
  }
}

const mapStateToProps = ({ authentication, locale }: IRootState) => ({
  loginSuccess: authentication.loginSuccess,
  loading: authentication.loading,
  loginError: authentication.loginError,
  account: authentication.account,
  errorMessage: authentication.errorMessage,
  currentLocale: locale.currentLocale
});

const mapDispatchToProps = { getSession, frejaLogin };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Freja);
