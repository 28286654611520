import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Classes } from 'jss';
import { IRootState } from 'app/shared/reducers';
import { getCurrentUserData as getCurrentUserDataFn } from 'app/entities/user-data/user-data.reducer';
import { endAndDeleteMeeting as endAndDeleteMeetingFn, getMeeting as getMeetingFn, reset as resetFn } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { connect } from 'react-redux';
import { Prompt, RouteComponentProps } from 'react-router-dom';
import AppItemContainer from 'app/custom-components/app-item/AppItemContainer';
import DashBoardBase from 'app/custom-components/dashboard/DashBoardBase';
import { DASH_BOARD_MENU_BUTTONS } from 'app/custom-components/dashboard/DashBoardMenuButtons';
import { defaultValue as defaultZoomMeetingValue, IZoomMeeting } from 'app/shared/model/zoom-meeting.model';
import { translate } from 'react-jhipster';

const ZoomAppViewStyles = () =>
  createStyles({
    frame: {
      overflow: 'hidden'
    }
  });

interface IZoomAppViewProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  classes: Classes;
}

interface IZoomAppViewState {
  currentMeeting: IZoomMeeting;
}

export function ZoomAppView({
  classes,
  history,
  match,
  deferredPrompt,
  zoomMeeting,
  currentUserData,
  endAndDeleteMeeting,
  getCurrentUserData,
  getMeeting,
  reset
}: IZoomAppViewProps) {
  const [currentMeeting, setCurrentMeeting] = useState<IZoomAppViewState['currentMeeting']>(defaultZoomMeetingValue);

  const handleBrandClick = event => {
    event.preventDefault();
    history.push('/');
  };

  const getZoomMeetingFrameUrl = () => {
    const urlBase = window.location.protocol + '//' + window.location.host;
    const frameUrl = `${urlBase}/#/dashboard${translate('digijagApp.routes.meeting')}/${currentMeeting.id}/frame`;
    return frameUrl;
  };

  useEffect(() => {
    getCurrentUserData();
    getMeeting(match.params.id);
    setCurrentMeeting(zoomMeeting);
  }, []);

  useEffect(
    () => {
      setCurrentMeeting(defaultZoomMeetingValue);
      getMeeting(match.params.id);
      setCurrentMeeting(zoomMeeting);
      return () => {
        reset();
      };
    },
    [match.params.id, zoomMeeting.id]
  );

  const beforeLeaveMeeting = location => {
    if (zoomMeeting && zoomMeeting.id && currentUserData && currentUserData.id && zoomMeeting.hostId === currentUserData.id) {
      endAndDeleteMeeting(zoomMeeting.id);
    }
    return true;
  };

  return (
    <>
      <DashBoardBase
        navigate={url => history.push(url)}
        deferredPrompt={deferredPrompt}
        handleBrandClick={handleBrandClick}
        boardBgColor="#e4e4e4"
        disabledMenuButtons={[DASH_BOARD_MENU_BUTTONS.ENTITIES]}
      >
        <Grid container direction="row">
          <AppItemContainer appName={translate('digijagApp.zoomMeeting.zoomCall')} noScroll noNavigation>
            {currentMeeting.id ? (
              <iframe
                src={getZoomMeetingFrameUrl()}
                scrolling="no"
                className={classes.frame}
                sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation"
                allow="microphone; camera; fullscreen; autoplay;"
                title={translate('digijagApp.zoomMeeting.zoomCall')}
              />
            ) : null}
          </AppItemContainer>
        </Grid>
      </DashBoardBase>
      <Prompt message={beforeLeaveMeeting} />
    </>
  );
}

const mapStateToProps = ({ zoom, authentication, userData }: IRootState) => ({
  deferredPrompt: authentication.deferredPrompt,
  zoomMeeting: zoom.zoomMeeting,
  currentUserData: userData.entity
});

const mapDispatchToProps = {
  endAndDeleteMeeting: endAndDeleteMeetingFn,
  getCurrentUserData: getCurrentUserDataFn,
  getMeeting: getMeetingFn,
  reset: resetFn
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ZoomAppViewStyles)(ZoomAppView) as any);
