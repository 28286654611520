import React from 'react';
import { Classes } from 'jss';
import { ButtonProps } from '@material-ui/core/Button';
import CustomButton from './CustomButton';
import { Translate } from 'react-jhipster';
import { createStyles, Theme, withStyles } from '@material-ui/core';

export const AcceptButtonStyles = ({ breakpoints }: Theme) =>
  createStyles({
    buttonIcon: {
      height: 25,
      width: 25,
      marginRight: 10,
      [breakpoints.down('xs')]: {
        height: 18,
        width: 18
      }
    }
  });

interface IAcceptButtonProps extends ButtonProps {
  classes?: Classes;
  onClick?: any;
  icon?: string;
}

export class AcceptButton extends React.Component<IAcceptButtonProps> {
  render() {
    const { onClick, children, classes, icon = 'agree', ...otherProps } = this.props;
    return (
      <CustomButton onClick={onClick} {...otherProps}>
        <img src={`content/icons/${icon}.svg`} alt="" className={classes.buttonIcon} />
        {children || <Translate contentKey="entity.action.yes">Ja</Translate>}
      </CustomButton>
    );
  }
}

export default withStyles(AcceptButtonStyles)(AcceptButton);
