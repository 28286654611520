import React, { RefObject, ClassAttributes } from 'react';
import { RootRef, Theme, createStyles, withStyles, OutlinedInput, InputLabel, FormControl } from '@material-ui/core';
import { Classes } from 'jss';
import SpeechToTextButton from './SpeechToTextButton';

export const OutlinedVoiceInputStyles = ({ breakpoints }: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    speechToTextButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    noBorderRadiusRight: {
      '& fieldset': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 'none !important'
      }
    }
  });

interface IOutlinedVoiceInputProps {
  classes: Classes;
  type?: string;
  id?: string;
  placeholder?: string;
  value?: string;
  autoFocus?: boolean;
  required?: boolean;
  buttonLabel?: any;
  buttonColor?: any;
  buttonVariant?: any;
  buttonExtra?: any;
  extra?: any;
  handleChange?(value: string, isSpoken: boolean): any;
}

export class OutlinedVoiceInput extends React.Component<IOutlinedVoiceInputProps> {
  inputRef = React.createRef<HTMLInputElement>();

  setFocus = (domElement: RefObject<HTMLElement>) => {
    const node = domElement.current;
    if (node) {
      node.focus();
    }
  };

  setInputValue = (domElement: RefObject<HTMLElement>, value: string) => {
    const node = domElement.current;
    if (node) {
      node.setAttribute('value', value);
    }
  };

  getSpokenWord = (value: string) => {
    const { handleChange } = this.props;
    const { inputRef, setFocus, setInputValue } = this;
    if (handleChange) {
      handleChange(value, true);
    } else {
      setInputValue(inputRef, value);
    }
    setFocus(inputRef);
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange(e.currentTarget.value, false);
    }
  };

  render() {
    const { classes, type, id, autoFocus, required, value, buttonColor, buttonVariant, buttonLabel, buttonExtra, extra, placeholder } = this.props;
    const { inputRef, getSpokenWord, onChange } = this;
    const { className: inputClassName, ...otherInputExtra } = (extra || {}) as any;
    const { className: buttonClassName, ...otherButtonExtra } = (buttonExtra || {}) as any;

    return (
      <div className={classes.container}>
        <RootRef rootRef={inputRef}>
          <OutlinedInput
            id={id}
            type={type}
            className={`${'webkitSpeechRecognition' in window ? classes.noBorderRadiusRight : ''} ${inputClassName || ''}`}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            autoFocus={autoFocus}
            required={required}
            {...otherInputExtra}
          />
        </RootRef>
        <SpeechToTextButton
          color={buttonColor}
          variant={buttonVariant}
          onSpeechEnd={getSpokenWord}
          extra={otherButtonExtra}
          className={`${classes.speechToTextButton} ${buttonClassName || ''}`}
        >
          {buttonLabel || <img src="content/icons/microphone.svg" alt="" />}
        </SpeechToTextButton>
      </div>
    );
  }
}

export default withStyles(OutlinedVoiceInputStyles)(OutlinedVoiceInput);
