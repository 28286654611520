import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Alert, Col, Row } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import { APP_EMAIL_LENGTH } from 'app/config/constants';

export interface IPasswordResetInitProps extends StateProps, DispatchProps {}

export class PasswordResetInit extends React.Component<IPasswordResetInitProps> {
  alertRef = React.createRef<HTMLDivElement>();

  componentDidUpdate = prevProps => {
    const { resetPasswordSuccess, resetPasswordFailure } = this.props;
    const { focusDiv } = this;
    if (resetPasswordFailure && !prevProps.resetPasswordFailure) focusDiv();
    if (resetPasswordSuccess && !prevProps.resetPasswordSuccess) focusDiv();
  };

  focusDiv = () => {
    const { alertRef } = this;
    const node = alertRef.current;
    if (node) node.focus();
  };

  componentWillUnmount() {
    this.props.reset();
  }

  handleValidSubmit = (event, values) => {
    this.props.handlePasswordResetInit(values.email);
    event.preventDefault();
  };

  render() {
    const { resetPasswordSuccess, resetPasswordFailure, loading } = this.props;
    const { alertRef } = this;
    return (
      <div className="container-fluid" style={{ padding: '1rem' }}>
        <Row className="justify-content-center">
          <Col md="8">
            <h1>
              <Translate contentKey="reset.request.title">Reset your password</Translate>
            </h1>
            <Alert color="warning">
              <p>
                <Translate contentKey="reset.request.messages.info">Enter the email address you used to register</Translate>
              </p>
            </Alert>
            <AvForm onValidSubmit={this.handleValidSubmit}>
              <AvField
                name="email"
                label={translate('global.form.email')}
                type="email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: translate('global.messages.validate.email.required')
                  },
                  minLength: {
                    value: APP_EMAIL_LENGTH.min,
                    errorMessage: translate('global.messages.validate.email.minlength', { length: APP_EMAIL_LENGTH.min })
                  },
                  maxLength: {
                    value: APP_EMAIL_LENGTH.max,
                    errorMessage: translate('global.messages.validate.email.maxlength', { length: APP_EMAIL_LENGTH.max })
                  }
                }}
              />
              <Button color="primary" type="submit">
                <Translate contentKey="reset.request.form.button">Reset password</Translate>
              </Button>
            </AvForm>
          </Col>
        </Row>
        <Row className="justify-content-center mt-3">
          <Col md="8">
            <div ref={alertRef} tabIndex={0}>
              {resetPasswordFailure && (
                <Alert color="danger">
                  <Translate contentKey="reset.request.messages.notfound" />
                </Alert>
              )}
              {resetPasswordSuccess && (
                <Alert color="success">
                  <Translate contentKey="reset.request.messages.success" />
                </Alert>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = ({ passwordReset }: IRootState) => ({
  resetPasswordSuccess: passwordReset.resetPasswordSuccess,
  resetPasswordFailure: passwordReset.resetPasswordFailure,
  loading: passwordReset.loading
});

const mapDispatchToProps = { handlePasswordResetInit, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetInit);
