import * as React from 'react';
import { withStyles, createStyles, Theme, Card, Button, CardContent, IconButton, Menu, MenuItem, Typography, Divider } from '@material-ui/core';
import { Classes } from 'jss';
import customTheme, { SmallCardHeader } from '../theme';
import { Container } from 'react-smooth-dnd';
import NotesList from './NotesList';
import DNDColumnContainer from './DNDColumnContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { formatNumber } from 'app/shared/util/number-utils';
import { IExpense } from 'app/shared/model/budgettavlan/expense.model';
import { NoteType } from 'app/shared/model/budgettavlan/note.model';

export const StyledCard = withStyles({
  root: {
    border: '2px solid #454545',
    background: 'transparent !important',
    boxShadow: 'none !important'
  }
})(Card);

export const AmountButton = withStyles({
  root: {
    height: '2rem',
    margin: '-0.2rem auto 1rem',
    '&:hover, &:focus': {
      backgroundColor: customTheme.palette.secondary.main
    }
  }
})(Button);

const ExpenseCardStyles = (theme: Theme) =>
  createStyles({
    scenarioCard: {
      cursor: 'pointer'
    },
    scenarioHeader: {
      backgroundColor: '#FFFFFF'
    },
    scenarioHeaderContent: {
      color: '#000000',
      '& .svg-inline--fa': {
        color: theme.palette.primary.main
      }
    },
    scenarioExpenseAmount: {
      height: '2rem',
      margin: '-0.2rem auto 1rem',
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      border: '2px solid #000000',
      fontSize: '0.8125rem',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      borderRadius: '15px',
      lineHeight: '1.75',
      padding: '4px 10px'
    },
    expenseImg: {
      margin: '-1rem -1rem 1rem -1rem',
      width: 'calc(100% + 2rem)'
    }
  });

interface IExpenseCardProps {
  classes: Classes;
  expense: IExpense;
  budgetId: number;
  noteType: NoteType;
  getChildPayload: any;
  onDrop?: any;
  onClick?: Function;
  editExpenseAmount?: any;
  onClickMenu?: any;
  onClickEditMenu?: any;
  onClickDeleteMenu?: any;
  onCloseMenu?: any;
  menuAnchorEl?: any;
  isScenario?: boolean;
}

export class ExpenseCard extends React.Component<IExpenseCardProps> {
  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(this.props.expense);
    }
  };

  render() {
    const {
      classes,
      expense,
      budgetId,
      noteType,
      getChildPayload,
      onDrop,
      editExpenseAmount,
      onClickMenu,
      onClickEditMenu,
      onClickDeleteMenu,
      onCloseMenu,
      menuAnchorEl,
      isScenario
    } = this.props;

    return (
      <StyledCard classes={{ root: isScenario ? classes.scenarioCard : '' }} onClick={this.handleClick}>
        <SmallCardHeader
          classes={{
            root: isScenario ? classes.scenarioHeader : '',
            content: isScenario ? classes.scenarioHeaderContent : ''
          }}
          action={
            !isScenario ? (
              <IconButton aria-label="settings" onClick={onClickMenu}>
                <FontAwesomeIcon icon="ellipsis-v" />
              </IconButton>
            ) : null
          }
          title={
            <>
              {!expense.imgUrl && <FontAwesomeIcon icon={isScenario ? (expense.icon as IconProp) : 'sack-dollar'} />} {expense.name}
            </>
          }
        />

        {!isScenario && (
          <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={onCloseMenu}>
            <MenuItem onClick={onClickEditMenu}>
              <Typography variant="body1">
                <FontAwesomeIcon icon="edit" /> Ändra kostnad
              </Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={onClickDeleteMenu}>
              <Typography variant="body1">
                <FontAwesomeIcon icon="times-circle" /> Ta bort
              </Typography>
            </MenuItem>
          </Menu>
        )}

        <DNDColumnContainer as={CardContent}>
          {isScenario ? (
            <Container>
              <div className={classes.scenarioExpenseAmount}>{formatNumber(expense.amount)} kronor</div>
              <NotesList id={`exp${expense.name}`} distribution={expense.notesDistribution} noteType={noteType} />
            </Container>
          ) : (
            <Container
              groupName={`budget-${budgetId}`}
              getChildPayload={getChildPayload(expense.notesDistribution)}
              onDrop={onDrop}
              behaviour="drop-zone"
              shouldAnimateDrop={() => false}
              removeOnDropOut
            >
              {expense.imgUrl && <img src={expense.imgUrl} className={classes.expenseImg} alt="" />}
              <AmountButton onClick={editExpenseAmount} variant="contained" size="small" fullWidth>
                {formatNumber(expense.amount)} kronor
              </AmountButton>
              <NotesList id={`exp${expense.name}`} distribution={expense.notesDistribution} noteType={noteType} draggable />
            </Container>
          )}
        </DNDColumnContainer>
      </StyledCard>
    );
  }
}

export default withStyles(ExpenseCardStyles)(ExpenseCard);
