import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogBackButton from '../DialogBackButton';
import { IDay, emptyDay } from 'app/shared/model/fixatiden/day.model';
import CustomVoiceInput from '../../../shared-components/CustomVoiceInput';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ICreateDayDialogProps {
  open: boolean;
  onClose: any;
  onSubmit: (newDay: IDay) => any;
  noBackdrop?: boolean;
}

interface ICreateDayDialogState {
  name: string;
}

function CreateDayDialog(props: ICreateDayDialogProps) {
  const { open, onClose, onSubmit, noBackdrop } = props;
  const [name, setName] = useState<ICreateDayDialogState['name']>('');

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const newDay: IDay = { ...JSON.parse(JSON.stringify(emptyDay)), name };
    onSubmit(newDay);
    setName('');
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="create-day-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{ open: !noBackdrop }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="create-day-dialog-title">
          <FontAwesomeIcon icon={'day-sun' as IconProp} />
          Ge schemat namn
        </DialogTitle>
        <DialogContent>
          <CustomVoiceInput value={name} handleChange={handleChangeName} extra={{ placeholder: 'Ge dagen namn' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} startIcon={<FontAwesomeIcon icon="thumbs-up" />} disabled={!name} fullWidth disableFocusRipple>
            Okej
          </Button>
          <DialogBackButton onClick={onClose} startIcon={<FontAwesomeIcon icon="arrow-circle-left" />} fullWidth disableFocusRipple>
            Tillbaka
          </DialogBackButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateDayDialog;
