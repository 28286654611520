import { withStyles, createStyles, Theme, MuiThemeProvider, CssBaseline, Typography } from '@material-ui/core';
import { Classes } from 'jss';
import customTheme from './theme';
import CreateBudgetPage from './pages/CreateBudgetPage';

import BudgetIncomePage from './pages/BudgetIncomePage';
import BudgetsPage from './pages/BudgetsPage';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IBudget } from 'app/shared/model/budgettavlan/budget.model';
import { NoteType } from 'app/shared/model/budgettavlan/note.model';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import {
  getMyEntities as getBudgets,
  getEntitiesNoBackend as getBudgetsNoBackend,
  getEntity as getBudget,
  getEntityNoBackend as getBudgetNoBackend,
  createEntity as createBudget,
  createEntityNoBackend as createBudgetNoBackend,
  updateEntity as updateBudget,
  updateEntityNoBackend as updateBudgetNoBackend,
  deleteEntity as deleteBudget,
  deleteEntityNoBackend as deleteBudgetNoBackend,
  transferBudgetsFromSession,
  hasBudgetsInSessionStorage,
  reset
} from 'app/entities/budgettavlan/budget/budget.reducer';
import React, { useEffect, useState } from 'react';
import defaultExpenses from 'app/shared/model/budgettavlan/expense.model';
import CreateSampleBudgetPage from './pages/CreateSampleBudgetPage';
import HomePage from './pages/HomePage';
import StartPage from '../shared-components/StartPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BudgettavlanStyles = (theme: Theme) => createStyles({});

interface IBudgettavlanProps extends StateProps, DispatchProps, RouteComponentProps {
  classes: Classes;
}

interface IBudgettavlanState {
  started: boolean;
  currentPage: number;
  noteType: NoteType;
  newBudget: IBudget;
}

const defaultNotes = 'cash';
const maxIncome = 50000;
const inputStep = 10;
const sliderStep = 500;
const excludeAmounts = [10, 30];

function Budgettavlan(props: IBudgettavlanProps) {
  const { budget, budgets } = props;
  const [started, setStarted] = useState<IBudgettavlanState['started']>(false);
  const [currentPage, setCurrentPage] = useState<IBudgettavlanState['currentPage']>(0);
  const [noteType, setNoteType] = useState<IBudgettavlanState['noteType']>(defaultNotes);
  const [newBudget, setNewBudget] = useState<IBudgettavlanState['newBudget']>({});

  useEffect(
    () => {
      if (props.isAuthenticated) {
        props.getBudgets();
        handleStartApp();
        props.transferring || transferObjectsFromSessionToAccount();
      } else {
        props.getBudgetsNoBackend();
      }
    },
    [props.isAuthenticated]
  );

  useEffect(
    () => {
      if (budgets.length > 0) {
        setCurrentPage(4);
        handleStartApp();
      } else if (!props.isAuthenticated) {
        setCurrentPage(0);
        setStarted(false);
      } else {
        // Logged in but no Budgets exist
        setCurrentPage(0);
        setStarted(true);
      }
    },
    [budgets]
  );

  const transferObjectsFromSessionToAccount = () => {
    props.hasBudgetsInSessionStorage && props.transferBudgetsFromSession();
  };

  const handleSubmitNewBudgetName = (value: IBudget) => {
    setNewBudget(value);
    setCurrentPage(2);
  };

  const saveNewBudget = (incomeAmount: number) => {
    handleCreateBudget({ ...newBudget, incomeAmount, expenses: defaultExpenses });
    setNewBudget({});
  };

  const handleGetBudget = (id: number) => {
    props.isAuthenticated ? props.getBudget(id) : props.getBudgetNoBackend(id);
  };

  const handleCreateBudget = (entity: IBudget) => {
    props.isAuthenticated ? props.createBudget(entity) : props.createBudgetNoBackend(entity);
    setNewBudget({});
  };

  const handleUpdateBudget = (updatedEntity: IBudget) => {
    props.isAuthenticated ? props.updateBudget(updatedEntity) : props.updateBudgetNoBackend(updatedEntity);
  };

  const handleDeleteBudget = (id: number) => {
    props.isAuthenticated ? props.deleteBudget(id) : props.deleteBudgetNoBackend(id);
  };

  const handleSubmitNewSampleBudget = (sampleBudget: IBudget) => {
    handleCreateBudget({ ...sampleBudget });
  };

  const handleStartApp = () => {
    setStarted(true);
  };

  const handleSubmitCreateMode = (mode: string) => {
    switch (mode) {
      case 'custom':
        setCurrentPage(1);
        break;
      default:
        setCurrentPage(3);
    }
  };

  const handleLogout = () => {
    setStarted(false);
    props.reset();
    props.logout();
  };

  return (
    <MuiThemeProvider theme={customTheme}>
      <CssBaseline />
      {started ? (
        <>
          {
            {
              0: <HomePage onSubmit={handleSubmitCreateMode} />,
              1: (
                <CreateBudgetPage
                  defaultName={budget && budget.name ? budget.name : 'Budget 1'}
                  onSubmit={handleSubmitNewBudgetName}
                  onCancel={() => setCurrentPage(0)}
                />
              ),
              2: (
                <BudgetIncomePage
                  defaultAmount={budgets.length ? budgets[0].incomeAmount : 0}
                  maxValue={maxIncome}
                  inputStep={inputStep}
                  sliderStep={sliderStep}
                  excludeAmounts={excludeAmounts}
                  onSubmit={saveNewBudget}
                  onCancel={() => setCurrentPage(0)}
                />
              ),
              3: <CreateSampleBudgetPage onSubmit={handleSubmitNewSampleBudget} onCancel={() => setCurrentPage(0)} />,
              4: (
                <BudgetsPage
                  noteType={noteType}
                  maxValue={maxIncome}
                  inputStep={inputStep}
                  sliderStep={sliderStep}
                  excludeAmounts={excludeAmounts}
                  onChangeNoteType={setNoteType}
                  newBudget={newBudget}
                  onGetBudget={handleGetBudget}
                  onEditNewBudget={setNewBudget}
                  onCreateBudget={handleCreateBudget}
                  onEditBudget={handleUpdateBudget}
                  onDeleteBudget={handleDeleteBudget}
                  onLogOut={handleLogout}
                />
              )
            }[currentPage]
          }
        </>
      ) : (
        <StartPage
          title={
            <Typography variant="h6" component="h1">
              <FontAwesomeIcon icon="sack-dollar" size="2x" style={{ marginRight: '1rem', verticalAlign: 'middle' }} />
              Välkommen till Budgettavlan
            </Typography>
          }
          guestSubtitle="Skapa din budget utan att logga in"
          loginSubtitle="Skapa din budget och logga in med Freja eID"
          guestButtonLabel="Skapa din budget"
          projectName="budget"
          onStartApp={handleStartApp}
          backdropBgColor="#9ede6360"
        />
      )}
    </MuiThemeProvider>
  );
}

const mapStateToProps = ({ authentication, budget }: IRootState) => ({
  budget: budget.entity,
  budgets: budget.entities,
  isAuthenticated: authentication.isAuthenticated,
  transferring: budget.transferring
});

const mapDispatchToProps = {
  getBudgets,
  getBudget,
  createBudget,
  updateBudget,
  deleteBudget,
  getBudgetsNoBackend,
  getBudgetNoBackend,
  createBudgetNoBackend,
  updateBudgetNoBackend,
  deleteBudgetNoBackend,
  transferBudgetsFromSession,
  hasBudgetsInSessionStorage,
  logout,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(BudgettavlanStyles)(withRouter(Budgettavlan)) as any);
