import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogBackButton from '../DialogBackButton';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IWeek } from 'app/shared/model/fixatiden/week.model';

interface IDeleteTimePlanDialogProps {
  open: boolean;
  plan: IDay | IWeek;
  onClose: any;
  onDelete: any;
}

function DeleteTimePlanDialog(props: IDeleteTimePlanDialogProps) {
  const { open, plan, onClose, onDelete } = props;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="default-activities-dialog-title" disableBackdropClick disableEscapeKeyDown maxWidth="sm" fullWidth>
      <DialogTitle id="default-activities-dialog-title">
        <FontAwesomeIcon icon="question-circle" />
        Ta bort {'activities' in plan ? 'dagen' : 'veckan'}
      </DialogTitle>
      <DialogContent>
        <Typography variant="inherit">
          Är du säker att du vill ta bort {'activities' in plan ? 'dagen' : 'veckan'} “{plan.name}
          ”?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDelete} startIcon={<FontAwesomeIcon icon="thumbs-up" />} fullWidth disableFocusRipple>
          Ja, ta bort {'activities' in plan ? 'dagen' : 'veckan'}
        </Button>
        <DialogBackButton onClick={onClose} startIcon={<FontAwesomeIcon icon="times-circle" />} fullWidth>
          Nej
        </DialogBackButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteTimePlanDialog;
