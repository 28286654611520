import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getMeeting, endAndDeleteMeeting } from 'app/entities/zoom-meeting/zoom-meeting.reducer';

export interface IZoomMeetingDeleteDialogProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class ZoomMeetingDeleteDialog extends React.Component<IZoomMeetingDeleteDialogProps> {
  componentDidMount() {
    this.props.getMeeting(this.props.match.params.id);
  }

  confirmDelete = event => {
    this.props.endAndDeleteMeeting(this.props.zoomMeetingEntity.id);
    this.handleClose(event);
  };

  handleClose = event => {
    event.stopPropagation();
    this.props.history.goBack();
  };

  render() {
    const { zoomMeetingEntity } = this.props;
    return (
      <Modal isOpen toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
        </ModalHeader>
        <ModalBody id="digijagApp.zoomMeeting.delete.question">
          <Translate contentKey="digijagApp.zoomMeeting.delete.question" interpolate={{ id: zoomMeetingEntity.id }}>
            Are you sure you want to delete this ZoomMeeting?
          </Translate>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button id="jhi-confirm-delete-zoomMeeting" color="danger" onClick={this.confirmDelete}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ zoom }: IRootState) => ({
  zoomMeetingEntity: zoom.zoomMeeting
});

const mapDispatchToProps = { getMeeting, endAndDeleteMeeting };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomMeetingDeleteDialog);
