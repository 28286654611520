import React, { useState } from 'react';
import { withStyles, createStyles, Theme, List } from '@material-ui/core';
import { Classes } from 'jss';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';
import { convertPxToRem } from 'app/shared/util/number-utils';
import { baseFontSize } from '../theme';
import ActivityCard from './ActivityCard';
import SideBarButton from './SideBarButton';
import DeleteActivityDialog from './dialogs/DeleteActivityDialog';

const ActivityViewStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: `calc(100vh - ${convertPxToRem(80, baseFontSize)})`,
      alignItems: 'stretch',
      backgroundColor: '#293A5F',
      color: '#fff'
    },
    activityDetails: {
      padding: '1rem'
    },
    activityIcon: {
      filter: 'invert(1)',
      height: convertPxToRem(50, baseFontSize),
      marginRight: convertPxToRem(20, baseFontSize)
    }
  });

interface IActivityViewProps {
  classes: Classes;
  onCancel: any;
  onDelete: (id: number) => void;
  onEdit: any;
  activity: IActivity;
}

interface IActivityViewState {
  openDeleteActivityDialog: boolean;
}

function ActivityView(props: React.PropsWithChildren<IActivityViewProps>) {
  const { classes, onCancel, onDelete, onEdit, activity } = props;

  const [openDeleteActivityDialog, setOpenDeleteActivityDialog] = useState<IActivityViewState['openDeleteActivityDialog']>(false);

  const onClickDelete = () => {
    setOpenDeleteActivityDialog(true);
  };

  const handleDeleteActivity = id => {
    onDelete(id);
    setOpenDeleteActivityDialog(false);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.activityDetails}>
          <p>
            {!activity.imgUrl && activity.icon && <img src={`content/icons/${activity.icon}.svg`} alt="" className={classes.activityIcon} />}
            {activity.name}
          </p>
          <ActivityCard activity={activity} maxWidth="100%" typeList nonInteractive />
        </div>
        <List>
          <SideBarButton onClick={onEdit} icon="pen-square" text="Ändra aktiviteten" />
          <SideBarButton onClick={onClickDelete} icon="trash" text="Ta bort aktiviteten" />
          <SideBarButton onClick={onCancel} icon="arrow-circle-left" text="Tillbaka" />
        </List>
      </div>
      <DeleteActivityDialog
        open={openDeleteActivityDialog}
        activity={activity}
        onClose={() => setOpenDeleteActivityDialog(false)}
        onDelete={handleDeleteActivity}
      />
    </>
  );
}

export default withStyles(ActivityViewStyles)(ActivityView);
