import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from '../header-components';
import { remoteControl } from 'app/shared/reducers/account';
import { DropdownItem, NavItem, NavLink } from 'reactstrap';

const accountMenuItemsAuthenticated = (
  <>
    <DropdownItem tag={Link} to="/account/settings">
      <FontAwesomeIcon icon="wrench" /> <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/account/password">
      <FontAwesomeIcon icon="clock" /> <Translate contentKey="global.menu.account.password">Password</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/logout">
      <FontAwesomeIcon icon="sign-out-alt" /> <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </DropdownItem>
  </>
);

const accountMenuItemsStudent = (
  <>
    <DropdownItem tag={Link} to="/account/settings">
      <FontAwesomeIcon icon="wrench" /> <Translate contentKey="global.menu.account.settings">Settings</Translate>
    </DropdownItem>
    <DropdownItem tag={Link} to="/account/password">
      <FontAwesomeIcon icon="clock" /> <Translate contentKey="global.menu.account.password">Password</Translate>
    </DropdownItem>
    <DropdownItem onClick={remoteControl()}>
      <FontAwesomeIcon icon="eye" /> Be om fjärrstyrning
    </DropdownItem>
    <DropdownItem tag={Link} to="/logout">
      <FontAwesomeIcon icon="sign-out-alt" /> <Translate contentKey="global.menu.account.logout">Sign out</Translate>
    </DropdownItem>
  </>
);

const accountMenu = (
  <NavItem>
    <NavLink tag={Link} to="/login" className="d-flex align-items-center login-nav-item">
      <FontAwesomeIcon icon="sign-in-alt" />
      <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </NavLink>
  </NavItem>
);

export const AccountMenu = ({ isAuthenticated = false, isStudent = false }) => (
  <>
    {isAuthenticated ? (
      <NavDropdown icon="user" name={translate('global.menu.account.main')} id="account-menu">
        {isStudent ? accountMenuItemsStudent : accountMenuItemsAuthenticated}
      </NavDropdown>
    ) : (
      accountMenu
    )}
  </>
);

export default AccountMenu;
