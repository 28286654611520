import axios from 'axios';
import { parseHeaderForLinks, loadMoreDataWhenScrolled, ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IOrganization, defaultValue } from 'app/shared/model/organization.model';

export const ACTION_TYPES = {
  FETCH_ORGANIZATION_LIST: 'organization/FETCH_ORGANIZATION_LIST',
  FETCH_ORGANIZATION: 'organization/FETCH_ORGANIZATION',
  FETCH_CURRENT_ORGANIZATION: 'organization/FETCH_CURRENT_ORGANIZATION',
  CREATE_ORGANIZATION: 'organization/CREATE_ORGANIZATION',
  UPDATE_ORGANIZATION: 'organization/UPDATE_ORGANIZATION',
  DELETE_ORGANIZATION: 'organization/DELETE_ORGANIZATION',
  RESET: 'organization/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IOrganization>,
  entity: defaultValue,
  current: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type OrganizationState = Readonly<typeof initialState>;

// Reducer

export default (state: OrganizationState = initialState, action): OrganizationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ORGANIZATION):
    case REQUEST(ACTION_TYPES.FETCH_CURRENT_ORGANIZATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.UPDATE_ORGANIZATION):
    case REQUEST(ACTION_TYPES.DELETE_ORGANIZATION):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ORGANIZATION):
    case FAILURE(ACTION_TYPES.FETCH_CURRENT_ORGANIZATION):
    case FAILURE(ACTION_TYPES.CREATE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.UPDATE_ORGANIZATION):
    case FAILURE(ACTION_TYPES.DELETE_ORGANIZATION):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION_LIST):
      const links = parseHeaderForLinks(action.payload.headers.link);
      return {
        ...state,
        links,
        loading: false,
        totalItems: action.payload.headers['x-total-count'],
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ORGANIZATION):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_CURRENT_ORGANIZATION):
      return {
        ...state,
        loading: false,
        current: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ORGANIZATION):
    case SUCCESS(ACTION_TYPES.UPDATE_ORGANIZATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ORGANIZATION):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/organizations';

// Actions

export const getEntities: ICrudGetAllAction<IOrganization> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION_LIST,
    payload: axios.get<IOrganization>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}?page=0&size=10000`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION_LIST,
    payload: axios.get<IOrganization>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IOrganization> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION,
    payload: axios.get<IOrganization>(requestUrl)
  };
};

export const searchEntities = (searchterm, page, size, sort) => {
  const requestUrl = `${apiUrl}/search/${searchterm}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ORGANIZATION_LIST,
    payload: axios.get<IOrganization>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getCurrentOrganization = () => async dispatch => {
  const requestUrl = `api/organization`;
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_CURRENT_ORGANIZATION,
    payload: axios.get<IOrganization>(requestUrl)
  });
  return result;
};

export const createEntity: ICrudPutAction<IOrganization> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ORGANIZATION,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IOrganization> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ORGANIZATION,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IOrganization> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ORGANIZATION,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
