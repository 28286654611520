import React, { useEffect, useRef, useState } from 'react';
import { Classes } from 'jss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import InviteHelperForm from './InviteHelperForm';
import InviteHelperSuccess from './InviteHelperSuccess';
import { inviteHelper, reset } from 'app/entities/user-data/user-data.reducer';
import DigiJagBaseDialog from '../base-dialogs/DigiJagBaseDialog';
import DigiJagConfirmationContent from '../base-dialogs/DigiJagConfirmationContent';
import { createStyles, LinearProgress, Theme, withStyles } from '@material-ui/core';
import { Translate } from 'react-jhipster';

export const InviteHelperDialogStyles = ({ breakpoints }: Theme) =>
  createStyles({
    linearProgress: {
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      color: '#9b2f50'
    }
  });

interface IInviteHelperDialogProps extends StateProps, DispatchProps {
  classes: Classes;
  isOpen: boolean;
  onClose: Function;
}

interface IInviteHelperDialogState {
  isShowingInviteHelperForm: boolean;
  email: string;
  emailError: boolean;
}

export function InviteHelperDialog({ classes, isOpen, onClose, userDataUpdating, userDataUpdateSuccess, location }: IInviteHelperDialogProps) {
  const [isShowingInviteHelperForm, setIsShowingInviteHelperForm] = useState<IInviteHelperDialogState['isShowingInviteHelperForm']>(false);
  const [email, setEmail] = useState<IInviteHelperDialogState['email']>('');
  const [emailError, setEmailError] = useState<IInviteHelperDialogState['emailError']>(false);

  const dataUpdateStatus = useRef<boolean>();

  useEffect(() => {
    reset();
  }, []);

  useEffect(
    () => {
      if (dataUpdateStatus.current !== userDataUpdateSuccess) {
        dataUpdateStatus.current = userDataUpdateSuccess;
        setIsShowingInviteHelperForm(false);
      }
    },
    [userDataUpdateSuccess]
  );

  const showInviteHelperForm = () => {
    setIsShowingInviteHelperForm(true);
  };

  const handleEmailChange = (value: string, isSpoken: boolean) => {
    let atReplaceString;
    if (location === 'sv') {
      atReplaceString = 'snabel-a';
    }

    if (location === 'no') {
      atReplaceString = 'krøllalfa';
    }

    const emailVal = isSpoken
      ? value
          .replace(/\s/g, '')
          .replace('at', '@')
          .replace(atReplaceString, '@')
          .toLowerCase()
      : value;

    setEmail(emailVal);
    setEmailError(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (!e.currentTarget.checkValidity()) {
      setEmailError(true);
      return;
    }

    inviteHelper(email);
  };

  return (
    <DigiJagBaseDialog open={isOpen} onClose={onClose} withCloseButton aria-labelledby="dialog-description">
      {isShowingInviteHelperForm && !userDataUpdateSuccess ? (
        <InviteHelperForm
          userDataUpdating={userDataUpdating}
          handleSubmit={handleSubmit}
          handleEmailChange={handleEmailChange}
          emailError={emailError}
          email={email}
        />
      ) : !isShowingInviteHelperForm && userDataUpdateSuccess && !userDataUpdating ? (
        <InviteHelperSuccess handleCloseDialog={onClose} email={email} />
      ) : (
        <>
          {userDataUpdating ? <LinearProgress className={classes.linearProgress} color="primary" /> : null}
          <DigiJagConfirmationContent
            icon="invite-helpers_big"
            text={<Translate contentKey="digijagApp.inviteHelper.confirmationDialog" />}
            onConfirm={showInviteHelperForm}
            onClose={onClose}
          />
        </>
      )}
    </DigiJagBaseDialog>
  );
}

const mapStateToProps = ({ userData, location }: IRootState) => ({
  userDataUpdating: userData.updating,
  userDataUpdateSuccess: userData.updateSuccess,
  location: location.currentLocation
});

const mapDispatchToProps = {
  inviteHelper,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(InviteHelperDialogStyles)(InviteHelperDialog));
