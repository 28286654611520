// docs: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia
// see: https://www.webrtc-experiment.com/Pluginfree-Screen-Sharing/#20893521368186473
// see: https://github.com/muaz-khan/WebRTC-Experiment/blob/master/Pluginfree-Screen-Sharing/conference.js

import html2canvas from 'html2canvas';

const getDisplayMedia = options => {
  if ((navigator.mediaDevices as any) && (navigator.mediaDevices as any).getDisplayMedia) {
    return (navigator.mediaDevices as any).getDisplayMedia(options);
  }
  if ((navigator as any).getDisplayMedia) {
    return (navigator as any).getDisplayMedia(options);
  }
  if ((navigator as any).webkitGetDisplayMedia) {
    return (navigator as any).webkitGetDisplayMedia(options);
  }
  if ((navigator as any).mozGetDisplayMedia) {
    return (navigator as any).mozGetDisplayMedia(options);
  }
  throw new Error('getDisplayMedia is not defined');
};

const getUserMedia = options => {
  if ((navigator.mediaDevices as any) && (navigator.mediaDevices as any).getUserMedia) {
    return (navigator.mediaDevices as any).getUserMedia(options);
  }
  if ((navigator as any).getUserMedia) {
    return (navigator as any).getUserMedia(options);
  }
  if ((navigator as any).webkitGetUserMedia) {
    return (navigator as any).webkitGetUserMedia(options);
  }
  if ((navigator as any).mozGetUserMedia) {
    return (navigator as any).mozGetUserMedia(options);
  }
  throw new Error('getUserMedia is not defined');
};

const takeScreenshotStream = async () => {
  // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/screen
  const width = screen.width * (window.devicePixelRatio || 1);
  const height = screen.height * (window.devicePixelRatio || 1);

  const errors = [];
  let stream;
  try {
    stream = await getDisplayMedia({
      audio: false,
      // see: https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints/video
      video: {
        width,
        height,
        frameRate: 1
      }
    });
  } catch (ex) {
    errors.push(ex);
  }

  // for electron js
  if (navigator.userAgent.indexOf('Electron') >= 0) {
    try {
      stream = await getUserMedia({
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
            // chromeMediaSourceId: source.id,
            minWidth: width,
            maxWidth: width,
            minHeight: height,
            maxHeight: height
          }
        }
      });
    } catch (ex) {
      errors.push(ex);
    }
  }

  if (errors.length) {
    console.dir(errors);
    if (!stream) {
      throw errors[errors.length - 1];
    }
  }

  return stream;
};

const takeScreenshotCanvas = async (): Promise<HTMLCanvasElement> => {
  const stream = await takeScreenshotStream();

  // from: https://stackoverflow.com/a/57665309/5221762
  const video = document.createElement('video');
  const result = await new Promise<HTMLCanvasElement>((resolve, reject) => {
    video.onloadedmetadata = () => {
      video.play();
      video.pause();

      // from: https://github.com/kasprownik/electron-screencapture/blob/master/index.js
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      resolve(canvas);
    };
    video.srcObject = stream;
  });

  stream.getTracks().forEach(track => {
    track.stop();
  });

  if (result == null) {
    throw new Error('Cannot take canvas screenshot');
  }

  return result;
};

// from: https://stackoverflow.com/a/46182044/5221762
const getJpegBlob = async (canvas: HTMLCanvasElement) =>
  new Promise<Blob>((resolve, reject) => {
    // docs: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
    canvas.toBlob(blob => resolve(blob), 'image/jpeg', 0.95);
  });

// const getJpegBytes = async (canvas: HTMLCanvasElement) => {
//   const blob = await getJpegBlob(canvas);
//   return new Promise((resolve, reject) => {
//     const fileReader = new FileReader();

//     fileReader.addEventListener('loadend', function() {
//       if (this.error) {
//         reject(this.error);
//         return;
//       }
//       resolve(this.result);
//     });

//     fileReader.readAsArrayBuffer(blob as Blob);
//   });
// };

export const takeScreenshotJpegBlob = async (): Promise<Blob> => {
  // Convert body element to canvas
  const canvas = await html2canvas(document.getElementById('root'));
  // const canvas: HTMLCanvasElement = await takeScreenshotCanvas();

  // Convert canvas to Jpeg Blob and return it
  return getJpegBlob(canvas);
};

// export const takeScreenshotJpegBytes = async () => {
//   const canvas: HTMLCanvasElement = await takeScreenshotCanvas();
//   return getJpegBytes(canvas);
// };

export const blobToCanvas = (blob, maxWidth, maxHeight) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const scale = Math.min(1, maxWidth ? maxWidth / img.width : 1, maxHeight ? maxHeight / img.height : 1);
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
      resolve(canvas);
    };
    img.onerror = () => {
      reject(new Error('Error load blob to Image'));
    };
    img.src = URL.createObjectURL(blob);
  });
