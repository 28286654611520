import { TranslatorContext, Storage } from 'react-jhipster';

import { setApplicationLocale } from 'app/shared/reducers/locale';

TranslatorContext.setDefaultLocale('sv');
TranslatorContext.setRenderInnerTextForMissingKeys(false);

export const languages: any = {
  sv: { name: 'Svenska' },
  no: { name: 'Norsk' },
  en: { name: 'English' }
  // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
};

export const locales = Object.keys(languages);

export const registerApplicationLocale = store => {
  store.dispatch(setApplicationLocale());
};
