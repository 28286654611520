import { createMuiTheme, darken, lighten, CardHeader, withStyles } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides';

const palette = createPalette({
  primary: {
    light: lighten('#454545', 0.1),
    main: '#454545',
    dark: darken('#454545', 0.1),
    contrastText: '#FFFFFF'
  },
  secondary: {
    light: lighten('#96df62', 0.1),
    main: '#96df62',
    dark: darken('#96df62', 0.1),
    contrastText: '#000000'
  },
  background: {
    default: '#FFFFFF'
  }
});

export const WhiteCardHeader = withStyles({
  root: {
    padding: '.5rem 1rem !important',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    height: '4rem'
  },
  content: {
    color: '#000000',
    '& .svg-inline--fa': {
      color: '#000000'
    }
  },
  title: {
    fontSize: '1rem',
    whiteSpace: 'nowrap'
  },
  action: {
    marginTop: 0,
    marginRight: '-.5rem',
    '& button': {
      color: '#000000',
      fontSize: '1rem'
    }
  }
})(CardHeader);

export const SmallCardHeader = withStyles({
  root: {
    padding: '.5rem 1rem !important',
    borderBottom: '2px solid #454545',
    wordBreak: 'break-word'
  },
  title: {
    fontSize: '1rem',
    padding: '3px 0'
  },
  action: {
    marginTop: 0,
    marginRight: '-.5rem',
    '& button': {
      color: '#FFFFFF',
      fontSize: '1rem',
      padding: '6px 12px'
    }
  },
  content: {
    wordBreak: 'break-word',
    hyphens: 'auto',
    '& .svg-inline--fa': {
      color: palette.secondary.main,
      marginRight: '.5rem'
    }
  }
})(CardHeader);

interface IOverrides extends CoreOverrides {
  MuiToggleButtonGroup?: any;
  MuiToggleButton?: any;
}

export const baseFontSize = 16;

const overrides: IOverrides = {
  MuiCssBaseline: {
    '@global': {
      'html, body': {
        fontFamily: 'Quicksand, sans-serif',
        fontSize: baseFontSize
      },
      body: {
        minHeight: '100vh',
        backgroundImage: 'url(/content/images/bokeh-product-background.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        letterSpacing: 0
      },
      a: {
        color: 'inherit',
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline'
        },
        '&.external:after': {
          fontFamily: '"Font Awesome 5 Free"',
          fontWeight: 900,
          content: '" \\F35D"'
        }
      }
    }
  },
  MuiButton: {
    root: {
      borderRadius: 15,
      padding: '1rem 1.5rem',
      height: '3rem'
    },
    label: {
      lineHeight: 1
    },
    textSizeSmall: {
      fontSize: '1rem',
      padding: '.5rem 1.5rem',
      height: 'auto'
    },
    contained: {
      border: '2px solid #000000',
      backgroundColor: '#FFFFFF'
    },
    containedSizeSmall: {
      padding: '.5rem 1rem'
    },
    containedPrimary: {
      backgroundColor: palette.primary.main,
      color: '#ffffff',
      '& .svg-inline--fa': {
        color: palette.secondary.main
      },
      '&:hover .svg-inline--fa': {
        color: palette.secondary.contrastText
      },
      '&:hover': {
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText
      },
      '&.Mui-disabled': {
        opacity: '50%',
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText
      }
    }
  },
  MuiToggleButtonGroup: {
    root: {
      borderRadius: 15
    }
  },
  MuiToggleButton: {
    root: {
      color: 'inherit',
      border: '2px solid #000000',
      backgroundColor: '#FFFFFF',
      borderRadius: 15,
      padding: '.8rem',
      height: 'auto',
      '&$selected': {
        color: 'inherit !important',
        backgroundColor: '#1AB7B9 !important',
        border: '4px solid #000000 !important',
        padding: 'calc(.8rem - 2px)',
        '&:hover': {
          backgroundColor: '#1AB7B9 !important'
        }
      },
      '&:hover': {
        backgroundColor: '#FFFFFF !important'
      }
    },
    label: {
      display: 'inline-block'
    }
  },
  MuiCard: {
    root: {
      background: 'rgba(255, 255, 255, .7) 0% 0% no-repeat padding-box',
      borderRadius: 15
    }
  },
  MuiCardHeader: {
    root: {
      padding: '1rem 2rem',
      backgroundColor: palette.primary.main
    },
    content: {
      color: '#FFFFFF',
      fontSize: '1.25rem',
      '& > .svg-inline--fa': {
        color: palette.secondary.main,
        marginRight: '1rem'
      }
    }
  },
  MuiCardContent: {
    root: {
      padding: '2rem !important'
    }
  },
  MuiBackdrop: {
    root: {
      backgroundImage: 'url(/content/images/bokeh-product-background.png)',
      backgroundSize: 'cover'
    }
  },
  MuiDialog: {
    paper: {
      backgroundColor: 'rgba(255, 255, 255, .5)',
      padding: 0,
      borderRadius: 20
    }
  },
  MuiDialogTitle: {
    root: {
      padding: '1rem 2rem !important',
      backgroundColor: palette.primary.main,
      color: '#FFFFFF',
      '& .svg-inline--fa': {
        color: palette.secondary.main,
        marginRight: '.75rem'
      }
    }
  },
  MuiDialogContent: {
    root: {
      padding: '2rem 2rem 1.5rem !important'
    }
  },
  MuiDialogActions: {
    root: {
      margin: '0 !important',
      padding: '1rem 2rem 2rem !important',
      justifyContent: 'flex-start'
    },
    spacing: {
      margin: '0 1.5rem 0 0 !important',
      '&:last-child': {
        margin: '0 !important'
      },
      '& > :not(:first-child)': {
        marginLeft: '1.5rem'
      }
    }
  },
  MuiSlider: {
    root: {
      height: '1rem',
      padding: '2rem 0 !important'
    },
    thumb: {
      height: 60,
      width: 60,
      lineHeight: 60,
      marginTop: '-12px',
      marginLeft: '-30px',
      backgroundColor: '#ffffff',
      fontSize: '2rem',
      color: '#000000',
      border: '2px solid #000000',
      boxShadow: '0px 3px 6px #00000029',
      '&:before': {
        fontFamily: '"Font Awesome 5 Free"',
        fontWeight: 900,
        content: '" \\F337"'
      },
      '&:focus, &:hover, &$active': {
        boxShadow: '0px 3px 6px #00000029 !important',
        backgroundColor: palette.secondary.main
      }
    },
    rail: {
      opacity: 1,
      height: 32,
      backgroundColor: '#FFFFFF',
      border: '2px solid #000000',
      borderRadius: 15,
      boxShadow: '0px 3px 6px #00000029'
    },
    track: {
      height: 32,
      backgroundColor: '#FFFFFF',
      backgroundImage: 'linear-gradient(270deg, #94d59c 0%, #d9ebcd 100%)',
      border: '2px solid #000000',
      borderRadius: 15,
      boxShadow: '0px 3px 6px #00000029'
    }
  },
  MuiOutlinedInput: {
    root: {
      borderRadius: 15
    },
    input: {
      color: '#000000',
      zIndex: 1,
      maxHeight: '3rem',
      padding: '0 1rem'
    },
    notchedOutline: {
      border: '2px solid #000000 !important',
      backgroundColor: '#FFFFFF',
      top: 0,
      zIndex: 0,
      '& legend': {
        lineHeight: '0 !important'
      }
    }
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  MuiListItem: {
    root: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      backgroundColor: palette.primary.main,
      transition: 'none',
      '&$selected': {
        position: 'relative',
        backgroundColor: 'transparent !important',
        '& *': {
          color: '#000000 !important'
        },
        '&:before': {
          content: '" "',
          position: 'absolute',
          top: 0,
          left: 0,
          borderTopLeftRadius: '1rem',
          height: '1rem',
          width: '1rem',
          boxShadow: '-10px -10px 0 10px #454545'
        },
        '&:after': {
          content: '" "',
          position: 'absolute',
          bottom: 0,
          left: 0,
          borderBottomLeftRadius: '1rem',
          height: '1rem',
          width: '1rem',
          boxShadow: '-10px 10px 0 10px #454545'
        }
      }
    },
    button: {
      transition: 'none',
      '&:hover': {
        backgroundColor: palette.primary.main
      }
    }
  },
  MuiListItemIcon: {
    root: {
      minWidth: '2rem',
      paddingTop: 9,
      color: palette.secondary.main,
      '&$selected': {
        color: '#000000'
      }
    }
  },
  MuiListItemText: {
    root: {
      color: '#ffffff',
      wordBreak: 'break-word'
    }
  },
  MuiMenu: {
    paper: {
      borderRadius: 15
    },
    list: {
      border: '2px solid #000000',
      borderRadius: 15,
      overflow: 'hidden'
    }
  },
  MuiMenuItem: {
    root: {
      backgroundColor: '#FFFFFF',
      padding: '.8rem 1.5rem !important',
      '& svg': {
        fontSize: '1.4rem',
        verticalAlign: 'bottom',
        marginRight: 15
      },
      '&:hover, &$selected': {
        backgroundColor: `${palette.secondary.main} !important`,
        '&:before, &:after': {
          content: '',
          boxShadow: 'none !important'
        }
      }
    }
  },
  MuiDivider: {
    root: {
      height: 2,
      backgroundColor: '#000000'
    }
  },
  MuiFormLabel: {
    root: {
      color: '#000'
    }
  }
};

const customTheme = createMuiTheme({
  typography: {
    fontFamily: ['Quicksand', 'sans-serif'].join(', '),
    fontWeightLight: 700,
    fontWeightRegular: 700,
    fontWeightMedium: 700,
    button: {
      textTransform: 'none',
      fontSize: '1rem'
    }
  },
  shape: {
    borderRadius: 20
  },
  palette,
  overrides
});

export default customTheme;
