import React from 'react';
import {
  FREJA_EID_TEST_ENV_URL,
  FREJA_EID_PROD_ENV_URL,
  DIGIJAG_LOCALHOST_REDIRECT_URI,
  DIGIJAG_TEST_REDIRECT_URI,
  DIGIJAG_PROD_REDIRECT_URI
} from 'app/shared/util/freja_eid.constants';

interface IFrejaButtonProps {
  authMode: string;
  onClick?: Function;
  labelBeforeLogo?: string;
}

function FrejaButton(props: React.PropsWithChildren<IFrejaButtonProps>) {
  const FREJA_AUTH_MODE_KEY = 'frejaAuthMode';
  const FREJA_SUBDOMAIN_KEY = 'frejaSubdomain';
  const { authMode } = props;
  const { labelBeforeLogo } = props;

  const generateRandomString = len => {
    const arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec => dec.toString(16).padStart(2, '0')).join('');
  };

  const getFrejaAuthenticationURI = () => {
    let clientId;
    const scope = 'openid%20email';

    const nonce = generateRandomString(11);
    const state = generateRandomString(11);

    let frejaUrl: string;
    let redirectUrl: string;

    if (window.location.href.includes('test')) {
      frejaUrl = FREJA_EID_PROD_ENV_URL;
      redirectUrl = DIGIJAG_TEST_REDIRECT_URI;
      clientId = 'oidc-prod-digijag';
    } else if (window.location.href.includes('localhost')) {
      frejaUrl = FREJA_EID_TEST_ENV_URL;
      redirectUrl = DIGIJAG_LOCALHOST_REDIRECT_URI;
      clientId = 'oidc-digijag';
    } else {
      frejaUrl = FREJA_EID_PROD_ENV_URL;
      redirectUrl = DIGIJAG_PROD_REDIRECT_URI;
      clientId = 'oidc-prod-digijag';
    }

    return (
      frejaUrl +
      '?response_type=code' +
      '&scope=' +
      scope +
      '&client_id=' +
      clientId +
      '&nonce=' +
      nonce +
      '&state=' +
      state +
      '&redirect_uri=' +
      redirectUrl +
      '&min_registration_level=BASIC'
    );
  };

  const handleClick = () => {
    // Store authMode in local storage
    window.localStorage.setItem(FREJA_AUTH_MODE_KEY, authMode);
    // Store subdomain in local storage
    window.localStorage.setItem(FREJA_SUBDOMAIN_KEY, getSubdomain());
    // Set local state to storageDone
    window.location.href = getFrejaAuthenticationURI();
    // Do app specific logic:
    props.onClick && props.onClick();
  };

  const getSubdomain = () => window.location.host.split('.')[0];

  return (
    <button
      onClick={handleClick}
      style={{ margin: 0 }}
      className="frejaeid-nm-button frejaeid-button-White frejaeid-medium frejaeid-button-small frejaeid-button-basic"
    >
      <span className="frejaeid-label-wrapper">
        {labelBeforeLogo}
        <span className="frejaeid-logo" style={{ marginLeft: 5 }}>
          FREJA
        </span>
        <span className="frejaeid-logo-sufix" />
      </span>
    </button>
  );
}

export default FrejaButton;
