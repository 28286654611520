import React, { Component } from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';

interface IMainSectionProps {
  classes: Classes;
}

const MainSectionStyles = createStyles(({ breakpoints }: Theme) => ({
  root: {
    display: 'flex',
    minHeight: 'calc(var(--vh, 1vh) * 100)',
    paddingTop: '6.5rem',
    backgroundImage: 'url(content/images/bg_home.svg)',
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [breakpoints.down('sm')]: {
      backgroundImage: 'none'
    }
  }
}));

class MainSection extends Component<IMainSectionProps> {
  render() {
    const { classes, children } = this.props;
    return (
      <section id="main" className={classes.root}>
        {children}
      </section>
    );
  }
}

export default withStyles(MainSectionStyles)(MainSection);
