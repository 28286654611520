import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DialogBackButton from '../DialogBackButton';
import { IWeek } from 'app/shared/model/fixatiden/week.model';
import { IDay } from 'app/shared/model/fixatiden/day.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IDefaultActivitiesDialogProps {
  open: boolean;
  timePlan: IWeek | IDay;
  onClose: any;
  onSubmit: (response: boolean) => any;
  noBackdrop?: boolean;
}

const isDay = (plan: IDay | IWeek): boolean => 'activities' in plan;

function DefaultActivitiesDialog(props: IDefaultActivitiesDialogProps) {
  const { open, timePlan, onClose, onSubmit, noBackdrop } = props;

  const handleSubmit = (response: boolean) => e => {
    e.preventDefault();
    onSubmit(response);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="default-activities-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        BackdropProps={{ open: !noBackdrop }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="default-activities-dialog-title">
          <FontAwesomeIcon icon={(timePlan && isDay(timePlan) ? 'day-sun' : 'week-sun') as IconProp} />
          Hur vill du ha det?
        </DialogTitle>
        <DialogActions>
          <Button disableFocusRipple onClick={handleSubmit(false)} startIcon={<FontAwesomeIcon icon="expand" />} fullWidth>
            {timePlan && isDay(timePlan) ? 'Börja med en tom dag' : 'Börja med tomma dagar'}
          </Button>
          <Button
            disableFocusRipple
            onClick={handleSubmit(true)}
            startIcon={<img src="content/icons/default-activities.svg" className="svg-inline--fa" alt="" />}
            fullWidth
          >
            {timePlan && isDay(timePlan) ? (
              <>
                Börja med en dag som har
                <br />
                frukost, lunch, middag och sömn
              </>
            ) : (
              <>
                Börja med dagar som har
                <br />
                frukost, lunch, middag och sömn
              </>
            )}
          </Button>
          <DialogBackButton disableFocusRipple onClick={onClose} startIcon={<FontAwesomeIcon icon="arrow-circle-left" />} fullWidth>
            Tillbaka
          </DialogBackButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DefaultActivitiesDialog;
