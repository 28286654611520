import { Moment } from 'moment';

export interface IInvitation {
  id?: number;
  token?: string;
  createdDate?: Moment;
  helperEmail?: string;
  studentId?: number;
}

export const defaultValue: Readonly<IInvitation> = {};
