import React from 'react';
import { createStyles, Theme, Slider, withStyles } from '@material-ui/core';
import { Classes } from 'jss';

export const CustomSliderStyles = ({ breakpoints }: Theme) => createStyles({});

interface ICustomSliderProps {
  classes: Classes;
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  handleChange?: Function;
}

export class CustomSlider extends React.Component<ICustomSliderProps> {
  onSliderChange = (ev, value) => {
    const { handleChange } = this.props;
    window.requestAnimationFrame(() => handleChange(value));
  };

  render() {
    const { value, min, max, step, classes } = this.props;

    return <Slider value={value || 0} min={min} max={max} step={step} onChange={this.onSliderChange} />;
  }
}

export default withStyles(CustomSliderStyles)(CustomSlider);
