import React from 'react';
import { createStyles, withStyles, Button } from '@material-ui/core';
import { Classes } from 'jss';
import { ButtonProps } from '@material-ui/core/Button';

const RedButtonStyles = () =>
  createStyles({
    redButton: {
      padding: '1rem 1.25rem',
      border: '2px solid rgba(255, 255, 255, 0)',
      backgroundColor: '#9a1750',
      borderRadius: '6.25rem',
      textTransform: 'none',
      fontSize: '1.0625rem',
      fontWeight: 400,
      color: 'white',
      '&:hover, &:active, &:focus': {
        backgroundColor: 'black',
        borderColor: 'white',
        color: 'white'
      },
      '& .svg-inline--fa': {
        height: '1.5625rem',
        width: 'auto',
        marginRight: '.625rem'
      }
    }
  });

export interface IRedButtonProps extends ButtonProps {
  classes: Classes;
  component?: React.ElementType;
}

export class RedButton extends React.Component<IRedButtonProps> {
  public render() {
    const { classes, children, className, ...others } = this.props;
    return (
      <Button className={`${classes.redButton} ${className || ''}`} {...others}>
        {children}
      </Button>
    );
  }
}

export default withStyles(RedButtonStyles)(RedButton);
