import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBudget, sampleBudgets } from 'app/shared/model/budgettavlan/budget.model';

interface ICreateSampleBudgetDialogProps {
  open: boolean;
  onClose: any;
  onSubmit: any;
}

export class CreateSampleBudgetDialog extends React.Component<ICreateSampleBudgetDialogProps> {
  handleOnClick = (sampleBudget: IBudget) => () => this.props.onSubmit(sampleBudget);

  render() {
    const { open, onClose, onSubmit } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="budget-dialog-title" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="budget-dialog-title">
          <FontAwesomeIcon icon="sack-dollar" />
          Välj ett färdigt exempel
        </DialogTitle>

        <DialogContent>
          {sampleBudgets.map((sampleBudget: IBudget, idx: number) => (
            <Box mt={idx > 0 ? 4 : 0} key={`new-sample-budget-${idx}`}>
              <Button variant="contained" onClick={this.handleOnClick(sampleBudget)} fullWidth>
                {sampleBudget.name}
              </Button>
            </Box>
          ))}
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateSampleBudgetDialog;
