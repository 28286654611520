import React from 'react';

interface IPageTitleProps {
  title?: string;
}

export class PageTitle extends React.Component<IPageTitleProps> {
  defaultTitle = 'DigiJag';

  componentDidMount() {
    const { title } = this.props;
    document.title = title ? `${this.defaultTitle} - ${title}` : this.defaultTitle;
  }

  componentWillUnmount() {
    document.title = this.defaultTitle;
  }

  render() {
    return null;
  }
}
