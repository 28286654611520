import * as React from 'react';
import { withStyles, createStyles, Theme, Button, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { scenarios } from 'app/shared/model/budgettavlan/expense.model';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const BudgetCardHeaderTitleStyles = (theme: Theme) =>
  createStyles({
    btNewExpense: {
      margin: '.5rem 1rem .5rem 0',
      verticalAlign: 'middle',
      '&:hover': {
        color: '#FFFFFF',
        background: theme.palette.primary.main,
        '& svg': {
          color: '#FFFFFF'
        }
      }
    },
    scenarioIcon: {
      marginRight: '.5rem'
    },
    scenarioLabel: {
      margin: '0 1rem 0 0',
      border: '2px solid #000000',
      borderRadius: 10,
      padding: '5px 10px',
      boxShadow: '0px 3px 6px #00000029'
    },
    scenarioCheckbox: {
      padding: '0 0 0 40px'
    }
  });

interface IBudgetCardHeaderTitleProps {
  classes: Classes;
  onCreateExpense: any;
  selectedScenarios: string[];
  onChangeScenario: any;
}

export class BudgetCardHeaderTitle extends React.Component<IBudgetCardHeaderTitleProps> {
  render() {
    const { classes, onCreateExpense, onChangeScenario, selectedScenarios } = this.props;

    return (
      <>
        <Button onClick={onCreateExpense} startIcon={<FontAwesomeIcon icon="plus-circle" />} size="small" className={classes.btNewExpense}>
          Ny kostnad
        </Button>

        <Box whiteSpace="nowrap" display="inline-block">
          {Object.values(scenarios).map(scenario => (
            <FormControlLabel
              key={scenario.expense.scenarioId}
              className={classes.scenarioLabel}
              control={
                <Checkbox
                  checked={selectedScenarios.indexOf(scenario.expense.scenarioId) !== -1}
                  onChange={onChangeScenario}
                  name={scenario.expense.scenarioId}
                  className={classes.scenarioCheckbox}
                  color="primary"
                />
              }
              label={
                <>
                  <FontAwesomeIcon icon={scenario.expense.icon as IconProp} className={classes.scenarioIcon} />
                  {scenario.expense.name}
                </>
              }
              labelPlacement="start"
            />
          ))}
        </Box>
      </>
    );
  }
}

export default withStyles(BudgetCardHeaderTitleStyles)(BudgetCardHeaderTitle);
