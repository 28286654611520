import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { Classes } from 'jss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

const AdminSideBarItemStyles = ({ breakpoints }: Theme) =>
  createStyles({
    sidebarItem: {
      width: '100%'
    },

    sidebarItemActive: {
      backgroundColor: '#790f3d'
    },

    sidebarBtnRoot: {
      color: '#fff',
      width: '100%',
      padding: '2.5rem',
      fontSize: '1.25rem',
      textTransform: 'none',
      letterSpacing: '0.98px',
      justifyContent: 'flex-start',
      '&:hover': {
        backgroundColor: '#55112e'
      },
      [breakpoints.down('md')]: {
        padding: '1.5rem'
      }
    },

    sidebarBtnLabel: {
      textAlign: 'left'
    },

    sidebarBtnIcon: {
      width: '2.1875rem',
      marginRight: '1rem'
    }
  });

export interface IAdminSideBarItemData {
  icon: string;
  label: string;
  url: string;
}

interface IAdminSideBarItemProps {
  classes: Classes;
  itemData: IAdminSideBarItemData;
  active: boolean;
}

export const AdminSideBarItem: React.SFC<IAdminSideBarItemProps> = ({ classes, itemData, active }) => (
  <Grid container direction="row" justify="flex-start" className={classes.sidebarContainer}>
    <Grid
      item
      className={classNames({
        [classes.sidebarItem]: true,
        [classes.sidebarItemActive]: active
      })}
    >
      <NavLink to={itemData.url}>
        <Button
          classes={{
            root: classes.sidebarBtnRoot,
            label: classes.sidebarBtnLabel
          }}
        >
          <img src={`content/icons/${itemData.icon}.svg`} alt="" className={classes.sidebarBtnIcon} />
          {itemData.label}
        </Button>
      </NavLink>
    </Grid>
  </Grid>
);

export default withStyles(AdminSideBarItemStyles)(AdminSideBarItem as any);
