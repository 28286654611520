import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { Classes } from 'jss';
import SrOnly from 'app/custom-components/sronly/SrOnly';
import ImageInput from 'app/custom-components/form-inputs/imageInput';

const ActivityFormImageStepStyles = (theme: Theme) => createStyles({});

interface IActivityFormImageStepProps {
  classes: Classes;
  imgUrl: string;
  onChange: any;
  isAuthenticated?: boolean;
}

function ActivityFormImageStep(props: React.PropsWithChildren<IActivityFormImageStepProps>) {
  const { imgUrl, onChange, isAuthenticated } = props;

  return (
    <>
      <SrOnly>Steg 2 av 5</SrOnly>

      <ImageInput id="imgUrl" imgUrl={imgUrl} handleChange={onChange} required={false} offline={!isAuthenticated} originalRatio borderedPlaceholder />
    </>
  );
}

export default withStyles(ActivityFormImageStepStyles)(ActivityFormImageStep);
