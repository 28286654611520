import React, { Component } from 'react';
import { Grid, Typography, createStyles, withStyles, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { Classes } from 'jss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { updateEntity, getEntitiesByCommunity } from 'app/entities/app/app.reducer';
import { getAllEntities as getAllCommunities, updateEntity as updateCommunity, getEntity as getCommunity } from 'app/entities/community/community.reducer';
import { Translate, translate } from 'react-jhipster';
import { categories, NO_CATEGORY_LABEL } from 'app/shared/model/category';
import CustomButton from '../form-inputs/CustomButton';

const MyAppsListStyles = () =>
  createStyles({
    altContent: {
      marginTop: '30vh'
    },
    category: {
      minWidth: 120,
      marginTop: -14
    },
    appCheckboxes: {
      display: 'flex'
    },
    padding: {
      padding: '2rem 0'
    },
    checkboxContainer: {
      width: '25%'
    },
    buttonTitle: {
      fontWeight: 'bold',
      color: '#ffffff',
      marginLeft: 5
    }
  });
interface IMyCommunityAppsProps extends StateProps, DispatchProps, RouteComponentProps {
  classes: Classes;
}
interface IMyCommunityAppsState {
  group?: number;
  apps?: number[];
}
export class MyCommunityApps extends Component<IMyCommunityAppsProps, IMyCommunityAppsState> {
  readonly state = {
    group: null,
    apps: []
  };

  componentDidMount = () => {
    if (!this.props.loading) {
      this.loadInitialData();
    }
  };

  loadInitialData = () => {
    this.props.getAllCommunities();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      this.loadInitialData();
    }
    if (prevProps.updating !== this.props.updating && !this.props.updating && !this.props.loading) {
      this.props.getEntitiesByCommunity(this.props.communityEntity.id);
    }
  };

  getAlreadySavedApps = () => {
    this.setState({ apps: [] });
    this.props.communityEntity.apps.map(communityApp => {
      this.setState({
        apps: [...this.state.apps, communityApp.id]
      });
    });
  };

  groupsFilter = async event => {
    await this.props.getEntitiesByCommunity(event.target.value);
    await this.props.getCommunity(event.target.value);
    this.getAlreadySavedApps();
    this.setState({
      group: event.target.value
    });
  };

  addToCommunityApps = event => {
    event.target.checked
      ? this.setState({
          apps: [...this.state.apps, Number(event.target.value)]
        })
      : this.setState({ apps: this.state.apps.filter(app => app !== Number(event.target.value)) });
  };

  saveCommunityApps = async () => {
    const apps = [];
    this.state.apps.map(appId => apps.push({ id: appId }));
    await this.props.updateCommunity({
      ...this.props.communityEntity,
      id: this.state.group,
      name: this.props.communityEntity.name,
      apps
    });
    this.getAlreadySavedApps();
  };

  appsByCategory = category => this.props.appEntities.filter(app => app[category] === true);

  otherCategory = () =>
    this.props.appEntities.filter(
      app =>
        app['categoryEconomy'] === false &&
        app['categoryGame'] === false &&
        app['categoryKids'] === false &&
        app['categoryFood'] === false &&
        app['categoryNews'] === false &&
        app['categorySchool'] === false &&
        app['categoryVideo'] === false &&
        app['categoryHealth'] === false &&
        app['categorySchool2'] === false &&
        app['categorySociety'] === false
    );

  render() {
    const { classes, communities, appEntities } = this.props;
    return (
      <>
        <Grid container direction="row" justify="flex-start">
          <Grid item xs={6}>
            <Typography component="h1" variant="body1">
              <img src="content/icons/information.svg" alt="" />
              &nbsp;
              <strong>
                <Translate contentKey="digijagApp.app.communityApps.information" />
              </strong>
            </Typography>
          </Grid>
          <Grid className={classes.padding} container>
            <FormControl className={classes.category}>
              <InputLabel htmlFor="groups-filter">
                <Translate contentKey="digijagApp.appStore.group">Grupp</Translate>
              </InputLabel>
              <Select
                displayEmpty
                variant="outlined"
                onChange={this.groupsFilter}
                value={this.state.group || ''}
                inputProps={{
                  name: 'groups-filter',
                  id: 'groups-filter'
                }}
              >
                <MenuItem key="default-value" value="" />
                {communities.map((community, index) => (
                  <MenuItem key={index} value={community.id}>
                    {community.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {this.props.appLoading ? (
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.altContent} align="center" color="inherit">
                <Translate contentKey="register.form.loading" />
              </Typography>
            </Grid>
          ) : !appEntities || appEntities.length === 0 || !this.state.group ? (
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.altContent} align="center">
                <Translate contentKey="digijagApp.app.myApps.noApps">Inga appar hittades.</Translate>
              </Typography>
            </Grid>
          ) : (
            <Grid container justify="flex-start">
              {categories.map(
                category =>
                  this.appsByCategory(category.category).length > 0 && (
                    <Grid key={'category-' + category.label} item className={classes.checkboxContainer}>
                      <Typography component="h2" variant="h6">
                        {translate(category.label)}
                      </Typography>
                      {this.appsByCategory(category.category).map(app => (
                        <FormControlLabel
                          key={'app-' + app.id}
                          className={classes.appCheckboxes}
                          control={<Checkbox checked={this.state.apps.includes(app.id) ? true : false} value={app.id} onChange={this.addToCommunityApps} />}
                          label={app.name}
                        />
                      ))}
                    </Grid>
                  )
              )}
              <Grid item className={classes.checkboxContainer}>
                <Typography component="h2" variant="h6">
                  {translate(NO_CATEGORY_LABEL)}
                </Typography>
                {this.otherCategory().map(app => (
                  <FormControlLabel
                    key={'app-' + app.id}
                    classes={{ root: classes.root }}
                    className={classes.appCheckboxes}
                    control={<Checkbox checked={this.state.apps.includes(app.id) ? true : false} value={app.id} onChange={this.addToCommunityApps} />}
                    label={app.name}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          <CustomButton onClick={this.saveCommunityApps} color="primary">
            <img src={`content/icons/spara-red.svg`} alt="" />
            <Typography className={classes.buttonTitle}>
              <Translate contentKey="digijagApp.app.communityApps.save" />
            </Typography>
          </CustomButton>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ userData, app, community }: IRootState) => ({
  currentUserData: userData.entity,
  loading: userData.loading,
  appLoading: app.loading,
  updating: app.updating,
  appEntities: app.entities,
  communities: community.entities,
  communityEntity: community.entity
});

const mapDispatchToProps = {
  updateEntity,
  getAllCommunities,
  getEntitiesByCommunity,
  updateCommunity,
  getCommunity
};

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(MyAppsListStyles)(MyCommunityApps));
