import React, { useEffect, useRef, useState } from 'react';
import { withStyles, createStyles, Theme, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IActivity } from 'app/shared/model/fixatiden/activity.model';
import { secondsToHourMinute } from 'app/shared/util/date-time-utils';
import ActivityFormStep1 from './ActivityFormStep1';
import ActivityFormStep2 from './ActivityFormStep2';
import ActivityFormStep3 from './ActivityFormStep3';
import ActivityFormStep4 from './ActivityFormStep4';
import ActivityFormButtons from './ActivityFormButtons';
import { convertPxToRem } from 'app/shared/util/number-utils';
import { baseFontSize } from '../theme';
import ActivityFormImageStep from './ActivityFormImageStep';

const ActivityFormStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: `calc(100vh - ${convertPxToRem(80, baseFontSize)})`,
      alignItems: 'stretch',
      backgroundColor: '#293A5F',
      color: '#fff'
    },
    formStep: {
      padding: '1rem',
      '&:focus': {
        border: 'none'
      }
    },
    stepsList: {
      position: 'sticky',
      top: 80,
      zIndex: 100
    },
    stepListItem: {
      background: '#E5E9F7 !important',
      padding: '.8rem 1.2rem !important',
      '&.Mui-selected': {
        background: '#293A5F !important'
      }
    }
  });

interface IActivityFormProps {
  classes: Classes;
  activityName: string;
  imgUrl: string;
  onChangeActivityName: any;
  onChangeActivityImgUrl: any;
  startTime: number;
  onChangeStartTime: any;
  endTime: number;
  onChangeEndTime: any;
  extraStartTime: number;
  onChangeextraStartTime: any;
  extraEndTime: number;
  onChangeextraEndTime: any;
  isAuthenticated: boolean;
  onSubmit: any;
  onCancel: any;
  editMode?: boolean;
}

interface IActivityFormState {
  noExtraTime: boolean;
  activeStep: number;
}

function ActivityForm(props: React.PropsWithChildren<IActivityFormProps>) {
  const {
    classes,
    activityName,
    imgUrl,
    onChangeActivityName,
    onChangeActivityImgUrl,
    startTime,
    onChangeStartTime,
    endTime,
    onChangeEndTime,
    extraStartTime,
    onChangeextraStartTime,
    extraEndTime,
    onChangeextraEndTime,
    isAuthenticated,
    onSubmit,
    onCancel,
    editMode
  } = props;

  const [noExtraTime, setNoExtraTime] = useState<IActivityFormState['noExtraTime']>(false);
  const [activeStep, setActiveStep] = useState<IActivityFormState['activeStep']>(0);

  const formEl = useRef<null | HTMLFormElement>(null);

  useEffect(
    () => {
      if (formEl.current) {
        const stepContainer = formEl.current.querySelector(':scope > div');
        if (stepContainer) {
          // @ts-ignore
          stepContainer.focus({ preventScroll: true });
        }
      }
    },
    [formEl, activeStep]
  );

  const handleNextStep = () => {
    if (activeStep > 3) return;
    setActiveStep(activeStep + 1);
    setTimeout(() => document.querySelector('[class*="SideBar-drawerPaper"]').scrollTo(0, 0), 50);
  };

  const handleSubmit = () => {
    const newActivity: IActivity = {
      name: activityName,
      imgUrl,
      icon: 'clock-solid',
      startTime,
      endTime,
      extraStartTime: !noExtraTime ? extraStartTime : null,
      extraEndTime: !noExtraTime ? extraEndTime : null,
      conflict: false
    };

    onSubmit(newActivity);
  };

  const handleFormSubmit = ev => {
    ev.preventDefault();
    switch (activeStep) {
      case 0:
      case 1:
      case 2:
      case 3:
        handleNextStep();
        break;
      default:
        handleSubmit();
    }
  };

  const stepIcons: IconProp[] = [['far', 'sticky-note'], 'image', 'clock', 'clock'];

  const editLabels: string[] = ['Redigera namm', 'Redigera bilden (valfritt)', 'Redigera start- och sluttid', 'Redigera mellantider'];

  return (
    <>
      <form ref={formEl} tabIndex={-1} onSubmit={handleFormSubmit} className={classes.root}>
        <List className={classes.stepsList}>
          {new Array(activeStep < 4 ? activeStep + 1 : 4).fill(null).map((_, idx) => (
            <ListItem
              key={`prev-step-${idx}`}
              selected={activeStep === idx}
              classes={{
                root: classes.stepListItem
              }}
            >
              <ListItemIcon style={{ color: activeStep === idx ? '#FFF' : '#3B538B' }}>
                <FontAwesomeIcon icon={stepIcons[idx]} />
              </ListItemIcon>
              <ListItemText style={{ color: activeStep === idx ? '#FFF' : '#000' }}>
                {activeStep === idx
                  ? ['1. Skriv ett namn för aktiviteten', '2. Ladda upp en bild (valfritt)', '3. Välj start- och sluttid', '4. Lägg till mellantider'][idx]
                  : [activityName, 'Ändra bild', `${secondsToHourMinute(startTime)} - ${secondsToHourMinute(endTime)}`, 'Ändra mellantider'][idx]}
              </ListItemText>
              {activeStep !== idx && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label={editLabels[idx]} color="secondary" onClick={() => setActiveStep(idx)}>
                    <FontAwesomeIcon icon="pen-square" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>

        {
          [
            <>
              <div className={classes.formStep} tabIndex={0}>
                <ActivityFormStep1 activityName={activityName} onChangeActivityName={onChangeActivityName} />
              </div>
              <ActivityFormButtons
                submitButtonIcon="thumbs-up"
                cancelButtonIcon="times-circle"
                submitButtonText="Okej"
                cancelButtonText="Avbryt"
                onSubmit={handleNextStep}
                onCancel={onCancel}
                disabled={!activityName.trim()}
              />
            </>,
            <>
              <div className={classes.formStep} tabIndex={0}>
                <ActivityFormImageStep imgUrl={imgUrl} onChange={onChangeActivityImgUrl} isAuthenticated={isAuthenticated} />
              </div>
              <ActivityFormButtons
                submitButtonIcon="thumbs-up"
                cancelButtonIcon="times-circle"
                submitButtonText="Okej"
                cancelButtonText="Avbryt"
                onSubmit={handleNextStep}
                onCancel={onCancel}
              />
            </>,
            <>
              <div className={classes.formStep} tabIndex={0}>
                <ActivityFormStep2 startTime={startTime} onChangeStartTime={onChangeStartTime} endTime={endTime} onChangeEndTime={onChangeEndTime} />
              </div>
              <ActivityFormButtons
                submitButtonIcon="thumbs-up"
                cancelButtonIcon="times-circle"
                submitButtonText="Okej"
                cancelButtonText="Avbryt"
                onSubmit={handleNextStep}
                onCancel={onCancel}
              />
            </>,
            <>
              <div className={classes.formStep} tabIndex={0}>
                <ActivityFormStep3
                  noExtraTime={noExtraTime}
                  onChangeNoExtraTime={() => setNoExtraTime(!noExtraTime)}
                  extraStartTime={extraStartTime}
                  onChangeextraStartTime={onChangeextraStartTime}
                  extraEndTime={extraEndTime}
                  onChangeextraEndTime={onChangeextraEndTime}
                />
              </div>
              <ActivityFormButtons
                submitButtonIcon="thumbs-up"
                cancelButtonIcon="times-circle"
                submitButtonText="Okej"
                cancelButtonText="Avbryt"
                onSubmit={handleNextStep}
                onCancel={onCancel}
              />
            </>,
            <>
              <div className={classes.formStep} tabIndex={0}>
                <ActivityFormStep4
                  activity={{
                    name: activityName,
                    imgUrl,
                    icon: 'clock-solid',
                    startTime,
                    endTime,
                    extraStartTime: !noExtraTime ? extraStartTime : null,
                    extraEndTime: !noExtraTime ? extraEndTime : null
                  }}
                />
              </div>
              <ActivityFormButtons
                submitButtonIcon="plus-circle"
                cancelButtonIcon="times-circle"
                submitButtonText={editMode ? 'Uppdatera aktiviteten till schemat' : 'Lägg till aktiviteten i schemat'}
                cancelButtonText="Avbryt"
                onSubmit={handleSubmit}
                onCancel={onCancel}
              />
            </>
          ][activeStep]
        }

        {activeStep < 3 && (
          <List>
            {new Array(3 - activeStep).fill(null).map((_, idx) => (
              <ListItem key={`next-step-${idx}`} selected>
                <ListItemIcon>
                  <FontAwesomeIcon icon={stepIcons[idx + activeStep + 1]} />
                </ListItemIcon>
                <ListItemText>{['2. Ladda upp en bild (valfritt)', '3. Välj start- och sluttid', '4. Lägg till mellantider'][idx + activeStep]}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
      </form>
    </>
  );
}

export default withStyles(ActivityFormStyles)(ActivityForm);
