import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, FormLabel } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomVoiceInput from '../../../shared-components/CustomVoiceInput';
import ImageInput from 'app/custom-components/form-inputs/imageInput';
import { IExpense } from 'app/shared/model/budgettavlan/expense.model';

interface ICreateExpenseDialogProps {
  open: boolean;
  onClose: any;
  value: IExpense;
  handleChange: any;
  onSubmit: any;
  isAuthenticated?: boolean;
}

export class CreateExpenseDialog extends React.Component<ICreateExpenseDialogProps> {
  render() {
    const { open, onClose, value, handleChange, onSubmit, isAuthenticated } = this.props;

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="budget-dialog-title" maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
        <DialogTitle id="budget-dialog-title">
          <FontAwesomeIcon icon="plus-circle" />
          Ny kostnad
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit}>
            <div>
              <FormLabel htmlFor="expenseName">Namn:</FormLabel>
              <CustomVoiceInput id="expenseName" placeholder="Namn" value={value.name} handleChange={name => handleChange({ ...value, name })} />
            </div>
            <Box mt={4}>
              <FormLabel htmlFor="imgUrl">Bild:</FormLabel>
              <ImageInput
                id="imgUrl"
                imgUrl={value.imgUrl}
                handleChange={imgUrl => handleChange({ ...value, imgUrl })}
                required={false}
                offline={!isAuthenticated}
                originalRatio
                borderedPlaceholder
              />
            </Box>
          </form>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" onClick={onSubmit} color="primary" disabled={value.name.length === 0} startIcon={<FontAwesomeIcon icon="thumbs-up" />}>
            Okej
          </Button>
          <Button variant="contained" onClick={onClose} color="default" startIcon={<FontAwesomeIcon icon="times-circle" />}>
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateExpenseDialog;
