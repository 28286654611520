import React, { RefObject, ClassAttributes } from 'react';
import { RootRef, Theme, createStyles, withStyles } from '@material-ui/core';
import { Classes } from 'jss';
import { Input } from 'reactstrap';
import SpeechToTextButton from './SpeechToTextButton';

export const VoiceInputStyles = ({ breakpoints }: Theme) =>
  createStyles({
    inputDiv: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
      padding: 1,
      borderRadius: 10
    },
    formInput: {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      backgroundColor: '#e4e4e4',
      height: 45,
      [breakpoints.down('xs')]: {
        height: 40
      }
    },
    speechToTextButton: {
      height: 'auto',
      width: 45,
      padding: 0,
      backgroundColor: '#ffffff',
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    },
    borderRadius: {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10
    }
  });

interface IVoiceInputProps {
  classes: Classes;
  type?: string;
  id?: string;
  value?: string;
  autoFocus?: boolean;
  required?: boolean;
  valid?: boolean;
  buttonLabel?: any;
  extra?: any;
  handleChange?(value: string, isSpoken: boolean): any;
}

export class VoiceInput extends React.Component<IVoiceInputProps> {
  inputRef = React.createRef<HTMLInputElement>();

  setFocus = (domElement: RefObject<HTMLElement>) => {
    const node = domElement.current;
    if (node) {
      node.focus();
    }
  };

  setInputValue = (domElement: RefObject<HTMLElement>, value: string) => {
    const node = domElement.current;
    if (node) {
      node.setAttribute('value', value);
    }
  };

  getSpokenWord = (value: string) => {
    const { handleChange } = this.props;
    const { inputRef, setFocus, setInputValue } = this;
    if (handleChange) {
      handleChange(value, true);
    } else {
      setInputValue(inputRef, value);
    }
    setFocus(inputRef);
  };

  onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { handleChange } = this.props;
    if (handleChange) {
      handleChange(e.currentTarget.value, false);
    }
  };

  render() {
    const { classes, type, id, autoFocus, required, value, buttonLabel, extra, valid } = this.props;
    const { inputRef, getSpokenWord, onChange } = this;
    return (
      <div className={classes.inputDiv}>
        <RootRef rootRef={inputRef}>
          <Input
            id={id}
            type={type}
            className={'webkitSpeechRecognition' in window ? classes.formInput : `${classes.formInput} ${classes.borderRadius}`}
            onChange={onChange}
            valid={valid}
            invalid={valid !== undefined && !valid}
            value={value}
            autoFocus={autoFocus}
            required={required}
            {...extra}
          />
        </RootRef>
        <SpeechToTextButton onSpeechEnd={getSpokenWord} className={classes.speechToTextButton}>
          {buttonLabel || <img src="content/icons/microphone.svg" alt="" />}
        </SpeechToTextButton>
      </div>
    );
  }
}

export default withStyles(VoiceInputStyles)(VoiceInput);
