import React, { Component } from 'react';
import { Classes } from 'jss';
import { ICommunity } from 'app/shared/model/community.model';
import {
  Theme,
  createStyles,
  DialogContent,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
  withStyles
} from '@material-ui/core';
import DigiJagConfirmationDialog from '../base-dialogs/DigiJagConfirmationDialog';
import { Translate, translate } from 'react-jhipster';
const ZoomUserOwnerDialogStyles = ({ breakpoints }: Theme) =>
  createStyles({
    dialogContent: {
      padding: '0 5rem'
    },
    dialogTitle: {
      fontSize: '1.5625rem',
      fontWeight: 'bold',
      letterSpacing: '1.23px',
      marginBottom: '2.5rem'
    },
    dialogText: {
      marginBottom: '2.5rem'
    },
    globalText: {
      marginTop: '2.5rem'
    },
    formControl: {
      width: '100%'
    },
    multiselect: {
      '&:focus': {
        background: 'none'
      }
    },
    multiselectInput: {
      padding: '.5rem',
      background: '#e4e4e4',
      boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
      borderRadius: 10,
      '&:hover::before': {
        border: 'none !important'
      }
    },
    multiselectInputUnderline: {
      '&::before': {
        border: 'none !important'
      },
      '&::after': {
        border: 'none !important'
      }
    }
  });

interface IZoomUserOwnerDialogProps {
  classes: Classes;
  communities: ICommunity[];
  selectedCommunity: ICommunity;
  isGlobal: boolean;
  handleIsGlobalChange: any;
  handleCloseDialog: any;
  onCreateZoomUser: any;
  handleCommunitiesChange: any;
  dialogOpen: boolean;
}

interface IZoomUserOwnerDialogState {
  confirmDialogOpen: boolean;
}

export class ZoomUserOwnerDialog extends Component<IZoomUserOwnerDialogProps, IZoomUserOwnerDialogState> {
  readonly state = {
    confirmDialogOpen: false
  };

  onCreate = () => {
    const { onCreateZoomUser } = this.props;
    this.setState({ confirmDialogOpen: false }, onCreateZoomUser);
  };

  validateAndCreate = () => {
    const { handleCloseDialog, isGlobal, selectedCommunity } = this.props;

    const alert1 = translate('digijagApp.zoomMeeting.userOwnerDialog.alert1');
    const alert2 = translate('digijagApp.zoomMeeting.userOwnerDialog.alert2');

    if (!isGlobal && !selectedCommunity) {
      alert(alert1);
      return;
    } else if (!isGlobal && !selectedCommunity.zoomApiKey) {
      alert(alert2);
      return;
    }
    this.setState({ confirmDialogOpen: true }, handleCloseDialog);
  };

  render() {
    const { confirmDialogOpen } = this.state;
    const {
      classes,
      communities,
      selectedCommunity,
      isGlobal,
      handleIsGlobalChange,
      handleCloseDialog,
      handleCommunitiesChange,
      dialogOpen
    } = this.props;

    return (
      <>
        <DigiJagConfirmationDialog
          isOpen={confirmDialogOpen}
          icon="plus-big"
          text={translate('digijagApp.zoomMeeting.userOwnerDialog.confirmationDialog.text')}
          acceptButtonText={translate('digijagApp.zoomMeeting.userOwnerDialog.confirmationDialog.acceptBtn')}
          declineButtonText={translate('digijagApp.zoomMeeting.userOwnerDialog.confirmationDialog.declineBtn')}
          onClose={() => this.setState({ confirmDialogOpen: false })}
          onConfirm={this.onCreate}
        />

        <DigiJagConfirmationDialog
          isOpen={dialogOpen}
          onClose={handleCloseDialog}
          onConfirm={this.validateAndCreate}
          acceptButtonIcon="send-form"
          acceptButtonText={translate('digijagApp.zoomMeeting.userOwnerDialog.createUser')}
          declineButtonText={translate('digijagApp.zoomMeeting.userOwnerDialog.confirmationDialog.declineBtn')}
        >
          <DialogContent className={classes.dialogContent}>
            <Typography variant="h2" align="center" className={classes.dialogTitle}>
              <Translate contentKey="digijagApp.zoomMeeting.userOwnerDialog.createUser" />
            </Typography>
            <Typography variant="body1" align="inherit" className={classes.dialogText}>
              <Translate contentKey="digijagApp.zoomMeeting.userOwnerDialog.group" />
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                value={selectedCommunity}
                onChange={handleCommunitiesChange}
                inputProps={{ 'aria-label': translate('digijagApp.zoomMeeting.userOwnerDialog.chooseGroup') }}
                displayEmpty
                autoFocus
                disabled={isGlobal}
              >
                {communities.map((community, idx) => (
                  <MenuItem key={idx} value={community as any}>
                    <ListItemText primary={community.displayName ? community.displayName : community.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body1" align="inherit" className={classes.globalText}>
              <Translate contentKey="digijagApp.zoomMeeting.userOwnerDialog.digiGroup" />
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={isGlobal} onChange={handleIsGlobalChange} />}
              label={translate('digijagApp.zoomMeeting.userOwnerDialog.belongToDig')}
            />
          </DialogContent>
        </DigiJagConfirmationDialog>
      </>
    );
  }
}

export default withStyles(ZoomUserOwnerDialogStyles)(ZoomUserOwnerDialog);
