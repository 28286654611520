import * as React from 'react';
import { Card, CardContent, Grid, Button, CardHeader, Box, Theme, createStyles, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VerticalContainer from '../../shared-components/VerticalContainer';
import { Classes } from 'jss';
import { IBudget, sampleBudgets } from 'app/shared/model/budgettavlan/budget.model';

const CreateSampleBudgetPageStyles = (theme: Theme) =>
  createStyles({
    cardForm: {
      margin: '0 auto',
      maxWidth: theme.breakpoints.width('sm'),
      width: '100%'
    }
  });

interface ICreateSampleBudgetPageProps {
  classes: Classes;
  onSubmit: Function;
  onCancel: Function;
}

export class CreateSampleBudgetPage extends React.Component<ICreateSampleBudgetPageProps> {
  handleOnClick = (sampleBudget: IBudget) => () => this.props.onSubmit(sampleBudget);

  onCancel = () => this.props.onCancel();

  render() {
    const { classes } = this.props;

    return (
      <VerticalContainer>
        <Grid item style={{ padding: '0 20px' }}>
          <Card className={classes.cardForm}>
            <CardHeader
              title={
                <>
                  <FontAwesomeIcon icon="sack-dollar" />
                  Välj ett färdigt exempel
                </>
              }
              disableTypography
            />
            <CardContent>
              {sampleBudgets.map((sampleBudget: IBudget, idx: number) => (
                <Box mb={4} key={`new-sample-budget-${idx}`}>
                  <Button variant="contained" onClick={this.handleOnClick(sampleBudget)} fullWidth>
                    {sampleBudget.name}
                  </Button>
                </Box>
              ))}
              <Button variant="contained" onClick={this.onCancel} startIcon={<FontAwesomeIcon icon="arrow-circle-left" />}>
                Tillbaka
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </VerticalContainer>
    );
  }
}

export default withStyles(CreateSampleBudgetPageStyles)(CreateSampleBudgetPage);
