import * as React from 'react';
import { Card, CardContent, Grid, Button, CardHeader, Box, Theme, createStyles, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomVoiceInput from '../../shared-components/CustomVoiceInput';
import VerticalContainer from '../../shared-components/VerticalContainer';
import { Classes } from 'jss';

const CreateBudgetPageStyles = (theme: Theme) =>
  createStyles({
    cardForm: {
      margin: '0 auto',
      maxWidth: theme.breakpoints.width('sm'),
      width: '100%'
    },
    btnSpacing: {
      marginLeft: '1.5rem'
    }
  });

interface ICreateBudgetPageProps {
  classes: Classes;
  defaultName: string;
  onSubmit: Function;
  onCancel: Function;
}

interface ICreateBudgetPageState {
  name: string;
}

export class CreateBudgetPage extends React.Component<ICreateBudgetPageProps, ICreateBudgetPageState> {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.defaultName
    };
  }

  onChangeName = name => {
    this.setState({ name });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.onSubmit({ name: this.state.name });
  };

  onCancel = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onCancel();
  };

  render() {
    const { classes } = this.props;
    const { name } = this.state;

    return (
      <VerticalContainer>
        <Grid item style={{ padding: '0 20px' }}>
          <Card className={classes.cardForm}>
            <CardHeader
              title={
                <>
                  <FontAwesomeIcon icon="sack-dollar" />
                  Skapa din budget
                </>
              }
              disableTypography
            />
            <CardContent>
              <form onSubmit={this.onSubmit}>
                <CustomVoiceInput value={name} handleChange={this.onChangeName} />
                <Box mt={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.onSubmit}
                    startIcon={<FontAwesomeIcon icon="thumbs-up" />}
                    disabled={this.state.name.length === 0}
                  >
                    Okej
                  </Button>

                  <Button variant="contained" className={classes.btnSpacing} onClick={this.onCancel} startIcon={<FontAwesomeIcon icon="arrow-circle-left" />}>
                    Tillbaka
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </VerticalContainer>
    );
  }
}

export default withStyles(CreateBudgetPageStyles)(CreateBudgetPage);
