import React from 'react';
import { IRootState } from 'app/shared/reducers';
import { getMeeting, generateSignature, reset } from 'app/entities/zoom-meeting/zoom-meeting.reducer';
import { getCurrentUserData } from 'app/entities/user-data/user-data.reducer';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ZoomApp from './ZoomApp';
import { IZoomSignatureRequest } from 'app/shared/model/zoom-signature-request.model';
import { Typography } from '@material-ui/core';
import { Translate } from 'react-jhipster';

interface IZoomAppFrameProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

interface IZoomAppFrameState {
  loaded: boolean;
  isHost: boolean;
}

export class ZoomAppFrame extends React.Component<IZoomAppFrameProps, IZoomAppFrameState> {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isHost: false
    };
  }

  loadZoomStyle = () => {
    const headEl = document.getElementsByTagName('head')[0];
    const zoomCss1 = document.createElement('link');
    const zoomCss2 = document.createElement('link');

    zoomCss1.setAttribute('rel', 'stylesheet');
    zoomCss1.setAttribute('type', 'text/css');
    zoomCss1.setAttribute('href', 'https://source.zoom.us/1.9.0/css/bootstrap.css');

    zoomCss2.setAttribute('rel', 'stylesheet');
    zoomCss2.setAttribute('type', 'text/css');
    zoomCss2.setAttribute('href', 'https://source.zoom.us/1.9.0/css/react-select.css');

    headEl.appendChild(zoomCss1);
    headEl.appendChild(zoomCss2);
  };

  componentDidMount = () => {
    this.props.getCurrentUserData();
    this.props.getMeeting(this.props.match.params.id);
  };

  componentWillUnmount = () => {
    this.props.reset();
  };

  componentDidUpdate = prevProps => {
    if (this.state.loaded) {
      return;
    }

    const { loadingZoomMeeting, zoomMeeting, zoomSignature, currentUserData } = this.props;

    if (!loadingZoomMeeting && zoomMeeting && zoomMeeting.id && zoomSignature) {
      this.setState({ loaded: true });
      return;
    }

    if (!loadingZoomMeeting && !zoomSignature && zoomMeeting && zoomMeeting.id && currentUserData && currentUserData.id) {
      const isHost = zoomMeeting.hostId === currentUserData.id;
      this.setState({ isHost });

      if (!isHost && !zoomMeeting.startDate) {
        this.setState({ loaded: true });
        return;
      }

      this.loadZoomStyle();

      const requestData: IZoomSignatureRequest = {
        meetingId: zoomMeeting.id,
        role: isHost ? 1 : 0
      } as any;

      this.props.generateSignature(requestData);
    }
  };

  render() {
    const { account, currentUserData, zoomMeeting, zoomSignature } = this.props;
    const { isHost } = this.state;

    return (
      <>
        {this.state.loaded ? (
          isHost || zoomMeeting.startDate ? (
            <ZoomApp zoomMeeting={zoomMeeting} zoomSignature={zoomSignature} userDataId={currentUserData.id} userName={account.firstName} />
          ) : (
            <Typography variant="body1">
              <Translate contentKey="digijagApp.zoomMeeting.notStarted">This meeting wasn't started yet.</Translate>
            </Typography>
          )
        ) : (
          <Typography variant="body1">
            <Translate contentKey="digijagApp.zoomMeeting.loading">Loading...</Translate>
          </Typography>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ zoom, authentication, userData }: IRootState) => ({
  account: authentication.account,
  loadingZoomMeeting: zoom.loading,
  zoomMeeting: zoom.zoomMeeting,
  zoomSignature: zoom.signature,
  currentUserData: userData.entity
});

const mapDispatchToProps = {
  getCurrentUserData,
  generateSignature,
  getMeeting,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomAppFrame);
