import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Alert } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
// tslint:disable-next-line:no-unused-variable
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getRoles } from 'app/modules/administration/user-management/user-management.reducer';
import { getAllEntities as getAllCommunities } from 'app/entities/community/community.reducer';
import { getAllEntities as getAllOrganizations } from 'app/entities/organization/organization.reducer';
import { getEntity, updateEntity, createEntity, reset } from './user-data.reducer';
// tslint:disable-next-line:no-unused-variable
import { mapIdList } from 'app/shared/util/entity-utils';
import ImageInput from 'app/custom-components/form-inputs/imageInput';
import { setDefaultProfileImage, reset as resetFileUpload } from 'app/shared/reducers/file-upload';
import { APP_EMAIL_LENGTH, APP_USERNAME_LENGTH, AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IUserDataUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IUserDataUpdateState {
  isNew: boolean;
}

export class UserDataUpdate extends React.Component<IUserDataUpdateProps, IUserDataUpdateState> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.resetFileUpload();
    this.props.getEntity(this.props.match.params.id);
    this.props.getRoles();
    this.props.getAllCommunities();
    this.props.getAllOrganizations();
  }

  componentDidUpdate = prevProps => {
    const { updating, updateSuccess } = this.props;
    // When backend response for create/update comes we go from updating to not updating
    if (prevProps.updating && !updating) {
      // We check if update was successfull or not to show error message or close modal.
      if (updateSuccess) {
        this.handleClose();
      } else {
        // Shows error message, see render()
      }
    }
  };

  saveEntity = (event, values) => {
    const { userDataEntity, uploadedImgUrl } = this.props;
    const entity = {
      ...userDataEntity,
      ...values,
      userImageUrl: uploadedImgUrl || userDataEntity.userImageUrl,
      communities: mapIdList(values.communities),
      organizations: mapIdList(values.organizations)
    };
    this.props.updateEntity(entity);
  };

  handleClose = () => {
    this.props.history.push('/entity/user-data');
  };

  render() {
    const { userDataEntity, roles, communities, loading, updating, uploadedImgUrl, errorMessage, isAdmin, organizations } = this.props;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="digijagApp.userData.home.createOrEditLabel">
              <Translate contentKey="digijagApp.userData.home.createOrEditLabel">Create or edit a UserData</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : userDataEntity ? (
              <AvForm model={userDataEntity} onValidSubmit={this.saveEntity}>
                <AvGroup>
                  <Label for="user-data-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="user-data-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
                <AvGroup>
                  <Label id="loginLabel" for="login">
                    <Translate contentKey="userManagement.login">Username</Translate>
                  </Label>
                  <AvField
                    id="login"
                    type="text"
                    name="userLogin"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate('register.messages.validate.login.required')
                      },
                      pattern: {
                        value: '^[_.@A-Za-z0-9-öäåÖÄÅ]*$',
                        errorMessage: translate('register.messages.validate.login.pattern')
                      },
                      minLength: {
                        value: APP_USERNAME_LENGTH.min,
                        errorMessage: translate('register.messages.validate.login.minlength', { length: APP_USERNAME_LENGTH.min })
                      },
                      maxLength: {
                        value: APP_USERNAME_LENGTH.max,
                        errorMessage: translate('register.messages.validate.login.maxlength', { length: APP_USERNAME_LENGTH.max })
                      }
                    }}
                    value={userDataEntity.userLogin}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="email">
                    <Translate contentKey="userManagement.email">Email</Translate>
                  </Label>
                  <AvField
                    id="email"
                    name="userEmail"
                    type="email"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate('global.messages.validate.email.required')
                      },
                      email: {
                        errorMessage: translate('global.messages.validate.email.invalid')
                      },
                      minLength: {
                        value: APP_EMAIL_LENGTH.min,
                        errorMessage: translate('global.messages.validate.email.minlength', { length: APP_EMAIL_LENGTH.min })
                      },
                      maxLength: {
                        value: APP_EMAIL_LENGTH.max,
                        errorMessage: translate('global.messages.validate.email.maxlength', { length: APP_EMAIL_LENGTH.max })
                      }
                    }}
                    value={userDataEntity.userEmail}
                  />
                </AvGroup>
                {isAdmin && (
                  <>
                    <AvGroup>
                      <Label id="zoomUserIdLabel" for="zoomUserId">
                        <Translate contentKey="digijagApp.userData.editZoomUserId">Zoom user ID (Don't change this!)</Translate>
                      </Label>
                      <AvField id="zoomUserId" name="zoomUserId" type="text" value={userDataEntity.zoomUserId} />
                    </AvGroup>
                    <AvGroup>
                      <Label id="zoomApiKey" for="zoomApiKey>">
                        <Translate contentKey="digijagApp.userData.editZoomApiKey">
                          Zoom Api Key ID (Only use this if your Zoom account does not belong to Digijag community)
                        </Translate>
                      </Label>
                      <AvField id="zoomApiKey" name="zoomApiKey" type="text" value={userDataEntity.zoomApiKey} />
                    </AvGroup>

                    <AvGroup>
                      <Label id="zoomApiSecret" for="zoomApiSecret>">
                        <Translate contentKey="digijagApp.userData.editZoomSecret">
                          Zoom Secret (Only use this if your Zoom account does not belong to Digijag community)
                        </Translate>
                      </Label>
                      <AvField id="zoomApiSecret" name="zoomApiSecret" type="text" value={userDataEntity.zoomApiSecret} />
                    </AvGroup>
                  </>
                )}
                <AvGroup>
                  <Label id="phoneNumberLabel" for="phoneNumber">
                    <Translate contentKey="digijagApp.userData.phoneNumber">Phone number</Translate>
                  </Label>
                  <AvField id="phoneNumber" name="phoneNumber" type="text" value={userDataEntity.phoneNumber} />
                </AvGroup>
                <AvGroup>
                  <Label id="firstNameLabel" for="firstName">
                    <Translate contentKey="digijagApp.userData.firstName">First name</Translate>
                  </Label>
                  <AvField id="firstName" name="userFirstName" type="text" value={userDataEntity.userFirstName} />
                </AvGroup>
                <AvGroup>
                  <Label id="lastNameLabel" for="lastName">
                    <Translate contentKey="digijagApp.userData.lastName">Last name</Translate>
                  </Label>
                  <AvField id="lastName" name="userLastName" type="text" value={userDataEntity.userLastName} />
                </AvGroup>
                <AvGroup>
                  <Label id="ssn" for="ssn">
                    <Translate contentKey="digijagApp.userData.ssn">Social security number</Translate>
                  </Label>
                  <AvField
                    id="ssn"
                    name="ssn"
                    type="text"
                    value={userDataEntity.ssn}
                    // The pattern below forces non-null ssn values but we want optional ssn so we can't have it
                    // pattern="^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\-[0-9]{4}$"
                    placeholder="YYYYMMDD-XXXX"
                  />
                </AvGroup>
                <AvGroup>
                  <Label for="authorities">
                    <Translate contentKey="userManagement.profiles">Roles</Translate>
                  </Label>
                  <AvInput id="authorities" type="select" className="form-control" name="userAuthorities" value={userDataEntity.userAuthorities} multiple>
                    {roles.map(role => (
                      <option value={role} key={role}>
                        {role}
                      </option>
                    ))}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="user-data-community">
                    <Translate contentKey="digijagApp.userData.community">Community</Translate>
                  </Label>
                  <AvInput
                    id="user-data-community"
                    type="select"
                    multiple
                    className="form-control"
                    name="communities"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: translate('global.messages.validate.community.required')
                      }
                    }}
                    value={userDataEntity.communities && userDataEntity.communities.map(e => e.id)}
                  >
                    {communities
                      ? communities.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="user-data-organizations">
                    <Translate contentKey="digijagApp.community.organization">Organization</Translate>
                  </Label>
                  <AvInput
                    id="user-data-organizations"
                    type="select"
                    multiple
                    className="form-control"
                    name="organizations"
                    value={userDataEntity.organizations && userDataEntity.organizations.map(e => e.id)}
                  >
                    {organizations
                      ? organizations.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.name}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup required={false}>
                  <ImageInput
                    id="profileImageInput"
                    imgUrl={uploadedImgUrl || userDataEntity.userImageUrl}
                    title={translate('digijagApp.userData.profileImage')}
                  />
                </AvGroup>
                {errorMessage && (
                  <Row>
                    <Alert color="danger" className="mt-3 ml-3">
                      <Translate contentKey={errorMessage.response.data.message.toString()}>Failed to update user!</Translate>
                    </Alert>
                  </Row>
                )}
                <Button tag={Link} id="cancel-save" to="/entity/user-data" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </AvForm>
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  roles: storeState.userManagement.authorities,
  communities: storeState.community.entities,
  organizations: storeState.organization.entities,
  userDataEntity: storeState.userData.entity,
  loading: storeState.userData.loading,
  updating: storeState.userData.updating,
  updateSuccess: storeState.userData.updateSuccess,
  errorMessage: storeState.userData.errorMessage,
  uploadedImgUrl: storeState.fileUpload.downloadUrl,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.SUPERADMIN])
});

const mapDispatchToProps = {
  getRoles,
  getAllCommunities,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  resetFileUpload,
  setDefaultProfileImage,
  getAllOrganizations
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDataUpdate);
