import React, { RefObject } from 'react';
import { Input } from 'reactstrap';
import { Classes } from 'jss';
import { withStyles, createStyles, Theme } from '@material-ui/core';

interface IInputFieldProps {
  classes: Classes;
  type?: string;
  id?: string;
  value?: string;
  className?: string;
  required?: boolean;
  onChange?: Function;
}

export const InputFieldStyles = ({ breakpoints }: Theme) =>
  createStyles({
    inputField: {
      borderRadius: 10,
      backgroundColor: '#e4e4e4',
      boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
      height: '2.8125rem',
      lineHeight: '2.8125rem',
      [breakpoints.down('xs')]: {
        height: '2.5rem',
        lineHeight: '2.5rem'
      },
      '.text-danger &': {
        backgroundColor: '#f3ccdd'
      }
    }
  });

export class InputField extends React.Component<IInputFieldProps> {
  render() {
    const { type, id, required, value, onChange, classes } = this.props;
    return <Input type={type} id={id} className={classes.inputField} onChange={onChange} value={value} required={required} />;
  }
}

export default withStyles(InputFieldStyles)(InputField);
