import axios from 'axios';
import { ICrudPutAction, ICrudDeleteAction, ICrudGetAction, ICrudGetAllAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { defaultValue, IWeek } from 'app/shared/model/fixatiden/week.model';
import { addIdsToActivitiesBeforeSavingWeek } from '../activity/activity.reducer';
import { addIdsToDaysBeforeSavingWeek } from '../day/day.reducer';

export const ACTION_TYPES = {
  FETCH_WEEK_LIST: 'week/FETCH_WEEK_LIST',
  FETCH_WEEK: 'week/FETCH_WEEK',
  CREATE_WEEK: 'week/CREATE_WEEK',
  TRANSFER_WEEKS: 'week/TRANSFER_WEEKS',
  UPDATE_WEEK: 'week/UPDATE_WEEK',
  DELETE_WEEK: 'week/DELETE_WEEK',
  CREATE_WEEK_LOCAL_STATE: 'week/CREATE_WEEK_LOCAL_STATE',
  UPDATE_WEEK_LOCAL_STATE: 'week/UPDATE_WEEK_LOCAL_STATE',
  RESET: 'week/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IWeek>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  createSuccess: false,
  updateSuccess: false,
  transferSuccess: false,
  transferring: false
};

export type WeekState = Readonly<typeof initialState>;

// Reducer

export default (state: WeekState = initialState, action): WeekState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_WEEK_LIST):
    case REQUEST(ACTION_TYPES.FETCH_WEEK):
      return {
        ...state,
        errorMessage: null,
        createSuccess: false,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_WEEK):
    case REQUEST(ACTION_TYPES.UPDATE_WEEK):
    case REQUEST(ACTION_TYPES.DELETE_WEEK):
      return {
        ...state,
        errorMessage: null,
        createSuccess: false,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.TRANSFER_WEEKS):
      return {
        ...state,
        errorMessage: null,
        transferSuccess: false,
        transferring: true
      };
    case FAILURE(ACTION_TYPES.FETCH_WEEK_LIST):
    case FAILURE(ACTION_TYPES.FETCH_WEEK):
    case FAILURE(ACTION_TYPES.CREATE_WEEK):
    case FAILURE(ACTION_TYPES.UPDATE_WEEK):
    case FAILURE(ACTION_TYPES.DELETE_WEEK):
      return {
        ...state,
        loading: false,
        updating: false,
        createSuccess: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.TRANSFER_WEEKS):
      return {
        ...state,
        transferring: false,
        transferSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_WEEK_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_WEEK):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_WEEK): {
      const newEntitiesArray = state.entities.slice();
      newEntitiesArray.push(action.payload.data);
      return {
        ...state,
        updating: false,
        createSuccess: true,
        updateSuccess: false,
        entity: action.payload.data,
        entities: newEntitiesArray
      };
    }
    case SUCCESS(ACTION_TYPES.TRANSFER_WEEKS): {
      // Remove saved entities from sessionStorage so they don't get transferred again.
      window.sessionStorage.removeItem(storageKey);
      return {
        ...state,
        transferring: false,
        transferSuccess: true,
        errorMessage: null,
        entities: action.payload.data
      };
    }
    case SUCCESS(ACTION_TYPES.UPDATE_WEEK):
      return {
        ...state,
        updating: false,
        createSuccess: false,
        updateSuccess: true,
        entity: action.payload.data,
        entities: state.entities.map(entity => (entity.id === action.payload.data.id ? { ...entity, ...action.payload.data } : entity))
      };
    case SUCCESS(ACTION_TYPES.DELETE_WEEK):
      return {
        ...state,
        updating: false,
        createSuccess: false,
        updateSuccess: true,
        entity: {},
        entities: state.entities.filter(entity => entity.id !== action.meta.id)
      };
    case ACTION_TYPES.CREATE_WEEK_LOCAL_STATE: {
      // When set locally without failure/success status
      return {
        ...state,
        updating: false,
        createSuccess: true,
        updateSuccess: false,
        loading: false,
        errorMessage: null,
        entity: action.payload.entity,
        entities: action.payload.updatedEntities
      };
    }
    case ACTION_TYPES.UPDATE_WEEK_LOCAL_STATE: {
      // When set locally without failure/success status
      return {
        ...state,
        updating: false,
        createSuccess: false,
        updateSuccess: true,
        loading: false,
        errorMessage: null,
        entity: action.payload.entity,
        entities: action.payload.updatedEntities
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/tidsplanering/week';
export const storageKey = 'tidsplanering/weeks';

// Actions

export const getEntities: ICrudGetAllAction<IWeek> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_WEEK_LIST,
    payload: axios.get<IWeek>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getAllEntities = () => {
  const requestUrl = `${apiUrl}?page=0&size=10000&sort=id,asc`;
  return {
    type: ACTION_TYPES.FETCH_WEEK_LIST,
    payload: axios.get<IWeek>(`${requestUrl}&cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IWeek> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_WEEK,
    payload: axios.get<IWeek>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IWeek> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_WEEK,
    payload: axios.post<IWeek>(`${apiUrl}?cacheBuster=${new Date().getTime()}`, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IWeek> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/${entity.id}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_WEEK,
    payload: axios.put(requestUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IWeek> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_WEEK,
    payload: axios.delete(requestUrl),
    meta: { id }
  });
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const transferWeeksFromSession = () => async dispatch => {
  const entities: IWeek[] = getEntitiesFromLocalStorage();
  if (!entities || !entities.length) {
    return;
  }
  const result = await dispatch({
    type: ACTION_TYPES.TRANSFER_WEEKS,
    payload: axios.post<IWeek[]>(
      `${apiUrl}/transfer?page=0&size=10000&sort=id,asc&cacheBuster=${new Date().getTime()}`,
      entities.map(entity => cleanEntity(entity))
    )
  });
  return result;
};

//
// Actions for non-logged in users:
//
function getEntitiesFromLocalStorage(): IWeek[] {
  const storedObject = window.sessionStorage.getItem(storageKey);
  return storedObject ? (JSON.parse(storedObject) as IWeek[]) : ([] as IWeek[]);
}

export const hasWeeksInSessionStorage = (): boolean => !!window.sessionStorage.getItem(storageKey);

function storeEntities(entities: IWeek[]) {
  entities = entities.map(week => addIdsToActivitiesBeforeSavingWeek(week));
  entities = entities.map(week => addIdsToDaysBeforeSavingWeek(week));
  window.sessionStorage.setItem(storageKey, JSON.stringify(entities));
}

export const createEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  // Add id as max ID in sessionStorage + 10, or '10' if first object stored
  // We use increments of 10 to allow IDs of days in the week to be 11, 12, ..., 17. next week has id 20, with days 21, 22...27.
  // Activities IDs are handled uniquely since there are no limits on amount of activities in a day.
  entity.id = entities.length ? Math.max(...entities.map(o => o.id)) + 10 : 10;
  entities.push(entity);
  storeEntities(entities);
  return {
    type: ACTION_TYPES.CREATE_WEEK_LOCAL_STATE,
    payload: { entity, updatedEntities: entities }
  };
};

export const updateEntityNoBackend = entity => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.map(storedEntity => (storedEntity.id === entity.id ? { ...storedEntity, ...entity } : storedEntity));
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_WEEK_LOCAL_STATE,
    payload: { entity, updatedEntities }
  };
};

export const deleteEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const updatedEntities = entities.filter(e => e.id !== id);
  storeEntities(updatedEntities);
  return {
    type: ACTION_TYPES.UPDATE_WEEK_LOCAL_STATE,
    payload: {
      entity: null,
      updatedEntities
    }
  };
};

export const getEntitiesNoBackend = () => {
  const entities = getEntitiesFromLocalStorage();
  return {
    type: ACTION_TYPES.UPDATE_WEEK_LOCAL_STATE,
    payload: {
      entity: entities && entities.length ? entities[0] : null,
      updatedEntities: entities
    }
  };
};

export const getEntityNoBackend = id => {
  const entities = getEntitiesFromLocalStorage();
  const entity = entities.find(e => e.id === id);
  return {
    type: ACTION_TYPES.UPDATE_WEEK_LOCAL_STATE,
    payload: {
      entity,
      updatedEntities: entities
    }
  };
};
